import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../../useToken.js';
import prodOrDev from '../../../utils.js';


export function AddAlternativeName() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { threatActorId } = useParams();

  // Data
  const [threatActorAlternativeName, setThreatActorAlternativeName] = useState({
    name_id: '',
    name_threat_actor_id: threatActorId,
    name_value: ''
  });
  const [threatActor, setThreatActor] = useState({
    threat_actor_id: threatActorId,
    threat_actor_name: '',
    threat_actor_short_description: '',
    threat_actor_first_seen_date: '',
    threat_actor_first_seen_date_saying: '',
    threat_actor_country_of_origin_name: '',
    threat_actor_country_of_origin_flag: '',
    threat_actor_country_of_origin_two: '',
    threat_actor_country_of_origin_three: '',
    threat_actor_country_of_attribution_name: '',
    threat_actor_country_of_attribution_flag: '',
    threat_actor_country_of_attribution_two: '',
    threat_actor_country_of_attribution_three: '',
    threat_actor_description: '',
    threat_actor_motivation: '', 
    threat_actor_capability: '',
    threat_actor_tools: '',
    threat_actor_techniques: '',
    threat_actor_infrastructure: '',
    threat_actor_counter_operations: '',
    threat_actor_sources: '',
    threat_actor_mitre_attck_link_name: '',
    threat_actor_mitre_attck_link_url: '',
    threat_actor_dangerous_number: '',
    threat_actor_dangerous_title: '',
    threat_actor_logo_path: '',
    threat_actor_logo_file: '',
    threat_actor_created_date: '',
    threat_actor_created_date_saying: '',
    threat_actor_created_by_user_id: '',
    threat_actor_created_by_display_name: '',
    threat_actor_updated_date: '',
    threat_actor_updated_date_saying: '',
    threat_actor_updated_by_user_id: '',
    threat_actor_updated_by_display_name: ''
  });

  // Load data
  const loadThreatActor = async (e) => {
    let result = await fetch(backendURL + '/api/threat-actors/get-threat-actor?threat_actor_id=' + threatActorId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setThreatActor(resultJson);
      document.getElementById('main_box').style.display = 'block';
    }
  };

  // Form data chaned
  const handleChange = (e) => {
    setThreatActorAlternativeName({
      ...threatActorAlternativeName,
      [e.target.name]: e.target.value
    });
  };

  // Send new
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/threat-actors/new-alternative-name', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(threatActorAlternativeName)
    });
    let resultJson = await result.json();
    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
      
      // Focus
      document.forms['form'].elements['name_value'].focus();

    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadThreatActor();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/intelligence">Intelligence</a>
          &nbsp; / &nbsp;
          <a href={`/intelligence/threat-actors/`}>Threat actor</a>
          &nbsp; / &nbsp;
          <a href={`/intelligence/threat-actors/view-threat-actor/${threatActorId}`}>{threatActor.threat_actor_name}</a>
          &nbsp; / &nbsp;
          <a href={`/intelligence/threat-actors/new-alternative-name/${threatActorId}`}>New alternative name</a>
        </p>
      </div>
      <div className="clear"></div>
      {/* Main */}
      <h1>New alternative name for {threatActor.threat_actor_name}</h1>
      <div className="main_box" id="main_box">
        <form onSubmit={handleSubmit} id="form">

        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
        <p id="feedback_p">Feedback box is here</p>
        </div>

        {/* Form */}
        <p>Name<br />
        <input type="text" name="name_value" value={threatActorAlternativeName.name_value} onChange={handleChange} style={{ minWidth: '50%' }} autoFocus />
        </p>
        <p><button>Submit</button></p>
        </form>

      </div>{' '}
      {/* // main_box */}
    </div>
  );
}

export default AddAlternativeName;
