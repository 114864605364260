import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../../useToken.js';
import prodOrDev from '../../../utils.js';

export function EditCaseCode() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { caseCodeId } = useParams();

  const [caseCode, setCaseCode] = useState({
    case_code_id: caseCodeId, 
    case_code_title: '', 
    case_code_title_full: '', 
    case_code_abbreviation: '', 
    case_code_description: '', 
    case_code_piece_bg_color: '', 
    case_code_piece_text_color: '', 
    case_code_external_id: '', 
    case_code_is_active: '', 
    case_code_created_datetime: '', 
    case_code_created_date_saying: '', 
    case_code_created_by_user_id: '', 
    case_code_created_by_user_name: '',
    case_code_updated_datetime: '',
    case_code_updated_date_saying: '',
    case_code_updated_by_user_id: '',
    case_code_updated_by_user_name: ''
  });

  // Load 
  const loadData = async (e) => {
    let result = await fetch(backendURL + '/api/case-codes/get-case-code?case_code_id=' + caseCodeId, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setCaseCode(resultJson);

      // Display form (last element is loaded)
      document.getElementById('form').style.display = 'block';
    }
  };

  // Form data chaned
  const handleChange = (e) => {
    setCaseCode({
      ...caseCode,
      [e.target.name]: e.target.value
    });
  };

  // Send new identifier
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/case-codes/edit-case-code', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(caseCode)
    });
    let resultJson = await result.json();
    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadData();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/">Settings</a>
          &nbsp; / &nbsp;
          <a href={`/settings/case-codes`}>Case Codes</a>
          &nbsp; / &nbsp;
          <a href={`/settings/case-codes/edit-case-code/${caseCode.case_code_id}`}>Edit Case Code</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>Edit case code {caseCode.case_code_title_full}</h1>
      <form onSubmit={handleSubmit} id="form" style={{ display: 'none' }}>
        <div className="main_box">
          {/* Feedback */}
          <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
            <p id="feedback_p">Feedback box is here</p>
          </div>

          {/* Form */}

          <p>Title:<br />
          <input type="text" name="case_code_title" value={caseCode.case_code_title} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>Title full:<br />
          <input type="text" name="case_code_title_full" value={caseCode.case_code_title_full} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>Abbreviation:<br />
          <input type="text" name="case_code_abbreviation" value={caseCode.case_code_abbreviation} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>


          <p>Description:<br />
          <input type="text" name="case_code_description" value={caseCode.case_code_description} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>Piece BG color:<br />
          <input type="text" name="case_code_piece_bg_color" value={caseCode.case_code_piece_bg_color} onChange={handleChange} size="7" />
          </p>

          <p>Piece Text color:<br />
          <input type="text" name="case_code_piece_text_color" value={caseCode.case_code_piece_text_color} onChange={handleChange} size="7" />
          </p>

          <p>External ID:<br />
          <input type="text" name="case_code_external_id" value={caseCode.case_code_external_id} onChange={handleChange} size="7" />
          </p>


          <p>Is active?:<br />
          <select name="case_code_is_active" value={caseCode.case_code_is_active} onChange={handleChange}>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
          </p>


          <p>
          <button>Submit</button>
          </p>
        </div>
      </form>
    </div>
  );
}
export default EditCaseCode;
