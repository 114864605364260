import React from 'react';

export function NewEngagementFortraSecpod(props) {
  return (
    <div id={props.serviceName.replace(' ', '_').toLowerCase()}>
      <h2 style={{ padding: '20px 0px 0px 0px' }}>{props.serviceName}</h2>
      <p>
        Number of licenses
        <br />
        <input type="text" name="status_ordered_licenses_value" value={props.engagementStatus.status_ordered_licenses_value} onChange={props.handleChange} size={4} />
      </p>

      <p>
        Method of installation of agents
        <br />
        <select name="status_agents_method" value={props.engagementStatus.status_agents_method} onChange={props.handleChange}>
          <option value="N/A">Please select</option>
          <option value="change">Change</option>
          <option value="email">Email</option>
          <option value="cube">Cube</option>
        </select>
      </p>

      <p>
        Agents method email to
        <br />
        <input type="text" name="status_agents_method_email_to" value={props.engagementStatus.status_agents_method_email_to} onChange={props.handleChange} size={25} />
      </p>
    </div>
  );
}

export default NewEngagementFortraSecpod;