import React from 'react';

export const NewEngagementLC = (props) => {
  return (
    <div id="limacharlie">
      <h2 style={{ padding: '20px 0px 0px 0px' }}>LimaCharlie</h2>

      <p style={{ marginBottom: '0px', paddingBottom: '0px' }}>
        <b>Remiders:</b>
      </p>
      <ul style={{ margin: '0px 0px 5px 30px', padding: '0px 0px 0px 0px' }}>
        <li>
          <span>Organization exists: Add LC organization ID to Identifiers</span>
        </li>
        <li>
          <span>Advania customer number should be added to identifiers if present7</span>
        </li>
      </ul>

      <p>
        Method of installation of sensors and ingest
        <br />
        <select name="status_sensors_and_ingest_method" value={props.engagementStatus.status_sensors_and_ingest_method} onChange={props.handleChange}>
          <option value="N/A">Please select</option>
          <option value="change">Change</option>
          <option value="email">Email</option>
          <option value="cube">Cube</option>
        </select>
      </p>

      {props.engagementStatus.status_sensors_and_ingest_method == 'email' ? (
        <p>
          Sensors and ingest method email to
          <br />
          <span className="small">
            This is the person(s) that will install LimaCharlie Sensor and Ingest
            <br />
            More emails can be seperated with comma.
          </span>
          <input type="text" name="status_sensors_and_ingest_method_email_to" value={props.engagementStatus.status_sensors_and_ingest_method_email_to} onChange={props.handleChange} size={25} style={{ width: '99%' }} placeholder="kari@cdcadvania.com, kari.nordmann@advania.no" />
        </p>
      ) : (
        <></>
      )}

      <p>
        Sensors and ingest scope
        <br />
        <textarea name="status_sensors_and_ingest_method_scope" value={props.engagementStatus.status_sensors_and_ingest_method_scope} onChange={props.handleChange} rows={10} cols={60} style={{ width: '99%' }} />
      </p>

      <p>
        Sensors and ingest method invocing details
        <br />
        <span className="small">Example: Invoice customer as normal</span>
        <input type="text" name="status_sensors_and_ingest_method_invoicing" value={props.engagementStatus.status_sensors_and_ingest_method_invoicing} onChange={props.handleChange} size={25} style={{ width: '99%' }} />
      </p>
    </div>
  );
};

export default NewEngagementLC;