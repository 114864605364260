import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../../useToken.js';
import prodOrDev from '../../../utils.js';


export function NewThreatActor() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();

  // Data
  const [threatActor, setThreatActor] = useState({
    threat_actor_id: '',
    threat_actor_name: '',
    threat_actor_short_description: ''
  });

  // Form data chaned
  const handleChange = (e) => {
    setThreatActor({
      ...threatActor,
      [e.target.name]: e.target.value
    });
  };

  // Send new
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/threat-actors/new-threat-actor', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(threatActor)
    });
    let resultJson = await result.json();
    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
      window.history.back();
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    }
  };


  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/intelligence">Intelligence</a>
          &nbsp; / &nbsp;
          <a href={`/intelligence/threat-actors/`}>Threat actor</a>
          &nbsp; / &nbsp;
          <a href={`/intelligence/threat-actors/new-threat-actor`}>New threat actor</a>
        </p>
      </div>
      <div className="clear"></div>
      {/* Main */}
      <h1>New threat actor</h1>
      <div className="main_box" id="main_box">
        <form onSubmit={handleSubmit} id="form">

        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
        <p id="feedback_p">Feedback box is here</p>
        </div>

        {/* Form */}
        <p>Name<br />
        <input type="text" name="threat_actor_name" value={threatActor.threat_actor_name} onChange={handleChange} style={{ minWidth: '50%' }} autoFocus />
        </p>

        <p>Short description<br />
        <textarea name="threat_actor_short_description" value={threatActor.threat_actor_short_description} onChange={handleChange} style={{ minWidth: '50%' }}></textarea>
        </p>

        <p><button>Submit</button></p>
        </form>

      </div>{' '}
      {/* // main_box */}
    </div>
  );
}

export default NewThreatActor;
