import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../../useToken.js';
import prodOrDev from '../../../utils.js';

export function APIPermissionInfo() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { permissionId } = useParams();

  const [api, setAPI] = useState({
    api_id: '',
    api_title: '',
    api_description: '',
    api_valid_to_date: '',
    api_valid_to_date_saying: '',
    api_valid_to_warning_sent: '',
    api_created_timestamp: '',
    api_created_date_saying: '',
    api_created_by_user_email: '',
    api_created_by_full_name: '',
    api_updated_timestamp: '',
    api_updated_date_saying: '',
    api_updated_by_user_email: '',
    api_updated_by_full_name: ''
  });
  const [apiPermission, setAPIPermission] = useState({
    permission_id: permissionId,
    permission_api_id: '',
    permission_route: '',
    permission_url: '',
    permission_read_write_delete: '',
    permission_reason: ''
  });

  // Load API Permission
  const loadAPIPermission = async (e) => {
    let result = await fetch(backendURL + '/api/apis/get_api_permission', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify({
        permission_id: permissionId
      })
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setAPIPermission(resultJson);
      // Display form (last element is loaded)
      document.getElementById('form').style.display = 'block';
      apiPermission.permission_api_id = resultJson['permission_api_id'];
      loadAPI();
    }
  };

  // Load API
  const loadAPI = async (e) => {
    let result = await fetch(backendURL + '/api/apis/get_api', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify({
        api_id: apiPermission.permission_api_id
      })
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setAPI(resultJson);
    }
  };

  // Confirm delete
  async function confirmDelete(e) {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/apis/delete_api_permission', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify({
        permission_id: permissionId
      })
    });
    let resultJson = await result.json();

    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      window.history.back();
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
    }
  }
  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadAPIPermission();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/">Settings</a>
          &nbsp; / &nbsp;
          <a href={`/settings/apis`}>APIs</a>
          &nbsp; / &nbsp;
          <a href={`/settings/apis/view_api/${apiPermission.permission_api_id}`}>{api.api_title}</a>
          &nbsp; / &nbsp;
          <a href={`/settings/apis/api_permissions_index/${apiPermission.permission_api_id}`}>API Permissions {api.api_title}</a>
          &nbsp; / &nbsp;
          <a href={`/settings/apis/test_api_permission/${apiPermission.permission_id}`}>Test API Permissions</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>Test API permission {apiPermission.permission_id}</h1>
      <div className="main_box" id="form" style={{ display: 'none' }}>
        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        {/* Form */}
        <p>To test the API permission do the following:</p>

        <p>
          <b>1. Download Postman</b>
          <br />
          https://www.postman.com/downloads/
        </p>

        <p>
          <b>2. In Postman Create Collection</b>
          <br />
          <em>New collection name:</em> cdc-24-api-prod
        </p>

        {apiPermission.permission_route === 'auto-deploy-limacharlie/new_connection_string' ? (
          <div>
            <p>Depricated!</p>
          </div>
        ) : apiPermission.permission_route === 'auto-deploy-limacharlie/get_connection_strings' ? (
          <div>
            <p>Depricated!</p>
          </div>
        ) : apiPermission.permission_route === 'cdc-24-backend/customers_api/get_customer_abbreviation_and_contacts_for_name' ? (
          <div style={{ background: '#eeeeee', border: '#cccccc 1px solid', padding: '5px' }}>
            <p>
              <em>Headers:</em>
            </p>
            <ul>
              <li>
                <span>
                  <b>Authorization:</b> ApiKey
                </span>
              </li>
              <li>
                <span>
                  <b>Content-Type:</b> application/json
                </span>
              </li>
            </ul>
            &#123;
            <br />
            &nbsp; &quot;customer_name&quot;: &quot;Gorgon Shipping&quot;
            <br />
            &#125;
          </div>
        ) : apiPermission.permission_route === 'cdc-24-backend/customers_api/get_customer_contacts_for_threshold_and_case_codes' ? (
          <div style={{ background: '#eeeeee', border: '#cccccc 1px solid', padding: '5px' }}>
            <p>
              <em>Headers:</em>
            </p>
            <ul>
              <li>
                <span>
                  <b>Authorization:</b> ApiKey
                </span>
              </li>
              <li>
                <span>
                  <b>Content-Type:</b> application/json
                </span>
              </li>
            </ul>
            &#123;
            <br />
            &nbsp; &quot;customer_name&quot;: &quot;Gorgon Shipping&quot;,
            <br />
            &nbsp; &quot;threshold&quot;: &quot;amber&quot;,
            <br />
            &nbsp; &quot;case_code&quot;: &quot;INC: Incident&quot;
            <br />
            &#125;
          </div>
        ) : apiPermission.permission_route === 'cdc-24-backend/customers_api/update_requested_participants_based_on_jira_key' ? (
          <div style={{ background: '#eeeeee', border: '#cccccc 1px solid', padding: '5px' }}>
            <p>
              <em>Headers:</em>
            </p>
            <ul>
              <li>
                <span>
                  <b>Authorization:</b> ApiKey
                </span>
              </li>
              <li>
                <span>
                  <b>Content-Type:</b> application/json
                </span>
              </li>
            </ul>
            &#123;
            <br />
            &nbsp; &quot;jira_key&quot;: &quot;CDC-5881&quot; &#125;
          </div>
        ) : apiPermission.permission_route === 'cdc-24-backend/customers_api/get_customers_names_abbreviations_operational_status_urls_and_identifiers' ? (
          <div>
            <p>
              <b>3. In Postman Create Request</b>
            </p>
            <ul>
              <li>
                <span>
                  <b>New request name:</b> {apiPermission.permission_route}
                </span>
              </li>
              <li>
                <span>
                  <b>Method:</b> GET
                </span>
              </li>
              <li>
                <span>
                  <b>URL:</b> {apiPermission.permission_url}
                </span>
              </li>
            </ul>

            <p>
              <em>Headers:</em>
            </p>
            <ul>
              <li>
                <span>
                  <b>Authorization:</b> ApiKey
                </span>
              </li>
              <li>
                <span>
                  <b>Content-Type:</b> application/json
                </span>
              </li>
            </ul>
            <p>
              <em>Body -&gt; raw:</em>
            </p>
            <div style={{ background: '#eeeeee', border: '#cccccc 1px solid', padding: '5px' }}></div>
          </div>
        ) : apiPermission.permission_route === 'cdc-24-backend/customers_api/get_customers_names_abbreviations_operational_status_urls_identifiers_and_contacts' ? (
          <div>
            <p>
              <b>3. In Postman Create Request</b>
            </p>
            <ul>
              <li>
                <span>
                  <b>New request name:</b> {apiPermission.permission_route}
                </span>
              </li>
              <li>
                <span>
                  <b>Method:</b> GET
                </span>
              </li>
              <li>
                <span>
                  <b>URL:</b> {apiPermission.permission_url}
                </span>
                <br />
                Optional GET parameters:
                <br />
                ?customer_number=AdvaniaCustomerNumber
              </li>
            </ul>

            <p>
              <em>Headers:</em>
            </p>
            <ul>
              <li>
                <span>
                  <b>Authorization:</b> ApiKey
                </span>
              </li>
              <li>
                <span>
                  <b>Content-Type:</b> application/json
                </span>
              </li>
            </ul>
            <p>
              <em>Body -&gt; raw:</em>
            </p>
            <div style={{ background: '#eeeeee', border: '#cccccc 1px solid', padding: '5px' }}></div>
          </div>
        ) : apiPermission.permission_route === 'cdc-24-backend/customers_api/get_soc_alerts_index' ? (
          <div>
            <p>
              <b>3. In Postman Create Request</b>
            </p>
            <ul>
              <li>
                <span>
                  <b>New request name:</b> {apiPermission.permission_route}
                </span>
              </li>
              <li>
                <span>
                  <b>Method:</b> GET
                </span>
              </li>
              <li>
                <span>
                  <b>URL:</b> {apiPermission.permission_url}
                </span>
                <br />
                Optional GET parameters:
                <br />
                ?customer_number=AdvaniaCustomerNumber
              </li>
            </ul>

            <p>
              <em>Headers:</em>
            </p>
            <ul>
              <li>
                <span>
                  <b>Authorization:</b> ApiKey
                </span>
              </li>
              <li>
                <span>
                  <b>Content-Type:</b> application/json
                </span>
              </li>
            </ul>
            <p>
              <em>Body -&gt; raw:</em>
            </p>
            <div style={{ background: '#eeeeee', border: '#cccccc 1px solid', padding: '5px' }}></div>
          </div>
        ) : apiPermission.permission_route === 'cdc-24-backend/customers_api/get_soc_tickets_index' ? (
          <div>
            <p>
              <b>3. In Postman Create Request</b>
            </p>
            <ul>
              <li>
                <span>
                  <b>New request name:</b> {apiPermission.permission_route}
                </span>
              </li>
              <li>
                <span>
                  <b>Method:</b> GET
                </span>
              </li>
              <li>
                <span>
                  <b>URL:</b> {apiPermission.permission_url}
                </span>
                <br />
                Optional GET parameters:
                <br />
                ?customer_number=AdvaniaCustomerNumber
              </li>
            </ul>

            <p>
              <em>Headers:</em>
            </p>
            <ul>
              <li>
                <span>
                  <b>Authorization:</b> ApiKey
                </span>
              </li>
              <li>
                <span>
                  <b>Content-Type:</b> application/json
                </span>
              </li>
            </ul>
            <p>
              <em>Body -&gt; raw:</em>
            </p>
            <div style={{ background: '#eeeeee', border: '#cccccc 1px solid', padding: '5px' }}></div>
          </div>
        ) : apiPermission.permission_route === 'cdc-24-backend/customers_api/get_soc_cases_case_codes' ? (
          <div>
            <p>
              <b>3. In Postman Create Request</b>
            </p>
            <ul>
              <li>
                <span>
                  <b>New request name:</b> {apiPermission.permission_route}
                </span>
              </li>
              <li>
                <span>
                  <b>Method:</b> GET
                </span>
              </li>
              <li>
                <span>
                  <b>URL:</b> {apiPermission.permission_url}
                </span>
              </li>
            </ul>

            <p>
              <em>Headers:</em>
            </p>
            <ul>
              <li>
                <span>
                  <b>Authorization:</b> ApiKey
                </span>
              </li>
              <li>
                <span>
                  <b>Content-Type:</b> application/json
                </span>
              </li>
            </ul>
            <p>
              <em>Body -&gt; raw:</em>
            </p>
            <div style={{ background: '#eeeeee', border: '#cccccc 1px solid', padding: '5px' }}></div>
          </div>
        ) : apiPermission.permission_route === 'cdc-24-backend/customers_api/get_soc_cases_classifications' ? (
          <div>
            <p>
              <b>3. In Postman Create Request</b>
            </p>
            <ul>
              <li>
                <span>
                  <b>New request name:</b> {apiPermission.permission_route}
                </span>
              </li>
              <li>
                <span>
                  <b>Method:</b> GET
                </span>
              </li>
              <li>
                <span>
                  <b>URL:</b> {apiPermission.permission_url}
                </span>
              </li>
            </ul>

            <p>
              <em>Headers:</em>
            </p>
            <ul>
              <li>
                <span>
                  <b>Authorization:</b> ApiKey
                </span>
              </li>
              <li>
                <span>
                  <b>Content-Type:</b> application/json
                </span>
              </li>
            </ul>
            <p>
              <em>Body -&gt; raw:</em>
            </p>
            <div style={{ background: '#eeeeee', border: '#cccccc 1px solid', padding: '5px' }}></div>
          </div>
        ) : apiPermission.permission_route === 'cdc-24-backend/customers_api/get_soc_intelligence_assessments' ? (
          <div>
            <p>
              <b>3. In Postman Create Request</b>
            </p>
            <ul>
              <li>
                <span>
                  <b>New request name:</b> {apiPermission.permission_route}
                </span>
              </li>
              <li>
                <span>
                  <b>Method:</b> GET
                </span>
              </li>
              <li>
                <span>
                  <b>URL:</b> {apiPermission.permission_url}
                </span>
              </li>
            </ul>

            <p>
              <em>Headers:</em>
            </p>
            <ul>
              <li>
                <span>
                  <b>Authorization:</b> ApiKey
                </span>
              </li>
              <li>
                <span>
                  <b>Content-Type:</b> application/json
                </span>
              </li>
            </ul>
            <p>
              <em>Body -&gt; raw:</em>
            </p>
            <div style={{ background: '#eeeeee', border: '#cccccc 1px solid', padding: '5px' }}></div>
          </div>
        ) : apiPermission.permission_route === 'cdc-24-backend/customers_api/get_stats_alerts_cases_tickets_all_customers_per_month' ? (
          <div>
            <p>
              <b>3. In Postman Create Request</b>
            </p>
            <ul>
              <li>
                <span>
                  <b>New request name:</b> {apiPermission.permission_route}
                </span>
              </li>
              <li>
                <span>
                  <b>Method:</b> GET
                </span>
              </li>
              <li>
                <span>
                  <b>URL:</b> {apiPermission.permission_url}
                </span>
              </li>
            </ul>

            <p>
              <em>Headers:</em>
            </p>
            <ul>
              <li>
                <span>
                  <b>Authorization:</b> ApiKey
                </span>
              </li>
              <li>
                <span>
                  <b>Content-Type:</b> application/json
                </span>
              </li>
            </ul>
            <p>
              <em>Body -&gt; raw:</em>
            </p>
            <div style={{ background: '#eeeeee', border: '#cccccc 1px solid', padding: '5px' }}></div>
          </div>
        ) : apiPermission.permission_route === 'cdc-24-backend/customers_api/get_stats_alerts_cases_tickets_per_customers_per_month' ? (
          <div>
            <p>
              <b>3. In Postman Create Request</b>
            </p>
            <ul>
              <li>
                <span>
                  <b>New request name:</b> {apiPermission.permission_route}
                </span>
              </li>
              <li>
                <span>
                  <b>Method:</b> GET
                </span>
              </li>
              <li>
                <span>
                  <b>URL:</b> {apiPermission.permission_url}
                </span>
                <br />
                Optional GET parameters:
                <br />
                ?customer_number=AdvaniaCustomerNumber
                <br />
                &month=2&year=2024
              </li>
            </ul>

            <p>
              <em>Headers:</em>
            </p>
            <ul>
              <li>
                <span>
                  <b>Authorization:</b> ApiKey
                </span>
              </li>
              <li>
                <span>
                  <b>Content-Type:</b> application/json
                </span>
              </li>
            </ul>
            <p>
              <em>Body -&gt; raw:</em>
            </p>
            <div style={{ background: '#eeeeee', border: '#cccccc 1px solid', padding: '5px' }}></div>
          </div>
        ) : apiPermission.permission_route === 'cdc-24-backend/customers_api/get_stats_cases_case_codes_all_customers_per_month' ? (
          <div>
            <p>
              <b>3. In Postman Create Request</b>
            </p>
            <ul>
              <li>
                <span>
                  <b>New request name:</b> {apiPermission.permission_route}
                </span>
              </li>
              <li>
                <span>
                  <b>Method:</b> GET
                </span>
              </li>
              <li>
                <span>
                  <b>URL:</b> {apiPermission.permission_url}
                </span>
                <br />
                GET parameters:
                <br />
                ?month=2&year=2024
              </li>
            </ul>

            <p>
              <em>Headers:</em>
            </p>
            <ul>
              <li>
                <span>
                  <b>Authorization:</b> ApiKey
                </span>
              </li>
              <li>
                <span>
                  <b>Content-Type:</b> application/json
                </span>
              </li>
            </ul>
            <p>
              <em>Body -&gt; raw:</em>
            </p>
            <div style={{ background: '#eeeeee', border: '#cccccc 1px solid', padding: '5px' }}></div>
          </div>
        ) : apiPermission.permission_route === 'cdc-24-backend/customers_api/get_stats_cases_case_codes_per_customer_per_month' ? (
          <div>
            <p>
              <b>3. In Postman Create Request</b>
            </p>
            <ul>
              <li>
                <span>
                  <b>New request name:</b> {apiPermission.permission_route}
                </span>
              </li>
              <li>
                <span>
                  <b>Method:</b> GET
                </span>
              </li>
              <li>
                <span>
                  <b>URL:</b> {apiPermission.permission_url}
                </span>
                <br />
                GET parameters:
                <br />
                ?customer_id= OR ?customer_number=
                <br />
                Optional GET parameters:
                <br />
                ?month=2&year=2024
              </li>
            </ul>

            <p>
              <em>Headers:</em>
            </p>
            <ul>
              <li>
                <span>
                  <b>Authorization:</b> ApiKey
                </span>
              </li>
              <li>
                <span>
                  <b>Content-Type:</b> application/json
                </span>
              </li>
            </ul>
            <p>
              <em>Body -&gt; raw:</em>
            </p>
            <div style={{ background: '#eeeeee', border: '#cccccc 1px solid', padding: '5px' }}></div>
          </div>
        ) : apiPermission.permission_route === 'cdc-24-backend/customers_api/get_stats_cases_classifications_all_customers_per_month' ? (
          <div>
            <p>
              <b>3. In Postman Create Request</b>
            </p>
            <ul>
              <li>
                <span>
                  <b>New request name:</b> {apiPermission.permission_route}
                </span>
              </li>
              <li>
                <span>
                  <b>Method:</b> GET
                </span>
              </li>
              <li>
                <span>
                  <b>URL:</b> {apiPermission.permission_url}
                </span>
                <br />
                GET parameters:
                <br />
                ?month=2&year=2024
              </li>
            </ul>

            <p>
              <em>Headers:</em>
            </p>
            <ul>
              <li>
                <span>
                  <b>Authorization:</b> ApiKey
                </span>
              </li>
              <li>
                <span>
                  <b>Content-Type:</b> application/json
                </span>
              </li>
            </ul>
            <p>
              <em>Body -&gt; raw:</em>
            </p>
            <div style={{ background: '#eeeeee', border: '#cccccc 1px solid', padding: '5px' }}></div>
          </div>
        ) : apiPermission.permission_route === 'cdc-24-backend/customers_api/get_stats_cases_classifications_per_customer_per_month' ? (
          <div>
            <p>
              <b>3. In Postman Create Request</b>
            </p>
            <ul>
              <li>
                <span>
                  <b>New request name:</b> {apiPermission.permission_route}
                </span>
              </li>
              <li>
                <span>
                  <b>Method:</b> GET
                </span>
              </li>
              <li>
                <span>
                  <b>URL:</b> {apiPermission.permission_url}
                </span>
                <br />
                GET parameters:
                <br />
                ?customer_id= OR ?customer_number=
                <br />
                ?month=2&year=2024
              </li>
            </ul>

            <p>
              <em>Headers:</em>
            </p>
            <ul>
              <li>
                <span>
                  <b>Authorization:</b> ApiKey
                </span>
              </li>
              <li>
                <span>
                  <b>Content-Type:</b> application/json
                </span>
              </li>
            </ul>
            <p>
              <em>Body -&gt; raw:</em>
            </p>
            <div style={{ background: '#eeeeee', border: '#cccccc 1px solid', padding: '5px' }}></div>
          </div>
        ) : (
          <span>Unknown route</span>
        )}
      </div>
    </div>
  );
}
export default APIPermissionInfo;
