import { useContext, useEffect, useState } from 'react';
import useToken from '../../../useToken.js';
import parse from 'html-react-parser';
import prodOrDev from '../../../utils.js';

export function AssessmentsIndex() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const [assessments, setAssessments] = useState(() => []);

  // Load data
  const loadData = async (e) => {
    let result = await fetch(backendURL + '/api/intelligence/get-intelligence-assessments', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const resultJson = await result.json();
    if (result.status === 200) {
      setAssessments(
        resultJson.map((fetch_object) => {
          return fetch_object;
        })
      );
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadData();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/intelligence">Intelligence</a>
          &nbsp; / &nbsp;
          <a href="/intelligence/assessments">Assessments</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>Intelligence Assessments</h1>
      <div className="main_box">
        {/* Actions */}
        <p><a href="/intelligence/assessments/new-assessment" className="btn_default">New assessment</a></p>

        <p>Assessments on monthly report is ordered by assessment_id ASC. This means that the report will be in the reverse order as seen here. In Wolf the order will be assessment_id DESC.</p>

        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        {/* Table */}
        {assessments.map((item, index) => (
          <div>
            <p><hr /></p>

            {/* Actions */}
            <div style={{ float: 'right', paddingLeft: '10px' }}>
              <p>
              <a href={`/intelligence/assessments/edit-assessment/${item.assessment_id}`}><img src="/gfx/icons/20x20/edit_rounded_fill_black_20x20.svg" alt="edit_rounded_fill_black_20x20.svg" /></a>
              &nbsp;
              <a href={`/intelligence/assessments/edit-assessment-image/${item.assessment_id}`}><img src="/gfx/icons/20x20/imagesmode_outlined_black_20x20.svg" alt="imagesmode_outlined_black_20x20.svg" /></a>
              &nbsp;
              <a href={`/intelligence/assessments/delete-assessment/${item.assessment_id}`}><img src="/gfx/icons/20x20/delete_rounded_fill_black_20x20.svg" alt="edit_rounded_fill_black_20x20.svg" /></a>
              </p>
            </div>

            {/* Image
            {item.assessment_intro_image_file ? (
              <p style={{color: "darkgrey"}}>
              <a href={`/intelligence/assessments/view-assessment/${item.assessment_id}`}><img id="assessment_intro_image_file_img" src="" alt={`${item.assessment_intro_image_file}`}  /></a><br />
              {item.assessment_intro_image_text}
              </p>
            ) : (
              <span></span>
            )} */}



            {/* TLP */}
            <div style={{ float: 'right', paddingLeft: '10px' }}>
              <p>
              {item.assessment_tlp === 'CLEAR' || item.assessment_tlp === 'TLP:CLEAR' ? <span className="tlp_clear">{item.assessment_tlp}</span> : item.assessment_tlp === 'GREEN' || item.assessment_tlp === 'TLP:GREEN' ? <span className="tlp_green">{item.assessment_tlp}</span> : item.assessment_tlp === 'AMBER' || item.assessment_tlp === 'TLP:AMBER' ? <span className="tlp_amber">{item.assessment_tlp}</span> : item.assessment_tlp === 'AMBER+STRICT' || item.assessment_tlp === 'TLP:AMBER+STRICT' ? <span className="tlp_amber">{item.assessment_tlp}</span> : item.assessment_tlp === 'RED' || item.assessment_tlp === 'TLP:RED' ? <span className="tlp_red">{item.assessment_tlp}</span> : <span>?{item.assessment_tlp}?</span>}
              </p>
            </div>

            {/* Headline */}
            {item.assessment_alternative_title_value === '' ? (
              <a href={`/intelligence/assessments/view-assessment/${item.assessment_id}`}><h2 style={{paddingLeft: "0"}}>{item.assessment_title}</h2></a>
            ) : (
              <a href={`/intelligence/assessments/view-assessment/${item.assessment_id}`}><h2 style={{paddingLeft: "0"}}><span style={{color: item.assessment_alternative_title_color}}>{item.assessment_alternative_title_value}</span> {item.assessment_title}</h2></a>
            )}

            {/* Date */}
            <span>{item.assessment_created_date_saying}</span>

            {/* Introduction */}
            {typeof item.assessment_introduction == 'string' ? (
              <div>{parse(item?.assessment_introduction)}</div>
            ) : (
              <div></div>
            )}


          </div>
        ))}
      </div>
    </div>
  );
}

export default AssessmentsIndex;
