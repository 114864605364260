import { useContext, useEffect, useState } from 'react';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

export function MonthlyReportsIndex() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const [monthlyReports, setMonthlyReports] = useState(() => []);
  const date = new Date();

  // Load data
  const loadData = async (e) => {
    let result = await fetch(backendURL + '/api/monthly_reports/get_monthly_reports', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const resultJson = await result.json();
    if (result.status === 200) {
      setMonthlyReports(
        resultJson.map((fetch_object) => {
          return fetch_object;
        })
      );
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadData();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      <h1>Monthly Reports</h1>
      <div className="main_box">
        

        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        {/* Table */}
        <table id="cust_table" className="hor-zebra">
          <thead>
            <tr>
              <th style={{"textAlign": "center", "borderRight": "#000 1px solid"}}>
                <span>Periode</span>
              </th>

              <th style={{"textAlign": "center"}}>
                <span>Generated</span>
              </th>
              <th style={{"textAlign": "center"}}>
                <span>Generated date</span>
              </th>
              <th style={{"textAlign": "center"}}>
                <span>Generated how many</span>
              </th>
              <th style={{"textAlign": "center", "borderRight": "#000 1px solid"}}>
                <span>Generated left</span>
              </th>

              
              <th style={{"textAlign": "center"}}>
                <span>Sent internally</span>
              </th>
              <th style={{"textAlign": "center"}}>
                <span>Sent internally date</span>
              </th>
              <th style={{"textAlign": "center"}}>
                <span>Sent internally how many</span>
              </th>
              <th style={{"textAlign": "center", "borderRight": "#000 1px solid"}}>
                <span>Sent internally left</span>
              </th>
              
              
              
              <th style={{"textAlign": "center"}}>
                <span>Sent externally</span>
              </th>
              <th style={{"textAlign": "center"}}>
                <span>Sent externally date</span>
              </th>
              <th style={{"textAlign": "center"}}>
                <span>Sent externally how many</span>
              </th>
              <th style={{"textAlign": "center", "borderRight": "#000 1px solid"}}>
                <span>Sent externally left</span>
              </th>

              <th>
                <span>Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {monthlyReports.map((item, index) => (
              <tr key={index}>
               <td style={{"textAlign": "center", "borderRight": "#000 1px solid"}}>
                  <span>
                  <a href={`https://console.cloud.google.com/storage/browser/monthly-customer-reports/prod/${item.reports_year}/${item.reports_month_int}?pageState=(%22StorageObjectListTable%22:(%22f%22:%22%255B%255D%22))&project=advania-cdc-engineering-infra&prefix=&forceOnObjectsSortingFiltering=false`}>{item.reports_month}/{item.reports_year}</a>
                  </span>
                </td>
               <td style={{"textAlign": "center"}}>
                  <span>{item.reports_generated}</span>
                </td>
               <td style={{"textAlign": "center"}}>
                  <span>{item.reports_generated_date}</span>
                </td>
               <td style={{"textAlign": "center"}}>
                  <span>{item.reports_generated_how_many}</span>
                </td>
               <td style={{"textAlign": "center", "borderRight": "#000 1px solid"}}>
                  <span>{item.reports_generated_left}</span>
                </td>

                
               <td style={{"textAlign": "center"}}>
                  <span>{item.reports_email_internally}</span>
                </td>
               <td style={{"textAlign": "center"}}>
                  <span>{item.reports_email_internally_date}</span>
                </td>
               <td style={{"textAlign": "center"}}>
                  <span>{item.reports_email_internally_how_many}</span>
                </td>
                <td style={{"textAlign": "center", "borderRight": "#000 1px solid"}}>
                  <span>{item.reports_email_internally_left}</span>
                </td>



                
               <td style={{"textAlign": "center"}}>
                  <span>{item.reports_email_externally}</span>
                </td>
               <td style={{"textAlign": "center"}}>
                  <span>{item.reports_email_externally_date}</span>
                </td>
               <td style={{"textAlign": "center"}}>
                  <span>{item.reports_email_externally_how_many}</span>
                </td>
                <td style={{"textAlign": "center","borderRight": "#000 1px solid"}}>
                  <span>{item.reports_email_externally_left}</span>
                </td>

                <td>
                  <span>
                    <a href={`https://console.cloud.google.com/storage/browser/monthly-customer-reports/prod/${item.reports_year}/${item.reports_month_int}?pageState=(%22StorageObjectListTable%22:(%22f%22:%22%255B%255D%22))&project=advania-cdc-engineering-infra&prefix=&forceOnObjectsSortingFiltering=false`}><img src="/gfx/icons/20x20/cleaning_bucket_outlined_black_20x20.svg" alt="cleaning_bucket_outlined_black_20x20.svg" title='Google Bucket' /></a>
                    &nbsp;
                    <a href={`/monthly-reports/edit-monthly-reports/${item.reports_year}/${item.reports_month}`}><img src="/gfx/icons/20x20/edit_rounded_fill_black_20x20.svg" alt="edit_rounded_fill_black_20x20.svg" /></a>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MonthlyReportsIndex;
