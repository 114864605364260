import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../../useToken.js';
import prodOrDev from '../../../utils.js';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export function EditAssessment() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { assessmentId } = useParams();

  // Data
  const [assessment, setAssessment] = useState({
    assessment_id: assessmentId,

    assessment_created_datetime: '',
    assessment_created_date: '',
    assessment_created_date_saying: '',
    assessment_created_by_user_id: '',
    assessment_created_by_first_name: '',
    assessment_created_by_last_name: '',
    assessment_created_by_display_name: '',
    assessment_created_by_email: '',
    
    assessment_alternative_title_value: '',
    assessment_alternative_title_color: '',
    assessment_title: '',
    
    assessment_introduction: '',
    assessment_intro_image_path: '',
    assessment_intro_image_file: '',
    assessment_intro_image_url: '',
    assessment_intro_image_text: '',
    assessment_text: '',
    assessment_summary: '',
    assessment_tlp: '',
    assessment_references: '',
    assessment_tag_a: '',
    assessment_tag_b: '',
    assessment_tag_c: '',
    assessment_state: '',
    assessment_customer_id: '',
    assessment_customer_number: '',
    assessment_customers_tag: '',
    assessment_unique_clicks: '',
    assessment_unique_clicks_ipblock: '',
    assessment_unique_hits: '',
    assessment_unique_hits_ipblock: ''
  });
  const [assessmentCreatedDate, setAssessmentCreatedDate] = useState('');
  const [assessmentReferences, setAssessmentReferences] = useState('');
  const [customers, setCustomers] = useState(() => []);
  const [customersTagsIndex, setCustomersTagsIndex] = useState(() => []);

  // Fomat Date
  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }
  // Load data
  const loadAssesment = async (e) => {
    let result = await fetch(backendURL + '/api/intelligence/get-intelligence-assessment-for-id?assessment_id=' + assessmentId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setAssessment(resultJson);
      document.getElementById('main_box').style.display = 'block';

      // setAssessmentCreatedDate
      if (resultJson['assessment_created_date'] != '') {
        const dateFormated = formatDate(resultJson['assessment_created_date']);
        assessment.assessment_created_date = dateFormated;
        // console.log("EditAssessment() :: loadAssesment() :: assessment_created_date=" + dateFormated)

        setAssessmentCreatedDate(dateFormated);
      }

      // Intro image
      if (resultJson['assessment_intro_image_file'] != '') {
        const imageId = 'assessment_intro_image_file_img';
        const imageUrl = backendURL + '/api/intelligence/get-intelligence-assessment-intro-image?assessment_id=' + assessmentId;
        const authToken = token;
        displayProtectedImage(imageId, imageUrl, authToken);
      }

      // References
      setAssessmentReferences(resultJson['assessment_references']);

      // Customers
      loadCustomers();

      // Tags index
      loadCustomerTagsIndex();
    }
  };

  // Load intro image
  function fetchWithAuthentication(url, authToken) {
    const headers = new Headers();
    headers.set('Authorization', `Bearer ${authToken}`);
    return fetch(url, { headers });
  }
  async function displayProtectedImage(imageId, imageUrl, authToken) {
    // Fetch the image.
    const response = await fetchWithAuthentication(imageUrl, authToken);
  
    // Create an object URL from the data.
    const blob = await response.blob();
    const objectUrl = URL.createObjectURL(blob);
  
    // Update the source of the image.
    const imageElement = document.getElementById(imageId);
    imageElement.src = objectUrl;
  }

  // Load customers
  const loadCustomers  = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get_customers', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setCustomers(resultJson);
    }
  };

  // Load customers tags index
  const loadCustomerTagsIndex  = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get-customer-tags-index', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setCustomersTagsIndex(resultJson);
      console.log("EditAssessment.js::loadCustomerTagsIndex()::resultJson=" + JSON.stringify(resultJson, null, 2));
    }
  };



  // Form data chaned
  const handleChange = (e) => {
    setAssessment({
      ...assessment,
      [e.target.name]: e.target.value
    });
  };

  // Send assessment
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Update references
    assessment.assessment_references = assessmentReferences;

    // Send request
    let result = await fetch(backendURL + '/api/intelligence/edit_intelligence_assessments_all_customers_for_id', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(assessment)
    });
    let resultJson = await result.json();
    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    }
  };

  const handleChangeIntroduction = (e) => {
    assessment.assessment_introduction = e;
  };
  const handleChangeSummary = (e) => {
    assessment.assessment_summary = e;
  };
  const handleChangeText = (e) => {
    assessment.assessment_text = e;
  };
  const handleChangeReferences = (e) => {
    console.log('EditAssesment() :: handleChangeReferences() :: e.target.name=' + e.target.value);
    
    assessment.assessment_references = e.target.value;
  };

  

  const handleAssessmentCreatedDateChange = (e) => {
    console.log('EditAssesment() :: handleAssessmentCreatedDateChange() :: e.target.name=' + e.target.name + ' e.target.value=' + e.target.value);
    setAssessmentCreatedDate(e.target.value);
    setAssessment({
      ...assessment,
      ['assessment_created_date']: e.target.value
    });
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadAssesment();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/intelligence">Intelligence</a>
          &nbsp; / &nbsp;
          <a href="/intelligence/assessments">Assessments</a>
          &nbsp; / &nbsp;
          <a href={`/intelligence/assessments/view-assessment/${assessmentId}`}>{assessment.assessment_title}</a>
          &nbsp; / &nbsp;
          <a href={`/intelligence/assessments/edit-assessment/${assessmentId}`}>Edit</a>
        </p>
      </div>
      <div className="clear"></div>
      {/* Main Left */}
      <h1>Edit {assessment.assessment_title}</h1>
      <div className="main_box" id="main_box" style={{ display: 'none' }}>
        <form onSubmit={handleSubmit} id="form">
          {/* Feedback */}
          <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
            <p id="feedback_p">Feedback box is here</p>
          </div>

          {/* Form */}

          {/* Image */}
          {assessment.assessment_intro_image_file ? (
            <span>
            <img id="assessment_intro_image_file_img" src="" alt={`${assessment.assessment_intro_image_file}`} />
            </span>
          ) : (
            <span><a href={`/intelligence/assessments/edit-assessment-image/${assessmentId}`}>Upload image to assessment</a></span>
          )}
          <p>
          <input type="text" name="assessment_intro_image_text" value={assessment.assessment_intro_image_text} onChange={handleChange} style={{ minWidth: '50%' }} />
          &nbsp; <a href={`/intelligence/assessments/edit-assessment-image/${assessmentId}`}>Edit image</a>
          </p>


          <p>Alternative title:<br />
          <input type="text" name="assessment_alternative_title_value" value={assessment.assessment_alternative_title_value} onChange={handleChange} style={{ minWidth: '50%' }} />
          
          &nbsp;<select name="assessment_alternative_title_color" value={assessment.assessment_alternative_title_color} onChange={handleChange}>
            <option value="grey">Grey</option>
            <option value="red">Red</option>
            <option value="black">Black</option>
          </select>
          </p>

          <p>Title<br />
          <input type="text" name="assessment_title" value={assessment.assessment_title} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p style={{ paddingBottom: 0, marginBottom: 0 }}>Introduction</p>
          <ReactQuill theme="snow" name="assessment_introduction" value={assessment.assessment_introduction} onChange={handleChangeIntroduction} />

          <p style={{ paddingBottom: 0, marginBottom: 0 }}>Summary</p>
          <ReactQuill theme="snow" name="assessment_summary" value={assessment.assessment_summary} onChange={handleChangeSummary} />

          <p style={{ paddingBottom: 0, marginBottom: 0 }}>Text</p>
          <ReactQuill theme="snow" name="assessment_text" value={assessment.assessment_text} onChange={handleChangeText} />

          <p>TLP<br />
          <select name="assessment_tlp" value={assessment.assessment_tlp} onChange={handleChange}>
            <option value="N/A">Please select</option>
            <option value="TLP:CLEAR">CLEAR</option>
            <option value="TLP:GREEN">GREEN</option>
            <option value="TLP:AMBER">AMBER</option>
            <option value="TLP:AMBER+STRICT">AMBER+STRICT</option>
            <option value="TLP:RED">RED</option>
          </select>
          </p>

          <p>References<br />
          <textarea name="assessment_references" value={assessmentReferences} onChange={(e) => setAssessmentReferences(e.target.value)} rows="5" cols="100" style={{ minWidth: '50%' }}></textarea>
          </p>

          <p>Tags<br />
          <input type="text" name="assessment_tag_a" value={assessment.assessment_tag_a} onChange={handleChange} size="10" />
          &nbsp;
          <input type="text" name="assessment_tag_b" value={assessment.assessment_tag_b} onChange={handleChange} size="10" />
          &nbsp;
          <input type="text" name="assessment_tag_c" value={assessment.assessment_tag_c} onChange={handleChange} size="10" />
          </p>

          <p>Date<br />
          <span className="small">{assessment.assessment_created_date_saying} ({assessmentCreatedDate})</span><br />
          <input type="date" name="assessment_created_date" value={assessmentCreatedDate} onChange={handleAssessmentCreatedDateChange} />
          </p>

          <p>State<br />
          <select name="assessment_state" value={assessment.assessment_state} onChange={handleChange}>
            <option value="">Please select</option>
            <option value="draft">Draft</option>
            <option value="published">Published</option>
          </select>
          </p>

          <hr />
          <h2>Who is this assessment for?</h2>
          <p>Customer<br />
          <select name="assessment_customer_id" value={assessment.assessment_customer_id} onChange={handleChange}>
            <option value="-1">All customers</option>
            {customers.map((item, index) => (
              <option key={index} value={item.customer_id}>{item.customer_name_abbreviated} {item.customer_name}</option>
            ))}
          </select>
          </p>

          <p><em>...or...</em></p>


          <p>Customer with tag<br />
          <select name="assessment_customers_tag" value={assessment.assessment_customers_tag} onChange={handleChange}>
            <option value="-1">All customers</option>
            {customersTagsIndex.map((item, index) => (
              <option key={index} value={item.tag_title_clean}>{item.tag_title}</option>
            ))}
          </select>
          </p>


          <p>
          <button>Save assessment</button>
          </p>
        </form>
      </div>{' '}
      {/* // main_box */}
    </div>
  );
}

export default EditAssessment;
