import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './pages/Login/Login.js';
import useToken from './useToken.js';

import Layout from './pages/Layout.js';
import About from './pages/About.js';
import Profile from './pages/Profile/Profile.js';
import NoPage from './pages/NoPage.js';
import Logout from './pages/Login/Logout.js';

// Collectors
import CollectorsIndex from './pages/Collectors/CollectorsIndex.js';
import ViewCollector from './pages/Collectors/ViewCollector.js';
import NewClient from './pages/Collectors/NewClient.js';
import EditClient from './pages/Collectors/EditClient.js';
import DeleteClient from './pages/Collectors/DeleteClient.js';
import DeleteCollector from './pages/Collectors/DeleteCollector.js';

// Customers
import CustomersIndex from './pages/Customers/CustomersIndex.js';
import ViewCustomer from './pages/Customers/ViewCustomer.js';
import ViewBusiness from './pages/Customers/ViewBusiness.js';
import ViewEngagements from './pages/Customers/ViewEngagements.js';
import NewCustomer from './pages/Customers/NewCustomer.js';
import EditCustomer from './pages/Customers/EditCustomer.js';
import ContactsIndex from './pages/Customers/ContactsIndex.js';
import NewContact from './pages/Customers/NewContact.js';
import EditContact from './pages/Customers/EditContact.js';
import DeleteContact from './pages/Customers/DeleteContact.js';
import NewURL from './pages/Customers/NewURL.js';
import EditURL from './pages/Customers/EditURL.js';
import DeleteURL from './pages/Customers/DeleteURL.js';
import NewOperatingCountry from './pages/Customers/NewOperatingCountry.js';
import EditOperatingCountry from './pages/Customers/EditOperatingCountry.js';
import DeleteOperatingCountry from './pages/Customers/DeleteOperatingCountry.js';
import ViewIdentifiers from './pages/Customers/ViewIdentifiers.js';
import NewIdentifier from './pages/Customers/NewIdentifier.js';
import EditIdentifier from './pages/Customers/EditIdentifier.js';
import DeleteIdentifier from './pages/Customers/DeleteIdentifier.js';
import DeleteCustomer from './pages/Customers/DeleteCustomer.js';
import InitializeEngagementStage from './pages/Customers/InitializeEngagementStage.js';
import NewEngagement from './pages/Customers/NewEngagement.js';
import ViewEngagementStatusLimaCharlie from './pages/Customers/ViewEngagementStatusLimaCharlie.js';
import ViewEngagementStatusVulnerabilityManagement from './pages/Customers/ViewEngagementStatusVulnerabilityManagement.js';
import ViewEngagementDefenderAddon from './pages/Customers/ViewEngagementDefenderAddon.js';
import ViewAffiliations from './pages/Customers/ViewAffiliations.js';
import NewAffilation from './pages/Customers/NewAffilation.js';
import DeleteAffiliation from './pages/Customers/DeleteAffiliation.js';
import DeleteEngagement from './pages/Customers/DeleteEngagement.js';
import CopyEmails from './pages/Customers/CopyEmails.js';
import ViewSensors from './pages/Customers/ViewSensors.js';
import SetCustomerStatusToHistoric from './pages/Customers/SetCustomerStatusToHistoric.js';

import ViewCustomerTag from './pages/Customers/ViewCustomerTag.js';
import ViewCustomerTagsPerCustomer from './pages/Customers/ViewCustomerTagsPerCustomer.js';
import DeleteCustomerTagPerCustomer from './pages/Customers/DeleteCustomerTagPerCustomer.js';



// Intelligence
import IntelligenceIndex from './pages/Intelligence/IntelligenceIndex.js';
import AssessmentsIndex from './pages/Intelligence/Assessments/AssessmentsIndex.js';
import EditAssessment from './pages/Intelligence/Assessments/EditAssessment.js';
import DeleteAssessment from './pages/Intelligence/Assessments/DeleteAssessment.js';
import NewAssessment from './pages/Intelligence/Assessments/NewAssessment.js';
import ViewAssessment from './pages/Intelligence/Assessments/ViewAssessment.js';
import EditAssessmentImage from './pages/Intelligence/Assessments/EditAssessmentImage.js';

// Threat Actors
import ThreatActorsIndex from './pages/Intelligence/ThreatActors/ThreatActorsIndex.js';
import NewThreatActor from './pages/Intelligence/ThreatActors/NewThreatActor.js';
import EditThreatActor from './pages/Intelligence/ThreatActors/EditThreatActor.js';
import ViewThreatActor from './pages/Intelligence/ThreatActors/ViewThreatActor.js';
import DeleteThreatActor from './pages/Intelligence/ThreatActors/DeleteThreatActor.js';
import NewAlternativeName from './pages/Intelligence/ThreatActors/NewAlternativeName.js';
import DeleteAlternativeName from './pages/Intelligence/ThreatActors/DeleteAlternativeName.js';
import ViewOperationPerformed from './pages/Intelligence/ThreatActors/ViewOperationPerformed.js';
import NewOperationPerformed from './pages/Intelligence/ThreatActors/NewOperationPerformed.js';
import EditOperationPerformed from './pages/Intelligence/ThreatActors/EditOperationPerformed.js';
import DeleteOperationPerformed from './pages/Intelligence/ThreatActors/DeleteOperationPerformed.js';

import ViewTarget from './pages/Intelligence/ThreatActors/ViewTarget.js';
import NewTarget from './pages/Intelligence/ThreatActors/NewTarget.js';
import EditTarget from './pages/Intelligence/ThreatActors/EditTarget.js';
import DeleteTarget from './pages/Intelligence/ThreatActors/DeleteTarget.js';




// Monitor
import MonitorIndex from './pages/Monitor/MonitorIndex.js';
import ViewMonitorParent from './pages/Monitor/ViewMonitorParent.js';
import NewMonitorParent from './pages/Monitor/NewMonitorParent.js';
import EditMonitorParent from './pages/Monitor/EditMonitorParent.js';
import DeleteMonitorParent from './pages/Monitor/DeleteMonitorParent.js';
import GenerateNewAPIKeyForMonitorParent from './pages/Monitor/GenerateNewAPIKeyForMonitorParent.js';
import PingInstructions from './pages/Monitor/PingInstructions.js';
import NewMonitorChild from './pages/Monitor/NewMonitorChild.js';
import EditMonitorChild from './pages/Monitor/EditMonitorChild.js';
import DeleteMonitorChild from './pages/Monitor/DeleteMonitorChild.js';

// Monhtly Reports
import MonthlyReportsIndex from './pages/MonthlyReports/MonthlyReportsIndex.js';
import EditMonthlyReports from './pages/MonthlyReports/EditMonthlyReports.js';
import RegenerateReports from './pages/MonthlyReports/RegenerateReports.js';
import ResendReportsInternally from './pages/MonthlyReports/ResendReportsInternally.js';
import ResendReportsExternally from './pages/MonthlyReports/ResendReportsExternally.js';

// Settings
import SettingsIndex from './pages/Settings/SettingsIndex.js';

// Settings :: APIs
import APIsIndex from './pages/Settings/APIs/APIsIndex.js';
import NewAPI from './pages/Settings/APIs/NewAPI.js';
import ViewAPI from './pages/Settings/APIs/ViewAPI.js';
import DeleteAPI from './pages/Settings/APIs/DeleteAPI.js';
import EditAPI from './pages/Settings/APIs/EditAPI.js';
import APIPermissionsIndex from './pages/Settings/APIs/APIPermissionsIndex.js';
import NewAPIPermission from './pages/Settings/APIs/NewAPIPermission.js';
import DeleteAPIPermission from './pages/Settings/APIs/DeleteAPIPermission.js';
import APIAllowedIPsIndex from './pages/Settings/APIs/APIAllowedIPsIndex.js';
import NewAllowedIP from './pages/Settings/APIs/NewAllowedIP.js';
import EditAllowedIP from './pages/Settings/APIs/EditAllowedIP.js';
import DeleteAllowedIP from './pages/Settings/APIs/DeleteAllowedIP.js';
import APIPermissionInfo from './pages/Settings/APIs/APIPermissionInfo.js';
import GenerateNewAPIKey from './pages/Settings/APIs/GenerateNewAPIKey.js';
import APIUsageLogs from './pages/Settings/APIs/APIUsageLogs.js';



// Settings :: Case Codes
import CaseCodesIndex from './pages/Settings/CaseCodes/CaseCodesIndex.js';
import EditCaseCode from './pages/Settings/CaseCodes/EditCaseCode.js';
import DeleteCaseCode from './pages/Settings/CaseCodes/DeleteCaseCode.js';

// Settings :: Classifications
import ClassificationsIndex from './pages/Settings/Classifications/ClassificationsIndex.js';

// Settings :: Countries
import CountriesIndex from './pages/Settings/Countries/CountriesIndex.js';

// Settings :: Users
import UsersIndex from './pages/Settings/Users/UsersIndex.js';
import NewUser from './pages/Settings/Users/NewUser.js';
import EditUser from './pages/Settings/Users/EditUser.js';
import DeleteUser from './pages/Settings/Users/DeleteUser.js';

// Statitics
import StatisticsIndex from './pages/Statistics/StatisticsIndex.js';
import StatisticsIndexClassificationsPerMonth from './pages/Statistics/StatisticsIndexClassificationsPerMonth.js';
import StatisticsIndexCaseCodesPerMonth from './pages/Statistics/StatisticsIndexCaseCodesPerMonth.js';
import ViewStatsAlertsAllCustomersPerMonth from './pages/Statistics/ViewStatsAlertsAllCustomersPerMonth.js';
import EditOperationalReadinessThreatImpact from './pages/Statistics/EditOperationalReadinessThreatImpact.js';

function App() {
  const { token, setToken } = useToken();

  if (token === undefined || token === null || !token || token == 'undefined') {
    return <Login setToken={setToken} />;
  } else {
    // console.log("App() :: App() :: User is logged in because token=" + token)
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<CustomersIndex />} />


            <Route path="collectors" element={<CollectorsIndex />} />
            <Route path="collectors/view-collector/:collectorId" element={<ViewCollector />} />
            <Route path="collectors/new-client/:collectorId" element={<NewClient />} />
            <Route path="collectors/edit-client/:clientId" element={<EditClient />} />
            <Route path="collectors/delete-client/:clientId" element={<DeleteClient />} />
            <Route path="collectors/delete-collector/:collectorId" element={<DeleteCollector />} />

            <Route path="customers/view_customer/:customerId" element={<ViewCustomer />} />
            <Route path="customers/view_business/:customerId" element={<ViewBusiness />} />
            <Route path="customers/view_identifiers/:customerId" element={<ViewIdentifiers />} />
            <Route path="customers/view_engagements/:customerId" element={<ViewEngagements />} />
            <Route path="customers/new_customer" element={<NewCustomer />} />
            <Route path="customers/edit_customer/:customerId" element={<EditCustomer />} />
            <Route path="customers/contacts_index" element={<ContactsIndex />} />
            <Route path="customers/new_contact/:customerId" element={<NewContact />} />
            <Route path="customers/edit_contact/:contactId" element={<EditContact />} />
            <Route path="customers/delete_contact/:contactId" element={<DeleteContact />} />
            <Route path="customers/new_url/:customerId" element={<NewURL />} />
            <Route path="customers/edit_url/:urlId" element={<EditURL />} />
            <Route path="customers/delete_url/:urlId" element={<DeleteURL />} />
            <Route path="customers/new_operating_country/:customerId" element={<NewOperatingCountry />} />
            <Route path="customers/edit_operating_country/:operatingCountryId" element={<EditOperatingCountry />} />
            <Route path="customers/delete_operating_country/:operatingCountryId" element={<DeleteOperatingCountry />} />
            <Route path="customers/new_identifier/:customerId" element={<NewIdentifier />} />
            <Route path="customers/edit_identifier/:identifierId" element={<EditIdentifier />} />
            <Route path="customers/delete_identifier/:identifierId" element={<DeleteIdentifier />} />
            <Route path="customers/delete_customer/:customerId" element={<DeleteCustomer />} />
            <Route path="customers/initialize_engagement_stage/:engagementId" element={<InitializeEngagementStage />} />
            <Route path="customers/view_affiliations/:customerId" element={<ViewAffiliations />} />
            <Route path="customers/new_affilation/:customerId" element={<NewAffilation />} />
            <Route path="customers/delete_affiliation/:affiliationId" element={<DeleteAffiliation />} />
            <Route path="customers/delete_engagement/:engagementId" element={<DeleteEngagement />} />
            <Route path="customers/new_engagement/:customerId" element={<NewEngagement />} />
            <Route path="customers/view-engagement-status-limacharlie/:engagementId" element={<ViewEngagementStatusLimaCharlie />} />
            <Route path="customers/view-engagement-status-vulnerability-management/:engagementId" element={<ViewEngagementStatusVulnerabilityManagement />} />
            <Route path="customers/view-engagement-defender-addon/:engagementId" element={<ViewEngagementDefenderAddon />} />
            <Route path="customers/view_sensors/:customerId" element={<ViewSensors />} />
            <Route path="customers/copy_emails" element={<CopyEmails />} />
            <Route path="customers/set-customer-status-to-historic/:customerId" element={<SetCustomerStatusToHistoric />} />

            <Route path="customers/view-customer-tag/:tagTitleClean" element={<ViewCustomerTag />} />
            <Route path="customers/view-customer-tags-per-customer/:customerId" element={<ViewCustomerTagsPerCustomer />} />
            <Route path="customers/delete-customer-tag-per-customer/:tagPerCustomerId" element={<DeleteCustomerTagPerCustomer />} />
            
            <Route path="intelligence" element={<IntelligenceIndex />} />
            <Route path="intelligence/assessments" element={<AssessmentsIndex />} />
            <Route path="intelligence/assessments/new-assessment" element={<NewAssessment />} />
            <Route path="intelligence/assessments/edit-assessment/:assessmentId" element={<EditAssessment />} />
            <Route path="intelligence/assessments/edit-assessment-image/:assessmentId" element={<EditAssessmentImage />} />
            <Route path="intelligence/assessments/delete-assessment/:assessmentId" element={<DeleteAssessment />} />
            <Route path="intelligence/assessments/view-assessment/:assessmentId" element={<ViewAssessment />} />

            <Route path="intelligence/threat-actors" element={<ThreatActorsIndex />} />
            <Route path="intelligence/threat-actors/new-threat-actor" element={<NewThreatActor />} />
            <Route path="intelligence/threat-actors/view-threat-actor/:threatActorId" element={<ViewThreatActor />} />
            <Route path="intelligence/threat-actors/edit-threat-actor/:threatActorId" element={<EditThreatActor />} />
            <Route path="intelligence/threat-actors/delete-threat-actor/:threatActorId" element={<DeleteThreatActor />} />
            <Route path="intelligence/threat-actors/new-alternative-name/:threatActorId" element={<NewAlternativeName />} />
            <Route path="intelligence/threat-actors/delete-alternative-name/:nameId" element={<DeleteAlternativeName />} />
            <Route path="intelligence/threat-actors/new-operation-performed/:threatActorId" element={<NewOperationPerformed />} />
            <Route path="intelligence/threat-actors/view-operation-performed/:performedId" element={<ViewOperationPerformed />} />
            <Route path="intelligence/threat-actors/edit-operation-performed/:performedId" element={<EditOperationPerformed />} />
            <Route path="intelligence/threat-actors/delete-operation-performed/:performedId" element={<DeleteOperationPerformed />} />

            <Route path="intelligence/threat-actors/new-target/:threatActorId" element={<NewTarget />} />
            <Route path="intelligence/threat-actors/view-target/:targetId" element={<ViewTarget />} />
            <Route path="intelligence/threat-actors/edit-target/:targetId" element={<EditTarget />} />
            <Route path="intelligence/threat-actors/delete-target/:targetId" element={<DeleteTarget />} />


            <Route path="monitor" element={<MonitorIndex />} />
            <Route path="monitor/view_monitor_parent/:monitorParentId" element={<ViewMonitorParent />} />
            <Route path="monitor/new_monitor_parent" element={<NewMonitorParent />} />
            <Route path="monitor/edit_monitor_parent/:monitorParentId" element={<EditMonitorParent />} />
            <Route path="monitor/delete_monitor_parent/:monitorParentId" element={<DeleteMonitorParent />} />
            <Route path="monitor/generate_new_api_key_for_monitor_parent/:monitorParentId" element={<GenerateNewAPIKeyForMonitorParent />} />
            <Route path="monitor/ping_instructions/:monitorParentId" element={<PingInstructions />} />
            <Route path="monitor/new_monitor_child/:monitorParentId" element={<NewMonitorChild />} />
            <Route path="monitor/edit_monitor_child/:monitorChildId" element={<EditMonitorChild />} />
            <Route path="monitor/delete_monitor_child/:monitorChildId" element={<DeleteMonitorChild />} />

            <Route path="about" element={<About />} />
            <Route path="profile" element={<Profile />} />
            <Route path="*" element={<NoPage />} />
            <Route path="logout" element={<Logout />} />
            <Route path="settings" element={<SettingsIndex />} />

            <Route path="monthly-reports" element={<MonthlyReportsIndex />} />
            <Route path="monthly-reports/edit-monthly-reports/:year/:month" element={<EditMonthlyReports />} />
            <Route path="monthly-reports/edit-monthly-reports/:year/:month/regenerate-reports" element={<RegenerateReports />} />
            <Route path="monthly-reports/edit-monthly-reports/:year/:month/resend-reports-internally" element={<ResendReportsInternally />} />
            <Route path="monthly-reports/edit-monthly-reports/:year/:month/resend-reports-externally" element={<ResendReportsExternally />} />
            
            <Route path="settings/apis" element={<APIsIndex />} />
            <Route path="settings/apis/new_api" element={<NewAPI />} />
            <Route path="settings/apis/view_api/:apiId" element={<ViewAPI />} />
            <Route path="settings/apis/delete_api/:apiId" element={<DeleteAPI />} />
            <Route path="settings/apis/edit_api/:apiId" element={<EditAPI />} />
            <Route path="settings/apis/api_permissions_index/:apiId" element={<APIPermissionsIndex />} />
            <Route path="settings/apis/new_api_permission/:apiId" element={<NewAPIPermission />} />
            <Route path="settings/apis/delete_api_permission/:permissionId" element={<DeleteAPIPermission />} />
            <Route path="settings/apis/api_permission_info/:permissionId" element={<APIPermissionInfo />} />
            <Route path="settings/apis/api_allowed_ips_index/:apiId" element={<APIAllowedIPsIndex />} />
            <Route path="settings/apis/edit-allowed-ip/:ipId" element={<EditAllowedIP />} />
            <Route path="settings/apis/new_api_allowed_ip/:apiId" element={<NewAllowedIP />} />
            <Route path="settings/apis/delete_allowed_ip/:ipId" element={<DeleteAllowedIP />} />
            <Route path="settings/apis/generate_new_api_key/:apiId" element={<GenerateNewAPIKey />} />
            <Route path="settings/apis/api_usage_logs/:apiId" element={<APIUsageLogs />} />

            <Route path="settings/case-codes" element={<CaseCodesIndex />} />
            <Route path="settings/case-codes/edit-case-code/:caseCodeId" element={<EditCaseCode />} />
            <Route path="settings/case-codes/delete-case-code/:caseCodeId" element={<DeleteCaseCode />} />

            <Route path="settings/classifications" element={<ClassificationsIndex />} />

            <Route path="settings/countries" element={<CountriesIndex />} />

            <Route path="settings/users" element={<UsersIndex />} />
            <Route path="settings/users/new-user" element={<NewUser />} />
            <Route path="settings/users/edit-user/:userId" element={<EditUser />} />
            <Route path="settings/users/delete-user/:userId" element={<DeleteUser />} />

            <Route path="statistics" element={<StatisticsIndex />} />
            <Route path="statistics/statistics_index_classifications_per_month/:year" element={<StatisticsIndexClassificationsPerMonth />} />
            <Route path="statistics/statistics_index_case_codes_per_month/:year" element={<StatisticsIndexCaseCodesPerMonth />} />
            <Route path="statistics/view_stats_alerts_all_customers_per_month/:statsId" element={<ViewStatsAlertsAllCustomersPerMonth />} />
            <Route path="statistics/edit-operational-readiness-impact-threat/:statsId" element={<EditOperationalReadinessThreatImpact />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
