import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';


export function DeleteCollector() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { collectorId } = useParams();

  // Data
  const [collector, setCollector] = useState({
    collector_id: collectorId,
    collector_customer_id: '', 
    collector_customer_abbreviation: '', 
    collector_hostname: '', 
    collector_ip_now: '',
    collector_ip_now_date: '',
    collector_ip_now_date_saying: '',
    collector_ip_historic: '',
    collector_ip_historic_date: '',
    collector_ip_historic_date_saying: '',
    collector_os: '',
    collector_os_version: '',
    collector_os_eol_date: '',
    collector_os_eol_saying: '',
    collector_os_eol_notified_year: '',
    collector_os_eol_emailed: '',
    collector_os_eol_slack: '',
    collector_created_datetime: '',
    collector_created_date_saying: '',
    collector_created_by_ip: '',
    collector_updated_datetime: '',
    collector_updated_date_saying: '',
    collector_updated_by_ip: ''
  });
  const [certs, setCerts] = useState([]);
  const [clients, setClients] = useState([]);

  // Load data
  const loadCollector = async (e) => {
    let result = await fetch(backendURL + '/api/collectors/get-collector?collector_id=' + collectorId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setCollector(resultJson);
      document.getElementById('main_box').style.display = 'block';

    }
  };
  const loadCertificates = async (e) => {
    let result = await fetch(backendURL + '/api/collectors/get-certs?collector_id=' + collectorId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setCerts(resultJson);

    }
  };
  const loadClients = async (e) => {
    let result = await fetch(backendURL + '/api/collectors/get-clients?collector_id=' + collectorId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setClients(resultJson);

    }
  };
  // Confirm delete
  async function confirmDelete(e) {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/collectors/delete-collector?collector_id=' + collectorId, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();

    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
    }
  }

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadCollector();
      loadCertificates();
      loadClients();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/collectors">Collectors</a>
          &nbsp; / &nbsp;
          <a href={`/collectors/view-collector/${collector.collector_id}`}>{collector.collector_hostname}</a>
          &nbsp; / &nbsp;
          <a href={`/collectors/delete-collector/${collector.collector_id}`}>Delete</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>Delete {collector.collector_hostname}</h1>


      {/* Collector */}
      <div className="main_box" id="main_box" style={{ display: 'none' }}>
          {/* Feedback */}
          <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
            <p id="feedback_p">Feedback box is here</p>
          </div>

          {/* Form */}
          <p>Are you sure you want to delete the collector? This action cannot be undone!</p>

          <p>
          <a href="#delete" className="btn_danger" onClick={confirmDelete}>Confirm</a>
          </p>
      </div>

    </div>
  );
}

export default DeleteCollector;
