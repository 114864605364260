import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

export function EditURL() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { urlId } = useParams();

  const [url, setURL] = useState({
    url_id: '',
    url_customer_id: '',
    url_type: '',
    url_title: '',
    url_value: ''
  });
  const [urlTypes, setURLTypes] = useState(() => []);
  const [customerIndex, setCustomerIndex] = useState(() => []);

  // Load URL Types
  const loadURLTypes = async (e) => {
    let result = await fetch(backendURL + '/api/options/get_url_types', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setURLTypes(resultJson);
      loadURL();
    }
  };

  // Load URL
  const loadURL = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get_url?url_id=' + urlId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setURL(resultJson);
      // Display form (last element is loaded)
      document.getElementById('form').style.display = 'block';
      // Focus
      document.forms['form'].elements['url_type'].focus();

      // Set cusomer ID
      url.url_customer_id = resultJson['url_customer_id'];

      // Load customer
      loadCustomerIndex();
    }
  };
  // Load customer
  const loadCustomerIndex = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get_customer?customer_id=' + url.url_customer_id, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setCustomerIndex(resultJson);
    }
  };

  // Form data chaned
  const handleChange = (e) => {
    setURL({
      ...url,
      [e.target.name]: e.target.value
    });
  };

  // Send new contact
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/customers/edit_url', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(url)
    });
    let resultJson = await result.json();
    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadURLTypes();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/">Customers</a>
          &nbsp; / &nbsp;
          <a href={`/customers/view_customer/${url.url_customer_id}`}>{customerIndex.customer_name_abbreviated}</a>
          &nbsp; / &nbsp;
          <a href={`/customers/new_url/${url.url_customer_id}`}>Edit URL</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>Edit URL for {customerIndex.customer_name_abbreviated}</h1>
      <form onSubmit={handleSubmit} id="form" style={{ display: 'none' }}>
        <div className="main_box">
          {/* Feedback */}
          <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
            <p id="feedback_p">Feedback box is here</p>
          </div>

          {/* Form */}
          <p>
            Type
            <br />
            <select name="url_type" value={url.url_type} onChange={handleChange}>
              <option value="N/A">Please select</option>
              {urlTypes.map((item, index) => (
                <option key={index} value={item.url_type_title}>
                  {item.url_type_title}
                </option>
              ))}
            </select>
          </p>

          <p>
            URL title
            <br />
            <input type="text" name="url_title" value={url.url_title} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
            URL value
            <br />
            <input type="text" name="url_value" value={url.url_value} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
            <button>Submit</button>
          </p>
        </div>
      </form>
    </div>
  );
}

export default EditURL;
