import { useContext, useEffect, useState } from 'react';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

export function StatisticsIndex() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const [statsAlertsAllCustomers, setStatsAlertsAllCustomers] = useState(() => []);
  const date = new Date();

  // Load data
  const loadData = async (e) => {
    let result = await fetch(backendURL + '/api/statistics/get_statistics_alerts_cases_tickets_all_customers_per_month', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const resultJson = await result.json();
    if (result.status === 200) {
      setStatsAlertsAllCustomers(
        resultJson.map((fetch_object) => {
          return fetch_object;
        })
      );
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadData();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      <h1>Statistics</h1>
      <div className="main_box">
        {/* Tabs */}
        <ul className="tabs">
          <li>
            <a href={`/statistics`} className="active">
              Monthly
            </a>
          </li>
          <li>
            <a href={`/statistics/statistics_index_case_codes_per_month/${date.getFullYear()}`}>Case codes per month</a>
          </li>
          <li>
            <a href={`/statistics/statistics_index_classifications_per_month/${date.getFullYear()}`}>Classifications per month</a>
          </li>
        </ul>

        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        {/* Table */}
        <table id="cust_table" className="hor-zebra">
          <thead>
            <tr>
              <th>
                <span>Periode</span>
              </th>
              <th>
                <span>Alerts</span>
              </th>
              <th>
                <span>Tickets</span>
              </th>
              <th>
                <span>Cases</span>
              </th>
              <th>
                <span>FPO</span>
              </th>
              <th>
                <span>LEA</span>
              </th>
              <th>
                <span>Incidents</span>
              </th>
              <th>
                <span>Operational Readiness Level</span>
              </th>
              <th>
                <span>Threat</span>
              </th>
              <th>
                <span>Impact</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {statsAlertsAllCustomers.map((item, index) => (
              <tr key={index}>
                <td>
                  <span>
                    <a href={`/statistics/view_stats_alerts_all_customers_per_month/${item.stats_month_id}`}>
                      {item.stats_month_month}/{item.stats_month_year}
                    </a>
                  </span>
                </td>
                <td>
                  <span>{item.stats_month_alerts_count}</span>
                </td>
                <td>
                  <span>{item.stats_month_tickets_count}</span>
                </td>
                <td>
                  <span>{item.stats_month_cases_count}</span>
                </td>
                <td>
                  <span>{item.stats_month_cases_case_code_false_positives_rate} %</span>
                </td>
                <td>
                  <span>{item.stats_month_cases_case_code_legitimate_activity_rate} %</span>
                </td>
                <td>
                  <span>{item.stats_month_cases_case_code_incidents_count}</span>
                </td>
                <td>
                  <span>
                    {item.stats_month_operational_readiness_level_title}
                    &nbsp;
                    <a href={`/statistics/edit-operational-readiness-impact-threat/${item.stats_month_id}`}>
                      <img src="/gfx/icons/20x20/edit_rounded_fill_black_20x20.svg" alt="edit_rounded_fill_black_20x20.svg" />
                    </a>
                  </span>
                </td>
                <td>
                  <span>
                    {item.stats_month_threat_title}
                    &nbsp;
                    <a href={`/statistics/edit-operational-readiness-impact-threat/${item.stats_month_id}`}>
                      <img src="/gfx/icons/20x20/edit_rounded_fill_black_20x20.svg" alt="edit_rounded_fill_black_20x20.svg" />
                    </a>
                  </span>
                </td>
                <td>
                  <span>
                    {item.stats_month_impact_title}
                    &nbsp;
                    <a href={`/statistics/edit-operational-readiness-impact-threat/${item.stats_month_id}`}>
                      <img src="/gfx/icons/20x20/edit_rounded_fill_black_20x20.svg" alt="edit_rounded_fill_black_20x20.svg" />
                    </a>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default StatisticsIndex;
