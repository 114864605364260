import { useContext, useEffect, useState } from 'react';
import useToken from '../../../useToken.js';
import prodOrDev from '../../../utils.js';

export function CaseCodesIndex() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const [caseCodes, setCaseCodes] = useState(() => []);

  // Load data
  const loadData = async (e) => {
    let result = await fetch(backendURL + '/api/case-codes/get-case-codes', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const resultJson = await result.json();
    if (result.status === 200) {
      setCaseCodes(
        resultJson.map((fetch_object) => {
          return fetch_object;
        })
      );
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadData();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      <h1>Case Codes</h1>
      <div className="main_box">


        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        {/* Table */}
        <table className="hor-zebra">
          <thead>
            <tr>
              <th>
                <span>Abbreviation</span>
              </th>
              <th>
                <span>Title</span>
              </th>
              <th>
                <span>Title full</span>
              </th>
              <th>
                <span>Description</span>
              </th>
              <th>
                <span>Piece bg color and text color</span>
              </th>
              <th>
                <span>External ID</span>
              </th>
              <th>
                <span>Active</span>
              </th>
              <th>
                <span>Updated</span>
              </th>
              <th>
                <span>Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {caseCodes.map((item, index) => (
              <tr key={index}>
                <td>
                  <span>{item.case_code_abbreviation}</span>
                </td>
                <td>
                  <span>{item.case_code_title}</span>
                </td>
                <td>
                  <span>{item.case_code_title_full}</span>
                </td>
                <td>
                  <span>{item.case_code_description}</span>
                </td>
                <td>
                  <span style={{backround: `${item.case_code_piece_bg_color}`}}>{item.case_code_piece_bg_color}</span>
                  <span>&nbsp;</span>
                  <span style={{backround: `${item.case_code_piece_text_color}`}}>{item.case_code_piece_text_color}</span>
                </td>
                <td>
                  <span>{item.case_code_external_id}</span>
                </td>
                <td>
                  <span>{item.case_code_is_active}</span>
                </td>
                <td>
                  {item.case_code_updated_date_saying !== '' && item.case_code_updated_date_saying !== null ? (
                    <span>
                    {item.case_code_updated_date_saying}
                    &nbsp; by &nbsp;
                    {item.case_code_updated_by_user_name}
                    </span>
                  ) : (
                    <span></span>
                  )}


              
                </td>
                <td>
                <span>
                    <a href={`/settings/case-codes/edit-case-code/${item.case_code_id}`}><img src="/gfx/icons/20x20/edit_rounded_fill_black_20x20.svg" alt="edit_rounded_fill_black_20x20.svg" /></a>
                    &nbsp;
                    <a href={`/settings/case-codes/delete-case-code/${item.case_code_id}`}><img src="/gfx/icons/20x20/delete_rounded_nofill_black_20x20.svg" alt="delete_rounded_fill_black_20x20.svg" /></a>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CaseCodesIndex;
