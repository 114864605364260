import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../../useToken.js';
import prodOrDev from '../../../utils.js';
import parse from 'html-react-parser';

export function ViewThreatActor() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { threatActorId } = useParams();

  // Data
  const [threatActor, setThreatActor] = useState({
    threat_actor_id: threatActorId,
    threat_actor_name: '',
    threat_actor_short_description: '',
    threat_actor_first_seen_date: '',
    threat_actor_first_seen_date_saying: '',
    threat_actor_country_of_origin_name: '',
    threat_actor_country_of_origin_flag: '',
    threat_actor_country_of_origin_two: '',
    threat_actor_country_of_origin_three: '',
    threat_actor_country_of_attribution_name: '',
    threat_actor_country_of_attribution_flag: '',
    threat_actor_country_of_attribution_two: '',
    threat_actor_country_of_attribution_three: '',
    threat_actor_description: '',
    threat_actor_motivation: '', 
    threat_actor_capability: '',
    threat_actor_tools: '',
    threat_actor_techniques: '',
    threat_actor_infrastructure: '',
    threat_actor_counter_operations: '',
    threat_actor_sources: '',
    threat_actor_mitre_attck_link_name: '',
    threat_actor_mitre_attck_link_url: '',
    threat_actor_dangerous_number: '',
    threat_actor_dangerous_title: '',
    threat_actor_logo_path: '',
    threat_actor_logo_file: '',
    threat_actor_created_date: '',
    threat_actor_created_date_saying: '',
    threat_actor_created_by_user_id: '',
    threat_actor_created_by_display_name: '',
    threat_actor_updated_date: '',
    threat_actor_updated_date_saying: '',
    threat_actor_updated_by_user_id: '',
    threat_actor_updated_by_display_name: '',
    threat_actor_alternative_names: [],
    threat_actor_operations_performed: [],
    threat_actor_targets: []
  });
  const [threatActorAlternativeNames, setThreatActorAlternativeNames] = useState(() => []);
  const [threatActorOperationsPerformed, setThreatActorOperationsPerformed] = useState(() => []);
  const [threatActorTargets, setThreatActorTargets] = useState(() => []);

  // Load data
  const loadThreatActor = async (e) => {
    let result = await fetch(backendURL + '/api/threat-actors/get-threat-actor?threat_actor_id=' + threatActorId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setThreatActor(resultJson);
      document.getElementById('main_box').style.display = 'block';

      // Threat actor alternative names
      setThreatActorAlternativeNames(
        resultJson['threat_actor_alternative_names'].map((fetch_object) => {
          return fetch_object;
        })
      );

      // Threat actor operations performed
      setThreatActorOperationsPerformed(
        resultJson['threat_actor_operations_performed'].map((fetch_object) => {
          return fetch_object;
        })
      );

      // Threat actor operations performed
      setThreatActorTargets(
        resultJson['threat_actor_targets'].map((fetch_object) => {
          return fetch_object;
        })
      );

    }
  };
  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadThreatActor();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/intelligence">Intelligence</a>
          &nbsp; / &nbsp;
          <a href="/intelligence/threat-actors">Threat Actors</a>
          &nbsp; / &nbsp;
          <a href={`/intelligence/threat-actors/view-threat-actor/${threatActorId}`}>{threatActor.threat_actor_name}</a>
        </p>
      </div>
      <div className="clear"></div>

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
      <h1>{threatActor.threat_actor_name}</h1>
        <h1 style={{ background: '#000000', color: '#ffc000',fontWeight: 'bold', marginLeft: 'auto', paddingRight: '0.7rem' }}>TLP: AMBER+STRICT </h1>
      </div>

      {/* Main box */}
      <div className="main_box" id="main_box" style={{ display: 'none' }}>
        

        {/* Actions */}
        <p>
        <a href={`/intelligence/threat-actors/edit-threat-actor/${threatActor.threat_actor_id}`} className='btn_default'><img src={'/gfx/icons/20x20/edit_rounded_nofill_black_20x20.svg'} alt="edit_rounded_nofill_black_20x20.svg" /> Edit</a>
        <a href={`/intelligence/threat-actors/delete-threat-actor/${threatActor.threat_actor_id}`} className='btn_default'><img src={'/gfx/icons/20x20/delete_rounded_nofill_black_20x20.svg'} alt="delete_rounded_nofill_black_20x20.svg" /> Delete</a>
        </p>


        <p>
        {threatActor.threat_actor_short_description}
        </p>

      
        {/* Altnernative names */}
        <table>
         <tbody>

          <tr>
           <td style={{padding: "4px 5px 4px 0px"}}>
            <span><b>Names:</b></span>
           </td>
           <td style={{padding: "4px 25px 4px 0px"}} colSpan={4}>
              {threatActorAlternativeNames.map((item, index) => (
                  <span key={index}>&nbsp;
                  <a href={`/intelligence/threat-actors/delete-alternative-name/${item.name_id}`} className='tag'>{item.name_value}</a>
                  </span>
              ))}
              <span>
              <a href={`/intelligence/threat-actors/new-alternative-name/${threatActor.threat_actor_id}`}><img src={'/gfx/icons/20x20/edit_rounded_nofill_black_20x20.svg'} alt="edit_rounded_nofill_black_20x20.svg" /></a>
              </span>
           </td>
          </tr>


        {/* Mitre */}
          <tr>
           <td style={{padding: "4px 5px 4px 0px"}}>
            <span><b>Mitre Att&amp;ck:</b></span>
           </td>
           <td style={{padding: "4px 25px 4px 0px"}} colSpan={4}>
              <span>
              <a href={`${threatActor.threat_actor_mitre_attck_link_url}`}>{threatActor.threat_actor_mitre_attck_link_name}</a>
              </span>
           </td>
          </tr>

        {/* Dangerous and First seen */}
        <tr>
           <td style={{padding: "4px 5px 4px 0px"}}>
            <span><b>Dangerous:</b></span>
           </td>
           <td style={{padding: "4px 25px 4px 0px"}}>
              <span>
              {threatActor.threat_actor_dangerous_title}
              </span>
           </td>

           <td style={{padding: "4px 5px 4px 0px"}}>
            <span><b>First seen:</b></span>
           </td>
           <td style={{padding: "4px 25px 4px 0px"}}>
              <span>
              {threatActor.threat_actor_first_seen_date_saying}
              </span>
           </td>
          </tr>


        {/* Quick facts */}
          <tr>
           <td style={{padding: "4px 5px 4px 0px"}}>
            <span><b>Country of origin:</b></span>
           </td>
           <td style={{padding: "4px 25px 4px 0px"}}>
              <span>
              {threatActor.threat_actor_country_of_origin_three}
              </span>
           </td>

           
           <td style={{padding: "4px 5px 4px 0px"}}>
            <span><b>Country of attribution:</b></span>
           </td>
           <td style={{padding: "4px 25px 4px 0px"}}>
              <span>
              {threatActor.threat_actor_country_of_attribution_three}
              </span>
           </td>
          </tr>


         </tbody>
        </table>
      </div> {/* // main_box */}


      {/* Descriotion */}
      <h2>Description</h2>
      
      <div className="main_box">
        <a href={`/intelligence/threat-actors/edit-threat-actor/${threatActor.threat_actor_id}#description`} style={{float: "right", padding: "0px 0px 4px 4px"}}><img src={'/gfx/icons/20x20/edit_rounded_nofill_black_20x20.svg'} alt="edit_rounded_nofill_black_20x20.svg" /></a>
        {typeof threatActor.threat_actor_description == 'string' ? (
          <div>{parse(threatActor?.threat_actor_description)}</div>
        ) : (
        <div className="warning_smal">
          <p>Missing description!</p>
        </div>
        )}
      </div>

      {/* Targets */}
      <h2>Targets</h2>

      <div className="main_box">
        <p>
        <a href={`/intelligence/threat-actors/new-target/${threatActor.threat_actor_id}`} className='btn_default'><img src={'/gfx/icons/20x20/add_rounded_nofill_black_20x20.svg'} alt="add_rounded_nofill_black_20x20.svg" /> New Target</a>
        </p>
        
        {/* Targets Table */}
        <table className="hor-zebra">
         <thead>
          <tr>
           <th>
                <span>Country</span>
            </th>
            <th>
                <span>Sector</span>
            </th>
            <th>
                <span>Date</span>
           </th>
          </tr>
         </thead>
         <tbody>

        {threatActorTargets.map((item, index) => (
          <tr key={index}>
           <td>
                <span><a href={`/intelligence/threat-actors/view-target/${item.target_id}`}>{item.target_country_three}</a></span>
           </td>
           <td>
                <span>{item.target_sector}</span>
           </td>
           <td>
                <span>{item.target_date_saying}</span>
           </td>
          </tr>
        ))}
         </tbody>
        </table>
      </div>

      {/* Operations performed */}
      <h2>Operations performed</h2>

      <div className="main_box">
        <p>
        <a href={`/intelligence/threat-actors/new-operation-performed/${threatActor.threat_actor_id}`} className='btn_default'><img src={'/gfx/icons/20x20/add_rounded_nofill_black_20x20.svg'} alt="add_rounded_nofill_black_20x20.svg" /> New Operation Performed</a>
        </p>
        
        {/* Operations performed Table */}
        
        <table className="hor-zebra">
         <thead>
          <tr>
           <th>
                <span>Title</span>
            </th>
            <th>
                <span>Description</span>
            </th>
            <th>
                <span>Date</span>
           </th>
          </tr>
         </thead>
         <tbody>

        {threatActorOperationsPerformed.map((item, index) => (
          <tr key={index}>
           <td>
                <span><a href={`/intelligence/threat-actors/view-operation-performed/${item.performed_id}`}>{item.performed_title}</a></span>
           </td>
           <td>
                <span>{item.performed_short_description}</span>
           </td>
           <td>
                <span>{item.performed_date_saying}</span>
           </td>
          </tr>
        ))}
         </tbody>
        </table>
      </div>
      

      {/* Motivation */}
      <h2>Motivation</h2>
      
      <div className="main_box">
        <a href={`/intelligence/threat-actors/edit-threat-actor/${threatActor.threat_actor_id}#motivation`} style={{float: "right", padding: "0px 0px 4px 4px"}}><img src={'/gfx/icons/20x20/edit_rounded_nofill_black_20x20.svg'} alt="edit_rounded_nofill_black_20x20.svg" /></a>
        {typeof threatActor.threat_actor_motivation == 'string' ? (
          <div>{parse(threatActor?.threat_actor_motivation)}</div>
        ) : (
        <div className="warning_smal">
          <p>Missing motivation!</p>
        </div>
        )}
      </div>

      {/* Capability */}
      <h2>Capability</h2>
      
      <div className="main_box">
        <a href={`/intelligence/threat-actors/edit-threat-actor/${threatActor.threat_actor_id}#capability`} style={{float: "right", padding: "0px 0px 4px 4px"}}><img src={'/gfx/icons/20x20/edit_rounded_nofill_black_20x20.svg'} alt="edit_rounded_nofill_black_20x20.svg" /></a>
        {typeof threatActor.threat_actor_capability == 'string' ? (
          <div>{parse(threatActor?.threat_actor_capability)}</div>
        ) : (
        <div className="warning_smal">
          <p>Missing capability!</p>
        </div>
        )}
      </div>


      {/* Tools */}
      <h2>Tools</h2>
      
      <div className="main_box">
        <a href={`/intelligence/threat-actors/edit-threat-actor/${threatActor.threat_actor_id}#tools`} style={{float: "right", padding: "0px 0px 4px 4px"}}><img src={'/gfx/icons/20x20/edit_rounded_nofill_black_20x20.svg'} alt="edit_rounded_nofill_black_20x20.svg" /></a>
        {typeof threatActor.threat_actor_tools == 'string' ? (
          <div>{parse(threatActor?.threat_actor_tools)}</div>
        ) : (
        <div className="warning_smal">
          <p>Missing tools!</p>
        </div>
        )}
      </div>


      {/* Techniques */}
      <h2>Techniques</h2>
      
      <div className="main_box">
        <a href={`/intelligence/threat-actors/edit-threat-actor/${threatActor.threat_actor_id}#techniques`} style={{float: "right", padding: "0px 0px 4px 4px"}}><img src={'/gfx/icons/20x20/edit_rounded_nofill_black_20x20.svg'} alt="edit_rounded_nofill_black_20x20.svg" /></a>
        {typeof threatActor.threat_actor_techniques == 'string' ? (
          <div>{parse(threatActor?.threat_actor_techniques)}</div>
        ) : (
        <div className="warning_smal">
          <p>Missing techniques!</p>
        </div>
        )}
      </div>

      {/* Infrastructure */}
      <h2>Infrastructure</h2>
      
      <div className="main_box">
        <a href={`/intelligence/threat-actors/edit-threat-actor/${threatActor.threat_actor_id}#infrastructure`} style={{float: "right", padding: "0px 0px 4px 4px"}}><img src={'/gfx/icons/20x20/edit_rounded_nofill_black_20x20.svg'} alt="edit_rounded_nofill_black_20x20.svg" /></a>
        {typeof threatActor.threat_actor_infrastructure == 'string' ? (
          <div>{parse(threatActor?.threat_actor_infrastructure)}</div>
        ) : (
        <div className="warning_smal">
          <p>Missing infrastructure!</p>
        </div>
        )}
      </div>
      
      {/* Counter operations */}
      <h2>Counter operations</h2>
      
      <div className="main_box">
        <a href={`/intelligence/threat-actors/edit-threat-actor-counter/${threatActor.threat_actor_id}#operations`} style={{float: "right", padding: "0px 0px 4px 4px"}}><img src={'/gfx/icons/20x20/edit_rounded_nofill_black_20x20.svg'} alt="edit_rounded_nofill_black_20x20.svg" /></a>
        {typeof threatActor.threat_actor_counter_operations == 'string' ? (
          <div>{parse(threatActor?.threat_actor_counter_operations)}</div>
        ) : (
        <div className="warning_smal">
          <p>Missing Counter operations!</p>
        </div>
        )}
      </div>

      {/* sources */}
      <h2>Sources</h2>
      
      <div className="main_box">
        <a href={`/intelligence/threat-actors/edit-threat-actor/${threatActor.threat_actor_id}#sources`} style={{float: "right", padding: "0px 0px 4px 4px"}}><img src={'/gfx/icons/20x20/edit_rounded_nofill_black_20x20.svg'} alt="edit_rounded_nofill_black_20x20.svg" /></a>
        {typeof threatActor.threat_actor_sources == 'string' ? (
          <div>{parse(threatActor?.threat_actor_sources)}</div>
        ) : (
        <div className="warning_smal">
          <p>Missing sources!</p>
        </div>
        )}
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
          <h1 style={{ background: '#000000', color: '#ffc000',fontWeight: 'bold', marginLeft: 'auto', paddingRight: '0.7rem' }}>TLP: AMBER+STRICT </h1>
        </div>

    </div>
  );
}

export default ViewThreatActor;
