import { useContext } from 'react';

export function About() {
  return (
    <div>
      <h1>About</h1>
      <div className="main_box">
        <p>CDC 24 lets you have a overview of customers at Advania Cyber Defense Center. The backend is programmed with PyCharm in Python and has a PostgreSQL database. The frontend is developed with Visual Studio Code in ReactJS.</p>

        <p>
          You can request changes to this application by creating a card to our Kanban Board. Systems Egnineering will implement changes continuously. <br />
          <br />
          <a href="https://advcyber.atlassian.net/jira/core/projects/M22/board" className="btn_default">
            Request a change
          </a>
        </p>
      </div>

      <h2>Authors</h2>
      <div className="main_box">
        <p>The application was created in 2022 by Sindre Ditlefsen at Advania Cyber Defence Center - Systems Engineering as a part of the migration process from Azure to Google. It is a part of the automaticly onboarding system of customers.</p>
      </div>
    </div>
  );
}

export default About;
