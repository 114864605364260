import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

export function DeleteAffiliation() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { affiliationId } = useParams();

  // Data
  const [customerIndex, setCustomerIndex] = useState(() => []);
  const [customerAffiliation, setCustomerAffiliation] = useState(() => []);

  // Load data
  const loadCustomerAffiliation = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get_customer_affiliation', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        affiliation_id: affiliationId
      })
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setCustomerAffiliation(resultJson);
      customerAffiliation.affiliation_customer_id = resultJson['affiliation_customer_id'];
      loadCustomerIndex();
      document.getElementById('show_customer').style.display = 'block';
    }
  };
  // Load customer index
  const loadCustomerIndex = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get_customer?customer_id=' + customerAffiliation.affiliation_customer_id, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setCustomerIndex(resultJson);
    }
  };

  // Confirm delete
  async function confirmDelete(e) {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/customers/delete_customer_affiliation', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify({
        affiliation_id: affiliationId,
        customer_id: customerAffiliation.affiliation_customer_id
      })
    });
    let resultJson = await result.json();

    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      window.history.back();
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
    }
  }

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadCustomerAffiliation();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div id="show_customer" style={{ display: 'none' }}>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/">Customers</a>
          &nbsp; / &nbsp;
          <a href={`/customers/view_customer/${customerAffiliation.affiliation_customer_id}`}>{customerIndex.customer_name_abbreviated}</a>
          &nbsp; / &nbsp;
          <a href={`/customers/view_affiliations/${customerAffiliation.affiliation_customer_id}`}>Affilations</a>
          &nbsp; / &nbsp;
          <a href={`/customers/delete_affiliation/${customerAffiliation.affiliation_id}`}>Delete affilation</a>
        </p>
      </div>
      <div className="clear"></div>

      {/* Headline and actions */}
      <h1>Affliations for {customerIndex.customer_name}</h1>

      <div className="main_box">
        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        <p>Are you sure you want to delete the affilation?</p>

        <p>
          <a href="#delete" className="btn_danger" onClick={confirmDelete}>
            Confirm
          </a>
        </p>
      </div>
    </div>
  );
}

export default DeleteAffiliation;
