import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../../useToken.js';
import prodOrDev from '../../../utils.js';

export function DeleteAllowedIP() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { ipId } = useParams();

  const [allowedIP, setAllowedIP] = useState({
    ip_id: ipId,
    ip_api_id: '',
    ip_title: '',
    ip_ip: '',
    ip_last_used_timestamp: '',
    ip_last_used_datetime_saying: '',
    ip_created_timestamp: '',
    ip_created_date_saying: '',
    ip_created_by_user_email: '',
    ip_updated_timestamp: '',
    ip_updated_date_saying: '',
    ip_updated_by_user_email: ''
  });
  const [api, setAPI] = useState();

  // Load allowed IP
  const loadAllowedIP = async (e) => {
    let result = await fetch(backendURL + '/api/apis/get_api_allowed_ip', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify({
        ip_id: ipId
      })
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setAllowedIP(resultJson);
      // Display form (last element is loaded)
      document.getElementById('form').style.display = 'block';
      allowedIP.ip_api_id = resultJson['ip_api_id'];
      loadAPI();
    }
  };

  // Load API
  const loadAPI = async (e) => {
    let result = await fetch(backendURL + '/api/apis/get_api', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify({
        api_id: allowedIP.ip_api_id
      })
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setAPI(resultJson);
    }
  };

  // Confirm delete
  async function confirmDelete(e) {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/apis/delete_api_allowed_ip', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify({
        ip_id: ipId
      })
    });
    let resultJson = await result.json();

    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      // window.history.back();
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
    }
  }
  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadAllowedIP();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/">Settings</a>
          &nbsp; / &nbsp;
          <a href={`/settings/apis`}>APIs</a>
          &nbsp; / &nbsp;
          <a href={`/settings/apis/view_api/${allowedIP.ip_api_id}`}>API</a>
          &nbsp; / &nbsp;
          <a href={`/settings/apis/api_allowed_ips_index/${allowedIP.ip_api_id}`}>API Allowed IPs {allowedIP.ip_title}</a>
          &nbsp; / &nbsp;
          <a href={`/settings/apis/delete_allowed_ip/${allowedIP.ip_id}`}>Delete API Permissions</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>Delete API permission {allowedIP.ip_title}</h1>
      <div className="main_box" id="form" style={{ display: 'none' }}>
        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        {/* Form */}
        <p>Are you sure you want to delete the allowed IP? The action cannot be undone!</p>

        <p>
          <b>Title:</b> {allowedIP.ip_title}
          <br />
          <b>IP:</b> {allowedIP.ip_ip}
          <br />
        </p>

        <p>
          <a href="#delete" className="btn_danger" onClick={confirmDelete}>
            Confirm
          </a>
        </p>
      </div>
    </div>
  );
}
export default DeleteAllowedIP;
