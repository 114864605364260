import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';


export function ViewCollector() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { collectorId } = useParams();

  // Data
  const [collector, setCollector] = useState({
    collector_id: collectorId,
    collector_customer_id: '', 
    collector_customer_abbreviation: '', 
    collector_hostname: '', 
    collector_ip_now: '',
    collector_ip_now_date: '',
    collector_ip_now_date_saying: '',
    collector_ip_historic: '',
    collector_ip_historic_date: '',
    collector_ip_historic_date_saying: '',
    collector_os: '',
    collector_os_version: '',
    collector_os_eol_date: '',
    collector_os_eol_saying: '',
    collector_os_eol_notified_year: '',
    collector_os_eol_emailed: '',
    collector_os_eol_slack: '',
    collector_created_datetime: '',
    collector_created_date_saying: '',
    collector_created_by_ip: '',
    collector_updated_datetime: '',
    collector_updated_date_saying: '',
    collector_updated_by_ip: ''
  });
  const [certs, setCerts] = useState([]);
  const [clients, setClients] = useState([]);

  // Load data
  const loadCollector = async (e) => {
    let result = await fetch(backendURL + '/api/collectors/get-collector?collector_id=' + collectorId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setCollector(resultJson);
      document.getElementById('main_box').style.display = 'block';

    }
  };
  const loadCertificates = async (e) => {
    let result = await fetch(backendURL + '/api/collectors/get-certs?collector_id=' + collectorId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setCerts(resultJson);

    }
  };
  const loadClients = async (e) => {
    let result = await fetch(backendURL + '/api/collectors/get-clients?collector_id=' + collectorId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setClients(resultJson);

    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadCollector();
      loadCertificates();
      loadClients();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/collectors">Collectors</a>
          &nbsp; / &nbsp;
          <a href={`/collectors/view-collector/${collector.collector_id}`}>{collector.collector_hostname}</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>{collector.collector_hostname}</h1>


      {/* Collector info */}
      <div className="main_box" id="main_box" style={{ display: 'none' }}>

        <p>
        <a href={`/collectors/edit-collector/${collector.collector_id}`} className='btn_default'><img src={'/gfx/icons/20x20/edit_rounded_nofill_black_20x20.svg'} alt="edit_rounded_nofill_black_20x20.svg" /> Edit collector</a>
        &nbsp;
        <a href={`/collectors/delete-collector/${collector.collector_id}`} className='btn_default'><img src={'/gfx/icons/20x20/delete_rounded_nofill_black_20x20.svg'} alt="delete_rounded_nofill_black_20x20.svg" /> Delete collector</a>
        </p>

        <div className='left' style={{paddingRight: "40px"}}>
          <table>
           <tbody>
            <tr>
             <td style={{padding: "0px 5px 5px 0px"}}>
                  <span>Customer:</span>
             </td>
             <td style={{padding: "0px 5px 5px 0px"}}>
                  <span>{collector.collector_customer_abbreviation}</span>
             </td>
            </tr>
            <tr>
             <td style={{padding: "0px 5px 5px 0px"}}>
                  <span>Hostname:</span>
             </td>
             <td style={{padding: "0px 5px 5px 0px"}}>
                  <span>{collector.collector_hostname}</span>
             </td>
            </tr>
            <tr>
             <td style={{padding: "0px 5px 5px 0px"}}>
                  <span>IP now:</span>
             </td>
             <td style={{padding: "0px 5px 5px 0px"}}>
                  <span>{collector.sql_collector_ip_now}
                  ({collector.sql_collector_ip_now_date_saying})
                  </span>
             </td>
            </tr>
            <tr>
             <td style={{padding: "0px 5px 5px 0px"}}>
                  <span>IP histoic:</span>
             </td>
             <td style={{padding: "0px 5px 5px 0px"}}>
                  <span>{collector.collector_ip_historic}
                  ({collector.collector_ip_historic_date_saying})
                  </span>
             </td>
            </tr>
           </tbody>
          </table>
        </div>

        <div className='left' style={{paddingRight: "40px"}}>
          <table>
           <tbody>
            <tr>
             <td style={{padding: "0px 5px 5px 0px"}}>
                  <span>OS:</span>
             </td>
             <td style={{padding: "0px 5px 5px 0px"}}>
                  <span>{collector.collector_os} {collector.collector_os_version}</span>
             </td>
            </tr>
            <tr>
             <td style={{padding: "0px 5px 5px 0px"}}>
                  <span>OS EOL:</span>
             </td>
             <td style={{padding: "0px 5px 5px 0px"}}>
                  <span>{collector.collector_os_eol_saying}</span>
             </td>
            </tr>
            <tr>
             <td style={{padding: "0px 5px 5px 0px"}}>
                  <span>Notified year:</span>
             </td>
             <td style={{padding: "0px 5px 5px 0px"}}>
                  <span>{collector.collector_os_eol_notified_year}</span>
             </td>
            </tr>
            <tr>
             <td style={{padding: "0px 5px 5px 0px"}}>
                  <span>Notified email:</span>
             </td>
             <td style={{padding: "0px 5px 5px 0px"}}>
                  <span>{collector.collector_os_eol_emailed}</span>
             </td>
            </tr>
            <tr>
             <td style={{padding: "0px 5px 5px 0px"}}>
                  <span>Notified slack:</span>
             </td>
             <td style={{padding: "0px 5px 5px 0px"}}>
                  <span>{collector.collector_os_eol_slack}</span>
             </td>
            </tr>
           </tbody>
          </table>
        </div>

        
        
        <div className='left' style={{paddingRight: "40px"}}>
          <table>
           <tbody>
            <tr>
             <td style={{padding: "0px 5px 5px 0px"}}>
                  <span>Created:</span>
             </td>
             <td style={{padding: "0px 5px 5px 0px"}}>
                  <span>{collector.collector_created_date_saying}</span>
             </td>
            </tr>
            <tr>
             <td style={{padding: "0px 5px 5px 0px"}}>
                  <span>Created by IP:</span>
             </td>
             <td style={{padding: "0px 5px 5px 0px"}}>
                  <span>{collector.collector_created_by_ip}</span>
             </td>
            </tr>
            <tr>
             <td style={{padding: "0px 5px 5px 0px"}}>
                  <span>Updated:</span>
             </td>
             <td style={{padding: "0px 5px 5px 0px"}}>
                  <span>{collector.collector_updated_date_saying}</span>
             </td>
            </tr>
            <tr>
             <td style={{padding: "0px 5px 5px 0px"}}>
                  <span>Updated by IP:</span>
             </td>
             <td style={{padding: "0px 5px 5px 0px"}}>
                  <span>{collector.collector_updated_by_ip}</span>
             </td>
            </tr>
           </tbody>
          </table>
        </div>
        <div className="clear"></div>

      </div> {/* //Collector info */}

      <h2>Certificate</h2>
      <div className="main_box">
        
        <p>
        <a href={`https://console.cloud.google.com/storage/browser/cdc24/certificates/${collector.collector_customer_abbreviation}`} className='btn_default'><img src={'/gfx/icons/20x20/cleaning_bucket_outlined_black_20x20.svg'} alt="cleaning_bucket_outlined_black_20x20.svg" /> Bucket</a>
        </p>
        {/* Certificate Table */}
        <table className="hor-zebra">
          <thead>
            <tr>
              <th>
                <span>Zip</span>
              </th>
              <th>
                <span>Valid to</span>
              </th>
              <th>
                <span>Email to</span>
              </th>
              <th>
                <span>Email cc</span>
              </th>
              <th>
                <span>Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {certs.map((item, index) => (
              <tr key={index}>
                <td>
                  <span>
                    <a href={`https://storage.cloud.google.com/cdc24/certificates/${item.cert_customer_customer_abbreviation}/${item.cert_zip_file_name}`}>{item.cert_zip_file_name}</a>
                  </span>
                </td>
                <td>
                  <span>
                  {item.cert_valid_to_date_saying}
                  </span>
                </td>
                <td>
                  <span>{item.cert_email_to}</span>
                </td>
                <td>
                  <span>{item.cert_email_cc}</span>
                </td>
                <td>
                  <span>
                  <a href={`/collectors/edit-cert/${item.cert_id}`}>Edit</a>
                  &nbsp; | &nbsp;
                  <a href={`/collectors/delete-cert/${item.cert_id}`}>Delete</a>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

      </div>

      <h2>Clients</h2>
      <div className="main_box">
        <p>
        <a href={`/collectors/new-client/${collector.collector_id}`} className='btn_default'><img src={'/gfx/icons/20x20/add_rounded_nofill_black_20x20.svg'} alt="add_rounded_nofill_black_20x20.svg" /> New client</a>
        </p>

        {/* Clients Table */}
        <table className="hor-zebra">
          <thead>
            <tr>
              <th>
                <span>Name</span>
              </th>
              <th>
                <span>Type</span>
              </th>
              <th>
                <span>Sub domain</span>
              </th>
              <th>
                <span>Port</span>
              </th>
              <th>
                <span>Brand</span>
              </th>
              <th>
                <span>Admin</span>
              </th>
              <th>
                <span>Updated</span>
              </th>
              <th>
                <span>Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {clients.map((item, index) => (
              <tr key={index}>
                <td>
                  <span>
                  {item.client_name}
                  </span>
                </td>
                <td>
                  <span>
                  {item.client_type}
                  </span>
                </td>
                <td>
                  <span>{item.client_sub_domain}</span>
                </td>
                <td>
                  <span>{item.client_port}</span>
                </td>
                <td>
                  <span>{item.client_brand}</span>
                </td>
                <td>
                  <span>{item.client_administrator_email}</span>
                </td>
                <td>
                  <span>{item.client_updated_date_saying}</span>
                </td>
                <td>
                  
                  <span>
                  <a href={`/collectors/edit-client/${item.client_id}`}>Edit</a>
                  &nbsp; | &nbsp;
                  <a href={`/collectors/delete-client/${item.client_id}`}>Delete</a>
                  </span>

                </td>
              </tr>
            ))}
          </tbody>
         </table>
      </div>

    </div>
  );
}

export default ViewCollector;
