import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

export function ViewEngagementDefenderAddon() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { engagementId } = useParams();

  // Data
  const [engagementDefenderAddon, setEngagementDefenderAddon] = useState({
    defender_id: '',
    defender_engagement_id: engagementId,
    defender_customer_id: '',

    defender_created_datetime: '',
    defender_created_datetime_saying: '',
    defender_created_by_name: '',
    defender_created_by_email: '', 
    defender_updated_datetime: '',
    defender_updated_datetime_saying: '',

    defender_auto_close_issues_value: '',
    defender_auto_close_issues_max_level: '',

    customer_name: '',
    customer_name_abbreviated: '',

    engagement_service_name: '',
    engagement_service_flavor: ''
  });

  // Load engagement status
  const loadEngagementDefenderAddon = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get-customer-engagement-defender-addon?engagement_id=' + engagementId, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setEngagementDefenderAddon(resultJson);
      document.getElementById('show_customer').style.display = 'block';
    }
  };

  // Form data chaned
  const handleChange = (e) => {
    setEngagementDefenderAddon({
      ...engagementDefenderAddon,
      [e.target.name]: e.target.value
    });
  };


  // Send new contact
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/customers/edit-customer-engagement-defender-addon', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(engagementDefenderAddon)
    });
    let resultJson = await result.json();
    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadEngagementDefenderAddon();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div id="show_customer" style={{ display: 'none' }}>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/">Customers</a>
          &nbsp; / &nbsp;
          <a href={`/customers/view_customer/${engagementDefenderAddon.defender_customer_id}`}>{engagementDefenderAddon.customer_name_abbreviated}</a>
          &nbsp; / &nbsp;
        </p>
      </div>
      <div className="clear"></div>

      {/* Headline and actions */}
      <h1>{engagementDefenderAddon.customer_name}</h1>

      {/* Customer tabs */}
      <ul className="tabs">
        <li><a href={`/customers/view_customer/${engagementDefenderAddon.defender_customer_id}`}>Index</a></li>
        <li><a href={`/customers/view_business/${engagementDefenderAddon.defender_customer_id}`}>Business</a></li>
        <li><a href={`/customers/view_identifiers/${engagementDefenderAddon.defender_customer_id}`}>Identifiers</a></li>
        <li><a href={`/customers/view_engagements/${engagementDefenderAddon.defender_customer_id}`} className="active">Engagements</a></li>
      </ul>

      <div className="main_box">
        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        {/* Form */}
        <form onSubmit={handleSubmit} id="form">
          {/* General information */}
          <h2 style={{ paddingLeft: 0 }}>General information</h2>
          <div style={{ float: 'left', marginRight: '20px' }}>
            <table>
              <tbody>
                <tr>
                  <td style={{ padding: '0px 5px 0px 0px' }}>
                    <span>
                      <b>Defender ID:</b>
                    </span>
                  </td>
                  <td>
                    <span>{engagementDefenderAddon.defender_id}</span>
                  </td>
                </tr>

                <tr>
                  <td style={{ padding: '0px 5px 0px 0px' }}>
                    <span>
                      <b>Egagement ID:</b>
                    </span>
                  </td>
                  <td>
                    <span>{engagementDefenderAddon.defender_engagement_id}</span>
                  </td>
                </tr>

                <tr>
                  <td style={{ padding: '0px 5px 0px 0px' }}>
                    <span>
                      <b>Customer ID:</b>
                    </span>
                  </td>
                  <td>
                    <span>{engagementDefenderAddon.defender_customer_id}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{ float: 'left', marginRight: '20px' }}>
            <table>
              <tbody>
                <tr>
                  <td style={{ padding: '0px 5px 0px 0px' }}>
                    <span>
                      <b>Service name:</b>
                    </span>
                  </td>
                  <td>
                    <span>{engagementDefenderAddon.engagement_service_name}</span>
                  </td>
                </tr>

                <tr>
                  <td style={{ padding: '0px 5px 0px 0px' }}>
                    <span>
                      <b>Service flavor:</b>
                    </span>
                  </td>
                  <td>
                    <span>{engagementDefenderAddon.engagement_service_flavor}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{ float: 'left', marginRight: '20px' }}>
            <table>
              <tbody>
                <tr>
                  <td style={{ padding: '0px 5px 0px 0px' }}>
                    <span>
                      <b>Created:</b>
                    </span>
                  </td>
                  <td>
                    <span>{engagementDefenderAddon.defender_created_datetime_saying}</span>
                  </td>
                </tr>

                <tr>
                  <td style={{ padding: '0px 5px 0px 0px' }}>
                    <span>
                      <b>Created by name:</b>
                    </span>
                  </td>
                  <td>
                    <span>{engagementDefenderAddon.defender_created_by_name}</span>
                  </td>
                </tr>

                <tr>
                  <td style={{ padding: '0px 5px 0px 0px' }}>
                    <span>
                      <b>Created by email:</b>
                    </span>
                  </td>
                  <td>
                    <span>{engagementDefenderAddon.defender_created_by_email}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>{' '}
          {/* //left */}
          <div style={{ float: 'left' }}>
            <table>
              <tbody>
                <tr>
                  <td style={{ padding: '0px 5px 0px 0px' }}>
                    <span>
                      <b>Updated:</b>
                    </span>
                  </td>
                  <td>
                    <span>{engagementDefenderAddon.defender_updated_datetime_saying}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>{' '}
          {/* //left */}
          <div className="clear"></div>
          {/* //General information */}

          {/* Form */}
          <p style={{ padding: '10px 0px 0px 0px' }}><b>Auto close</b></p>
          <table>
            <tbody>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Auto close issues:</span>
                </td>
                <td>
                  <span>
                  <select name="defender_auto_close_issues_value" value={engagementDefenderAddon.defender_auto_close_issues_value} onChange={handleChange}>
                    <option value="1">True</option>
                    <option value="0">False</option>
                  </select>
                  </span>
                </td>
              </tr>


              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Auto close issues max level:</span>
                </td>
                <td>
                  <span>
                  <select name="defender_auto_close_issues_max_level"  value={engagementDefenderAddon.defender_auto_close_issues_max_level} onChange={handleChange}>
                    <option value="informational">Informational</option>
                    <option value="low">Low</option>
                    <option value="medium">Medium</option>
                    <option value="high">High</option>
                  </select>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            <button>Submit</button>
          </p>
        </form>
      </div>
    </div>
  );
}

export default ViewEngagementDefenderAddon;
