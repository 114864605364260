import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';
import NewEngagementLC from '../../pages/Customers/components/NewEngagementLC.js';
import NewEngagementFortraSecpod from '../../pages/Customers/components/NewEngagementFortraSecpod.js';

export function NewEngagement() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { customerId } = useParams();
  const [engagementStatus, setEngagementStatus] = useState({
    engagement_customer_id: customerId,
    engagement_service_name: ''
  });
  const [customerIndex, setCustomerIndex] = useState(() => []);

  // Load customer
  const loadCustomerIndex = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get_customer?customer_id=' + customerId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setCustomerIndex(resultJson);
      // Display form (last element is loaded)
      document.getElementById('form').style.display = 'block';
      // Focus
      document.forms['form'].elements['status_service_name'].focus();
    }
  };
  // IMPROVE? Clear status_sensors_and_ingest_method and other data when changing form type
  // Form data chaned
  const handleChange = (e) => {
    setEngagementStatus({
      ...engagementStatus,
      [e.target.name]: e.target.value
    });
  };

  // Send new engagement
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/customers/new_customer_engagement', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(engagementStatus)
    });
    let resultJson = await result.json();
    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadCustomerIndex();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/">Customers</a>
          &nbsp; / &nbsp;
          <a href={`/customers/view_customer/${customerId}`}>{customerIndex.customer_name_abbreviated}</a>
          &nbsp; / &nbsp;
          <a href={`/customers/view_engagements/${customerId}`}>Engagements</a>
          &nbsp; / &nbsp;
          <a href={`/customers/new_engagement/${customerId}`}>New engagement</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>New engagement {customerIndex.customer_name_abbreviated}</h1>
      <form onSubmit={handleSubmit} id="form" style={{ display: 'none' }}>
        <div className="main_box">
          {/* Feedback */}
          <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
            <p id="feedback_p">Feedback box is here</p>
          </div>

          {/* Form */}

          <p>
            Service name
            <br />
            <select name="engagement_service_name" value={engagementStatus.status_service_name} onChange={handleChange}>
              <option value="N/A">Please select</option>
              <option value="Security Intrusion Detection and Response">Security Intrusion Detection and Response</option>
              <option value="Tactical Security Intrusion Detection and Response">Tactical Security Intrusion Detection and Response</option>
              <option value="LimaCharlie">LimaCharlie</option>
              <option value="Fortra Frontline">Fortra Frontline</option>
              <option value="SecPod SanerNow">SecPod SanerNow</option>
              <option value="Defender Addon">Defender Addon</option>
            </select>
          </p>

          {/* LimaCharlie */}
          {engagementStatus.engagement_service_name.toLowerCase() == 'limacharlie' ? <NewEngagementLC engagementStatus={engagementStatus} handleChange={handleChange}></NewEngagementLC> : <></>}
          {/* //LimaCharlie */}

          {/* Fortra Frontline */}
          {engagementStatus.engagement_service_name.toLowerCase() == 'fortra frontline' ? <NewEngagementFortraSecpod engagementStatus={engagementStatus} handleChange={handleChange} serviceName="Fortra Frontline"></NewEngagementFortraSecpod> : <></>}
          {/* //Fortra Frontline */}

          {/* SecPod SanerNow */}
          {engagementStatus.engagement_service_name.toLowerCase() == 'secpod sanernow' ? <NewEngagementFortraSecpod engagementStatus={engagementStatus} handleChange={handleChange} serviceName="SecPod SanerNow"></NewEngagementFortraSecpod> : <></>}
          {/* //SecPod SanerNow */}

          <p>
            <button>Submit</button>
          </p>
        </div>
      </form>
    </div>
  );
}

export default NewEngagement;