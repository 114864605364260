import { NavLink, Outlet, Link } from 'react-router-dom';
import useToken from '../useToken.js';

const Layout = () => {
  const { userObject } = useToken();

  return (
    <>
      <header>
        {/* Header left */}
        <div id="header_left">
          <a href="/"><img src="/gfx/layout/a_square_logo_32x49.png" alt="a_square_logo_32x49.png" /><span>CDC 24</span></a>
        </div>
        {/* Header center */}
        <div id="header_center">
          <nav>
            <ul>
              <li><NavLink to="/about" className={({ isActive }) => (isActive ? 'active' : '')}>About</NavLink></li>
              <li><NavLink to="/settings" className={({ isActive }) => (isActive ? 'active' : '')}>Settings</NavLink></li>
              <li><a href="https://github.com/orgs/advcdc/projects/10/views/1">General Board (Request a Change)</a></li>
              <li><a href="https://advcyber.atlassian.net/wiki/spaces/GENERAL/pages">Confluence</a></li>
              <li><NavLink to="/statistics" className={({ isActive }) => (isActive ? 'active' : '')}>Statistics</NavLink></li>
              <li><NavLink to="/intelligence" className={({ isActive }) => (isActive ? 'active' : '')}>Intelligence</NavLink></li>
              <li><NavLink to="/monitor" className={({ isActive }) => (isActive ? 'active' : '')}>Monitor</NavLink></li>
              <li><NavLink to="/monthly-reports" className={({ isActive }) => (isActive ? 'active' : '')}>Monthly Reports</NavLink></li>
              <li><NavLink to="/collectors" className={({ isActive }) => (isActive ? 'active' : '')}>Collectors</NavLink></li>
            </ul>
          </nav>
        </div>
        {/* Header right */}
        <div id="header_right">
          <ul>
            <li><a href="/profile"><img src={userObject?.picture} alt={userObject?.picture} width="32" height="32" className="picture" /></a></li>
            <li><a href="/logout"><img src="/gfx/icons/48x48/logout_rounded_nofill_black_48x48.svg" alt="logout_rounded_48x48.svg" width="32" height="32" /></a></li>
          </ul>
        </div>
      </header>

      {/* Main */}
      <main>
        <Outlet />
      </main>

      {/* Footer */}
      <footer>&copy; Advania 2022-2024</footer>
    </>
  );
};

export default Layout;
