import { useContext, useEffect } from 'react';
import useToken from '../../useToken.js';

export function Logout() {
  const { token, setToken } = useToken();

  useEffect(() => {
    localStorage.removeItem('tokenGoogleCDC');
    setToken(null);
    window.location.href = '../';
  }, []);

  return (
    <>
      <h1>Cya later</h1>
      <div className="main_box">
        <p>Bye thanks for now!</p>
      </div>
    </>
  );
}

export default Logout;
