import { useContext, useEffect, useState } from 'react';
import useToken from '../../../useToken.js';
import prodOrDev from '../../../utils.js';

export function CountriesIndex() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const [countries, setCountries] = useState(() => []);

  // Load data
  const loadData = async (e) => {
    let result = await fetch(backendURL + '/api/options/get_countries', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const resultJson = await result.json();
    if (result.status === 200) {
      setCountries(
        resultJson.map((fetch_object) => {
          return fetch_object;
        })
      );
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadData();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      <h1>Countries</h1>
      <div className="main_box">


        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        {/* Table */}
        <table className="hor-zebra">
          <thead>
            <tr>
              <th>
                <span>Name</span>
              </th>
              <th>
                <span>ISO2</span>
              </th>
              <th>
                <span>ISO3</span>
              </th>
              <th>
                <span>Emoji code</span>
              </th>
              <th>
                <span>Emoji char</span>
              </th>
              <th>
                <span>Flag active</span>
              </th>
              <th>
                <span>Flag inactive</span>
              </th>
              <th>
                <span>Created</span>
              </th>
              <th>
                <span>Updated</span>
              </th>
              <th>
                <span>Last used</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {countries.map((item, index) => (
              <tr key={index}>
                <td>
                  <span>{item.country_name}</span>
                </td>
                <td>
                  <span>{item.country_iso_two}</span>
                </td>
                <td>
                  <span>{item.country_iso_three}</span>
                </td>
                <td>
                  <span>{item.country_flag_emoji_code}</span>
                </td>
                <td>
                  <span>{item.country_flag_emoji_char}</span>
                </td>
                <td>
                  <span>{item.country_flag_active_20x20}</span>
                </td>
                <td>
                  <span>{item.country_flag_inactive_20x20}</span>
                </td>
                <td>
                  <span>{item.country_created_datetime}</span>
                </td>
                <td>
                  <span>{item.country_updated_datetime}</span>
                </td>
                <td>
                  <span>{item.country_last_used_datetime}</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CountriesIndex;
