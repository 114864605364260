import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../../useToken.js';
import prodOrDev from '../../../utils.js';

export function APIAllowedIPsIndex() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { apiId } = useParams();
  const [api, setAPI] = useState(() => []);
  const [apiAllowedIps, setAPIAllowedIps] = useState(() => []);

  // Load API
  const loadAPI = async (e) => {
    let result = await fetch(backendURL + '/api/apis/get_api', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify({
        api_id: apiId
      })
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setAPI(resultJson);

      // Load API Permissions
      loadAPIAllowedIps();
    }
  };
  // Load Allowed IPs
  const loadAPIAllowedIps = async (e) => {
    try {
      let response = await fetch(backendURL + '/api/apis/get_api_allowed_ips', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        },
        body: JSON.stringify({
          api_id: apiId
        })
      });
  
      if (response.status === 200 || response.status === 201) {
        let resultJson = await response.json();
  
        // Assuming resultJson is an array of objects with a 'date' property
        resultJson.sort((a, b) => new Date(b.ip_created_date_saying) - new Date(a.ip_created_date_saying));
  
        setAPIAllowedIps(resultJson);
  
        // Display form (last element is loaded)
        document.getElementById('form').style.display = 'block';
      }
    } catch (error) {
      console.error('Error loading API allowed IPs:', error);
    }
  };
  

  // Confirm delete
  async function confirmMakeIpAllowedOrDisallowed(ipId) {
    let result = await fetch(backendURL + '/api/apis/make_ip_allowed_or_disallowed', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify({
        ip_id: ipId
      })
    });
    let resultJson = await result.json();

    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;

      // Change span content
      var name = 'ip_is_allowed_' + ipId;
      var ipIsAllowedSpan = document.getElementById(name);
      ipIsAllowedSpan.innerHTML = resultMessage;
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
    }
  }

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadAPI();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/">Settings</a>
          &nbsp; / &nbsp;
          <a href={`/settings/apis`}>APIs</a>
          &nbsp; / &nbsp;
          <a href={`/settings/apis/view_api/${api.api_id}`}>{api.api_title}</a>
          &nbsp; / &nbsp;
          <a href={`/settings/apis/api_allowed_ips_index/${api.api_id}`}>API Allowed IPs {api.api_title}</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>API allowed IP for {api.api_title}</h1>
      <div className="main_box" id="form" style={{ display: 'none' }}>
        {/* Actions */}
        <p>
          <a href={`/settings/apis/new_api_allowed_ip/${apiId}`} className="btn_default">
            New allowed IP
          </a>
        </p>

        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        {/* Table */}
        <table className="hor-zebra">
          <thead>
            <tr>
              <th>
                <span>IP title</span>
              </th>
              <th>
                <span>IP</span>
              </th>
              <th>
                <span>Datetime(UTC)</span>
              </th>
              <th>
                <span>Allowed</span>
              </th>
              <th>
                <span>Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {apiAllowedIps.map((item, index) => (
              <tr key={index}>
                <td>
                  <span>{item.ip_title}</span>
                </td>
                <td>
                  <span>{item.ip_ip}</span>
                </td>
                <td>
                  <span>{item.ip_created_date_saying}</span>
                </td>
                <td>
                  {item.ip_is_allowed === 1 ? (
                    <span id={`ip_is_allowed_${item.ip_id}`}>
                      Allowed [
                      <a href={`#disallow${item.ip_id}`} onClick={() => confirmMakeIpAllowedOrDisallowed(item.ip_id)}>
                        Disallow
                      </a>
                      ]
                    </span>
                  ) : (
                    <span id={`ip_is_allowed_${item.ip_id}`}>
                      Not allowed [<a href={`/settings/apis/edit-allowed-ip/${item.ip_id}`}>Allow</a>]
                    </span>
                  )}
                </td>
                <td>
                  <span>
                    <a href={`/settings/apis/delete_allowed_ip/${item.ip_id}`}>
                      <img src="/gfx/icons/20x20/delete_rounded_nofill_black_20x20.svg" alt="delete_rounded_fill_black_20x20.svg" />
                    </a>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default APIAllowedIPsIndex;
