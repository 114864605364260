import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../../useToken.js';
import prodOrDev from '../../../utils.js';

export function EditAllowedIP() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { ipId } = useParams();

  const [api, setAPI] = useState({
    api_id: '',
    api_title: '',
    api_description: '',
    api_key_encrypted: '',
    api_valid_to_date: '',
    api_valid_to_date_saying: '',
    api_valid_to_warning_sent: '',
    api_created_timestamp: '',
    api_created_date_saying: '',
    api_created_by_user_email: '',
    api_updated_timestamp: '',
    api_updated_date_saying: '',
    api_updated_by_user_email: ''
  });

  const [ip, setIP] = useState({
    ip_id: '',
    ip_api_id: '',
    ip_title: '',
    ip_ip: '',
    ip_is_allowed: '',
    ip_last_used_timestamp: '',
    ip_last_used_datetime_saying: '',
    ip_created_timestamp: '',
    ip_created_date_saying: '',
    ip_created_by_user_email: '',
    ip_created_by_hostname: '',
    ip_created_by_user_agent: '',
    ip_allowed_disallowed_timestamp: '',
    ip_allowed_disallowed_date_saying: '',
    ip_allowed_disallowed_by_user_email: '',
    ip_updated_timestamp: '',
    ip_updated_date_saying: '',
    ip_updated_by_user_email: ''
  });

  // Load IP
  const loadIP = async (e) => {
    let result = await fetch(backendURL + '/api/apis/get_api_allowed_ip', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify({
        ip_id: ipId
      })
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setIP(resultJson);
      // Display form (last element is loaded)
      document.getElementById('form').style.display = 'block';
      // Focus
      document.forms['form'].elements['ip_title'].focus();
      // Load API
      loadAPI();
    }
  };
  // Load API
  const loadAPI = async (e) => {
    let result = await fetch(backendURL + '/api/apis/get_api', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify({
        api_id: ip.ip_api_id
      })
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setAPI(resultJson);
      // Display form (last element is loaded)
      document.getElementById('form').style.display = 'block';
      // Focus
      document.forms['form'].elements['api_title'].focus();
    }
  };

  // Form data chaned
  const handleChange = (e) => {
    setIP({
      ...ip,
      [e.target.name]: e.target.value
    });
  };

  // Send new identifier
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/apis/edit_allowed_ip', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(ip)
    });
    let resultJson = await result.json();
    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadIP();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/">Settings</a>
          &nbsp; / &nbsp;
          <a href={`/settings/apis`}>APIs</a>
          &nbsp; / &nbsp;
          <a href={`/settings/apis/view_api/${api.api_id}`}>{api.api_title}</a>
          &nbsp; / &nbsp;
          <a href={`/settings/api_allowed_ips_index/${api.api_id}`}>Allowed IPs</a>
          &nbsp; / &nbsp;
          <a href={`/settings/edit-allowed-ip/${ip.ip_id}`}>Edit allowed IP</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>Edit allowed IP {ip.ip_ip}</h1>
      <form onSubmit={handleSubmit} id="form" style={{ display: 'none' }}>
        <div className="main_box">
          {/* Feedback */}
          <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
            <p id="feedback_p">Feedback box is here</p>
          </div>

          {/* Form */}

          <p>
            Title
            <br />
            <span className="small">Example: Sindre Ditlefsen, Bjarne Haugens Gate 42, 1475 Finstadjordet, Telenor Norge AS</span>
            <br />
            <input type="text" name="ip_title" value={ip.ip_title} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
            IP
            <br />
            {ip.ip_ip}
          </p>

          <p>
            Allowed ({ip.ip_is_allowed})<br />
            <input type="radio" name="ip_is_allowed" value="1" checked={ip.ip_is_allowed === 1} onChange={handleChange} />
            Yes &nbsp;
            <input type="radio" name="ip_is_allowed" value="0" checked={ip.ip_is_allowed === 0} onChange={handleChange} />
            No
          </p>

          <p>
            <button>Submit</button>
          </p>
        </div>
      </form>
    </div>
  );
}
export default EditAllowedIP;
