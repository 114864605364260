import { useContext, useEffect, useState } from 'react';
import useToken from '../../../useToken.js';
import prodOrDev from '../../../utils.js';

export function ClassificationsIndex() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const [classifications, setClassifications] = useState(() => []);

  // Load data
  const loadData = async (e) => {
    let result = await fetch(backendURL + '/api/classifications/get-classifications', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const resultJson = await result.json();
    if (result.status === 200) {
      setClassifications(
        resultJson.map((fetch_object) => {
          return fetch_object;
        })
      );
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadData();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      <h1>Classifications</h1>
      <div className="main_box">

        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        {/* Table */}
        <table className="hor-zebra">
          <thead>
            <tr>
              <th>
                <span>Title</span>
              </th>
              <th>
                <span>Color</span>
              </th>
              <th>
                <span>BgColor and Text Color</span>
              </th>
              <th>
                <span>External ID</span>
              </th>
              <th>
                <span>Active</span>
              </th>
              <th>
                <span>Created</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {classifications.map((item, index) => (
              <tr key={index}>
                <td>
                  <span>{item.classification_title}</span>
                </td>
                <td>
                  <span>{item.classification_color}</span>
                </td>
                <td>
                  <span style={{backround: `${item.classification_bg_color}`}}>{item.classification_bg_color}</span>
                  <span>&nbsp;</span>
                  <span style={{backround: `${item.classification_text_color}`}}>{item.classification_text_color}</span>
                </td>
                <td>
                  <span>{item.classification_external_id}</span>
                </td>
                <td>
                  <span>{item.classification_is_active}</span>
                </td>
                <td>
                  <span>
                    {item.classification_created_date_saying}
                    &nbsp; by &nbsp;
                    {item.classification_created_by_user_name}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ClassificationsIndex;
