import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

export function SetCustomerStatusToHistoric() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { customerId } = useParams();

  const [customerIndex, setCustomerIndex] = useState(() => []);
  const [customerHistoricData, setCustomerHistoricData] = useState(() => []);

  // Load Customer Index
  const loadCustomerIndex = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get_customer?customer_id=' + customerId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setCustomerIndex(resultJson);
    }
  };

  // Load Customer Historic data
  const loadCustomerHistoricData = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get_customer_historic_data?customer_id=' + customerId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setCustomerHistoricData(resultJson);
      console.log('setCustomerStatusToHistoric() :: customerHistoricData=' + customerHistoricData);
    }
  };

  // Confirm
  async function confirmClick(e) {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/customers/set_customer_historic', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify({
        customer_id: customerId
      })
    });
    let resultJson = await result.json();

    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      // window.history.back();
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
    }
  }
  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadCustomerIndex();
      loadCustomerHistoricData();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/">Customers</a>
          &nbsp; / &nbsp;
          <a href={`/customers/view_customer/${customerId}`}>{customerIndex.customer_name_abbreviated}</a>
          &nbsp; / &nbsp;
          <a href={`/customers/set-customer-status-to-historic/${customerId}`}>Delete customers alerts, contacts, table rows and set the customer as historic</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>Delete customers alerts, contacts, table rows and set the customer as historic {customerIndex.customer_name_abbreviated}</h1>

      <div className="main_box">
        {/* Form */}
        <p>Are you sure you want to set the customer status to historic? This will:</p>

        <ul>
          <li>
            <p>Delete all contacts</p>
          </li>
          <li>
            <p>Delete all alerts</p>
          </li>
        </ul>

        <p>
          <b>Data to be deleted:</b>
        </p>
        <pre>{JSON.stringify(customerHistoricData, null, 2)}</pre>

        <p>Please confirm</p>

        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        <p>
          <a href="#top" className="btn_danger" onClick={confirmClick}>
            Confirm
          </a>
        </p>
      </div>
    </div>
  );
}

export default SetCustomerStatusToHistoric;
