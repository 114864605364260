import { useContext, useEffect, useState } from 'react';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

export function CopyEmails() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const [contactEmailAddressForAllCustomers, setContactEmailAddressForAllCustomers] = useState(() => []);
  const [contactEmailAddressToAllTAMsForAllCustomers, setContactEmailAddressToAllTAMsForAllCustomers] = useState(() => []);

  // Load data
  const loadContactEmailAddressForAllCustomers = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get_contacts_email_addresses_for_all_customers', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const resultJson = await result.json();
    if (result.status === 200) {
      setContactEmailAddressForAllCustomers(
        resultJson.map((fetch_object) => {
          return fetch_object;
        })
      );
    }
  };
  const loadContactEmailAddressToAllTAMsForAllCustomers = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get_contacts_email_addresses_to_tams_for_all_customers', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const resultJson = await result.json();
    if (result.status === 200) {
      setContactEmailAddressToAllTAMsForAllCustomers(
        resultJson.map((fetch_object) => {
          return fetch_object;
        })
      );
    }
  };

  function copyToClipboard(whatToCopy) {
    let emailsForAllContacts = '';
    if (whatToCopy == 'copyAllContacts') {
      // All contacts
      contactEmailAddressForAllCustomers.forEach(function (item, index) {
        // console.log("item=" + JSON.stringify(item))
        if (index == 0) {
          emailsForAllContacts = item['contact_email'];
        } else {
          emailsForAllContacts = emailsForAllContacts + ', ' + item['contact_email'];
        }
      });
    } else if (whatToCopy == 'copyAllTAMs') {
      // TAM contacts
      contactEmailAddressToAllTAMsForAllCustomers.forEach(function (item, index) {
        // console.log("item=" + JSON.stringify(item))
        if (index == 0) {
          emailsForAllContacts = item['contact_email'];
        } else {
          emailsForAllContacts = emailsForAllContacts + ', ' + item['contact_email'];
        }
      });
    } else {
      alert(`Unknown copy what: ${whatToCopy}`);
    }
    navigator.clipboard.writeText(emailsForAllContacts);
    document.getElementById('feedback_div').style.display = 'block';
    document.getElementById('feedback_div').className = '';
    document.getElementById('feedback_div').classList.add('info_smal');
    document.getElementById('feedback_p').innerHTML = "Copyed '" + whatToCopy + "' to clipboard!";
    document.getElementById('feedback_div').scrollIntoView();
  }

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadContactEmailAddressForAllCustomers();
      loadContactEmailAddressToAllTAMsForAllCustomers();
    }
    return () => {
      ignore = true;
    };
  }, []);
  return (
    <div>
      <h1>Copy ... emails</h1>

      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/">Customers</a>
          &nbsp; / &nbsp;
          <a href={`/cusomerts/copy_emails`}>Copy ... emails</a>
        </p>
      </div>
      <div className="clear"></div>

      <div className="main_box">
        {/* Actions */}
        <p>
          {
            <a href="#copyAllContacts" className="btn_default" onClick={() => copyToClipboard('copyAllContacts')}>
              <img src="/gfx/icons/20x20/assignment_add_rounded_nofill_black_20x20.svg" alt="assignment_add_rounded_nofill_black_20x20.svg" /> Copy all emails for all customers
            </a>
          }
        </p>

        <p>
          {
            <a href="#copyAllTAMs" className="btn_default" onClick={() => copyToClipboard('copyAllTAMs')}>
              <img src="/gfx/icons/20x20/assignment_add_rounded_nofill_black_20x20.svg" alt="assignment_add_rounded_nofill_black_20x20.svg" /> Copy all TAMS emails
            </a>
          }
        </p>

        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>
      </div>
    </div>
  );
}

export default CopyEmails;
