import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../../useToken.js';
import prodOrDev from '../../../utils.js';

export function DeleteUser() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { userId } = useParams();

  const [user, setUser] = useState({
    user_id: '',
    user_email: '',
    user_first_name: '',
    user_middle_name: '',
    user_last_name: '',
    user_display_name: '',
    user_rank: '',
    user_department: '',
    user_created_timestamp: '',
    user_created_by_user_email: '',
    user_updated_timestamp: '',
    user_updated_by_user_email: '',
    user_updated_items: '',
    user_deleted_flag: '',
    user_deleted_timestamp: '',
    user_deleted_by_user_email: '',
    user_login_tries_count: '',
    user_login_tries_timestamp: '',
    user_last_online_timestamp: '',
    user_last_ip: '',
    user_last_user_agent: '',
    user_type: ''
  });

  // Load User
  const loadUser = async (e) => {
    let result = await fetch(backendURL + '/api/users/get_user?user_id=' + userId, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setUser(resultJson);

      // Display form (last element is loaded)
      document.getElementById('form').style.display = 'block';
    }
  };

  // Confirm delete
  async function confirmDelete(e) {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/users/delete_user?user_id=' + userId, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();

    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      // window.history.back();
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
    }
  }
  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadUser();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/">Settings</a>
          &nbsp; / &nbsp;
          <a href={`/settings/users`}>Users</a>
          &nbsp; / &nbsp;
          <a href={`/settings/users/delete-user/${user.user_id}`}>Delete User</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>
        Delete user {user.user_id} {user.user_first_name}
      </h1>
      <div className="main_box" id="form" style={{ display: 'none' }}>
        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        {/* Form */}
        <p>Are you sure you want to delete the user? The action cannot be undone!</p>
        <p>
          <a href="#delete" className="btn_danger" onClick={confirmDelete}>
            Confirm
          </a>
        </p>
      </div>
    </div>
  );
}
export default DeleteUser;
