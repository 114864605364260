import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

export function ViewIdentifiers() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { customerId } = useParams();

  // Data
  const [customerIndex, setCustomerIndex] = useState({
    customer_id: customerId,
    customer_name: '',
    customer_name_abbreviated: '',
    customer_is_test_customer: '',
    customer_working_language: '',
    customer_restriction: '',
    customer_country_hq: '',
    customer_reason_of_hq_presence: '',
    customer_hq_address: '',
    customer_employee_count: '',
    customer_asset_client_count: '',
    customer_asset_server_count: '',
    customer_asset_total_count: '',
    customer_sector_code: '',
    customer_business_code: '',
    customer_revenue: '',
    customer_operational_status: '',
    customer_operational_operational_date: '',
    customer_operational_operational_date_saying: '',
    customer_decommissioned_date: '',
    customer_decommissioned_date_saying: '',
    customer_former_company_names: '',
    customer_compliance: '',
    customer_service_window_days: '',
    customer_service_window_hours: '',
    customer_response_time_hours: '',
    customer_output: '',
    customer_notes_description: '',
    customer_notes_source: '',
    customer_procedures_description: '',
    customer_procedures_agreed_date: '',
    customer_procedures_added_date: '',
    customer_summary: '',
    customer_business_environment: '',
    customer_business_enablers: '',
    customer_business_threats: '',

    customer_created_timestamp: '',
    customer_created_date_saying: '',
    customer_created_by_user_email: '',
    customer_updated_timestamp: '',
    customer_updated_date_saying: '',
    customer_updated_by_user_email: ''
  });
  const [customerIdentifiers, setCustomerIdentifiers] = useState(() => []);
  const [optionsIdentifiers, setOptionsIdentifiers] = useState(() => []);

  // Load data
  const loadCustomerIndex = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get_customer?customer_id=' + customerId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setCustomerIndex(resultJson);
      document.getElementById('show_customer').style.display = 'block';
    }
  };

  // Load customer identifiers
  const loadCustomersIdentifiers = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get_customer_identifiers', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify({
        customer_id: customerId
      })
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setCustomerIdentifiers(resultJson);
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultJson['error'] + ': ' + resultJson['message'];
      document.getElementById('feedback_div').scrollIntoView();
    }
  };

  // Load options identifiers
  const loadOptionsIdentifiers = async (e) => {
    let result = await fetch(backendURL + '/api/options/get_identifiers', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setOptionsIdentifiers(resultJson);
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadCustomerIndex();
      loadCustomersIdentifiers();
      loadOptionsIdentifiers();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div id="show_customer" style={{ display: 'none' }}>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/">Customers</a>
          &nbsp; / &nbsp;
          <a href={`/customers/view_customer/${customerId}`}>{customerIndex.customer_name_abbreviated}</a>
          &nbsp; / &nbsp;
          <a href={`/customers/view_identifiers/${customerId}`}>Identifiers</a>
        </p>
      </div>
      <div className="clear"></div>

      {/* Headline and actions */}
      <h1>{customerIndex.customer_name}</h1>

      {/* Customer tabs */}
      <ul className="tabs">
        <li>
          <a href={`/customers/view_customer/${customerId}`}>Index</a>
        </li>
        <li>
          <a href={`/customers/view_business/${customerId}`}>Business</a>
        </li>
        <li>
          <a href={`/customers/view_identifiers/${customerId}`} className="active">
            Identifiers
          </a>
        </li>
        <li>
          <a href={`/customers/view_engagements/${customerId}`}>Engagements</a>
        </li>
        {/* <li><a href={`/customers/view_sensors/${customerId}`}>Sensors</a></li> */}
      </ul>

      <div className="main_box">
        {/* Add identifier */}
        <p>
        {<a href={`/customers/new_identifier/${customerId}`} className="btn_default"><img src="/gfx/icons/20x20/add_rounded_nofill_black_20x20.svg" alt="add_rounded_nofill_black_20x20.svg" /> New identifier</a>}
        </p>

        <p>
        Please do not add secrets to the identifiers page. 
        </p>

        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        {/* Table */}
        <table className="hor-zebra">
          <thead>
            <tr>
              <th>
                <span>Type</span>
              </th>
              <th>
                <span>Title</span>
              </th>
              <th>
                <span>Value</span>
              </th>
              <th>
                <span>Description</span>
              </th>
              <th>
                <span>Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {customerIdentifiers.map((item, index) => (
              <tr key={index}>
                <td>
                  <span>{item.identifier_type}</span>
                </td>
                <td>
                  <span>{item.identifier_title}</span>
                </td>
                <td>
                  <span>{item.identifier_value}</span>
                </td>
                <td>
                  <span>{item.identifier_description}</span>
                </td>
                <td>
                  <span>
                    <a href={`/customers/edit_identifier/${item.identifier_id}`}>
                      <img src="/gfx/icons/20x20/edit_rounded_fill_black_20x20.svg" alt="edit_rounded_fill_black_20x20.svg" />
                    </a>
                    &nbsp;
                    {
                      <a href={`/customers/delete_identifier/${item.identifier_id}`}>
                        <img src="/gfx/icons/20x20/delete_rounded_nofill_black_20x20.svg" alt="delete_rounded_fill_black_20x20.svg" />
                      </a>
                    }
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ViewIdentifiers;
