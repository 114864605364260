import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../../useToken.js';
import prodOrDev from '../../../utils.js';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export function EditOperationPerformed() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { performedId } = useParams();

  // Data
  const [operationPerformed, setOperationPerformed] = useState({
    performed_id: performedId,
    performed_threat_actor_id: '',
    performed_title: '',
    performed_short_description: '',
    performed_handled_by_us: '',
    performed_reported_by_others: '',
    performed_date: '',
    performed_date_saying: '',
    performed_created_date: '',
    performed_created_date_saying: '',
    performed_created_by_user_id: '',
    performed_created_by_display_name: '',
    performed_updated_date: '',
    performed_updated_date_saying: '',
    performed_updated_by_user_id: '',
    performed_updated_by_display_name: '',
  });
  const [threatActor, setThreatActor] = useState({
    threat_actor_id: '',
    threat_actor_name: '',
    threat_actor_short_description: '',
    threat_actor_first_seen_date: '',
    threat_actor_first_seen_date_saying: '',
    threat_actor_country_of_origin_name: '',
    threat_actor_country_of_origin_flag: '',
    threat_actor_country_of_origin_two: '',
    threat_actor_country_of_origin_three: '',
    threat_actor_country_of_attribution_name: '',
    threat_actor_country_of_attribution_flag: '',
    threat_actor_country_of_attribution_two: '',
    threat_actor_country_of_attribution_three: '',
    threat_actor_description: '',
    threat_actor_motivation: '', 
    threat_actor_capability: '',
    threat_actor_tools: '',
    threat_actor_techniques: '',
    threat_actor_infrastructure: '',
    threat_actor_counter_operations: '',
    threat_actor_sources: '',
    threat_actor_mitre_attck_link_name: '',
    threat_actor_mitre_attck_link_url: '',
    threat_actor_dangerous_number: '',
    threat_actor_dangerous_title: '',
    threat_actor_logo_path: '',
    threat_actor_logo_file: '',
    threat_actor_created_date: '',
    threat_actor_created_date_saying: '',
    threat_actor_created_by_user_id: '',
    threat_actor_created_by_display_name: '',
    threat_actor_updated_date: '',
    threat_actor_updated_date_saying: '',
    threat_actor_updated_by_user_id: '',
    threat_actor_updated_by_display_name: '',
    threat_actor_alternative_names: [],
    threat_actor_operations_performed: []
  });
  const [performedDate, setPerformedDate] = useState('');

  // Load data
  const loadOperationPerformed = async (e) => {
    let result = await fetch(backendURL + '/api/threat-actors/get-operation-performed?performed_id=' + performedId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setOperationPerformed(resultJson);
      document.getElementById('main_box').style.display = 'block';

      // Operation Performed Date
      if (resultJson['performed_date'] != '') {
        const dateFormated = formatDate(resultJson['performed_date']);
        threatActor.performed_date = dateFormated;
        setPerformedDate(dateFormated);
      }
      
      // Load threat actor
      operationPerformed.performed_threat_actor_id = resultJson['performed_threat_actor_id'];
      loadThreatActor();
    }
  };
  const loadThreatActor = async (e) => {
    console.log("operationPerformed.performed_threat_actor_id=" + operationPerformed.performed_threat_actor_id)
    let result = await fetch(backendURL + '/api/threat-actors/get-threat-actor?threat_actor_id=' + operationPerformed.performed_threat_actor_id, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setThreatActor(resultJson);
    }
  };

  // Fomat Date
  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }
  
  // Form data chaned
  const handleChange = (e) => {
    setOperationPerformed({
      ...operationPerformed,
      [e.target.name]: e.target.value
    });
  };
  const handlePerformedDateChange = (e) => {
    setPerformedDate(e.target.value);
    setOperationPerformed({
      ...operationPerformed,
      ['performed_date']: e.target.value
    });
  };
  const handleChangeHandledByUs = (e) => {
    operationPerformed.performed_handled_by_us = e;
  };
  const handleChangeReportedByOthers = (e) => {
    operationPerformed.performed_reported_by_others = e;
  };

  
  // Send 
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/threat-actors/edit-operation-performed', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(operationPerformed)
    });
    let resultJson = await result.json();
    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    }
  };



  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadOperationPerformed();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/intelligence">Intelligence</a>
          &nbsp; / &nbsp;
          <a href="/intelligence/threat-actors">Threat Actors</a>
          &nbsp; / &nbsp;
          <a href={`/intelligence/threat-actors/view-threat-actor/${threatActor.threat_actor_id}`}>{threatActor.threat_actor_name}</a>
          &nbsp; / &nbsp;
          <a href={`/intelligence/threat-actors/view-operation-performed/${operationPerformed.performed_id}`}>{operationPerformed.performed_title}</a>
          &nbsp; / &nbsp;
          <a href={`/intelligence/threat-actors/edit-operation-performed/${operationPerformed.performed_id}`}>Edit</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>Edit {operationPerformed.performed_title}</h1>


      {/* Main box */}
      <div className="main_box" id="main_box" style={{ display: 'none' }}>

        <form onSubmit={handleSubmit} id="form">


        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        <p>
        <b>Title:</b><br />
        <input type="text" name="performed_title" value={operationPerformed.performed_title} onChange={handleChange} style={{ minWidth: '50%' }} autoFocus />
        </p>

        <p>
        <b>Short description:</b><br />
        <textarea name="performed_short_description" value={operationPerformed.performed_short_description} onChange={handleChange} style={{ minWidth: '50%' }}></textarea>
        </p>
        
        <p><b>Date:</b><br />
        <span className="small">{operationPerformed.performed_date_saying}</span><br />
        <input type="date" name="performed_date" value={performedDate} onChange={handlePerformedDateChange} />
        </p>



        <h2>Handled by us</h2>
        <ReactQuill theme="snow" name="performed_handled_by_us" value={operationPerformed.performed_handled_by_us} onChange={handleChangeHandledByUs} />
      
        <h2>Reported by others</h2>
        <ReactQuill theme="snow" name="performed_reported_by_others" value={operationPerformed.performed_reported_by_others} onChange={handleChangeReportedByOthers} />
      


        
        <p><button>Submit</button>
        </p>

        </form>

      </div>

    </div>
  );
}

export default EditOperationPerformed;
