import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export function EditCustomer() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { customerId } = useParams();

  const [customer, setCustomer] = useState({
    customer_id: customerId,
    customer_name: '',
    customer_name_abbreviated: '',
    customer_is_test_customer: '',
    customer_working_language: '',
    customer_restriction: '',
    customer_country_hq: '',
    customer_reason_of_hq_presence: '',
    customer_hq_address: '',
    customer_hq_latitude: '',
    customer_hq_longitude: '',
    customer_employee_count: '',
    customer_asset_client_count: '',
    customer_asset_server_count: '',
    customer_asset_total_count: '',
    customer_sector_code: '',
    customer_business_code: '',
    customer_revenue: '',
    customer_operational_status: '',
    customer_operational_operational_date: '',
    customer_operational_operational_date_saying: '',
    customer_decommissioned_date: '',
    customer_decommissioned_date_saying: '',
    customer_former_company_names: '',
    customer_compliance: '',
    customer_service_window_days: '',
    customer_service_window_hours: '',
    customer_response_time_hours: '',
    customer_output: '',
    customer_notes_description: '',
    customer_notes_source: '',
    customer_procedures_description: '',
    customer_procedures_agreed_date: '',
    customer_procedures_agreed_why: '',
    customer_procedures_added_date: '',
    customer_summary: '',
    customer_business_environment: '',
    customer_business_enablers: '',
    customer_business_threats: '',

    customer_created_timestamp: '',
    customer_created_date_saying: '',
    customer_created_by_user_email: '',
    customer_updated_timestamp: '',
    customer_updated_date_saying: '',
    customer_updated_by_user_email: ''
  });
  const [options, setOptions] = useState(() => []);
  const [customerOperationalOperationalDate, setCustomerOperationalOperationalDate] = useState('');
  const [customerDecommissionedDate, setCustomerDecommissionedDate] = useState('');
  const [customerProceduresAgreedDate, setCustomerProceduresAgreedDate] = useState('');
  const [languages, setLanguages] = useState(() => []);
  const [restrictions, setRestrictions] = useState(() => []);
  const [countries, setCountries] = useState(() => []);
  const [employeeCounts, setEmployeeCounts] = useState(() => []);
  const [sectorCodes, setSectorCodes] = useState(() => []);
  const [businessCodes, setBusinessCodes] = useState(() => []);
  const [revenues, setRevenues] = useState(() => []);
  const [operationalStatuses, setOperationalStatuses] = useState(() => []);
  const [compliances, setCompliances] = useState(() => []);
  const [serviceWindowsDays, setServiceWindowsDays] = useState(() => []);
  const [serviceWindowsHours, setServiceWindowsHours] = useState(() => []);

  // Load data
  const loadCustomerIndex = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get_customer?customer_id=' + customerId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setCustomer(resultJson);

      // setCustomerOperationalOperationalDate
      if (resultJson['customer_operational_operational_date'] != '') {
        const dateFormated = formatDate(resultJson['customer_operational_operational_date']);
        customer.customer_operational_operational_date = dateFormated;
        // console.log("EditCustomer() :: loadCustomerIndex() :: customer_operational_operational_date=" + dateFormated)

        setCustomerOperationalOperationalDate(dateFormated);
      }

      // customer_decommissioned_date
      if (resultJson['customer_decommissioned_date'] != '') {
        const dateFormated = formatDate(resultJson['customer_decommissioned_date']);
        if (dateFormated != '1970-01-01') {
          customer.customer_decommissioned_date = dateFormated;
          console.log('EditCustomer() :: loadCustomerIndex() :: customer_decommissioned_date=' + dateFormated);

          setCustomerDecommissionedDate(dateFormated);
        }
      }

      // setProceduresAgreedDate
      if (resultJson['customer_procedures_agreed_date'] != '') {
        const dateFormated = formatDate(resultJson['customer_procedures_agreed_date']);
        if (dateFormated != '1970-01-01') {
          customer.customer_procedures_agreed_date = dateFormated;
          // console.log("EditCustomer() :: loadCustomerIndex() :: customer_procedures_agreed_date=" + dateFormated)

          setCustomerProceduresAgreedDate(dateFormated);
        }
      }
    }
  };

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  // Load Options
  const loadOptions = async (e) => {
    let result = await fetch(backendURL + '/api/options/get_all_options', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setOptions(resultJson);

      setLanguages(resultJson['languages']);
      setRestrictions(resultJson['restrictions']);
      setCountries(resultJson['countries']);
      setEmployeeCounts(resultJson['employee_counts']);
      setSectorCodes(resultJson['sector_codes']);
      setBusinessCodes(resultJson['business_codes']);
      setRevenues(resultJson['revenues']);
      setOperationalStatuses(resultJson['operational_statuses']);
      setCompliances(resultJson['compliances']);
      setServiceWindowsDays(resultJson['service_windows_days']);
      setServiceWindowsHours(resultJson['service_windows_hours']);

      // Display form (last element is loaded)
      document.getElementById('form').style.display = 'block';

      // Focus
      document.forms['form'].elements['customer_name'].focus();
    }
  };

  // Form data chaned
  const handleChange = (e) => {
    console.log('EditCustomer() :: handleChange() :: e.target.value=' + e.target.value);
    setCustomer({
      ...customer,
      [e.target.name]: e.target.value
    });
  };
  const handleCustomerOperationalOperationalDateChange = (e) => {
    console.log('EditCustomer() :: handleCustomerOperationalOperationalDateChange() :: e.target.name=' + e.target.name + ' e.target.value=' + e.target.value);
    setCustomerOperationalOperationalDate(e.target.value);
    setCustomer({
      ...customer,
      ['customer_operational_operational_date']: e.target.value
    });
  };

  const handleCustomerDecommissionedDateChange = (e) => {
    console.log('EditCustomer() :: handleCustomerDecommissionedDateChange() :: e.target.name=' + e.target.name + ' e.target.value=' + e.target.value);
    setCustomerDecommissionedDate(e.target.value);
    setCustomer({
      ...customer,
      ['customer_decommissioned_date']: e.target.value
    });
  };
  const handleChangeCustomerProceduresAgreedDate = (e) => {
    console.log('EditCustomer() :: handleChangeCustomerProceduresAgreedDate() :: e.target.name=' + e.target.name + ' e.target.value=' + e.target.value);
    setCustomerProceduresAgreedDate(e.target.value);
    setCustomer({
      ...customer,
      ['customer_procedures_agreed_date']: e.target.value
    });
  };
  const handleChangeOutput = (e) => {
    customer.customer_output = e;
  };
  const handleChangeNotesDescription = (e) => {
    customer.customer_notes_description = e;
  };
  const handleChangeNotesSource = (e) => {
    customer.customer_notes_source = e;
  };
  const handleChangeProceduresDescription = (e) => {
    customer.customer_procedures_description = e;
  };
  const handleChangeProceduresAgreedWhy = (e) => {
    customer.customer_procedures_agreed_why = e;
  };
  const handleChangeSummary = (e) => {
    customer.customer_summary = e;
  };
  const handleChangeBusinessEnvironment = (e) => {
    customer.customer_business_environment = e;
  };
  const handleChangeBusinessEnablers = (e) => {
    customer.customer_business_enablers = e;
  };
  const handleChangeBusinessThreats = (e) => {
    customer.customer_business_threats = e;
  };

  //
  // Send general settings
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/customers/edit_customer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(customer)
    });
    let resultJson = await result.json();
    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadCustomerIndex();
      loadOptions();
      // Focus
      document.forms['form'].elements['customer_name'].focus();
    }
    return () => {
      ignore = true;
    };
  }, []);

  const handleChangeCheckbox = (e) => {
    console.log('EditCustomer() :: handleChangeCheckbox() :: e.target.name=' + e.target.name + ' e.target.value=' + e.target.value);

    if (e.target.value === '' || e.target.value === 'on') {
      e.target.value = 'checked';
    } else {
      e.target.value = '';
    }

    setCustomer({
      ...customer,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/">Customers</a>
          &nbsp; / &nbsp;
          <a href={`/customers/view_customer/${customerId}`}>{customer.customer_name_abbreviated}</a>
          &nbsp; / &nbsp;
          <a href={`/customers/new_customer/${customerId}`}>Edit Customer</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>Edit {customer.customer_name_abbreviated}</h1>
      <form onSubmit={handleSubmit} id="form" style={{ display: 'none' }}>
        <div className="main_box">
          {/* Feedback */}
          <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
            <p id="feedback_p">Feedback box is here</p>
          </div>

          {/* Form */}

          <p>
            Customer name
            <br />
            <input type="text" name="customer_name" value={customer.customer_name} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
            Abbreviated
            <br />
            <input type="text" name="customer_name_abbreviated" value={customer.customer_name_abbreviated} onChange={handleChange} size="3" />
          </p>

          <p>
            Test or production customer?
            <br />
            <select name="customer_is_test_customer" value={customer.customer_is_test_customer} onChange={handleChange}>
              <option value="0">Production customer</option>
              <option value="1">Test customer</option>
            </select>
          </p>

          <p>
            Working language
            <br />
            <select name="customer_working_language" value={customer.customer_working_language} onChange={handleChange}>
              <option value="N/A">Please select</option>
              {languages.map((item, index) => (
                <option key={index} value={item.language_iso_three}>
                  {item.language_iso_three}
                </option>
              ))}
            </select>
          </p>

          <p>
            Restriction
            <br />
            <select name="customer_restriction" value={customer.customer_restriction} onChange={handleChange}>
              <option value="N/A">Please select</option>
              {restrictions.map((item, index) => (
                <option key={index} value={item.restriction_title}>
                  {item.restriction_title}
                </option>
              ))}
            </select>
          </p>
        </div>

        <h2>Head Quarters</h2>
        <div className="main_box">
          <p>
            Country HQ
            <br />
            <select name="customer_country_hq" value={customer.customer_country_hq} onChange={handleChange}>
              <option value="N/A">Please select</option>
              {countries.map((item, index) => (
                <option key={index} value={item.country_iso_three_upper}>{item.country_iso_three_upper}</option>
              ))}
            </select>
          </p>

          <p>
            Reason of HQ Presence
            <br />
            <input type="text" name="customer_reason_of_hq_presence" value={customer.customer_reason_of_hq_presence} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
            HQ Address
            <br />
            <input type="text" name="customer_hq_address" value={customer.customer_hq_address} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
            HQ Latitude and Longitude
            <br />
            <span className="small">In order to show alarms on a map we need to know the lat and long</span>
            <br />
            <input type="text" name="customer_hq_latitude" value={customer.customer_hq_latitude} onChange={handleChange} style={{ minWidth: '20px' }} />
            &nbsp; <input type="text" name="customer_hq_longitude" value={customer.customer_hq_longitude} onChange={handleChange} style={{ minWidth: '20px' }} />
          </p>

          <p>
            Employee count
            <br />
            <select name="customer_employee_count" value={customer.customer_employee_count} onChange={handleChange}>
              <option value="N/A">Please select</option>
              {employeeCounts.map((item, index) => (
                <option key={index} value={item.employee_count_title}>
                  {item.employee_count_title}
                </option>
              ))}
            </select>
          </p>
        </div>

        <h2>Services</h2>
        <div className="main_box">
          <p>
            Has SIDR service
            <br />
            <input type="checkbox" name="customer_has_service_sidr" checked={customer.customer_has_service_sidr} onChange={handleChangeCheckbox} />
          </p>

          <p>
            Has vulnerability management service
            <br />
            <input type="checkbox" name="customer_has_service_vm" checked={customer.customer_has_service_vm} onChange={handleChangeCheckbox} />
          </p>
        </div>

        <h2>Assets</h2>
        <div className="main_box">
          <p>
            Asset adapter count manual
            <br />
            <input type="text" name="customer_asset_adapter_count_man" value={customer.customer_asset_adapter_count_man} onChange={handleChange} size="4" />
          </p>

          <p>
            Asset sensor count manual
            <br />
            <input type="text" name="customer_asset_sensor_count_man" value={customer.customer_asset_sensor_count_man} onChange={handleChange} size="4" />
          </p>

          <p>
            Total asset count manual
            <br />
            {customer.customer_asset_total_count_man}
          </p>

          <p>
            Asset adapter count automatic
            <br />
            {customer.customer_asset_adapter_count_auto}
          </p>

          <p>
            Asset sensor count automatic
            <br />
            {customer.customer_asset_sensor_count_auto}
          </p>

          <p>
            Total asset count automatic
            <br />
            {customer.customer_asset_total_count_auto}
          </p>

          <p>
            Total asset VM count manual
            <br />
            <input type="text" name="customer_asset_vm_count_man" value={customer.customer_asset_vm_count_man} onChange={handleChange} size="4" />
          </p>

          <p>
            Total asset VM count automatic
            <br />
            {customer.customer_asset_vm_count_auto}
          </p>
        </div>

        <h2>Organizational Numbers</h2>
        <div className="main_box">
          <p>
            Sector code
            <br />
            <select name="customer_sector_code" value={customer.customer_sector_code} onChange={handleChange}>
              <option value="N/A">Please select</option>
              {sectorCodes.map((item, index) => (
                <option key={index} value={item.sector_code_value}>
                  {item.sector_code_value}
                </option>
              ))}
            </select>
          </p>

          <p>
            Business code
            <br />
            <select name="customer_business_code" value={customer.customer_business_code} onChange={handleChange}>
              <option value="N/A">Please select</option>
              {businessCodes.map((item, index) => (
                <option key={index} value={item.business_code_value}>
                  {item.business_code_value}
                </option>
              ))}
            </select>
          </p>

          <p>
            Revenue
            <br />
            <select name="customer_revenue" value={customer.customer_revenue} onChange={handleChange}>
              <option value="N/A">Please select</option>
              {revenues.map((item, index) => (
                <option key={index} value={item.revenue_title}>
                  {item.revenue_title}
                </option>
              ))}
            </select>
          </p>
        </div>

        <h2>Status</h2>
        <div className="main_box">
          <p>
            Operational status
            <br />[<a href={`/customers/set-customer-status-to-historic/${customerId}`}>Delete customers alerts, contacts, table rows and set the customer as historic</a>]<br />
            <select name="customer_operational_status" value={customer.customer_operational_status} onChange={handleChange}>
              <option value="N/A">Please select</option>
              {operationalStatuses.map((item, index) => (
                <option key={index} value={item.status_title}>
                  {item.status_title}
                </option>
              ))}
            </select>
          </p>

          <p>
            Operational date
            <br />
            <span className="small">
              {customer.customer_operational_operational_date_saying} ({customerOperationalOperationalDate})
            </span>
            <br />
            <input type="date" name="customer_operational_operational_date" value={customerOperationalOperationalDate} onChange={handleCustomerOperationalOperationalDateChange} />
          </p>

          <p>
            Decommissioned date
            <br />
            <span className="small">
              {customer.customer_decommissioned_date_saying} ({customerDecommissionedDate})
            </span>
            <br />
            <input type="date" name="customer_decommissioned_date" value={customerDecommissionedDate} onChange={handleCustomerDecommissionedDateChange} />
          </p>
          <p>
            Compliance
            <br />
            <select name="customer_compliance" value={customer.customer_compliance} onChange={handleChange}>
              <option value="N/A">Please select</option>
              {compliances.map((item, index) => (
                <option key={index} value={item.compliance_title}>
                  {item.compliance_title}
                </option>
              ))}
            </select>
          </p>

          <p>
            Service Window
            <br />
            <select name="customer_service_window_days" value={customer.customer_service_window_days} onChange={handleChange}>
              <option value="N/A">Please select</option>
              {serviceWindowsDays.map((item, index) => (
                <option key={index} value={item.day_title}>
                  {item.day_title}
                </option>
              ))}
            </select>
            &nbsp;
            <select name="customer_service_window_hours" value={customer.customer_service_window_hours} onChange={handleChange}>
              <option value="N/A">Please select</option>
              {serviceWindowsHours.map((item, index) => (
                <option key={index} value={item.hour_title}>
                  {item.hour_title}
                </option>
              ))}
            </select>
          </p>

          <p>
            Response time (in hours)
            <br />
            <select name="customer_response_time_hours" value={customer.customer_response_time_hours} onChange={handleChange}>
              <option value="-1">Please select</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="20">20</option>
              <option value="21">21</option>
              <option value="22">22</option>
              <option value="23">23</option>
              <option value="24">24</option>
            </select>
          </p>
        </div>

        <h2>History</h2>
        <div className="main_box">
          <p>
            Former company names
            <br />
            <span className="small">Seperated by comma</span>
            <br />
            <input type="text" name="customer_former_company_names" value={customer.customer_former_company_names} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>
        </div>

        <h2>Description</h2>
        <div className="main_box">
          <p style={{ paddingBottom: 0, marginBottom: 0 }}>Output</p>
          <ReactQuill theme="snow" name="customer_output" value={customer.customer_output} onChange={handleChangeOutput} />

          <p style={{ paddingBottom: 0, marginBottom: 0 }}>Notes description</p>
          <ReactQuill theme="snow" name="customer_notes_description" value={customer.customer_notes_description} onChange={handleChangeNotesDescription} />

          <p style={{ paddingBottom: 0, marginBottom: 0 }}>Notes source</p>
          <ReactQuill theme="snow" name="customer_notes_source" value={customer.customer_notes_source} onChange={handleChangeNotesSource} />

          <p style={{ paddingBottom: 0, marginBottom: 0 }}>
            Procedures description
            <br />
            <span className="small">Added {customer.customer_procedures_added_date}</span>
          </p>
          <ReactQuill theme="snow" name="customer_procedures_description" value={customer.customer_procedures_description} onChange={handleChangeProceduresDescription} />

          <p>
            Procedures agreed date
            <br />
            <input type="date" name="customer_procedures_agreed_date" value={customerProceduresAgreedDate} onChange={handleChangeCustomerProceduresAgreedDate} />
          </p>

          <p style={{ paddingBottom: 0, marginBottom: 0 }}>Procedures agreed why</p>
          <ReactQuill theme="snow" name="customer_procedures_agreed_why" value={customer.customer_procedures_agreed_why} onChange={handleChangeProceduresAgreedWhy} />

          <p style={{ paddingBottom: 0, marginBottom: 0 }}>Summary</p>
          <ReactQuill theme="snow" name="customer_summary" value={customer.customer_summary} onChange={handleChangeSummary} />

          <p style={{ paddingBottom: 0, marginBottom: 0 }}>Business environment</p>
          <ReactQuill theme="snow" name="customer_business_environment" value={customer.customer_business_environment} onChange={handleChangeBusinessEnvironment} />

          <p style={{ paddingBottom: 0, marginBottom: 0 }}>Business enablers</p>
          <ReactQuill theme="snow" name="customer_business_enablers" value={customer.customer_business_enablers} onChange={handleChangeBusinessEnablers} />

          <p style={{ paddingBottom: 0, marginBottom: 0 }}>Business threats</p>
          <ReactQuill theme="snow" name="customer_business_threats" value={customer.customer_business_threats} onChange={handleChangeBusinessThreats} />
        </div>

        <p>
          <button>Submit</button>
        </p>
      </form>
    </div>
  );
}

export default EditCustomer;
