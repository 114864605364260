import React from 'react';
import useToken from '../../useToken.js';
import MyKnownDevices from '../../pages/Profile/components/MyKnownDevices.js';

export default function Profile() {
  // Get token
  const { token, setToken, userObject } = useToken();

  // Show edit warning
  const handleEditClick = () => {
    // Toggle edid profile code
    document.getElementById('edit_warning').style.display = 'block';
  };

  return (
    <div>
      {/* Profile overview */}
      <div className="right">
        <p>
          <a href="https://myaccount.google.com/" target="_blank" onClick={handleEditClick} rel="noreferrer">
            <img src="/gfx/icons/20x20/edit_rounded_fill_black_20x20.svg" alt="edit_rounded_fill_black_20x20.png" />
          </a>
        </p>
      </div>
      <h1>Profile Overview</h1>
      <div id="edit_warning" className="info_smal" style={{ display: 'none' }}>
        <p>The sync goes once a day at 10:00</p>
      </div>
      <div className="main_box">
        <p>
          <b>Name:</b> {userObject?.name}
          <br />
          <b>Email:</b> {userObject?.email}
        </p>
      </div>

      {/* Known Devices */}
      <h2>Known Devices</h2>
      <div className="main_box">
        <MyKnownDevices />
      </div>
    </div>
  );
}
