import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../../useToken.js';
import prodOrDev from '../../../utils.js';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export function NewAssessment() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { assessmentId } = useParams();

  // Data
  const [assessment, setAssessment] = useState({
    assessment_id: assessmentId,
    assessment_created_date: '',
    assessment_created_date_saying: '',
    assessment_tlp: '',
    assessment_title: '',
    assessment_text: '',
    assessment_reference: ''
  });

  // Form data chaned
  const handleChange = (e) => {
    setAssessment({
      ...assessment,
      [e.target.name]: e.target.value
    });
  };

  // Send new contact
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/intelligence/new-intelligence-assessment', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(assessment)
    });
    let resultJson = await result.json();
    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
      
      // Go to URL
      window.location.href = "/intelligence/assessments/edit-assessment/" + resulData; 



    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    }
  };

  const handleChangeText = (e) => {
    assessment.assessment_text = e;
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      // Focus
      document.forms['form'].elements['assessment_title'].focus();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/intelligence">Intelligence</a>
          &nbsp; / &nbsp;
          <a href={`/intelligence/assessments/new-assessment`}>New assessment</a>
        </p>
      </div>
      <div className="clear"></div>
      {/* Main */}
      <h1>New assessment</h1>
      <div className="main_box" id="main_box">
        <form onSubmit={handleSubmit} id="form">
          {/* Feedback */}
          <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
            <p id="feedback_p">Feedback box is here</p>
          </div>

          {/* Form */}
          <p>Title<br />
          <input type="text" name="assessment_title" value={assessment.assessment_title} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
          <button>Submit</button>
          </p>

        </form>
      </div>{' '}
      {/* // main_box */}
    </div>
  );
}

export default NewAssessment;