import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../../useToken.js';
import prodOrDev from '../../../utils.js';


import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export function EditThreatActor() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { threatActorId } = useParams();

  // Data
  const [threatActor, setThreatActor] = useState({
    threat_actor_id: threatActorId,
    threat_actor_name: '',
    threat_actor_short_description: '',
    threat_actor_first_seen_date: '',
    threat_actor_first_seen_date_saying: '',
    threat_actor_country_of_origin_name: '',
    threat_actor_country_of_origin_flag: '',
    threat_actor_country_of_origin_two: '',
    threat_actor_country_of_origin_three: '',
    threat_actor_country_of_attribution_name: '',
    threat_actor_country_of_attribution_flag: '',
    threat_actor_country_of_attribution_two: '',
    threat_actor_country_of_attribution_three: '',
    threat_actor_description: '',
    threat_actor_motivation: '', 
    threat_actor_capability: '',
    threat_actor_tools: '',
    threat_actor_techniques: '',
    threat_actor_infrastructure: '',
    threat_actor_counter_operations: '',
    threat_actor_sources: '',
    threat_actor_mitre_attck_link_name: '',
    threat_actor_mitre_attck_link_url: '',
    threat_actor_dangerous_number: '',
    threat_actor_dangerous_title: '',
    threat_actor_logo_path: '',
    threat_actor_logo_file: '',
    threat_actor_created_date: '',
    threat_actor_created_date_saying: '',
    threat_actor_created_by_user_id: '',
    threat_actor_created_by_display_name: '',
    threat_actor_updated_date: '',
    threat_actor_updated_date_saying: '',
    threat_actor_updated_by_user_id: '',
    threat_actor_updated_by_display_name: '',
    threat_actor_alternative_names: []
  });
  const [threatActorFirstSeenDate, setThreatActorFirstSeenDate] = useState('');
  const [languages, setLanguages] = useState(() => []);
  const [countries, setCountries] = useState(() => []);

  // Load data
  const loadThreatActor = async (e) => {
    let result = await fetch(backendURL + '/api/threat-actors/get-threat-actor?threat_actor_id=' + threatActorId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setThreatActor(resultJson);
      document.getElementById('main_box').style.display = 'block';

      // Threat actor first seen
      if (resultJson['threat_actor_first_seen_date'] != '') {
        const dateFormated = formatDate(resultJson['threat_actor_first_seen_date']);
        threatActor.threat_actor_first_seen_date = dateFormated;
        setThreatActorFirstSeenDate(dateFormated);
      }

    }
  };
  
  // Load Options
  const loadOptions = async (e) => {
    let result = await fetch(backendURL + '/api/options/get_all_options', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setLanguages(resultJson['languages']);
      //setRestrictions(resultJson['restrictions']);
      setCountries(resultJson['countries']);
      //setEmployeeCounts(resultJson['employee_counts']);
      //setSectorCodes(resultJson['sector_codes']);
      //setBusinessCodes(resultJson['business_codes']);
      //setRevenues(resultJson['revenues']);
      //setOperationalStatuses(resultJson['operational_statuses']);
      //setCompliances(resultJson['compliances']);
      //setServiceWindowsDays(resultJson['service_windows_days']);
      //setServiceWindowsHours(resultJson['service_windows_hours']);
    }
  };


  // Fomat Date
  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }
  
  // Form data chaned
  const handleChange = (e) => {
    setThreatActor({
      ...threatActor,
      [e.target.name]: e.target.value
    });
  };
  const handleThreatActorFirstSeenDateChange = (e) => {
    setThreatActorFirstSeenDate(e.target.value);
    setThreatActor({
      ...threatActor,
      ['threat_actor_first_seen_date']: e.target.value
    });
  };
  const handleChangeDescription = (e) => {
    threatActor.threat_actor_description = e;
  };
  const handleChangeMotivation = (e) => {
    threatActor.threat_actor_motivation = e;
  };
  const handleChangeCapability = (e) => {
    threatActor.threat_actor_capability = e;
  };
  const handleChangeTools = (e) => {
    threatActor.threat_actor_tools = e;
  };
  const handleChangeTechniques = (e) => {
    threatActor.threat_actor_techniques = e;
  };
  const handleChangeInfrastructure = (e) => {
    threatActor.threat_actor_infrastructure = e;
  };
  const handleChangeCounterOperations = (e) => {
    threatActor.threat_actor_counter_operations = e;
  };
  const handleChangeSources = (e) => {
    threatActor.threat_actor_sources = e;
  };

  // Send 
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/threat-actors/edit-threat-actor', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(threatActor)
    });
    let resultJson = await result.json();
    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    }
  };


  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadThreatActor();
      loadOptions();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/intelligence">Intelligence</a>
          &nbsp; / &nbsp;
          <a href="/intelligence/threat-actors">Threat Actors</a>
          &nbsp; / &nbsp;
          <a href={`/intelligence/threat-actors/view-threat-actor/${threatActorId}`}>{threatActor.threat_actor_name}</a>
          &nbsp; / &nbsp;
          <a href={`/intelligence/threat-actors/edit-threat-actor/${threatActorId}`}>Edit</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>Edit {threatActor.threat_actor_name}</h1>

      <form onSubmit={handleSubmit} id="form">


      {/* Feedback */}
      <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
        <p id="feedback_p">Feedback box is here</p>
      </div>

      {/* Main box */}
      <div className="main_box" id="main_box" style={{ display: 'none' }}>
          
        <p><a id="name"></a>
        <b>Name:</b><br />
        <input type="text" name="threat_actor_name" value={threatActor.threat_actor_name} onChange={handleChange} style={{ minWidth: '50%' }} autoFocus />
        </p>

        <p><a id="short_description"></a>
        <b>Short description:</b><br />
        <textarea name="threat_actor_short_description" value={threatActor.threat_actor_short_description} onChange={handleChange} style={{ minWidth: '50%' }}></textarea>
        </p>

        <p><a id="mitre_attck_link_name"></a>
        <b>Mitre Att&amp;ck Name:</b><br />
        <input type="text" name="threat_actor_mitre_attck_link_name" value={threatActor.threat_actor_mitre_attck_link_name} onChange={handleChange} style={{ minWidth: '50%' }} autoFocus />
        </p>

        <p><b>Mitre Att&amp;ck URL:</b><br />
        <input type="text" name="threat_actor_mitre_attck_link_url" value={threatActor.threat_actor_mitre_attck_link_url} onChange={handleChange} style={{ minWidth: '50%' }} autoFocus />
        </p>

        <p><b>Dangerous:</b><br />
        <select name="threat_actor_dangerous_number" value={threatActor.threat_actor_dangerous_number} onChange={handleChange}>
          <option value="-1">Please select</option>
          <option value="1">Low</option>
          <option value="2">Medium</option>
          <option value="3">High</option>
        </select>
        </p>

        <p><b>First seen:</b><br />
        <span className="small">{threatActor.threat_actor_first_seen_date_saying} ({threatActorFirstSeenDate})</span><br />
        <input type="date" name="threat_actor_first_seen_date" value={threatActorFirstSeenDate} onChange={handleThreatActorFirstSeenDateChange} />
        </p>

        <p><b>Country of origin:</b><br />
        <select name="threat_actor_country_of_origin_three" value={threatActor.threat_actor_country_of_origin_three} onChange={handleChange}>
          <option value="N/A">Please select</option>
          {countries.map((item, index) => (
            <option key={index} value={item.country_iso_three_upper}>{item.country_iso_three_upper}</option>
          ))}
        </select>
        </p>


        <p><b>Country of attribution:</b><br />
        <select name="threat_actor_country_of_attribution_three" value={threatActor.threat_actor_country_of_attribution_three} onChange={handleChange}>
          <option value="N/A">Please select</option>
          {countries.map((item, index) => (
            <option key={index} value={item.country_iso_three_upper}>{item.country_iso_three_upper}</option>
          ))}
        </select>
        </p>
      </div> {/* //Main box */}

      {/* Description */}
      <div className='main_box'>
        <p style={{ paddingBottom: 0, marginBottom: 0 }}><a id="description"></a><b>Description</b><br />
        </p>
        <ReactQuill theme="snow" name="threat_actor_description" value={threatActor.threat_actor_description} onChange={handleChangeDescription} />
      </div>

      {/* Motivation */}
      <div className='main_box'>
        <p style={{ paddingBottom: 0, marginBottom: 0 }}><a id="motivation"></a><b>Motivation</b><br />
        </p>
        <ReactQuill theme="snow" name="threat_actor_motivation" value={threatActor.threat_actor_motivation} onChange={handleChangeMotivation} />
      </div>

      {/* Capability */}
      <div className='main_box'>
        <p style={{ paddingBottom: 0, marginBottom: 0 }}><a id="capability"></a><b>Capability</b><br />
        </p>
        <ReactQuill theme="snow" name="threat_actor_capability" value={threatActor.threat_actor_capability} onChange={handleChangeCapability} />
      </div>
      
      {/* Tools */}
      <div className='main_box'>
        <p style={{ paddingBottom: 0, marginBottom: 0 }}><a id="tools"></a><b>Tools</b><br />
        </p>
        <ReactQuill theme="snow" name="threat_actor_tools" value={threatActor.threat_actor_tools} onChange={handleChangeTools} />
      </div>

      {/* Techniques */}
      <div className='main_box'>
        <p style={{ paddingBottom: 0, marginBottom: 0 }}><a id="techniques"></a><b>Techniques</b><br />
        </p>
        <ReactQuill theme="snow" name="threat_actor_techniques" value={threatActor.threat_actor_techniques} onChange={handleChangeTechniques} />
      </div>

      {/* Infrastructure */}
      <div className='main_box'>
        <p style={{ paddingBottom: 0, marginBottom: 0 }}><a id="infrastructure"></a><b>Infrastructure</b><br />
        </p>
        <ReactQuill theme="snow" name="threat_actor_infrastructure" value={threatActor.threat_actor_infrastructure} onChange={handleChangeInfrastructure} />
      </div>

      {/* Counter operations */}
      <div className='main_box'>
        <p style={{ paddingBottom: 0, marginBottom: 0 }}><a id="counter_operations"></a><b>Counter operations</b><br />
        </p>
        <ReactQuill theme="snow" name="threat_actor_counter_operations" value={threatActor.threat_actor_counter_operations} onChange={handleChangeCounterOperations} />
      </div>


      {/* Sources */}
      <div className='main_box'>
        <p style={{ paddingBottom: 0, marginBottom: 0 }}><a id="sources"></a><b>Sources</b><br />
        </p>
        <ReactQuill theme="snow" name="threat_actor_sources" value={threatActor.threat_actor_sources} onChange={handleChangeSources} />
      </div>

      <p><button>Submit</button>
          </p>

      </form>
    </div>
  );
}

export default EditThreatActor;
