import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

export function DeleteCustomer() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { customerId } = useParams();
  const [customerIndex, setCustomerIndex] = useState(() => []);

  // Load Customer
  const loadCustomerIndex = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get_customer?customer_id=' + customerId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setCustomerIndex(resultJson);
      // Display form (last element is loaded)
      document.getElementById('form').style.display = 'block';
    }
  };

  // Confirm delete
  async function confirmDelete(e) {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/customers/delete_customer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify({
        customer_id: customerId
      })
    });
    let resultJson = await result.json();

    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      // window.history.back();
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
    }
  }

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadCustomerIndex();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/">Customers</a>
          &nbsp; / &nbsp;
          <a href={`/customers/view_customer/${customerId}`}>{customerIndex.customer_name_abbreviated}</a>
          &nbsp; / &nbsp;
          <a href={`/customers/delete_customer/${customerId}`}>Delete customer</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>Delete customer {customerIndex.customer_name_abbreviated}</h1>

      <div className="main_box" id="form" style={{ display: 'none' }}>
        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        {/* Form */}
        <p>Are you sure you want to delete the customer? This action cannot be undone!</p>

        <p>
          <a href="#delete" className="btn_danger" onClick={confirmDelete}>
            Confirm
          </a>
        </p>
      </div>
    </div>
  );
}

export default DeleteCustomer;
