import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

export function EditMonitorParent() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { monitorParentId } = useParams();

  const [monitorParent, setMonitorParent] = useState({
    monitor_parent_id: monitorParentId,
    monitor_parent_name: '',
    monitor_parent_description: '',
    monitor_parent_url: '',
    monitor_parent_criticality: '',
    monitor_parent_type: '',
    monitor_parent_weight: '',
    monitor_parent_timeout_value: '',
    monitor_parent_timeout_measuring_unit: '',
    monitor_parent_last_ping_datetime: '',
    monitor_parent_last_ping_datetime_saying: '',
    monitor_parent_current_status_this: '',
    monitor_parent_current_status_children: '',
    monitor_parent_current_status_comment: '',
    monitor_parent_warning_slack_channel: '',
    monitor_parent_warning_email_receivers: '',
    monitor_parent_warning_sent: '',
    monitor_parent_owner_name: '',
    monitor_parent_administrator_name: '',
    monitor_parent_commercial_responsibility_name: '',
    monitor_parent_created_datetime: '',
    monitor_parent_created_date_saying: '',
    monitor_parent_created_user_email: '',
    monitor_parent_updated_datetime: '',
    monitor_parent_updated_date_saying: '',
    monitor_parent_updated_user_email: ''
  });

  // Load Identifier
  const loadMonitorParent = async (e) => {
    let result = await fetch(backendURL + '/api/monitor/get_monitor_parent?monitor_parent_id=' + monitorParentId, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setMonitorParent(resultJson);
      // Display form (last element is loaded)
      document.getElementById('form').style.display = 'block';
    }
  };

  // Form data chaned
  const handleChange = (e) => {
    setMonitorParent({
      ...monitorParent,
      [e.target.name]: e.target.value
    });
  };

  // Send new identifier
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/monitor/edit_monitor_parent', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(monitorParent)
    });
    let resultJson = await result.json();
    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadMonitorParent();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/monitor">Monitor</a>
          &nbsp; / &nbsp;
          <a href={`/monitor/view_monitor_parent/${monitorParent.monitor_parent_id}`}>{monitorParent.monitor_parent_name}</a>
          &nbsp; / &nbsp;
          <a href={`/monitor/edit_monitor_parent/${monitorParent.monitor_parent_id}`}>Edit {monitorParent.monitor_parent_name}</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>Edit {monitorParent.monitor_parent_name}</h1>
      <form onSubmit={handleSubmit} id="form" style={{ display: 'none' }}>
        <div className="main_box">
          {/* Feedback */}
          <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
            <p id="feedback_p">Feedback box is here</p>
          </div>

          {/* Form */}

          <p>
            Name
            <br />
            <input type="text" name="monitor_parent_name" value={monitorParent.monitor_parent_name} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
            Description
            <br />
            <textarea name="monitor_parent_description" value={monitorParent.monitor_parent_description} onChange={handleChange} style={{ minWidth: '50%' }}></textarea>
          </p>

          <p>
            URL
            <br />
            <input type="text" name="monitor_parent_url" value={monitorParent.monitor_parent_url} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
            Criticality
            <br />
            <select name="monitor_parent_criticality" value={monitorParent.monitor_parent_criticality} onChange={handleChange}>
              <option value="">Please select</option>
              <option value="high">High</option>
              <option value="medium">Medium</option>
              <option value="low">Low</option>
            </select>
          </p>

          <h2 style={{ paddingLeft: 0 }}>Setting</h2>
          <p>
            Type
            <br />
            <select name="monitor_parent_type" value={monitorParent.monitor_parent_type} onChange={handleChange}>
              <option value="">Please select</option>
              <option value="Receive ping every x minutes">Receive ping every x minutes</option>
              <option value="Manual">Manual</option>
            </select>
          </p>

          <p>
            Timeout value
            <br />
            <input type="text" name="monitor_parent_timeout_value" value={monitorParent.monitor_parent_timeout_value} onChange={handleChange} style={{ width: '6' }} />
            &nbsp;{' '}
            <select name="monitor_parent_timeout_measuring_unit" value={monitorParent.monitor_parent_timeout_measuring_unit} onChange={handleChange}>
              <option value="">-</option>
              <option value="min">min</option>
            </select>
          </p>

          <h2 style={{ paddingLeft: 0 }}>Status</h2>

          <p>
            Last ping datetime
            <br />
            {monitorParent.monitor_parent_last_ping_datetime_saying}
          </p>

          <p>
            Current status this
            <br />
            <select name="monitor_parent_current_status_this" value={monitorParent.monitor_parent_current_status_this} onChange={handleChange}>
              <option value="">Please select</option>
              <option value="Online">Online</option>
              <option value="Degraded">Degraded</option>
              <option value="Offline">Offline</option>
            </select>
          </p>

          <p>
            Current status children
            <br />
            <select name="monitor_parent_current_status_children" value={monitorParent.monitor_parent_current_status_children} onChange={handleChange}>
              <option value="">Please select</option>
              <option value="Online">Online</option>
              <option value="Degraded">Degraded</option>
              <option value="Offline">Offline</option>
            </select>
          </p>

          <p>
            Current status comment
            <br />
            <textarea name="monitor_parent_current_status_comment" value={monitorParent.monitor_parent_current_status_comment} onChange={handleChange} style={{ minWidth: '50%' }}></textarea>
          </p>

          <h2 style={{ paddingLeft: 0 }}>Warning</h2>
          <p>
            Slack channel
            <br />
            #<input type="text" name="monitor_parent_warning_slack_channel" value={monitorParent.monitor_parent_warning_slack_channel} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
            Email receivers
            <br />
            <span className="small">Seperated by space comma. Example: sindre@cdcadvania.com, ana@cdcadvania.com, don@cdcadvania.com</span>
            <br />
            <input type="text" name="monitor_parent_warning_email_receivers" value={monitorParent.monitor_parent_warning_email_receivers} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <h2 style={{ paddingLeft: 0 }}>Responsible</h2>

          <p>
            Owner name
            <br />
            <input type="text" name="monitor_parent_owner_name" value={monitorParent.monitor_parent_owner_name} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
            Administrator name
            <br />
            <input type="text" name="monitor_parent_administrator_name" value={monitorParent.monitor_parent_administrator_name} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
            Commercial responsibility name
            <br />
            <input type="text" name="monitor_parent_commercial_responsibility_name" value={monitorParent.monitor_parent_commercial_responsibility_name} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
            <button>Submit</button>
          </p>
        </div>
      </form>
    </div>
  );
}

export default EditMonitorParent;
