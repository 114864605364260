import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

export function EditMonitorChild() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { monitorChildId } = useParams();

  const [monitorChild, setMonitorChild] = useState({
    monitor_child_id: monitorChildId,
    monitor_child_parent_id: '',
    monitor_child_name: '',
    monitor_child_description: '',
    monitor_child_customer_id: '',
    monitor_child_type: '',
    monitor_child_weight: '',

    monitor_child_ping_timeout_value: '',
    monitor_child_ping_timeout_measuring_unit: '',

    monitor_child_ping_last_datetime: '',
    monitor_child_ping_last_datetime_saying: '',
    monitor_child_ping_last_message: '',
    monitor_child_ping_last_ip: '',
    monitor_child_ping_degraded_datetime: '',
    monitor_child_ping_degraded_datetime_saying: '',
    monitor_child_ping_degraded_acknowledged: '',
    monitor_child_ping_degraded_comment: '',
    monitor_child_ping_warning_sent: '',
    monitor_child_ping_resolved_datetime: '',
    monitor_child_ping_resolved_datetime_saying: '',
    monitor_child_ping_status: '',

    monitor_child_events_expected: '',
    monitor_child_events_month_last_reset: '',
    monitor_child_events_last_status_check_month: '',
    monitor_child_events_last_event_datetime: '',
    monitor_child_events_last_event_datetime_saying: '',
    monitor_child_events_degraded_datetime: '',
    monitor_child_events_degraded_datetime_saying: '',
    monitor_child_events_degraded_acknowledged: '',
    monitor_child_events_degraded_comment: '',
    monitor_child_events_warning_sent: '',
    monitor_child_events_resolved_datetime: '',
    monitor_child_events_resolved_datetime_saying: '',
    monitor_child_events_status: '',

    monitor_child_events_month_01: '',
    monitor_child_events_month_02: '',
    monitor_child_events_month_03: '',
    monitor_child_events_month_04: '',
    monitor_child_events_month_05: '',
    monitor_child_events_month_06: '',
    monitor_child_events_month_07: '',
    monitor_child_events_month_08: '',
    monitor_child_events_month_09: '',
    monitor_child_events_month_10: '',
    monitor_child_events_month_11: '',
    monitor_child_events_month_12: '',

    monitor_child_warning_slack_channel: '',
    monitor_child_warning_email_receivers: '',

    monitor_child_auto_delete_year: '',

    monitor_child_owner_name: '',
    monitor_child_administrator_name: '',
    monitor_child_commercial_responsibility_name: '',
    monitor_child_created_datetime: '',
    monitor_child_created_date_saying: '',
    monitor_child_created_user_email: '',
    monitor_child_updated_datetime: '',
    monitor_child_updated_date_saying: '',
    monitor_child_updated_user_email: ''
  });

  // Load Child
  const loadMonitorChild = async (e) => {
    let result = await fetch(backendURL + '/api/monitor/get_monitor_child?monitor_child_id=' + monitorChildId, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setMonitorChild(resultJson);
      // Display form (last element is loaded)
      document.getElementById('form').style.display = 'block';
    }
  };

  // Form data chaned
  const handleChange = (e) => {
    setMonitorChild({
      ...monitorChild,
      [e.target.name]: e.target.value
    });
  };

  // Send new identifier
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/monitor/edit_monitor_child', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(monitorChild)
    });
    let resultJson = await result.json();
    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadMonitorChild();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/monitor">Monitor</a>
          &nbsp; / &nbsp;
          <a href={`/monitor/view_monitor_parent/${monitorChild.monitor_child_parent_id}`}>Parent {monitorChild.monitor_child_parent_id}</a>
          &nbsp; / &nbsp;
          <a href={`/monitor/edit_monitor_child/${monitorChild.monitor_parent_id}`}>Edit {monitorChild.monitor_child_name}</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>Edit {monitorChild.monitor_child_name}</h1>
      <form onSubmit={handleSubmit} id="form" style={{ display: 'none' }}>
        <div className="main_box">
          {/* Feedback */}
          <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
            <p id="feedback_p">Feedback box is here</p>
          </div>

          {/* Form */}
          <h2 style={{ paddingLeft: 0 }}>General</h2>
          <p>
            <b>Name</b>
            <br />
            <input type="text" name="monitor_child_name" value={monitorChild.monitor_child_name} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
            <b>Description</b>
            <br />
            <textarea name="monitor_child_description" value={monitorChild.monitor_child_description} onChange={handleChange} style={{ minWidth: '50%' }}></textarea>
          </p>

          <p>
            <b>Customer ID</b>
            <br />
            <input type="text" name="monitor_child_customer_id" value={monitorChild.monitor_child_customer_id} onChange={handleChange} width="3" />
          </p>

          <h2 style={{ paddingLeft: 0 }}>Setting</h2>
          <p>
            <b>Type</b>
            <br />
            <select name="monitor_child_type" value={monitorChild.monitor_child_type} onChange={handleChange}>
              <option value="">Please select</option>
              <option value="Receive ping every x minutes">Receive ping every x minutes</option>
              <option value="Manual">Manual</option>
              <option value="Receive events per month">Receive events per month</option>
            </select>
          </p>

          <hr />
          <h2 style={{ paddingLeft: 0 }}>Ping Settings</h2>

          <table>
            <tr>
              <td style={{ padding: '0px 5px 0px 0px' }}>
                <span>
                  <b>Ping last datetime:</b>
                </span>
              </td>
              <td>
                <span>{monitorChild.monitor_child_ping_last_datetime_saying}</span>
              </td>
            </tr>
            <tr>
              <td style={{ padding: '0px 5px 0px 0px' }}>
                <span>
                  <b>Ping last message:</b>
                </span>
              </td>
              <td>
                <span>{monitorChild.monitor_child_ping_last_message}</span>
              </td>
            </tr>

            <tr>
              <td style={{ padding: '20px 5px 0px 0px' }}>
                <span>
                  <b>Ping degraded message:</b>
                </span>
              </td>
              <td style={{ padding: '20px 0px 0px 0px' }}>
                <span>{monitorChild.monitor_child_ping_degraded_datetime_saying}</span>
              </td>
            </tr>
            <tr>
              <td style={{ padding: '0px 5px 0px 0px' }}>
                <span>
                  <b>Ping degraded acknowledged:</b>
                </span>
              </td>
              <td>
                <span>
                  <select name="monitor_child_ping_degraded_acknowledged" value={monitorChild.monitor_child_ping_degraded_acknowledged} onChange={handleChange}>
                    <option value="1">Acknowledged</option>
                    <option value="0">Not acknowledged</option>
                  </select>
                </span>
                <span>{monitorChild.monitor_child_ping_degraded_datetime_saying}</span>
              </td>
            </tr>
            <tr>
              <td style={{ padding: '0px 5px 0px 0px' }}>
                <span>
                  <b>Ping degraded comment:</b>
                </span>
              </td>
              <td>
                <span>
                  <input type="text" name="monitor_child_ping_degraded_comment" value={monitorChild.monitor_child_ping_degraded_comment} onChange={handleChange} style={{ minWidth: '99%' }} />
                </span>
              </td>
            </tr>
            <tr>
              <td style={{ padding: '20px 5px 0px 0px' }}>
                <span>
                  <b>Ping resolved datetime:</b>
                </span>
              </td>
              <td style={{ padding: '20px 0px 0px 0px' }}>
                <span>{monitorChild.monitor_child_ping_resolved_datetime_saying}</span>
              </td>
            </tr>
            <tr>
              <td style={{ padding: '0px 5px 0px 0px' }}>
                <span>
                  <b>Ping status:</b>
                </span>
              </td>
              <td>
                <span>
                  <select name="monitor_child_ping_status" value={monitorChild.monitor_child_ping_status} onChange={handleChange}>
                    <option value="Online">Online</option>
                    <option value="Degraded"> Degraded</option>
                    <option value="Offline"> Offline</option>
                  </select>
                </span>
              </td>
            </tr>
            <tr>
              <td style={{ padding: '0px 5px 0px 0px' }}>
                <span>
                  <b>Ping degraded comment:</b>
                </span>
              </td>
              <td>
                <span>
                  <input type="text" name="monitor_child_ping_degraded_comment" value={monitorChild.monitor_child_ping_degraded_comment} onChange={handleChange} style={{ minWidth: '99%' }} />
                </span>
              </td>
            </tr>

            <tr>
              <td style={{ padding: '20px 5px 0px 0px' }}>
                <span>
                  <b>Timeout value:</b>
                </span>
              </td>
              <td style={{ padding: '20px 0px 0px 0px' }}>
                <span>
                  <input type="text" name="monitor_child_timeout_value" value={monitorChild.monitor_child_timeout_value} onChange={handleChange} style={{ width: '6' }} />
                  &nbsp;{' '}
                  <select name="monitor_child_timeout_measuring_unit" value={monitorChild.monitor_child_timeout_measuring_unit} onChange={handleChange}>
                    <option value="">-</option>
                    <option value="min">min</option>
                  </select>
                </span>
              </td>
            </tr>
          </table>

          <hr />
          <h2 style={{ padding: '20px 0px 0px 0px' }}>Events Settings</h2>

          <table>
            <tr>
              <td style={{ padding: '0px 5px 0px 0px' }}>
                <span>
                  <b>Events expected (per month):</b>
                </span>
              </td>
              <td>
                <span>
                  <input type="text" name="monitor_child_events_expected" value={monitorChild.monitor_child_events_expected} onChange={handleChange} size="5" />
                </span>
              </td>
            </tr>

            <tr>
              <td style={{ padding: '0px 5px 0px 0px' }}>
                <span>
                  <b>Events month last reset:</b>
                </span>
              </td>
              <td>
                <span>{monitorChild.monitor_child_events_month_last_reset}</span>
              </td>
            </tr>

            <tr>
              <td style={{ padding: '20px 5px 0px 0px' }}>
                <span>
                  <b>Events last status check month:</b>
                </span>
              </td>
              <td style={{ padding: '20px 0px 0px 0px' }}>
                <span>{monitorChild.monitor_child_events_last_status_check_month}</span>
              </td>
            </tr>
            <tr>
              <td style={{ padding: '0px 5px 0px 0px' }}>
                <span>
                  <b>Events last event datetime:</b>
                </span>
              </td>
              <td>
                <span>{monitorChild.monitor_child_events_last_event_datetime_saying}</span>
              </td>
            </tr>

            <tr>
              <td style={{ padding: '20px 5px 0px 0px' }}>
                <span>
                  <b>Events degraded datetime:</b>
                </span>
              </td>
              <td style={{ padding: '20px 0px 0px 0px' }}>
                <span>{monitorChild.monitor_child_events_degraded_datetime_saying}</span>
              </td>
            </tr>
            <tr>
              <td style={{ padding: '0px 5px 0px 0px' }}>
                <span>
                  <b>Events degraded acknowledged:</b>
                </span>
              </td>
              <td>
                <span>
                  <select name="monitor_child_events_degraded_acknowledged" value={monitorChild.monitor_child_events_degraded_acknowledged} onChange={handleChange}>
                    <option value="1">Acknowledged</option>
                    <option value="0">Not acknowledged</option>
                  </select>
                </span>
              </td>
            </tr>
            <tr>
              <td style={{ padding: '0px 5px 0px 0px' }}>
                <span>
                  <b>Events degraded comment:</b>
                </span>
              </td>
              <td>
                <span>
                  <input type="text" name="monitor_child_events_degraded_comment" value={monitorChild.monitor_child_events_degraded_comment} onChange={handleChange} size="5" />
                </span>
              </td>
            </tr>

            <tr>
              <td style={{ padding: '20px 5px 0px 0px' }}>
                <span>
                  <b>Events warning sent:</b>
                </span>
              </td>
              <td style={{ padding: '20px 0px 0px 0px' }}>
                <span>{monitorChild.monitor_child_events_warning_sent}</span>
              </td>
            </tr>
            <tr>
              <td style={{ padding: '0px 5px 0px 0px' }}>
                <span>
                  <b>Events resolved datetime:</b>
                </span>
              </td>
              <td>
                <span>{monitorChild.monitor_child_events_resolved_datetime_saying}</span>
              </td>
            </tr>
            <tr>
              <td style={{ padding: '0px 5px 0px 0px' }}>
                <span>
                  <b>Events status:</b>
                </span>
              </td>
              <td>
                <span>
                  <select name="monitor_child_ping_status" value={monitorChild.monitor_child_ping_status} onChange={handleChange}>
                    <option value="Online">Online</option>
                    <option value="Degraded">Degraded</option>
                    <option value="Did not receive desired number of events">Did not receive desired number of events</option>
                    <option value="Offline">Offline</option>
                  </select>
                </span>
              </td>
            </tr>
          </table>

          <hr />
          <h2 style={{ padding: '20px 0px 0px 0px' }}>Warning</h2>
          <p>
            Slack channel
            <br />
            #<input type="text" name="monitor_child_warning_slack_channel" value={monitorChild.monitor_child_warning_slack_channel} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
            Email receivers
            <br />
            <span className="small">Seperated by space comma. Example: sindre@cdcadvania.com, ana@cdcadvania.com, don@cdcadvania.com</span>
            <br />
            <input type="text" name="monitor_child_warning_email_receivers" value={monitorChild.monitor_child_warning_email_receivers} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <hr />
          <h2 style={{ paddingLeft: 0 }}>Deletion</h2>

          <p>
            Auto delete the child in year
            <br />
            <input type="text" name="monitor_child_auto_delete_year" value={monitorChild.monitor_child_auto_delete_year} onChange={handleChange} size="5" />
          </p>

          <hr />
          <h2 style={{ paddingLeft: 0 }}>Responsible</h2>

          <p>
            Owner name
            <br />
            <input type="text" name="monitor_child_owner_name" value={monitorChild.monitor_child_owner_name} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
            Administrator name
            <br />
            <input type="text" name="monitor_child_administrator_name" value={monitorChild.monitor_child_administrator_name} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
            Commercial responsibility name
            <br />
            <input type="text" name="monitor_child_commercial_responsibility_name" value={monitorChild.monitor_child_commercial_responsibility_name} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
            <button>Submit</button>
          </p>
        </div>
      </form>
    </div>
  );
}

export default EditMonitorChild;
