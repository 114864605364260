import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

export function DeleteClient() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { clientId } = useParams();

  // Data
  const [client, setClient] = useState({
    client_id: clientId,

    client_collector_id: '', 
    client_customer_id: '', 
    client_name: '', 
    client_type: '', 
    client_sub_domain: '', 
    client_port: '', 
    client_brand: '', 
    client_ip: '', 
    client_administrator_email: '', 
    client_created_datetime: '', 
    client_created_date_saying: '', 
    client_created_by_user_id: '',
    client_created_by_user_email: '',
    client_created_by_ip: '', 
    client_updated_datetime: '', 
    client_updated_date_saying: '', 
    client_updated_by_user_id: '',
    client_updated_by_user_email: '',
    client_updated_by_ip: ''
  });

  // Load data
  const loadClient = async (e) => {
    let result = await fetch(backendURL + '/api/collectors/get-client?client_id=' + clientId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setClient(resultJson);
      document.getElementById('main_box').style.display = 'block';

    }
  };
  // Confirm delete
  async function confirmDelete(e) {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/collectors/delete-client?client_id=' + clientId, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();

    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      window.history.back();
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
    }
  }

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadClient();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/collectors">Collectors</a>
          &nbsp; / &nbsp;
          <a href={`/collectors/view-collector/${client.client_collector_id}`}>Collector {client.client_collector_id}</a>
          &nbsp; / &nbsp;
          <a href={`/collectors/delete-client/${client.client_collector_id}`}>Delete {client.client_name}</a>
        </p>
      </div>
      <div className="clear"></div>
      {/* Main Left */}
      <h1>Delete {client.client_name}</h1>
      <div className="main_box" id="main_box" style={{ display: 'none' }}>
        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        {/* Form */}
        <p>Are you sure you want to delete the client? This action cannot be undone!</p>

        <p>
        <a href="#delete" className="btn_danger" onClick={confirmDelete}>Confirm</a>
        </p>
      </div>
      {/* // main_box */}
    </div>
  );
}

export default DeleteClient;
