import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

export function ViewEngagementStatusLimaCharlie() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { engagementId } = useParams();

  // Data
  const [engagementStatus, setEngagementStatus] = useState({
    status_id: '',
    status_engagement_id: engagementId,
    status_customer_id: '',

    status_created_datetime: '',
    status_created_datetime_saying: '',
    status_created_by_name: '',
    status_created_by_email: '',
    status_updated_datetime: '',
    status_updated_datetime_saying: '',
    status_completed_datetime: '',
    status_completed_datetime_saying: '',

    status_agile_external_id_value: '',
    status_agile_external_id_datetime: '',
    status_agile_external_id_datetime_saying: '',
    status_agile_external_id_notes: '',
    status_agile_external_id_completed: '',

    status_created_organization_value: '',
    status_created_organization_datetime: '',
    status_created_organization_datetime_saying: '',
    status_created_organization_notes: '',
    status_created_organization_completed: '',

    status_add_lc_org_as_identifier_value: '',
    status_add_lc_org_as_identifier_datetime: '',
    status_add_lc_org_as_identifier_datetime_saying: '',
    status_add_lc_org_as_identifier_notes: '',
    status_add_lc_org_as_identifier_completed: '',

    status_give_access_value: '',
    status_give_access_datetime: '',
    status_give_access_datetime_saying: '',
    status_give_access_notes: '',
    status_give_access_completed: '',

    status_give_external_users_tmp_access_emails: '',
    status_give_external_users_tmp_access_value: '',
    status_give_external_users_tmp_access_datetime: '',
    status_give_external_users_tmp_access_datetime_saying: '',
    status_give_external_users_tmp_access_notes: '',
    status_give_external_users_tmp_access_completed: '',

    status_create_api_key_deploy_value: '',
    status_create_api_key_deploy_datetime: '',
    status_create_api_key_deploy_datetime_saying: '',
    status_create_api_key_deploy_notes: '',
    status_create_api_key_deploy_completed: '',

    status_create_api_key_alerts_forwarder_value: '',
    status_create_api_key_alerts_forwarder_datetime: '',
    status_create_api_key_alerts_forwarder_datetime_saying: '',
    status_create_api_key_alerts_forwarder_notes: '',
    status_create_api_key_alerts_forwarder_completed: '',

    status_create_api_key_poll_alerts_value: '',
    status_create_api_key_poll_alerts_datetime: '',
    status_create_api_key_poll_alerts_datetime_saying: '',
    status_create_api_key_poll_alerts_notes: '',
    status_create_api_key_poll_alerts_completed: '',

    status_create_api_key_surveillance_value: '',
    status_create_api_key_surveillance_datetime: '',
    status_create_api_key_surveillance_datetime_saying: '',
    status_create_api_key_surveillance_notes: '',
    status_create_api_key_surveillance_completed: '',

    status_create_installation_keys_value: '',
    status_create_installation_keys_datetime: '',
    status_create_installation_keys_datetime_saying: '',
    status_create_installation_keys_notes: '',
    status_create_installation_keys_completed: '',

    status_enabled_artifact_collection_value: '',
    status_enabled_artifact_collection_datetime: '',
    status_enabled_artifact_collection_datetime_saying: '',
    status_enabled_artifact_collection_notes: '',
    status_enabled_artifact_collection_completed: '',

    status_subscribed_to_addons_value: '',
    status_subscribed_to_addons_datetime: '',
    status_subscribed_to_addons_datetime_saying: '',
    status_subscribed_to_addons_notes: '',
    status_subscribed_to_addons_completed: '',

    status_output_value: '',
    status_output_datetime: '',
    status_output_datetime_saying: '',
    status_output_notes: '',
    status_output_completed: '',

    status_sensors_and_ingest_method: '',
    status_sensors_and_ingest_method_email_to: '',
    status_sensors_and_ingest_method_web_key: '',
    status_sensors_and_ingest_method_greeting: '',
    status_sensors_and_ingest_method_scope: '',
    status_sensors_and_ingest_method_value: '',
    status_sensors_and_ingest_method_datetime: '',
    status_sensors_and_ingest_method_datetime_saying: '',
    status_sensors_and_ingest_method_notes: '',
    status_sensors_and_ingest_method_completed: '',

    status_sensors_installed_value: '',
    status_sensors_installed_datetime: '',
    status_sensors_installed_datetime_saying: '',
    status_sensors_installed_notes: '',
    status_sensors_installed_completed: '',

    customer_name: '',
    customer_name_abbreviated: '',

    engagement_service_name: '',
    engagement_service_flavor: ''
  });

  // Load engagement status
  const loadEngagementStatus = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get_customer_engagement_status_limacharlie?engagement_id=' + engagementId, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setEngagementStatus(resultJson);
      console.log('ViewEngagementStatusLimaCharlie() :: loadEngagementStatus() :: ok with ID=' + engagementStatus.status_engagement_id + ' and customer id=' + engagementStatus.status_customer_id);

      document.getElementById('show_customer').style.display = 'block';
    }
  };

  // Form data chaned
  const handleChange = (e) => {
    setEngagementStatus({
      ...engagementStatus,
      [e.target.name]: e.target.value
    });
  };

  const handleChangeCheckbox = (e) => {
    console.log('ViewEngagementStatusLimaCharlie() :: handleChangeCheckbox() :: e.target.name=' + e.target.name + ' e.target.value=' + e.target.value);

    if (e.target.value === '' || e.target.value === 'on') {
      e.target.value = 'checked';
    } else {
      e.target.value = '';
    }

    setEngagementStatus({
      ...engagementStatus,
      [e.target.name]: e.target.value
    });
  };

  // Send new contact
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/customers/edit_customer_engagement_status_limacharlie', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(engagementStatus)
    });
    let resultJson = await result.json();
    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadEngagementStatus();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div id="show_customer" style={{ display: 'none' }}>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/">Customers</a>
          &nbsp; / &nbsp;
          <a href={`/customers/view_customer/${engagementStatus.status_customer_id}`}>{engagementStatus.customer_name_abbreviated}</a>
          &nbsp; / &nbsp;
        </p>
      </div>
      <div className="clear"></div>

      {/* Headline and actions */}
      <h1>{engagementStatus.customer_name}</h1>

      {/* Customer tabs */}
      <ul className="tabs">
        <li>
          <a href={`/customers/view_customer/${engagementStatus.status_customer_id}`}>Index</a>
        </li>
        <li>
          <a href={`/customers/view_business/${engagementStatus.status_customer_id}`}>Business</a>
        </li>
        <li>
          <a href={`/customers/view_identifiers/${engagementStatus.status_customer_id}`}>Identifiers</a>
        </li>
        <li>
          <a href={`/customers/view_engagements/${engagementStatus.status_customer_idomerId}`} className="active">
            Engagements
          </a>
        </li>
        {/* <li><a href={`/customers/view_sensors/${engagementStatus.status_customer_id}`}>Sensors</a></li> */}
      </ul>

      <div className="main_box">
        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        {/* Debugging links */}
        <p>
          <a href="https://console.cloud.google.com/functions/details/europe-west1/limacharlie-onboarding?env=gen1&amp;project=advania-cdc-engineering-infra" className="btn_default">
            <img src="/gfx/icons/20x20/send_rounded_nofill_black_20x20.svg" alt="send_rounded_nofill_black_20x20.svg" /> limacharlie-onboarding in Google Functions
          </a>
          <a href="https://europe-west1-advania-cdc-engineering-infra.cloudfunctions.net/limacharlie-onboarding" className="btn_default">
            <img src="/gfx/icons/20x20/send_rounded_nofill_black_20x20.svg" alt="send_rounded_nofill_black_20x20.svg" /> Run limacharlie-onboarding
          </a>
        </p>

        {/* Form */}
        <form onSubmit={handleSubmit} id="form">
          {/* General information */}
          <h2 style={{ paddingLeft: 0 }}>General information</h2>
          <div style={{ float: 'left', marginRight: '20px' }}>
            <table>
              <tbody>
                <tr>
                  <td style={{ padding: '0px 5px 0px 0px' }}>
                    <span>
                      <b>Status ID:</b>
                    </span>
                  </td>
                  <td>
                    <span>{engagementStatus.status_id}</span>
                  </td>
                </tr>

                <tr>
                  <td style={{ padding: '0px 5px 0px 0px' }}>
                    <span>
                      <b>Egagement ID:</b>
                    </span>
                  </td>
                  <td>
                    <span>{engagementStatus.status_engagement_id}</span>
                  </td>
                </tr>

                <tr>
                  <td style={{ padding: '0px 5px 0px 0px' }}>
                    <span>
                      <b>Customer ID:</b>
                    </span>
                  </td>
                  <td>
                    <span>{engagementStatus.status_customer_id}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{ float: 'left', marginRight: '20px' }}>
            <table>
              <tbody>
                <tr>
                  <td style={{ padding: '0px 5px 0px 0px' }}>
                    <span>
                      <b>Service name:</b>
                    </span>
                  </td>
                  <td>
                    <span>{engagementStatus.engagement_service_name}</span>
                  </td>
                </tr>

                <tr>
                  <td style={{ padding: '0px 5px 0px 0px' }}>
                    <span>
                      <b>Service flavor:</b>
                    </span>
                  </td>
                  <td>
                    <span>{engagementStatus.engagement_service_flavor}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{ float: 'left', marginRight: '20px' }}>
            <table>
              <tbody>
                <tr>
                  <td style={{ padding: '0px 5px 0px 0px' }}>
                    <span>
                      <b>Created:</b>
                    </span>
                  </td>
                  <td>
                    <span>{engagementStatus.status_created_datetime_saying}</span>
                  </td>
                </tr>

                <tr>
                  <td style={{ padding: '0px 5px 0px 0px' }}>
                    <span>
                      <b>Created by name:</b>
                    </span>
                  </td>
                  <td>
                    <span>{engagementStatus.status_created_by_name}</span>
                  </td>
                </tr>

                <tr>
                  <td style={{ padding: '0px 5px 0px 0px' }}>
                    <span>
                      <b>Created by email:</b>
                    </span>
                  </td>
                  <td>
                    <span>{engagementStatus.status_created_by_email}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>{' '}
          {/* //left */}
          <div style={{ float: 'left' }}>
            <table>
              <tbody>
                <tr>
                  <td style={{ padding: '0px 5px 0px 0px' }}>
                    <span>
                      <b>Updated:</b>
                    </span>
                  </td>
                  <td>
                    <span>{engagementStatus.status_updated_datetime_saying}</span>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 5px 0px 0px' }}>
                    <span>
                      <b>Completed:</b>
                    </span>
                  </td>
                  <td>
                    <span>{engagementStatus.status_completed_datetime_saying}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>{' '}
          {/* //left */}
          <div className="clear"></div>
          {/* //General information */}
          {/* Agile */}
          <p style={{ padding: '10px 0px 0px 0px' }}>
            <b>Agile External ID</b>
          </p>
          <table>
            <tbody>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Value:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_agile_external_id_value" value={engagementStatus.status_agile_external_id_value} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Updated:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_agile_external_id_datetime_saying" value={engagementStatus.status_agile_external_id_datetime_saying} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Notes:</span>
                </td>
                <td>
                  <span>
                    <textarea name="status_agile_external_id_notes" value={engagementStatus.status_agile_external_id_notes} rows="4" cols="100" onChange={handleChange} style={{ minWidth: '99%' }}></textarea>
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Completed:</span>
                </td>
                <td>
                  <span>
                    <input type="checkbox" name="status_agile_external_id_completed" checked={engagementStatus.status_agile_external_id_completed} onChange={handleChangeCheckbox} />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          {/* //Jira */}
          {/* Created organization */}
          <p style={{ padding: '10px 0px 0px 0px' }}>
            <b>Created organization</b>
          </p>
          <table>
            <tbody>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Value:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_created_organization_value" value={engagementStatus.status_created_organization_value} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Updated:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_created_organization_datetime_saying" value={engagementStatus.status_created_organization_datetime_saying} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Notes:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_created_organization_notes" value={engagementStatus.status_created_organization_notes} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Completed:</span>
                </td>
                <td>
                  <span>
                    <input type="checkbox" name="status_created_organization_completed" checked={engagementStatus.status_created_organization_completed} onChange={handleChangeCheckbox} />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          {/* //Created organization */}
          {/*  Add LC org as identifier (to CDC24) */}
          <p style={{ padding: '10px 0px 0px 0px' }}>
            <b>Add LC org as identifier (to CDC24)</b>
          </p>
          <table>
            <tbody>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Value:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_add_lc_org_as_identifier_value" value={engagementStatus.status_add_lc_org_as_identifier_value} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Updated:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_add_lc_org_as_identifier_datetime_saying" value={engagementStatus.status_add_lc_org_as_identifier_datetime_saying} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Notes:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_add_lc_org_as_identifier_notes" value={engagementStatus.status_add_lc_org_as_identifier_notes} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Completed:</span>
                </td>
                <td>
                  <span>
                    <input type="checkbox" name="status_add_lc_org_as_identifier_completed" checked={engagementStatus.status_add_lc_org_as_identifier_completed} onChange={handleChangeCheckbox} />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          {/* //Add LC org as identifier (to CDC24) */}
          {/*  Give access (to the LC organization for Engineering etc) */}
          <p style={{ padding: '10px 0px 0px 0px' }}>
            <b>Give access (to the LC organization for Engineering etc)</b>
          </p>
          <table>
            <tbody>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Value:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_give_access_value" value={engagementStatus.status_give_access_value} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Updated:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_give_access_datetime_saying" value={engagementStatus.status_give_access_datetime_saying} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Notes:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_give_access_notes" value={engagementStatus.status_give_access_notes} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Completed:</span>
                </td>
                <td>
                  <span>
                    <input type="checkbox" name="status_give_access_completed" checked={engagementStatus.status_give_access_completed} onChange={handleChangeCheckbox} />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          {/* //Give access (to the LC organization for Engineering etc) */}
          {/*  Give external users tmp access */}
          <p style={{ padding: '10px 0px 0px 0px' }}>
            <b>Give external users tmp access (for update date + two months)</b>
          </p>
          <table>
            <tbody>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Emails:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_give_external_users_tmp_access_emails" value={engagementStatus.status_give_external_users_tmp_access_emails} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Value:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_give_external_users_tmp_access_value" value={engagementStatus.status_give_external_users_tmp_access_value} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Updated:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_give_external_users_tmp_access_datetime_saying" value={engagementStatus.status_give_external_users_tmp_access_datetime_saying} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Notes:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_give_external_users_tmp_access_notes" value={engagementStatus.status_give_external_users_tmp_access_notes} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Completed:</span>
                </td>
                <td>
                  <span>
                    <input type="checkbox" name="status_give_external_users_tmp_access_completed" checked={engagementStatus.status_give_external_users_tmp_access_completed} onChange={handleChangeCheckbox} />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          {/* //Give access (to the LC organization for Engineering etc) */}
          {/* Create API deploy key (for LC Signatures repo) */}
          <p style={{ padding: '10px 0px 0px 0px' }}>
            <b>Create API deploy key (for LC Signatures repo)</b>
          </p>
          <table>
            <tbody>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Value:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_create_api_key_deploy_value" value={engagementStatus.status_create_api_key_deploy_value} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Updated:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_create_api_key_deploy_datetime_saying" value={engagementStatus.status_create_api_key_deploy_datetime_saying} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Notes:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_create_api_key_deploy_notes" value={engagementStatus.status_create_api_key_deploy_notes} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Completed:</span>
                </td>
                <td>
                  <span>
                    <input type="checkbox" name="status_create_api_key_deploy_completed" checked={engagementStatus.status_create_api_key_deploy_completed} onChange={handleChangeCheckbox} />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          {/* //Create API deploy key (for LC Signatures repo) */}
          {/* Create API key for Poll Alerts */}
          <p style={{ padding: '10px 0px 0px 0px' }}>
            <b>Create API key for Alerts Forwarder</b>
          </p>
          <table>
            <tbody>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Value:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_create_api_key_alerts_forwarder_value" value={engagementStatus.status_create_api_key_alerts_forwarder_value} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Updated:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_create_api_key_alerts_forwarder_datetime_saying" value={engagementStatus.status_create_api_key_alerts_forwarder_datetime_saying} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Notes:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_create_api_key_alerts_forwarder_notes" value={engagementStatus.status_create_api_key_alerts_forwarder_notes} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Completed:</span>
                </td>
                <td>
                  <span>
                    <input type="checkbox" name="status_create_api_key_alerts_forwarder_completed" checked={engagementStatus.status_create_api_key_alerts_forwarder_completed} onChange={handleChangeCheckbox} />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          {/* //Create API key for Poll Alerts */}


          {/* Create API key for Poll Alerts */}
          <p style={{ padding: '10px 0px 0px 0px' }}>
            <b>Create API key for Poll Alerts</b>
          </p>
          <table>
            <tbody>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Value:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_create_api_key_poll_alerts_value" value={engagementStatus.status_create_api_key_poll_alerts_value} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Updated:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_create_api_key_poll_alerts_datetime_saying" value={engagementStatus.status_create_api_key_poll_alerts_datetime_saying} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Notes:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_create_api_key_poll_alerts_notes" value={engagementStatus.status_create_api_key_alertstatus_create_api_key_poll_alerts_notes} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Completed:</span>
                </td>
                <td>
                  <span>
                    <input type="checkbox" name="status_create_api_key_poll_alerts_completed" checked={engagementStatus.status_create_api_key_poll_alerts_completed} onChange={handleChangeCheckbox} />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          {/* //Create API key for Poll Alerts */}
          


          {/* Create API key for Surveillance */}
          <p style={{ padding: '10px 0px 0px 0px' }}>
            <b>Create API key for Surveillance</b>
          </p>
          <table>
            <tbody>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Value:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_create_api_key_surveillance_value" value={engagementStatus.status_create_api_key_surveillance_value} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Updated:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_create_api_key_surveillance_datetime_saying" value={engagementStatus.status_create_api_key_surveillance_datetime_saying} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Notes:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_create_api_key_surveillance_notes" value={engagementStatus.status_create_api_key_surveillance_notes} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Completed:</span>
                </td>
                <td>
                  <span>
                    <input type="checkbox" name="status_create_api_key_surveillance_completed" checked={engagementStatus.status_create_api_key_surveillance_completed} onChange={handleChangeCheckbox} />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          {/* //Create API key for Surveillance */}
          {/* Create installation keys (Production, Ingest, Firewalls etc) */}
          <p style={{ padding: '10px 0px 0px 0px' }}>
            <b>Create installation keys (Production, Ingest, Firewalls etc)</b>
          </p>
          <table>
            <tbody>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Value:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_create_installation_keys_value" value={engagementStatus.status_create_installation_keys_value} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Updated:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_create_installation_keys_datetime_saying" value={engagementStatus.status_create_installation_keys_datetime_saying} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Notes:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_create_installation_keys_notes" value={engagementStatus.status_create_installation_keys_notes} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Completed:</span>
                </td>
                <td>
                  <span>
                    <input type="checkbox" name="status_create_installation_keys_completed" checked={engagementStatus.status_create_installation_keys_completed} onChange={handleChangeCheckbox} />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          {/* //Create installation keys (Production, Ingest, Firewalls) */}
          {/* Enabled Artifact Collection */}
          <p style={{ padding: '10px 0px 0px 0px' }}>
            <b>Enabled Artifact Collection</b>
          </p>
          <table>
            <tbody>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Value:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_enabled_artifact_collection_value" value={engagementStatus.status_enabled_artifact_collection_value} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Updated:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_enabled_artifact_collection_datetime_saying" value={engagementStatus.status_enabled_artifact_collection_datetime_saying} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Notes:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_enabled_artifact_collection_notes" value={engagementStatus.status_enabled_artifact_collection_notes} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Completed:</span>
                </td>
                <td>
                  <span>
                    <input type="checkbox" name="status_enabled_artifact_collection_completed" checked={engagementStatus.status_enabled_artifact_collection_completed} onChange={handleChangeCheckbox} />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          {/* //Enabled Artifact Collection */}
          {/* Subscribed to addons */}
          <p style={{ padding: '10px 0px 0px 0px' }}>
            <b>Subscribed to addons</b>
          </p>
          <table>
            <tbody>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Value:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_subscribed_to_addons_value" value={engagementStatus.status_subscribed_to_addons_value} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Updated:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_subscribed_to_addons_datetime_saying" value={engagementStatus.status_subscribed_to_addons_datetime_saying} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Notes:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_subscribed_to_addons_notes" value={engagementStatus.status_subscribed_to_addons_notes} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Completed:</span>
                </td>
                <td>
                  <span>
                    <input type="checkbox" name="status_subscribed_to_addons_completed" checked={engagementStatus.status_subscribed_to_addons_completed} onChange={handleChangeCheckbox} />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          {/* //Subscribed to addons */}
          {/* Output */}
          <p style={{ padding: '10px 0px 0px 0px' }}>
            <b>Output</b>
          </p>
          <table>
            <tbody>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Value:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_output_value" value={engagementStatus.status_output_value} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Updated:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_output_datetime_saying" value={engagementStatus.status_output_datetime_saying} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Notes:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_output_notes" value={engagementStatus.status_output_notes} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Completed:</span>
                </td>
                <td>
                  <span>
                    <input type="checkbox" name="status_output_completed" checked={engagementStatus.status_output_completed} onChange={handleChangeCheckbox} />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          {/* //Subscribed to addons */}
          {/* Sensors and ingest method */}
          <p style={{ padding: '10px 0px 0px 0px' }}>
            <b>Sensors and ingest method</b>
          </p>
          <table>
            <tbody>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Method:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_sensors_and_ingest_method" value={engagementStatus.status_sensors_and_ingest_method} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                  <span className="small">&nbsp; email, change, cube</span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Email to:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_sensors_and_ingest_method_email_to" value={engagementStatus.status_sensors_and_ingest_method_email_to} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Greeting:</span>
                </td>
                <td>
                  <span>
                    <textarea name="status_sensors_and_ingest_method_greeting" value={engagementStatus.status_sensors_and_ingest_method_greeting} onChange={handleChange} rows={10} cols={60} style={{ width: '99%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Scope:</span>
                </td>
                <td>
                  <span>
                    <textarea name="status_sensors_and_ingest_method_scope" value={engagementStatus.status_sensors_and_ingest_method_scope} onChange={handleChange} rows={10} cols={60} style={{ width: '99%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Invoicing</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_sensors_and_ingest_method_invoicing" value={engagementStatus.status_sensors_and_ingest_method_invoicing} onChange={handleChange} size={25} style={{ width: '99%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Web key:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_sensors_and_ingest_method_web_key" value={engagementStatus.status_sensors_and_ingest_method_web_key} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Value:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_sensors_and_ingest_method_value" value={engagementStatus.status_sensors_and_ingest_method_value} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Updated:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_sensors_and_ingest_method_datetime_saying" value={engagementStatus.status_sensors_and_ingest_method_datetime_saying} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Notes:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_sensors_and_ingest_method_notes" value={engagementStatus.status_sensors_and_ingest_method_notes} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Completed:</span>
                </td>
                <td>
                  <span>
                    <input type="checkbox" name="status_sensors_and_ingest_method_completed" checked={engagementStatus.status_sensors_and_ingest_method_completed} onChange={handleChangeCheckbox} />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          {/* //Sensors and ingest method */}
          {/* Sensors installed */}
          <p style={{ padding: '10px 0px 0px 0px' }}>
            <b>Sensors installed</b>
          </p>
          <table>
            <tbody>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Value:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_sensors_installed_value" value={engagementStatus.status_sensors_installed_value} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Updated:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_sensors_installed_datetime_saying" value={engagementStatus.status_sensors_installed_datetime_saying} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Notes:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_sensors_installed_notes" value={engagementStatus.status_sensors_installed_notes} onChange={handleChange} style={{ minWidth: '99%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Completed:</span>
                </td>
                <td>
                  <span>
                    <input type="checkbox" name="status_sensors_installed_completed" checked={engagementStatus.status_sensors_installed_completed} onChange={handleChangeCheckbox} />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          {/* //Sensors installed */}
          <p>
            <button>Submit</button>
          </p>
        </form>
      </div>
    </div>
  );
}

export default ViewEngagementStatusLimaCharlie;
