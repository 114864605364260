import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

export function ViewEngagementStatusVulnerabilityManagement() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { engagementId } = useParams();

  // Data
  const [engagementStatus, setEngagementStatus] = useState({
    status_id: '',
    status_engagement_id: engagementId,
    status_customer_id: '',

    status_created_datetime: '',
    status_created_datetime_saying: '',
    status_updated_datetime: '',
    status_updated_datetime_saying: '',
    status_completed_datetime: '',
    status_completed_datetime_saying: '',

    status_jira_external_id_value: '',
    status_jira_external_id_datetime: '',
    status_jira_external_id_datetime_saying: '',
    status_jira_external_id_notes: '',
    status_jira_external_id_completed: '',

    status_ordered_licenses_value: '',
    status_ordered_licenses_datetime: '',
    status_ordered_licenses_datetime_saying: '',
    status_ordered_licenses_notes: '',
    status_ordered_licenses_completed: '',

    status_created_organization_value: '',
    status_created_organization_datetime: '',
    status_created_organization_datetime_saying: '',
    status_created_organization_notes: '',
    status_created_organization_completed: '',

    status_created_users_value: '',
    status_created_users_datetime: '',
    status_created_users_datetime_saying: '',
    status_created_users_notes: '',
    status_created_users_completed: '',

    status_emailed_users_value: '',
    status_emailed_users_datetime: '',
    status_emailed_users_datetime_saying: '',
    status_emailed_users_notes: '',
    status_emailed_users_completed: '',

    status_agents_method: '',
    status_agents_method_email_to: '',
    status_agents_method_web_key: '',
    status_agents_method_value: '',
    status_agents_method_datetime: '',
    status_agents_method_datetime_saying: '',
    status_agents_method_notes: '',
    status_agents_method_completed: '',

    status_agents_installed_value: '',
    status_agents_installed_datetime: '',
    status_agents_installed_datetime_saying: '',
    status_agents_installed_notes: '',
    status_agents_installed_completed: '',

    customer_name: '',
    customer_name_abbreviated: '',

    engagement_service_name: '',
    engagement_service_flavor: ''
  });

  // Load engagement status
  const loadEngagementStatus = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get_customer_engagement_status_vulnerability_management?engagement_id=' + engagementId, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setEngagementStatus(resultJson);
      // console.log("ViewEngagementStatusLimaCharlie() :: loadEngagementStatus() :: ok with ID=" + engagementStatus.status_engagement_id + " and customer id=" + engagementStatus.status_customer_id);

      document.getElementById('show_customer').style.display = 'block';
    }
  };

  // Form data chaned
  const handleChange = (e) => {
    setEngagementStatus({
      ...engagementStatus,
      [e.target.name]: e.target.value
    });
  };

  const handleChangeCheckbox = (e) => {
    console.log('ViewEngagementStatusLimaCharlie() :: handleChangeCheckbox() :: e.target.name=' + e.target.name + ' e.target.value=' + e.target.value);

    if (e.target.value === '' || e.target.value === 'on') {
      e.target.value = 'checked';
    } else {
      e.target.value = '';
    }

    setEngagementStatus({
      ...engagementStatus,
      [e.target.name]: e.target.value
    });
  };

  // Send changes
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/customers/edit_customer_engagement_status_vulnerability_management', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(engagementStatus)
    });
    let resultJson = await result.json();
    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadEngagementStatus();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div id="show_customer" style={{ display: 'none' }}>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/">Customers</a>
          &nbsp; / &nbsp;
          <a href={`/customers/view_customer/${engagementStatus.status_customer_id}`}>{engagementStatus.customer_name_abbreviated}</a>
          &nbsp; / &nbsp;
        </p>
      </div>
      <div className="clear"></div>

      {/* Headline and actions */}
      <h1>{engagementStatus.customer_name}</h1>

      {/* Customer tabs */}
      <ul className="tabs">
        <li>
          <a href={`/customers/view_customer/${engagementStatus.status_customer_id}`}>Index</a>
        </li>
        <li>
          <a href={`/customers/view_business/${engagementStatus.status_customer_id}`}>Business</a>
        </li>
        <li>
          <a href={`/customers/view_identifiers/${engagementStatus.status_customer_id}`}>Identifiers</a>
        </li>
        <li>
          <a href={`/customers/view_engagements/${engagementStatus.status_customer_idomerId}`} className="active">
            Engagements
          </a>
        </li>
        {/* <li><a href={`/customers/view_sensors/${engagementStatus.status_customer_id}`}>Sensors</a></li> */}
      </ul>

      <div className="main_box">
        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        {/* Form */}
        <form onSubmit={handleSubmit} id="form">
          {/* General information */}
          <h2 style={{ paddingLeft: 0 }}>General information</h2>
          <div style={{ float: 'left', marginRight: '20px' }}>
            <table>
              <tbody>
                <tr>
                  <td style={{ padding: '0px 5px 0px 0px' }}>
                    <span>
                      <b>Status ID:</b>
                    </span>
                  </td>
                  <td>
                    <span>{engagementStatus.status_id}</span>
                  </td>
                </tr>

                <tr>
                  <td style={{ padding: '0px 5px 0px 0px' }}>
                    <span>
                      <b>Egagement ID:</b>
                    </span>
                  </td>
                  <td>
                    <span>{engagementStatus.status_engagement_id}</span>
                  </td>
                </tr>

                <tr>
                  <td style={{ padding: '0px 5px 0px 0px' }}>
                    <span>
                      <b>Customer ID:</b>
                    </span>
                  </td>
                  <td>
                    <span>{engagementStatus.status_customer_id}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{ float: 'left', marginRight: '20px' }}>
            <table>
              <tbody>
                <tr>
                  <td style={{ padding: '0px 5px 0px 0px' }}>
                    <span>
                      <b>Service name:</b>
                    </span>
                  </td>
                  <td>
                    <span>{engagementStatus.engagement_service_name}</span>
                  </td>
                </tr>

                <tr>
                  <td style={{ padding: '0px 5px 0px 0px' }}>
                    <span>
                      <b>Service flavor:</b>
                    </span>
                  </td>
                  <td>
                    <span>{engagementStatus.engagement_service_flavor}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{ float: 'left' }}>
            <table>
              <tbody>
                <tr>
                  <td style={{ padding: '0px 5px 0px 0px' }}>
                    <span>
                      <b>Created:</b>
                    </span>
                  </td>
                  <td>
                    <span>{engagementStatus.status_created_datetime_saying}</span>
                  </td>
                </tr>

                <tr>
                  <td style={{ padding: '0px 5px 0px 0px' }}>
                    <span>
                      <b>Updated:</b>
                    </span>
                  </td>
                  <td>
                    <span>{engagementStatus.status_updated_datetime_saying}</span>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '0px 5px 0px 0px' }}>
                    <span>
                      <b>Completed:</b>
                    </span>
                  </td>
                  <td>
                    <span>{engagementStatus.status_completed_datetime_saying}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>{' '}
          {/* //left */}
          <div className="clear"></div>
          {/* //General information */}
          {/* Jira */}
          <p style={{ padding: '10px 0px 0px 0px' }}>
            <b>Jira External ID</b>
          </p>
          <table>
            <tbody>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Value:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_jira_external_id_value" value={engagementStatus.status_jira_external_id_value} onChange={handleChange} style={{ minWidth: '50%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Updated:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_jira_external_id_datetime_saying" value={engagementStatus.status_jira_external_id_datetime_saying} onChange={handleChange} style={{ minWidth: '50%' }} />
                  </span>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Notes:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_jira_external_id_notes" value={engagementStatus.status_jira_external_id_notes} onChange={handleChange} style={{ minWidth: '50%' }} />
                  </span>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Completed:</span>
                </td>
                <td>
                  <span>
                    <input type="checkbox" name="status_jira_external_id_completed" checked={engagementStatus.status_jira_external_id_completed} onChange={handleChangeCheckbox} />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          {/* //Jira */}
          {/* Ordered Licenses */}
          <p style={{ padding: '10px 0px 0px 0px' }}>
            <b>Ordered Licenses</b>
          </p>
          <table>
            <tbody>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Value:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_ordered_licenses_value" value={engagementStatus.status_ordered_licenses_value} onChange={handleChange} style={{ minWidth: '50%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Updated:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_ordered_licenses_datetime_saying" value={engagementStatus.status_ordered_licenses_datetime_saying} onChange={handleChange} style={{ minWidth: '50%' }} />
                  </span>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Notes:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_ordered_licenses_notes" value={engagementStatus.status_ordered_licenses_notes} onChange={handleChange} style={{ minWidth: '50%' }} />
                  </span>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Completed:</span>
                </td>
                <td>
                  <span>
                    <input type="checkbox" name="status_ordered_licenses_completed" checked={engagementStatus.status_ordered_licenses_completed} onChange={handleChangeCheckbox} />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          {/* //Ordered Licenses */}
          {/* Created organization */}
          <p style={{ padding: '10px 0px 0px 0px' }}>
            <b>Created organization</b>
          </p>
          <table>
            <tbody>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Value:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_created_organization_value" value={engagementStatus.status_created_organization_value} onChange={handleChange} style={{ minWidth: '50%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Updated:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_created_organization_datetime_saying" value={engagementStatus.status_created_organization_datetime_saying} onChange={handleChange} style={{ minWidth: '50%' }} />
                  </span>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Notes:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_created_organization_notes" value={engagementStatus.status_created_organization_notes} onChange={handleChange} style={{ minWidth: '50%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Completed:</span>
                </td>
                <td>
                  <span>
                    <input type="checkbox" name="status_created_organization_completed" checked={engagementStatus.status_created_organization_completed} onChange={handleChangeCheckbox} />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          {/* //Created organization */}
          {/* Created Users */}
          <p style={{ padding: '10px 0px 0px 0px' }}>
            <b>Created users</b>
          </p>
          <table>
            <tbody>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Value:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_created_users_value" value={engagementStatus.status_created_users_value} onChange={handleChange} style={{ minWidth: '50%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Updated:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_created_users_datetime_saying" value={engagementStatus.status_created_users_datetime_saying} onChange={handleChange} style={{ minWidth: '50%' }} />
                  </span>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Notes:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_created_users_notes" value={engagementStatus.status_created_users_notes} onChange={handleChange} style={{ minWidth: '50%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Completed:</span>
                </td>
                <td>
                  <span>
                    <input type="checkbox" name="status_created_users_completed" checked={engagementStatus.status_created_users_completed} onChange={handleChangeCheckbox} />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          {/* //Created organization */}
          {/* Emailed users */}
          <p style={{ padding: '10px 0px 0px 0px' }}>
            <b>Emailed users</b>
          </p>
          <table>
            <tbody>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Value:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_emailed_users_value" value={engagementStatus.status_emailed_users_value} onChange={handleChange} style={{ minWidth: '50%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Updated:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_emailed_users_datetime_saying" value={engagementStatus.status_emailed_users_datetime_saying} onChange={handleChange} style={{ minWidth: '50%' }} />
                  </span>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Notes:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_emailed_users_notes" value={engagementStatus.status_emailed_users_notes} onChange={handleChange} style={{ minWidth: '50%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Completed:</span>
                </td>
                <td>
                  <span>
                    <input type="checkbox" name="status_emailed_users_completed" checked={engagementStatus.status_emailed_users_completed} onChange={handleChangeCheckbox} />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          {/* //Emailed users */}
          {/* Agents method */}
          <p style={{ padding: '10px 0px 0px 0px' }}>
            <b>Agents method</b>
          </p>
          <table>
            <tbody>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Method:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_agents_method" value={engagementStatus.status_agents_method} onChange={handleChange} style={{ minWidth: '50%' }} />
                  </span>
                  <span className="small">&nbsp; email, change, cube</span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Email to:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_agents_method_email_to" value={engagementStatus.status_agents_method_email_to} onChange={handleChange} style={{ minWidth: '50%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Web key:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_agents_method_web_key" value={engagementStatus.status_agents_method_web_key} onChange={handleChange} style={{ minWidth: '50%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Value:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_agents_method_value" value={engagementStatus.status_agents_method_value} onChange={handleChange} style={{ minWidth: '50%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Updated:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_agents_method_datetime_saying" value={engagementStatus.status_agents_method_datetime_saying} onChange={handleChange} style={{ minWidth: '50%' }} />
                  </span>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Notes:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_agents_method_notes" value={engagementStatus.status_agents_method_notes} onChange={handleChange} style={{ minWidth: '50%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Completed:</span>
                </td>
                <td>
                  <span>
                    <input type="checkbox" name="status_agents_method_completed" checked={engagementStatus.status_agents_method_completed} onChange={handleChangeCheckbox} />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          {/* //Agents method */}
          {/* Agents installed */}
          <p style={{ padding: '10px 0px 0px 0px' }}>
            <b>Agents installed</b>
          </p>
          <table>
            <tbody>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Value:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_agents_installed_value" value={engagementStatus.status_agents_installed_value} onChange={handleChange} style={{ minWidth: '50%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Updated:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_agents_installed_datetime_saying" value={engagementStatus.status_agents_installed_datetime_saying} onChange={handleChange} style={{ minWidth: '50%' }} />
                  </span>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Notes:</span>
                </td>
                <td>
                  <span>
                    <input type="text" name="status_agents_installed_notes" value={engagementStatus.status_agents_installed_notes} onChange={handleChange} style={{ minWidth: '50%' }} />
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 5px 0px 0px' }}>
                  <span>Completed:</span>
                </td>
                <td>
                  <span>
                    <input type="checkbox" name="status_agents_installed_completed" checked={engagementStatus.status_agents_installed_completed} onChange={handleChangeCheckbox} />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          {/* //Agents installed */}
          <p>
            <button>Submit</button>
          </p>
        </form>
      </div>
    </div>
  );
}

export default ViewEngagementStatusVulnerabilityManagement;
