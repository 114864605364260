import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';


export function NewClient() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { collectorId } = useParams();

  // Data
  const [collector, setCollector] = useState({
    collector_id: collectorId,
    collector_customer_id: '', 
    collector_customer_abbreviation: '', 
    collector_hostname: '', 
    collector_ip: '', 
    collector_os: '',
    collector_os_eol_date: '',
    collector_os_eol_saying: '',
    collector_os_eol_notified_year: '',
    collector_os_eol_emailed: '',
    collector_os_eol_slack: '',
    collector_created_datetime: '',
    collector_created_date_saying: '',
    collector_created_by_ip: '',
    collector_updated_datetime: '',
    collector_updated_date_saying: '',
    collector_updated_by_ip: ''
  });
  const [certs, setCerts] = useState([]);
  const [clients, setClients] = useState([]);

  // Load data
  const loadCollector = async (e) => {
    let result = await fetch(backendURL + '/api/collectors/get-collector?collector_id=' + collectorId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setCollector(resultJson);
      document.getElementById('main_box').style.display = 'block';

    }
  };
  const newClient = async (e) => {
    let result = await fetch(backendURL + '/api/collectors/new-client?collector_id=' + collectorId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {

      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
      
      // Go to URL
      window.location.href = "/collectors/edit-client/" + resulData; 
    } 
    else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadCollector();
      newClient();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/collectors">Collectors</a>
          &nbsp; / &nbsp;
          <a href={`/collectors/view-collector/${collector.collector_id}`}>{collector.collector_hostname}</a>
          &nbsp; / &nbsp;
          <a href={`/collectors/new-client/${collector.collector_id}`}>New client</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>New client for {collector.collector_hostname}</h1>


      {/* Main box */}
      <div className="main_box" id="main_box" style={{ display: 'none' }}>

        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>
        <p>Generating ID... Please wait...</p>

      </div>

    </div>
  );
}

export default NewClient;
