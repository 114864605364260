import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export function NewContact() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { customerId } = useParams();

  const [customer, setCustomer] = useState({
    customer_id: customerId,
    customer_name: '',
    customer_name_abbreviated: ''
  });
  const [contact, setContact] = useState({
    contact_id: '',
    contact_customer_id: customerId,
    contact_name: '',
    contact_affiliation: '',
    contact_priority: '',
    contact_threshold_color: '',
    contact_company_role: '',
    contact_preferred_language: '',
    contact_email: '',
    contact_phone: '',
    contact_jira_user_id: '',
    contact_monthly_report_receiver_internally: '',
    contact_monthly_report_receiver_externally: '',
    contact_comment: '',
    contact_updated_timestamp: '',
    contact_updated_by_user_email: ''
  });
  const [checkedState, setCheckedState] = useState([false, false]);
  const [affiliations, setAffiliations] = useState(() => []);
  const [priorities, setPriorities] = useState(() => []);
  const [thresholds, setThresholds] = useState(() => []);
  const [roles, setRoles] = useState(() => []);
  const [languages, setLanguages] = useState(() => []);
  const [options, setOptions] = useState(() => []);

  // Load data
  const loadCustomerIndex = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get_customer?customer_id=' + customerId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setCustomer(resultJson);
      customer.contact_customer_id = customerId;

      // Set checkboxes
      if (resultJson['contact_monthly_report_receiver_internally'] === 1) {
        resultJson['contact_monthly_report_receiver_internally'] = 'checked';
      }
      if (resultJson['contact_monthly_report_receiver_externally'] === 1) {
        resultJson['contact_monthly_report_receiver_externally'] = 'checked';
      }
    }
  };
  // Load Options
  const loadOptions = async (e) => {
    let result = await fetch(backendURL + '/api/options/get_all_options', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setOptions(resultJson);

      setAffiliations(resultJson['affiliations']);
      setPriorities(resultJson['priorities']);
      setThresholds(resultJson['thresholds']);
      setRoles(resultJson['roles']);
      setLanguages(resultJson['languages']);

      // Display form (last element is loaded)
      document.getElementById('form').style.display = 'block';

      // Focus
      document.forms['form'].elements['contact_name'].focus();
    }
  };

  // Form data chaned
  const handleChange = (e) => {
    console.log('EditCustomer() :: handleChange() :: e.target.value=' + e.target.value);
    setContact({
      ...contact,
      [e.target.name]: e.target.value
    });
  };
  const handleChangeCheckbox = (e) => {
    console.log('NewContact() :: handleChangeCheckbox() :: e.target.name=' + e.target.name + ' e.target.value=' + e.target.value);

    if (e.target.value === '' || e.target.value === 'on') {
      e.target.value = 'checked';
    } else {
      e.target.value = '';
    }

    setContact({
      ...contact,
      [e.target.name]: e.target.value
    });
  };

  // Send new contact
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/customers/new_contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(contact, { customer_id: customerId })
    });
    let resultJson = await result.json();
    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      document.getElementById('feedback_div').scrollIntoView();

      window.location.href = '/customers/view_customer/' + customerId + '?ft=success&fm=created_successfully#contacts';
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadCustomerIndex();
      loadOptions();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/">Customers</a>
          &nbsp; / &nbsp;
          <a href={`/customers/view_customer/${customerId}`}>{customer.customer_name_abbreviated}</a>
          &nbsp; / &nbsp;
          <a href={`/customers/new_contact/${customerId}`}>New contact</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>New Contact for {customer.customer_name_abbreviated}</h1>
      <form onSubmit={handleSubmit} id="form" style={{ display: 'none' }}>
        <div className="main_box">
          {/* Feedback */}
          <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
            <p id="feedback_p">Feedback box is here</p>
          </div>

          {/* Form */}

          <p>
            Contact name
            <br />
            <input type="text" name="contact_name" value={contact.contact_name} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
            Affiliation
            <br />
            <select name="contact_affiliation" value={contact.contact_affiliation} onChange={handleChange}>
              <option value="N/A">Please select</option>
              {affiliations.map((item, index) => (
                <option key={index} value={item.affiliation_title}>
                  {item.affiliation_title}
                </option>
              ))}
            </select>
          </p>

          <p>
            Priority
            <br />
            <select name="contact_priority" value={contact.contact_priority} onChange={handleChange}>
              <option value="N/A">Please select</option>
              {priorities.map((item, index) => (
                <option key={index} value={item.priority_title}>
                  {item.priority_title}
                </option>
              ))}
            </select>
          </p>

          <p>
            Threshold
            <br />
            <select name="contact_threshold_color" value={contact.contact_threshold_color} onChange={handleChange}>
              <option value="N/A">Please select</option>
              {thresholds.map((item, index) => (
                <option key={index} value={item.threshold_color}>
                  {item.threshold_color}
                </option>
              ))}
            </select>
          </p>

          <p>
            Role
            <br />
            <select name="contact_company_role" value={contact.contact_company_role} onChange={handleChange}>
              <option value="N/A">Please select</option>
              {roles.map((item, index) => (
                <option key={index} value={item.role_title}>
                  {item.role_title}
                </option>
              ))}
            </select>
          </p>

          <p>
            Preferred language
            <br />
            <select name="contact_preferred_language" value={contact.contact_preferred_language} onChange={handleChange}>
              <option value="N/A">Please select</option>
              {languages.map((item, index) => (
                <option key={index} value={item.language_iso_three}>
                  {item.language_iso_three}
                </option>
              ))}
            </select>
          </p>

          <p>
            Contact email
            <br />
            <input type="text" name="contact_email" value={contact.contact_email} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
            Contact phone
            <br />
            <input type="text" name="contact_phone" value={contact.contact_phone} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
            Jira user ID
            <br />
            <input type="text" name="contact_jira_user_id" value={contact.contact_jira_user_id} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
            Monthly report receiver
            <br />
            <span className="small">
              Internally report receiver will get the report 3 days before externally receivers
              <br />
            </span>
            <input type="checkbox" name="contact_monthly_report_receiver_internally" checked={contact.contact_monthly_report_receiver_internally} onChange={handleChangeCheckbox} />
            Internally &nbsp; &nbsp;
            <input type="checkbox" name="contact_monthly_report_receiver_externally" checked={contact.contact_monthly_report_receiver_externally} onChange={handleChangeCheckbox} />
            Externally
          </p>

          <p>
            Offline asset email report
            <br />
            <input type="checkbox" name="contact_offline_asset_email" checked={contact.contact_offline_asset_email} onChange={handleChangeCheckbox} />
          </p>

          <p>
            Contact comment
            <br />
            <textarea name="contact_comment" value={contact.contact_comment} onChange={handleChange} style={{ minWidth: '50%' }}></textarea>
          </p>

          <p>
            <button>Submit</button>
          </p>
        </div>
      </form>
    </div>
  );
}

export default NewContact;
