import { useContext, useEffect, useState } from 'react';
import useToken from '../../useToken.js';
import parse from 'html-react-parser';
import prodOrDev from '../../utils.js';

export function IntelligenceIndex() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const [assessments, setAssessments] = useState(() => []);

  // Load data
  const loadData = async (e) => {
    let result = await fetch(backendURL + '/api/intelligence/get_intelligence_assessments_all_customers', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const resultJson = await result.json();
    if (result.status === 200) {
      setAssessments(
        resultJson.map((fetch_object) => {
          return fetch_object;
        })
      );
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadData();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      <h1>Intelligence</h1>
      <div className="main_box">
        <div className="vertical">
          <ul>
            <li><a href="/intelligence/assessments"><img src={'/gfx/icons/20x20/sdk_rounded_nofill_black_20x20.svg'} alt="sdk_rounded_nofill_black_20x20.svg" /> Assessments</a></li>
            <li><a href="/intelligence/threat-actors"><img src={'/gfx/icons/20x20/destruction_outlined_nofill_black_20x20.svg'} alt="destruction_outlined_nofill_black_20x20.svg" /> Threat Actors</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default IntelligenceIndex;
