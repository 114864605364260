import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

export function NewMonitorChild() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { monitorParentId } = useParams();

  const [monitorChild, setMonitorChild] = useState({
    monitor_child_id: '',
    monitor_child_parent_id: monitorParentId,
    monitor_child_name: '',
    monitor_child_description: '',
    monitor_child_type: '',
    monitor_child_weight: '',
    monitor_child_timeout_value: '',
    monitor_child_timeout_measuring_unit: '',
    monitor_child_last_ping_datetime: '',
    monitor_child_last_ping_datetime_saying: '',
    monitor_child_last_ping_message: '',
    monitor_child_last_ping_ip: '',
    monitor_child_events_expected: '',
    monitor_child_events_month_last_reset: '',
    monitor_child_events_month_01: '',
    monitor_child_events_month_02: '',
    monitor_child_events_month_03: '',
    monitor_child_events_month_04: '',
    monitor_child_events_month_05: '',
    monitor_child_events_month_06: '',
    monitor_child_events_month_07: '',
    monitor_child_events_month_08: '',
    monitor_child_events_month_09: '',
    monitor_child_events_month_10: '',
    monitor_child_events_month_11: '',
    monitor_child_events_month_12: '',
    monitor_child_current_status_child: '',
    monitor_child_current_status_comment: '',
    monitor_child_warning_slack_channel: '',
    monitor_child_warning_email_receivers: '',
    monitor_child_warning_sent: '',
    monitor_child_auto_delete_year: '',
    monitor_child_owner_name: '',
    monitor_child_administrator_name: '',
    monitor_child_commercial_responsibility_name: '',
    monitor_child_created_datetime: '',
    monitor_child_created_date_saying: '',
    monitor_child_created_user_email: '',
    monitor_child_updated_datetime: '',
    monitor_child_updated_date_saying: '',
    monitor_child_updated_user_email: ''
  });

  // Form data chaned
  const handleChange = (e) => {
    setMonitorChild({
      ...monitorChild,
      [e.target.name]: e.target.value
    });
  };

  // Send new identifier
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/monitor/new_monitor_child', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(monitorChild)
    });
    let resultJson = await result.json();
    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    }
  };

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/monitor">Monitor</a>
          &nbsp; / &nbsp;
          <a href={`/monitor/view_monitor_parent/${monitorChild.monitor_child_parent_id}`}>Monitor parent</a>
          &nbsp; / &nbsp;
          <a href={`/monitor/new_monitor_child/${monitorChild.monitor_child_parent_id}`}>New monitor child</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>New monitor child</h1>
      <form onSubmit={handleSubmit}>
        <div className="main_box">
          {/* Feedback */}
          <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
            <p id="feedback_p">Feedback box is here</p>
          </div>

          {/* Form */}

          <p>
            Name
            <br />
            <input type="text" name="monitor_child_name" value={monitorChild.monitor_child_name} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
            <button>Submit</button>
          </p>
        </div>
      </form>
    </div>
  );
}

export default NewMonitorChild;
