import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

export function NewIdentifier() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { customerId } = useParams();

  const [customerIdentifier, setCustomerIdentifier] = useState({
    identifier_id: '',
    identifier_customer_id: customerId,
    identifier_type: '',
    identifier_title: '',
    identifier_value: '',
    identifier_description: '',
    identifier_weight: ''
  });

  const [customerIndex, setCustomerIndex] = useState(() => []);
  const [optionsIdentifiers, setOptionsIdentifiers] = useState(() => []);

  // Load Customer
  const loadCustomerIndex = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get_customer?customer_id=' + customerId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setCustomerIndex(resultJson);
      // Display form (last element is loaded)
      document.getElementById('form').style.display = 'block';

      // Load customer
      customerIdentifier.identifier_customer_id = resultJson['customer_id'];
    }
  };

  // Load options identifiers
  const loadOptionsIdentifiers = async (e) => {
    let result = await fetch(backendURL + '/api/options/get_identifiers', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setOptionsIdentifiers(resultJson);
    }
  };

  // Form data changed
  const handleChange = (e) => {
    if (e.target.name === 'identifier_type') {
      const words = e.target.value.split('_');
      for (let i = 0; i < words.length; i++) {
        if (words[i] == 'id') {
          words[i] = words[i].toUpperCase();
        } else {
          words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        }
      }

      setCustomerIdentifier({
        ...customerIdentifier,
        ['identifier_title']: words.join(' '),
        [e.target.name]: e.target.value
      });
    } else {
      setCustomerIdentifier({
        ...customerIdentifier,
        [e.target.name]: e.target.value
      });
    }
  };

  // Send new identifier
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/customers/new_customer_identifier', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(customerIdentifier)
    });
    let resultJson = await result.json();
    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadCustomerIndex();
      loadOptionsIdentifiers();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/">Customers</a>
          &nbsp; / &nbsp;
          <a href={`/customers/view_customer/${customerId}`}>{customerIndex.customer_name_abbreviated}</a>
          &nbsp; / &nbsp;
          <a href={`/customers/new_identifier/${customerId}`}>New identifier</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>New identifier for {customerIndex.customer_name_abbreviated}</h1>
      <form onSubmit={handleSubmit} id="form" style={{ display: 'none' }}>
        <div className="main_box">
          {/* Feedback */}
          <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
            <p id="feedback_p">Feedback box is here</p>
          </div>

          {/* Form */}
          <p>
            Type
            <br />
            <select name="identifier_type" value={customerIdentifier.identifier_type} onChange={handleChange}>
              <option value="N/A">Please select</option>
              {optionsIdentifiers.map((item, index) => (
                <option key={index} value={item.identifier_title}>
                  {item.identifier_title}
                </option>
              ))}
            </select>
          </p>

          <p>
            Title
            <br />
            <input type="text" name="identifier_title" value={customerIdentifier.identifier_title} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
            Value
            <br />
            <input type="text" name="identifier_value" value={customerIdentifier.identifier_value} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
            Description
            <br />
            <input type="text" name="identifier_description" value={customerIdentifier.identifier_description} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
            <button>Submit</button>
          </p>
        </div>
      </form>
    </div>
  );
}

export default NewIdentifier;
