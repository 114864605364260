import { useContext } from 'react';
import useToken from '../../useToken.js';

export function SettingsIndex() {
  const { token, setToken, userObject } = useToken();

  // <li><a href="/settings/email_templates"><img src={"/gfx/icons/20x20/attach_email_rounded_fill_black_20x20.svg"} alt="attach_email_rounded_fill_black_20x20.svg" /> Email templates</a></li>
  return (
    <div>
      <h1>Settings</h1>
      <div className="main_box">
        <div className="vertical">
          <ul>
            <li><a href="/settings/users"><img src={'/gfx/icons/20x20/group_outlined_black_20x20.svg'} alt="group_outlined_black_20x20.svg" /> Users</a></li>
            <li><a href="/settings/apis"><img src={'/gfx/icons/20x20/api_rounded_nofill_black_20x20.svg'} alt="api_rounded_nofill_black_20x20.svg" /> APIs</a></li>
            <li><a href="/settings/case-codes"><img src={'/gfx/icons/20x20/data_array_outlined_nofill_black_20x20.svg'} alt="data_array_outlined_nofill_black_20x20.svg" /> Case Codes</a></li>
            <li><a href="/settings/classifications"><img src={'/gfx/icons/20x20/data_object_outlined_nofill_black_20x20.svg'} alt="data_object_outlined_nofill_black_20x20.svg" /> Classifications</a></li>
            <li><a href="/settings/countries"><img src={'/gfx/icons/20x20/flag_outlined_nofill_black_20x20.svg'} alt="flag_outlined_nofill_black_20x20.svg" /> Countries</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SettingsIndex;
