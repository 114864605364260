import { useContext, useEffect, useState } from 'react';
import useToken from '../../../useToken.js';
import prodOrDev from '../../../utils.js';

export function UsersIndex() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const [users, setUsers] = useState(() => []);

  // Load data
  const loadData = async (e) => {
    let result = await fetch(backendURL + '/api/users/get_users', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const resultJson = await result.json();
    if (result.status === 200) {
      setUsers(
        resultJson.map((fetch_object) => {
          return fetch_object;
        })
      );
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadData();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      <h1>Users</h1>
      <div className="main_box">
        {/* Actions */}
        <p>
          <a href="/settings/users/new-user" className="btn_default">
            <img src="/gfx/icons/20x20/add_rounded_nofill_black_20x20.svg" alt="add_rounded_nofill_black_20x20.svg" /> New user
          </a>
        </p>

        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        {/* Table */}
        <table className="hor-zebra">
          <thead>
            <tr>
              <th>
                <span>ID</span>
              </th>
              <th>
                <span>Name</span>
              </th>
              <th>
                <span>Email</span>
              </th>
              <th>
                <span>Department</span>
              </th>
              <th>
                <span>Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {users.map((item, index) => (
              <tr key={index}>
                <td>
                  <span>{item.user_id}</span>
                </td>
                <td>
                  <span>
                    {item.user_first_name} {item.user_last_name}
                  </span>
                </td>
                <td>
                  <span>{item.user_email}</span>
                </td>
                <td>
                  <span>{item.user_department}</span>
                </td>
                <td>
                  <span>
                    <a href={`/settings/users/edit-user/${item.user_id}`}>
                      <img src="/gfx/icons/20x20/edit_rounded_fill_black_20x20.svg" alt="edit_rounded_fill_black_20x20.svg" />
                    </a>
                    &nbsp;
                    <a href={`/settings/users/delete-user/${item.user_id}`}>
                      <img src="/gfx/icons/20x20/delete_rounded_nofill_black_20x20.svg" alt="delete_rounded_fill_black_20x20.svg" />
                    </a>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default UsersIndex;
