import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';


export function EditClient() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { clientId } = useParams();

  // Data
  const [client, setClient] = useState({
    client_id: clientId,

    client_collector_id: '', 
    client_customer_id: '', 
    client_name: '', 
    client_type: '', 
    client_sub_domain: '', 
    client_port: '', 
    client_brand: '', 
    client_ip: '', 
    client_internal_contact_name: '', 
    client_internal_contact_email: '', 
    client_administrator_name: '', 
    client_administrator_email: '', 
    client_created_datetime: '', 
    client_created_date_saying: '', 
    client_created_by_user_id: '',
    client_created_by_user_email: '',
    client_created_by_ip: '', 
    client_updated_datetime: '', 
    client_updated_date_saying: '', 
    client_updated_by_user_id: '',
    client_updated_by_user_email: '',
    client_updated_by_ip: ''
  });

  // Load data
  const loadClient = async (e) => {
    let result = await fetch(backendURL + '/api/collectors/get-client?client_id=' + clientId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setClient(resultJson);
      document.getElementById('main_box').style.display = 'block';

    }
  };


  // Form data chaned
  const handleChange = (e) => {
    setClient({
      ...client,
      [e.target.name]: e.target.value
    });
  };

  // Send
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Send request
    let result = await fetch(backendURL + '/api/collectors/edit-client', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(client)
    });
    let resultJson = await result.json();
    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    }
  };


  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadClient();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/collectors">Collectors</a>
          &nbsp; / &nbsp;
          <a href={`/collectors/view-collector/${client.client_collector_id}`}>Collector {client.client_collector_id}</a>
          &nbsp; / &nbsp;
          <a href={`/collectors/edit-client/${client.client_collector_id}`}>Edit {client.client_name}</a>
        </p>
      </div>
      <div className="clear"></div>
      {/* Main Left */}
      <h1>Edit {client.client_name}</h1>
      <div className="main_box" id="main_box" style={{ display: 'none' }}>
        <form onSubmit={handleSubmit} id="form">
          {/* Feedback */}
          <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
            <p id="feedback_p">Feedback box is here</p>
          </div>

          {/* Form */}

          <p>Name:<br />
          <input type="text" name="client_name" value={client.client_name} onChange={handleChange} style={{ minWidth: '50%' }} autofocus />
          </p>

          <p>Type:<br />
          <select name="client_type" value={client.client_type} onChange={handleChange}>
            <option value="N/A">Please select</option>
            <option value="Firewall">Firewall</option>
          </select>
          </p>

          <p>Sub domain:<br />
          <input type="text" name="client_sub_domain" value={client.client_sub_domain} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>Port:<br />
          <input type="text" name="client_port" value={client.client_port} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>Brand:<br />
          <select name="client_brand" value={client.client_brand} onChange={handleChange}>
            <option value="N/A">Please select</option>
            <option value="N/A">---</option>
            <option value="Cisco Meraki">Cisco Meraki</option>
            <option value="Cisco Meraki MX67">Cisco Meraki MX67</option>
            <option value="Cisco Meraki MX67W">Cisco Meraki MX67W</option>
            <option value="Cisco Meraki MX75">Cisco Meraki MX75</option>
            <option value="N/A">---</option>
            <option value="Fortigate">Fortigate</option>
            <option value="Fortigate 40f">Fortigate 40f</option>
            <option value="N/A">---</option>
            <option value="Palo Alto">Palo Alto</option>
            <option value="Palo Alto 440">Palo Alto 440</option>
          </select>
          </p>

          <p>IP<br />
          <input type="text" name="client_ip" value={client.client_ip} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>Internal contact name<br />
          <input type="text" name="client_internal_contact_name" value={client.client_internal_contact_name} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>Internal contact email<br />
          <input type="text" name="client_internal_contact_email" value={client.client_internal_contact_email} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>Administrator name<br />
          <input type="text" name="client_administrator_name" value={client.client_administrator_name} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>Administrator email<br />
          <input type="text" name="client_administrator_email" value={client.client_administrator_email} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
          <button>Save changes</button>
          </p>
        </form>
      </div>{' '}
      {/* // main_box */}
    </div>
  );
}

export default EditClient;
