import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

export function ViewAffiliations() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { customerId } = useParams();

  // Data
  const [customerIndex, setCustomerIndex] = useState(() => []);
  const [customerAffiliations, setCustomerAffiliations] = useState(() => []);

  // Load data
  const loadCustomerIndex = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get_customer?customer_id=' + customerId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setCustomerIndex(resultJson);
      document.getElementById('show_customer').style.display = 'block';
    }
  };

  // Load customer affiliations
  const loadCustomerAffiliations = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get_customer_afiliations?customer_id=' + customerId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const resultJson = await result.json();
    if (result.status === 200) {
      setCustomerAffiliations(
        resultJson.map((fetch_object) => {
          return fetch_object;
        })
      );
    }
  };
  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadCustomerIndex();
      loadCustomerAffiliations();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div id="show_customer" style={{ display: 'none' }}>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/">Customers</a>
          &nbsp; / &nbsp;
          <a href={`/customers/view_customer/${customerId}`}>{customerIndex.customer_name_abbreviated}</a>
          &nbsp; / &nbsp;
          <a href={`/customers/view_affiliations/${customerId}`}>Affilations</a>
        </p>
      </div>
      <div className="clear"></div>

      {/* Headline and actions */}
      <h1>Affliations for {customerIndex.customer_name}</h1>

      <div className="main_box">
        {/* Actions */}
        <p>
          {
            <a href={`/customers/new_affilation/${customerId}`} className="btn_default">
              <img src="/gfx/icons/20x20/add_rounded_nofill_black_20x20.svg" alt="add_rounded_nofill_black_20x20.svg" /> New affilation
            </a>
          }
        </p>

        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        {/* Table */}
        <table className="hor-zebra">
          <thead>
            <tr>
              <th>
                <span>Title</span>
              </th>
              <th>
                <span>Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {customerAffiliations.map((item, index) => (
              <tr key={index}>
                <td>
                  <span>{item.affiliation_title}</span>
                </td>
                <td>
                  <span>
                    {
                      <a href={`/customers/delete_affiliation/${item.affiliation_id}`}>
                        <img src="/gfx/icons/20x20/delete_rounded_nofill_black_20x20.svg" alt="delete_rounded_fill_black_20x20.svg" />
                      </a>
                    }
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ViewAffiliations;
