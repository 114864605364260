import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import parse from 'html-react-parser';
import prodOrDev from '../../utils.js';

export function ViewStatsAlertsAllCustomersPerMonth() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { statsId } = useParams();

  // Data
  const [statsAlertsAllCustomersPerMonth, setStatsAlertsAllCustomersPerMonth] = useState({});

  // Load data
  const loadStatsAlertsAllCustomersPerMonth = async (e) => {
    let result = await fetch(backendURL + '/api/statistics/get_statistics_alerts_cases_tickets_all_customers_per_month_for_stats_month_id?stats_month_id=' + statsId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setStatsAlertsAllCustomersPerMonth(resultJson);
      document.getElementById('main_box').style.display = 'block';
    }
  };
  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadStatsAlertsAllCustomersPerMonth();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/statistics">Statistics</a>
          &nbsp; / &nbsp;
          <a href={`/statistics/view_stats_alerts_all_customers_per_month/${statsId}`}>
            {statsAlertsAllCustomersPerMonth.stats_month_month}/{statsAlertsAllCustomersPerMonth.stats_month_year}
          </a>
        </p>
      </div>
      <div className="clear"></div>
      {/* Main Left */}
      <h1>
        {statsAlertsAllCustomersPerMonth.stats_month_month}/{statsAlertsAllCustomersPerMonth.stats_month_year}
      </h1>
      {/* Customers */}
      <h2>Customers</h2>
      <div className="main_box" id="main_box" style={{ display: 'none' }}>
        <div className="flex_container">
          <div className="flex_columns_three_wrapper">
            <div className="flex_columns_inner" style={{ textAlign: 'center' }}>
              <h2 className="purple" style={{ fontSize: '300%' }}>
                {statsAlertsAllCustomersPerMonth.stats_month_customers_operational_count}
              </h2>
              <p style={{ paddingTop: 0 }}>operational customers</p>
            </div>
          </div>

          <div className="flex_columns_three_wrapper">
            <div className="flex_columns_inner" style={{ textAlign: 'center' }}>
              <h2 className="purple" style={{ fontSize: '300%' }}>
                {statsAlertsAllCustomersPerMonth.stats_month_customers_asset_sensors_count_auto_total}
              </h2>
              <p style={{ paddingTop: 0 }}>
                sensors
                <br />({statsAlertsAllCustomersPerMonth.stats_month_customers_asset_adapters_count_auto_total} adapters)
              </p>
            </div>
          </div>

          <div className="flex_columns_three_wrapper">
            <div className="flex_columns_inner" style={{ textAlign: 'center' }}>
              <h2 className="purple" style={{ fontSize: '300%' }}>
                {statsAlertsAllCustomersPerMonth.stats_month_customers_asset_total_count_auto_total}
              </h2>
              <p style={{ paddingTop: 0 }}>Sensors and adapters</p>
            </div>
          </div>
        </div>
      </div>
      {/* Alerts */}
      <h2>Alerts</h2>
      <div className="main_box">
        <div className="flex_container">
          <div className="flex_columns_three_wrapper">
            <div className="flex_columns_inner" style={{ textAlign: 'center' }}>
              <h2 className="purple" style={{ fontSize: '300%' }}>
                {statsAlertsAllCustomersPerMonth.stats_month_alerts_count}
              </h2>
              <p style={{ paddingTop: 0 }}>
                alerts
                <br />
                {statsAlertsAllCustomersPerMonth.stats_month_alerts_count_diff_last_month}
                &nbsp; ({statsAlertsAllCustomersPerMonth.stats_month_alerts_count_diff_last_month_percentage} %) from last month
              </p>
            </div>
          </div>

          <div className="flex_columns_three_wrapper">
            <div className="flex_columns_inner" style={{ textAlign: 'center' }}>
              <h2 className="purple" style={{ fontSize: '300%' }}>
                {statsAlertsAllCustomersPerMonth.stats_month_alerts_day_count}
              </h2>
              <p style={{ paddingTop: 0 }}>
                alerts daytime
                <br />
                {statsAlertsAllCustomersPerMonth.stats_month_alerts_day_count_diff_last_month}
                &nbsp; ({statsAlertsAllCustomersPerMonth.stats_month_alerts_day_count_diff_last_month_percentage} %) from last month
              </p>
            </div>
          </div>

          <div className="flex_columns_three_wrapper">
            <div className="flex_columns_inner" style={{ textAlign: 'center' }}>
              <h2 className="purple" style={{ fontSize: '300%' }}>
                {statsAlertsAllCustomersPerMonth.stats_month_alerts_night_count}
              </h2>
              <p style={{ paddingTop: 0 }}>
                alerts nighttime
                <br />
                {statsAlertsAllCustomersPerMonth.stats_month_alerts_night_count_diff_last_month}
                &nbsp; ({statsAlertsAllCustomersPerMonth.stats_month_alerts_night_count_diff_last_month_percentage} %) from last month
              </p>
            </div>
          </div>

          <div className="flex_columns_three_wrapper">
            <div className="flex_columns_inner" style={{ textAlign: 'center' }}>
              <h2 className="purple" style={{ fontSize: '300%' }}>
                {statsAlertsAllCustomersPerMonth.stats_month_alerts_median_time_to_ack_saying}
              </h2>
              <p style={{ paddingTop: 0 }}>
                time to ack
                <br />
                {statsAlertsAllCustomersPerMonth.stats_month_alerts_median_time_to_ack_saying_diff_last_month} from last month
              </p>
            </div>
          </div>

          <div className="flex_columns_three_wrapper">
            <div className="flex_columns_inner" style={{ textAlign: 'center' }}>
              <h2 className="purple" style={{ fontSize: '300%' }}>
                {statsAlertsAllCustomersPerMonth.stats_month_alerts_day_median_time_to_ack_saying}
              </h2>
              <p style={{ paddingTop: 0 }}>
                time to ack day
                <br />
                {statsAlertsAllCustomersPerMonth.stats_month_alerts_day_median_time_to_ack_saying_diff_last_month} from last month
              </p>
            </div>
          </div>

          <div className="flex_columns_three_wrapper">
            <div className="flex_columns_inner" style={{ textAlign: 'center' }}>
              <h2 className="purple" style={{ fontSize: '300%' }}>
                {statsAlertsAllCustomersPerMonth.stats_month_alerts_night_median_time_to_ack_saying}
              </h2>
              <p style={{ paddingTop: 0 }}>
                time to ack night
                <br />
                {statsAlertsAllCustomersPerMonth.stats_month_alerts_night_median_time_to_ack_saying_diff_last_month} from last month
              </p>
            </div>
          </div>

          <div className="flex_columns_three_wrapper">
            <div className="flex_columns_inner" style={{ textAlign: 'center' }}>
              <h2 className="purple" style={{ fontSize: '300%' }}>
                {statsAlertsAllCustomersPerMonth.stats_month_alerts_median_time_to_close_saying}
              </h2>
              <p style={{ paddingTop: 0 }}>
                median time to close
                <br />
                &nbsp; {statsAlertsAllCustomersPerMonth.stats_month_alerts_median_time_to_close_saying_diff_last_month} from last month
              </p>
            </div>
          </div>

          <div className="flex_columns_three_wrapper">
            <div className="flex_columns_inner" style={{ textAlign: 'center' }}>
              <h2 className="purple" style={{ fontSize: '300%' }}>
                {statsAlertsAllCustomersPerMonth.stats_month_alerts_day_median_time_to_close_saying}
              </h2>
              <p style={{ paddingTop: 0 }}>
                median time to close daytime
                <br />
                &nbsp; {statsAlertsAllCustomersPerMonth.stats_month_alerts_day_median_time_to_close_saying_diff_last_mo} from last month
              </p>
            </div>
          </div>

          <div className="flex_columns_three_wrapper">
            <div className="flex_columns_inner" style={{ textAlign: 'center' }}>
              <h2 className="purple" style={{ fontSize: '300%' }}>
                {statsAlertsAllCustomersPerMonth.stats_month_alerts_night_median_time_to_close_saying}
              </h2>
              <p style={{ paddingTop: 0 }}>
                median time to close nighttime
                <br />
                &nbsp; {statsAlertsAllCustomersPerMonth.stats_month_alerts_night_median_time_to_close_saying_diff_last_mo} from last month
              </p>
            </div>
          </div>
        </div>{' '}
        {/* // flex_container */}
      </div>
      {/* Tickets */}
      <h2>Tickets</h2>
      <div className="main_box">
        <div className="flex_container">
          <div className="flex_columns_three_wrapper">
            <div className="flex_columns_inner" style={{ textAlign: 'center' }}>
              <h2 className="purple" style={{ fontSize: '300%' }}>
                {statsAlertsAllCustomersPerMonth.stats_month_tickets_count}
              </h2>
              <p style={{ paddingTop: 0 }}>
                tickets
                <br />
                {statsAlertsAllCustomersPerMonth.stats_month_tickets_count_diff_last_month}
                &nbsp; ({statsAlertsAllCustomersPerMonth.stats_month_tickets_count_diff_last_month_percenta} %) from last month
              </p>
            </div>
          </div>

          <div className="flex_columns_three_wrapper">
            <div className="flex_columns_inner" style={{ textAlign: 'center' }}>
              <h2 className="purple" style={{ fontSize: '300%' }}>
                {statsAlertsAllCustomersPerMonth.stats_month_tickets_median_time_to_close_saying}
              </h2>
              <p style={{ paddingTop: 0 }}>
                median time to close
                <br />
                {statsAlertsAllCustomersPerMonth.stats_month_tickets_median_time_to_close_saying_diff_last_mo} from last month
              </p>
            </div>
          </div>
        </div>{' '}
        {/* // flex_container */}
      </div>
      {/* Cases */}
      <h2>Cases</h2>
      <div className="main_box">
        <div className="flex_container">
          <div className="flex_columns_three_wrapper">
            <div className="flex_columns_inner" style={{ textAlign: 'center' }}>
              <h2 className="purple" style={{ fontSize: '300%' }}>
                {statsAlertsAllCustomersPerMonth.stats_month_cases_count}
              </h2>
              <p style={{ paddingTop: 0 }}>
                cases
                <br />
                {statsAlertsAllCustomersPerMonth.stats_month_cases_count_diff_last_month}
                &nbsp; {statsAlertsAllCustomersPerMonth.stats_month_cases_count_diff_last_month_percentage} % from last month
              </p>
            </div>
          </div>

          <div className="flex_columns_three_wrapper">
            <div className="flex_columns_inner" style={{ textAlign: 'center' }}>
              <h2 className="purple" style={{ fontSize: '300%' }}>
                {statsAlertsAllCustomersPerMonth.stats_month_cases_divided_on_customers}
              </h2>
              <p style={{ paddingTop: 0 }}>cases/customers</p>
            </div>
          </div>

          <div className="flex_columns_three_wrapper">
            <div className="flex_columns_inner" style={{ textAlign: 'center' }}>
              <h2 className="purple" style={{ fontSize: '300%' }}>
                {statsAlertsAllCustomersPerMonth.stats_month_cases_divided_on_asset_sensors_count_auto_total}
              </h2>
              <p style={{ paddingTop: 0 }}>cases/sensors</p>
            </div>
          </div>

          <div className="flex_columns_three_wrapper">
            <div className="flex_columns_inner" style={{ textAlign: 'center' }}>
              <h2 className="purple" style={{ fontSize: '300%' }}>
                {statsAlertsAllCustomersPerMonth.stats_month_cases_divided_on_asset_total_count_auto_total}
              </h2>
              <p style={{ paddingTop: 0 }}>cases/sensors+adapters</p>
            </div>
          </div>

          <div className="flex_columns_three_wrapper">
            <div className="flex_columns_inner" style={{ textAlign: 'center' }}>
              <h2 className="purple" style={{ fontSize: '300%' }}>
                {statsAlertsAllCustomersPerMonth.stats_month_cases_number_of_updates_per_case}
              </h2>
              <p style={{ paddingTop: 0 }}>
                updates per case
                <br />
                {statsAlertsAllCustomersPerMonth.stats_month_cases_number_of_updates_total} total updates
              </p>
            </div>
          </div>

          <div className="flex_columns_three_wrapper">
            <div className="flex_columns_inner" style={{ textAlign: 'center' }}>
              <h2 className="purple" style={{ fontSize: '300%' }}>
                {statsAlertsAllCustomersPerMonth.stats_month_cases_case_code_incidents_count}
              </h2>
              <p style={{ paddingTop: 0 }}>
                incidents
                <br />
                {statsAlertsAllCustomersPerMonth.stats_month_cases_case_code_incidents_rate} %
              </p>
            </div>
          </div>

          <div className="flex_columns_three_wrapper">
            <div className="flex_columns_inner" style={{ textAlign: 'center' }}>
              <h2 className="purple" style={{ fontSize: '300%' }}>
                {statsAlertsAllCustomersPerMonth.stats_month_cases_case_code_false_positives_rate} %
              </h2>
              <p style={{ paddingTop: 0 }}>
                False Positive
                <br />
                {statsAlertsAllCustomersPerMonth.stats_month_cases_case_code_false_positives_count} false positives
              </p>
            </div>
          </div>

          <div className="flex_columns_three_wrapper">
            <div className="flex_columns_inner" style={{ textAlign: 'center' }}>
              <h2 className="purple" style={{ fontSize: '300%' }}>
                {statsAlertsAllCustomersPerMonth.stats_month_cases_case_code_legitimate_activity_rate} %
              </h2>
              <p style={{ paddingTop: 0 }}>
                Legitimate Activities
                <br />
                {statsAlertsAllCustomersPerMonth.stats_month_cases_case_code_legitimate_activity_count} legitimate activities
              </p>
            </div>
          </div>

          <div className="flex_columns_three_wrapper">
            <div className="flex_columns_inner" style={{ textAlign: 'center' }}>
              <h2 className="purple" style={{ fontSize: '300%' }}>
                {statsAlertsAllCustomersPerMonth.stats_month_cases_median_time_to_close_saying_short}
              </h2>
              <p style={{ paddingTop: 0 }}>
                Median time to close
                <br />
                {statsAlertsAllCustomersPerMonth.stats_month_cases_median_time_to_close_saying_diff_last_mo} diff from last month
              </p>
            </div>
          </div>
        </div>{' '}
        {/* // flex_container */}
      </div>
      <h2>Levels</h2>
      <div className="main_box">
        {/* Levels Incidents, threat, operational readiness etc */}
        <h2>Levels</h2>
        <div className="flex_container">
          <div className="flex_columns_three_wrapper">
            <div className="flex_columns_inner" style={{ textAlign: 'center' }}>
              <h2 className="purple" style={{ marginBottom: 0, paddingBottom: 0, fontSize: '300%' }}>
                {statsAlertsAllCustomersPerMonth.stats_month_incidents_level_number} %
              </h2>
              <h3 className="purple" style={{ fontSize: '200%' }}>
                Incident level
              </h3>
              <p style={{ paddingTop: 0 }}>
                <b>{statsAlertsAllCustomersPerMonth.stats_month_incidents_level_title}</b>
                <br />
                {statsAlertsAllCustomersPerMonth.stats_month_incidents_level_reason}
              </p>
            </div>
          </div>

          <div className="flex_columns_three_wrapper">
            <div className="flex_columns_inner" style={{ textAlign: 'center' }}>
              <h2 className="purple" style={{ marginBottom: 0, paddingBottom: 0, fontSize: '300%' }}>
                {statsAlertsAllCustomersPerMonth.stats_month_threat_number} %
              </h2>
              <h3 className="purple" style={{ fontSize: '200%' }}>
                Threat level
              </h3>
              <p style={{ paddingTop: 0 }}>
                <b>{statsAlertsAllCustomersPerMonth.stats_month_threat_title}</b>
                <br />
                {statsAlertsAllCustomersPerMonth.stats_month_threat_reason}
              </p>
            </div>
          </div>

          <div className="flex_columns_three_wrapper">
            <div className="flex_columns_inner" style={{ textAlign: 'center' }}>
              <h2 className="purple" style={{ marginBottom: 0, paddingBottom: 0, fontSize: '300%' }}>
                {statsAlertsAllCustomersPerMonth.stats_month_impact_number} %
              </h2>
              <h3 className="purple" style={{ fontSize: '200%' }}>
                Impact level
              </h3>
              <p style={{ paddingTop: 0 }}>
                <b>{statsAlertsAllCustomersPerMonth.stats_month_impact_title}</b>
                <br />
                {statsAlertsAllCustomersPerMonth.stats_month_impact_reason}
              </p>
            </div>
          </div>
        </div>{' '}
        {/* // flex_container */}
      </div>
      {/* Operational readiness */}
      <h2>Operational readiness</h2>
      <div className="main_box">
        <div style={{ float: 'left', textAlign: 'center', paddingLeft: '10px' }}>
          {statsAlertsAllCustomersPerMonth.stats_month_operational_readiness_level_value === 'high' ? (
            <div>
              <p style={{ color: '#b00000', fontSize: '110%', fontWeight: 'bold' }}>
                <img src="/gfx/icons/48x48/priority_high_rounded_nofill_red_48x48.svg" alt="priority_high_rounded_nofill_red_48x48.svg" />
                <br />
                {statsAlertsAllCustomersPerMonth.stats_month_operational_readiness_level_title}
              </p>
            </div>
          ) : statsAlertsAllCustomersPerMonth.stats_month_operational_readiness_level_value === 'medium' ? (
            <div>
              <p style={{ color: '#d06900', fontSize: '110%', fontWeight: 'bold' }}>
                <img src="/gfx/icons/48x48/priority_high_rounded_nofill_orange_48x48.svg" alt="priority_high_rounded_nofill_orange_48x48.svg" />
                <br />
                {statsAlertsAllCustomersPerMonth.stats_month_operational_readiness_level_title}
              </p>
            </div>
          ) : (
            <div>
              <p style={{ color: '#0d9400', fontSize: '110%', fontWeight: 'bold' }}>
                <img src="/gfx/icons/48x48/priority_high_rounded_nofill_green_48x48.svg" alt="priority_high_rounded_nofill_green_48x48.svg" />
                <br />
                {statsAlertsAllCustomersPerMonth.stats_month_operational_readiness_level_title}
              </p>
            </div>
          )}
        </div>
        <div style={{ float: 'left', paddingLeft: '10px' }}>
          <p>{statsAlertsAllCustomersPerMonth.stats_month_operational_readiness_level_reason}</p>
        </div>
        <div style={{ float: 'left', paddingLeft: '10px' }}>
          <p>
            <a href={`/statistics/edit_operational_readiness/${statsId}`}>
              <img src="/gfx/icons/20x20/edit_rounded_fill_black_20x20.svg" alt="edit_rounded_fill_black_20x20.svg" />
            </a>
          </p>
        </div>
        <div className="clear"></div>
      </div>{' '}
      {/* // main_box */}
    </div>
  );
}

export default ViewStatsAlertsAllCustomersPerMonth;
