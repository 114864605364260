import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../../useToken.js';
import prodOrDev from '../../../utils.js';

export function ViewAPI() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { apiId } = useParams();

  const [api, setAPI] = useState({
    api_id: '',
    api_title: '',
    api_description: '',
    api_key_encrypted: '',
    api_valid_to_date: '',
    api_valid_to_date_saying: '',
    api_valid_to_warning_sent: '',
    api_created_timestamp: '',
    api_created_date_saying: '',
    api_created_by_user_email: '',
    api_updated_timestamp: '',
    api_updated_date_saying: '',
    api_updated_by_user_email: ''
  });

  // Load API
  const loadAPI = async (e) => {
    let result = await fetch(backendURL + '/api/apis/get_api', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify({
        api_id: apiId
      })
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setAPI(resultJson);
      // Display form (last element is loaded)
      document.getElementById('form').style.display = 'block';
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadAPI();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/">Settings</a>
          &nbsp; / &nbsp;
          <a href={`/settings/apis`}>APIs</a>
          &nbsp; / &nbsp;
          <a href={`/settings/view_api/${api.api_id}`}>{api.api_title}</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>
        {api.api_title} (API id={api.api_id})
      </h1>
      <div className="main_box" id="form" style={{ display: 'none' }}>
        <div className="vertical">
          <ul>
            <li>
              <a href={`/settings/apis/edit_api/${api.api_id}`}>
                <img src={'/gfx/icons/20x20/edit_rounded_fill_black_20x20.svg'} alt="face_rounded_nofill_black_20x20.svg" /> Edit API
              </a>
            </li>
            <li>
              <a href={`/settings/apis/api_permissions_index/${api.api_id}`}>
                <img src={'/gfx/icons/20x20/developer_board_nofill_black_20x20.svg'} alt="check_box_rounded_nofill_black_20x20.svg" /> API permissions
              </a>
            </li>
            <li>
              <a href={`/settings/apis/api_allowed_ips_index/${api.api_id}`}>
                <img src={'/gfx/icons/20x20/desktop_access_disabled_rounded_nofill_black_20x20.svg'} alt="api_rounded_nofill_black_20x20.svg" /> Allowed IPs
              </a>
            </li>
            <li>
              <a href={`/settings/apis/generate_new_api_key/${api.api_id}`}>
                <img src={'/gfx/icons/20x20/lock_rounded_nofill_black_20x20.svg'} alt="lock_rounded_nofill_black_20x20.svg" /> Generate new API key
              </a>
            </li>
            <li>
              <a href={`/settings/apis/api_usage_logs/${api.api_id}`}>
                <img src={'/gfx/icons/20x20/web_stories_rounded_nofill_black_20x20.svg'} alt="web_stories_rounded_nofill_black_20x20.svg" /> API Usage Logs
              </a>
            </li>
            <li>
              <a href={`/settings/apis/delete_api/${api.api_id}`}>
                <img src={'/gfx/icons/20x20/delete_rounded_nofill_black_20x20.svg'} alt="api_rounded_nofill_black_20x20.svg" /> Delete
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
export default ViewAPI;
