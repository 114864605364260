import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export function EditContact() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { contactId } = useParams();

  const [contact, setContact] = useState({
    contact_id: contactId,
    contact_customer_id: '',
    contact_name: '',
    contact_affiliation: '',
    contact_priority: '',
    contact_threshold_color: '',
    contact_company_role: '',
    contact_preferred_language: '',
    contact_email: '',
    contact_phone: '',
    contact_jira_user_id: '',
    contact_monthly_report_receiver_internally: '',
    contact_monthly_report_receiver_externally: '',
    contact_comment: '',
    contact_updated_timestamp: '',
    contact_updated_by_user_email: ''
  });
  const [options, setOptions] = useState(() => []);
  const [affiliations, setAffiliations] = useState(() => []);
  const [priorities, setPriorities] = useState(() => []);
  const [thresholds, setThresholds] = useState(() => []);
  const [roles, setRoles] = useState(() => []);
  const [languages, setLanguages] = useState(() => []);
  const [customerIndex, setCustomerIndex] = useState(() => []);

  // Load Options
  const loadOptions = async (e) => {
    let result = await fetch(backendURL + '/api/options/get_all_options', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setOptions(resultJson);

      setAffiliations(resultJson['affiliations']);
      setPriorities(resultJson['priorities']);
      setThresholds(resultJson['thresholds']);
      setRoles(resultJson['roles']);
      setLanguages(resultJson['languages']);

      // Display form (last element is loaded)
      document.getElementById('form').style.display = 'block';

      // Focus
      document.forms['form'].elements['contact_name'].focus();
    }
  };
  // Load Contact
  const loadContact = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get_contact?contact_id=' + contactId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setContact(resultJson);
      // Display form (last element is loaded)
      document.getElementById('form').style.display = 'block';
      // Focus
      document.forms['form'].elements['contact_name'].focus();

      // Load customer
      contact.contact_customer_id = resultJson['contact_customer_id'];
      loadCustomerIndex();
    }
  };
  // Load Customer Index
  const loadCustomerIndex = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get_customer?customer_id=' + contact.contact_customer_id, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setCustomerIndex(resultJson);
    }
  };

  // Form data chaned
  const handleChange = (e) => {
    // console.log("EditCustomer() :: handleChange() :: e.target.value="  + e.target.value)
    setContact({
      ...contact,
      [e.target.name]: e.target.value
    });
  };

  // Send new contact
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/customers/edit_contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(contact)
    });
    let resultJson = await result.json();
    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    }
  };

  const handleChangeCheckbox = (e) => {
    console.log('EditContact() :: handleChangeCheckbox() :: e.target.name=' + e.target.name + ' e.target.value=' + e.target.value);

    if (e.target.value === '' || e.target.value === 'on') {
      e.target.value = 'checked';
    } else {
      e.target.value = '';
    }

    setContact({
      ...contact,
      [e.target.name]: e.target.value
    });
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadOptions();
      loadContact();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/">Customers</a>
          &nbsp; / &nbsp;
          <a href={`/customers/view_customer/${contact.contact_customer_id}`}>{customerIndex.customer_name_abbreviated}</a>
          &nbsp; / &nbsp;
          <a href={`/customers/edit_contact/${contactId}`}>Edit contact</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>Edit Contact {contact.contact_name}</h1>
      <form onSubmit={handleSubmit} id="form" style={{ display: 'none' }}>
        <div className="main_box">
          {/* Feedback */}
          <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
            <p id="feedback_p">Feedback box is here</p>
          </div>

          {/* Form */}

          <p>
            Contact name
            <br />
            <input type="text" name="contact_name" value={contact.contact_name} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
            Affiliation
            <br />
            <input type="text" name="contact_affiliation" value={contact.contact_affiliation} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
            Priority
            <br />
            <span className="small">({contact.contact_priority})</span>
            <br />
            <select name="contact_priority" value={contact.contact_priority} onChange={handleChange}>
              <option value="N/A">Please select</option>
              {priorities.map((item, index) => (
                <option key={index} value={item.priority_title}>
                  {item.priority_title}
                </option>
              ))}
            </select>
          </p>

          <p>
            Threshold
            <br />
            <span className="small">({contact.contact_threshold_color})</span>
            <br />
            <select name="contact_threshold_color" value={contact.contact_threshold_color} onChange={handleChange}>
              <option value="N/A">Please select</option>
              {thresholds.map((item, index) => (
                <option key={index} value={item.threshold_color}>
                  {item.threshold_color}
                </option>
              ))}
            </select>
          </p>

          <p>
            Company role
            <br />
            <span className="small">({contact.contact_company_role})</span>
            <br />
            <select name="contact_company_role" value={contact.contact_company_role} onChange={handleChange}>
              <option value="N/A">Please select</option>
              {roles.map((item, index) => (
                <option key={index} value={item.role_title}>
                  {item.role_title}
                </option>
              ))}
            </select>
          </p>

          <p>
            Preferred language
            <br />
            <select name="contact_preferred_language" value={contact.contact_preferred_language} onChange={handleChange}>
              <option value="N/A">Please select</option>
              {languages.map((item, index) => (
                <option key={index} value={item.language_iso_three}>
                  {item.language_iso_three}
                </option>
              ))}
            </select>
          </p>

          <p>
            Contact email
            <br />
            <input type="text" name="contact_email" value={contact.contact_email} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
            Contact phone
            <br />
            <input type="text" name="contact_phone" value={contact.contact_phone} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>Jira user ID<br />
          <input type="text" name="contact_jira_user_id" value={contact.contact_jira_user_id} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>Case system user id and email id<br />
          -1 = Not yet synced to case system<br />
          0 = Not found. Will not try to sync again<br />
          Other values: The actual IDs in case system.<br />
          <input type="text" name="contact_case_system_user_id" value={contact.contact_case_system_user_id} onChange={handleChange} size="5" />
          &nbsp;
          <input type="text" name="contact_case_system_email_id" value={contact.contact_case_system_email_id} onChange={handleChange} size="5" />
          </p>

          <p>
            Monthly report receiver
            <br />
            <span className="small">
              Internally report receiver will get the report 3 days before externally receivers
              <br />
            </span>
            <span className="small">
              Last report internally sent month {contact.contact_monthly_report_receiver_internally_last_sent_month} ({contact.contact_monthly_report_receiver_internally_last_sent_saying})
            </span>
            <br />
            <input type="checkbox" name="contact_monthly_report_receiver_internally" checked={contact.contact_monthly_report_receiver_internally} onChange={handleChangeCheckbox} />
            Internally
            <br />
          </p>

          <p>
            Monthly report receiver externally
            <br />
            <span className="small">
              Last report externally sent month {contact.contact_monthly_report_receiver_externally_last_sent_month} ({contact.contact_monthly_report_receiver_externally_last_sent_saying})
            </span>
            <br />
            <input type="checkbox" name="contact_monthly_report_receiver_externally" checked={contact.contact_monthly_report_receiver_externally} onChange={handleChangeCheckbox} />
            Externally
          </p>

          <p>
            Offline asset email report
            <br />
            <span className="small">Last email report sent {contact.contact_offline_asset_email_datetime}</span>
            <br />
            <input type="checkbox" name="contact_offline_asset_email" checked={contact.contact_offline_asset_email} onChange={handleChangeCheckbox} />
          </p>

          <p>
            Contact comment
            <br />
            <textarea name="contact_comment" value={contact.contact_comment} onChange={handleChange} style={{ minWidth: '50%' }}></textarea>
          </p>

          <p>
            <button>Submit</button>
          </p>
        </div>
      </form>
    </div>
  );
}

export default EditContact;
