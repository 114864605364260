import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

export function PingInstructions() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { monitorParentId } = useParams();

  const [monitorParent, setMonitorParent] = useState({
    monitor_parent_id: monitorParentId,
    monitor_parent_name: '',
    monitor_parent_description: '',
    monitor_parent_url: '',
    monitor_parent_criticality: '',
    monitor_parent_type: '',
    monitor_parent_weight: '',
    monitor_parent_timeout_value: '',
    monitor_parent_timeout_measuring_unit: '',
    monitor_parent_last_ping_datetime: '',
    monitor_parent_last_ping_datetime_saying: '',
    monitor_parent_current_status_this: '',
    monitor_parent_current_status_children: '',
    monitor_parent_current_status_comment: '',
    monitor_parent_warning_slack_channel: '',
    monitor_parent_warning_email_receivers: '',
    monitor_parent_warning_sent: '',
    monitor_parent_owner_name: '',
    monitor_parent_administrator_name: '',
    monitor_parent_commercial_responsibility_name: '',
    monitor_parent_created_datetime: '',
    monitor_parent_created_date_saying: '',
    monitor_parent_created_user_email: '',
    monitor_parent_updated_datetime: '',
    monitor_parent_updated_date_saying: '',
    monitor_parent_updated_user_email: ''
  });

  // Load Identifier
  const loadMonitorParent = async (e) => {
    let result = await fetch(backendURL + '/api/monitor/get_monitor_parent?monitor_parent_id=' + monitorParentId, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setMonitorParent(resultJson);
      // Display form (last element is loaded)
      document.getElementById('main_box').style.display = 'block';
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadMonitorParent();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/monitor">Monitor</a>
          &nbsp; / &nbsp;
          <a href={`/monitor/view_monitor_parent/${monitorParent.monitor_parent_id}`}>{monitorParent.monitor_parent_name}</a>
          &nbsp; / &nbsp;
          <a href={`/monitor/ping_instructions/${monitorParent.monitor_parent_id}`}>Ping instructions</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>Ping instructions for {monitorParent.monitor_parent_name}</h1>

      <div className="main_box" id="main_box" style={{ display: 'none' }}>
        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        <p>To test the API permission do the following:</p>

        <p>
          <b>1. Download Postman</b>
          <br />
          https://www.postman.com/downloads/
        </p>

        <p>
          <b>2. In Postman Create Collection</b>
          <br />
          <em>New collection name:</em> cdc-24-monitor-prod
        </p>

        <p>
          <b>3a. In Postman Create Request - Monitor Parent</b>
          <br />
        </p>
        <ul>
          <li>
            <span>
              <b>New request name:</b> {monitorParent.monitor_parent_name}
            </span>
          </li>
          <li>
            <span>
              <b>Method:</b> POST
            </span>
          </li>
          <li>
            <span>
              <b>URL prod:</b> https://cdc-24-backend-t6qfqcqcha-lz.a.run.app/api/monitor/ping_monitor_parent
            </span>
          </li>
          <li>
            <span>
              <b>URL dev:</b> https://localhost:8080/api/monitor/ping_monitor_parent
            </span>
          </li>
        </ul>

        <p>
          <em>Headers:</em>
        </p>
        <ul>
          <li>
            <span>
              <b>Content-Type:</b> application/json
            </span>
          </li>
        </ul>

        <p>
          <em>Body -&gt; raw:</em>
        </p>
        <div>
          <div style={{ background: '#eeeeee', border: '#cccccc 1px solid', padding: '5px' }}>
            &#123;
            <br />
            &nbsp; &quot;parent_name&quot;: &quot;{monitorParent.monitor_parent_name}&quot;,
            <br />
            &nbsp; &quot;api_key&quot;: &quot;From CDC24. Example: 7Xh7aEho0jnRnVgkDVwlcUkGMRX_Y6vtsNY18n59I59fzLIks&quot;,
            <br />
            &nbsp; &quot;message&quot;: &quot;Some message to display, example Last alert id = 2233&quot;
            <br />
            &#125;
          </div>
        </div>

        <p>
          <br />
          <b>3b. In Postman Create Request - Monitor Children</b>
          <br />
        </p>
        <ul>
          <li>
            <span>
              <b>New request name:</b> {monitorParent.monitor_parent_name}-child-x
            </span>
          </li>
          <li>
            <span>
              <b>Method:</b> POST
            </span>
          </li>
          <li>
            <span>
              <b>URL prod:</b> https://cdc-24-backend-t6qfqcqcha-lz.a.run.app/api/monitor/ping_monitor_child
            </span>
          </li>
          <li>
            <span>
              <b>URL dev:</b> https://localhost:8080/api/monitor/ping_monitor_child
            </span>
          </li>
        </ul>

        <p>
          <em>Headers:</em>
        </p>
        <ul>
          <li>
            <span>
              <b>Content-Type:</b> application/json
            </span>
          </li>
        </ul>

        <p>
          <em>Body -&gt; raw:</em>
        </p>
        <div>
          <div style={{ background: '#eeeeee', border: '#cccccc 1px solid', padding: '5px' }}>
            &#123;
            <br />
            &nbsp; &quot;parent_name&quot;: &quot;{monitorParent.monitor_parent_name}&quot;,
            <br />
            &nbsp; &quot;api_key&quot;: &quot;From CDC24. Example: 7Xh7aEho0jnRnVgkDVwlcUkGMRX_Y6vtsNY18n59I59fzLIks&quot;,
            <br />
            &nbsp; &quot;child_name&quot;: &quot;Name of child, example GNO Ingest Azure Microsoft 365 Defender Graph API&quot;,
            <br />
            &nbsp; &quot;customer_name_abbreviated&quot;: &quot;Three or four letters of customer name (optional)&quot;,
            <br />
            &nbsp; &quot;message&quot;: &quot;Some message to display, example Last alert id = 2233&quot;,
            <br />
            &nbsp; &quot;new_events&quot;: &quot;Number of events to add, example 1&quot;
            <br />
            &#125;
          </div>
        </div>

        <p>
          <br />
          <b>3c. In Postman Create Request - Monitor Children list</b>
          <br />
        </p>
        <ul>
          <li>
            <span>
              <b>New request name:</b> {monitorParent.monitor_parent_name}-children
            </span>
          </li>
          <li>
            <span>
              <b>Method:</b> POST
            </span>
          </li>
          <li>
            <span>
              <b>URL prod:</b> https://cdc-24-backend-t6qfqcqcha-lz.a.run.app/api/monitor/ping_monitor_children
            </span>
          </li>
          <li>
            <span>
              <b>URL dev:</b> https://localhost:8080/api/monitor/ping_monitor_children
            </span>
          </li>
        </ul>

        <p>
          <em>Headers:</em>
        </p>
        <ul>
          <li>
            <span>
              <b>Content-Type:</b> application/json
            </span>
          </li>
        </ul>

        <p>
          <em>Body -&gt; raw:</em>
        </p>
        <div>
          <div style={{ background: '#eeeeee', border: '#cccccc 1px solid', padding: '5px' }}>
            &#123;
            <br />
            &nbsp; &quot;parent_name&quot;: &quot;{monitorParent.monitor_parent_name}&quot;,
            <br />
            &nbsp; &quot;api_key&quot;: &quot;From CDC24. Example: 7Xh7aEho0jnRnVgkDVwlcUkGMRX_Y6vtsNY18n59I59fzLIks&quot;,
            <br />
            &nbsp; &quot;children&quot;: [<br />
            &nbsp; &nbsp; &nbsp; &#123;
            <br />
            &nbsp; &nbsp; &nbsp; &nbsp; &quot;child_name&quot;: &quot;Name of child&quot;,
            <br />
            &nbsp; &nbsp; &nbsp; &nbsp; &quot;customer_name_abbreviated&quot;: &quot;Three or four letters of customer name (optional)&quot;,
            <br />
            &nbsp; &nbsp; &nbsp; &nbsp; &quot;message&quot;: &quot;Some message to display, example Last alert id = 2233&quot;,
            <br />
            &nbsp; &nbsp; &nbsp; &nbsp; &quot;new_events&quot;: &quot;Number of events to add, example 1&quot;
            <br />
            <br />
            &nbsp; &nbsp; &nbsp; &#125; ,<br />
            &nbsp; &nbsp; &nbsp; &#123;
            <br />
            &nbsp; &nbsp; &nbsp; &nbsp; &quot;child_name&quot;: &quot;Name of child&quot;,
            <br />
            &nbsp; &nbsp; &nbsp; &nbsp; &quot;customer_name_abbreviated&quot;: &quot;Three or four letters of customer name (optional)&quot;,
            <br />
            &nbsp; &nbsp; &nbsp; &nbsp; &quot;message&quot;: &quot;Some message to display, example Last alert id = 2233&quot;,
            <br />
            &nbsp; &nbsp; &nbsp; &nbsp; &quot;new_events&quot;: &quot;Number of events to add, example 1&quot;
            <br />
            &nbsp; &nbsp; &nbsp; &#125;
            <br />
            &nbsp; &nbsp; ]<br />
            &#125;
          </div>
        </div>
      </div>
    </div>
  );
}

export default PingInstructions;
