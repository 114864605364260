import { useState, useEffect } from 'react';
import { jwtDecode } from "jwt-decode";


// const userObject =  null;

export default function useToken() {

  // Get token
  const getToken = () => {
    const userToken = localStorage.getItem('tokenGoogleCDC');

    return userToken;
  };

  // Consts
  const [token, setToken] = useState(getToken());
  const [userObject, setUserObject] = useState(null);

  // Effect
  useEffect(() => {
    if (token && token !== 'undefined' && !userObject) {
      setUserObject(jwtDecode(token));
      // Check expire date
      const tokenDecoded = jwtDecode(token);
      const tokenExp = tokenDecoded['exp'];
      const timestamp = Math.floor(Date.now() / 1000);
      if (tokenExp < timestamp) {
        console.log('useToken() :: getToken() :: New login required because tokenExp=' + tokenExp + ' timestamp=' + timestamp);
        localStorage.removeItem('tokenGoogleCDC');
        setToken(null);
      }
    }
  }, [token]);

  // Save token
  const saveToken = (userToken) => {
    if (userToken === null) {
      localStorage.removeItem('tokenGoogleCDC');
      setToken(null);
    } else {
      localStorage.setItem('tokenGoogleCDC', userToken.token);
      setToken(userToken.token);
    }
  };

  // Return value
  return {
    setToken: saveToken,
    token,
    userObject
  };
}
