import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../../useToken.js';
import prodOrDev from '../../../utils.js';

export function DeleteAPIPermission() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { permissionId } = useParams();

  const [api, setAPI] = useState({
    api_id: '',
    api_title: '',
    api_description: '',
    api_valid_to_date: '',
    api_valid_to_date_saying: '',
    api_valid_to_warning_sent: '',
    api_created_timestamp: '',
    api_created_date_saying: '',
    api_created_by_user_email: '',
    api_created_by_full_name: '',
    api_updated_timestamp: '',
    api_updated_date_saying: '',
    api_updated_by_user_email: '',
    api_updated_by_full_name: ''
  });
  const [apiPermission, setAPIPermission] = useState({
    permission_id: permissionId,
    permission_api_id: '',
    permission_route: '',
    permission_read_write_delete: '',
    permission_reason: ''
  });

  // Load API Permission
  const loadAPIPermission = async (e) => {
    let result = await fetch(backendURL + '/api/apis/get_api_permission', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify({
        permission_id: permissionId
      })
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setAPIPermission(resultJson);
      // Display form (last element is loaded)
      document.getElementById('form').style.display = 'block';
      apiPermission.permission_api_id = resultJson['permission_api_id'];
      loadAPI();
    }
  };

  // Load API
  const loadAPI = async (e) => {
    let result = await fetch(backendURL + '/api/apis/get_api', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify({
        api_id: apiPermission.permission_api_id
      })
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setAPI(resultJson);
    }
  };

  // Confirm delete
  async function confirmDelete(e) {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/apis/delete_api_permission', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify({
        permission_id: permissionId
      })
    });
    let resultJson = await result.json();

    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      window.history.back();
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
    }
  }
  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadAPIPermission();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/">Settings</a>
          &nbsp; / &nbsp;
          <a href={`/settings/apis`}>APIs</a>
          &nbsp; / &nbsp;
          <a href={`/settings/apis/view_api/${api.api_id}`}>{api.api_title}</a>
          &nbsp; / &nbsp;
          <a href={`/settings/apis/api_permissions_index/${apiPermission.permission_api_id}`}>API Permissions {api.api_title}</a>
          &nbsp; / &nbsp;
          <a href={`/settings/apis/delete_api_permission/${apiPermission.permission_id}`}>Delete API Permissions</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>Delete API permission {apiPermission.permission_id}</h1>
      <div className="main_box" id="form" style={{ display: 'none' }}>
        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        {/* Form */}
        <p>Are you sure you want to delete the API permission? The action cannot be undone!</p>

        <p>
          <b>Route:</b> {apiPermission.permission_route}
          <br />
          <b>Read, write, delete:</b> {apiPermission.permission_read_write_delete}
          <br />
        </p>

        <p>
          <a href="#delete" className="btn_danger" onClick={confirmDelete}>
            Confirm
          </a>
        </p>
      </div>
    </div>
  );
}
export default DeleteAPIPermission;
