import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import prodOrDev from '../../utils.js';
import useToken from '../../useToken.js';
import parse from 'html-react-parser';

export function ViewCustomer() {
  // Backend URL
  const backendURL = prodOrDev();

  const { token, setToken, userObject } = useToken();
  const { customerId } = useParams();

  // Data
  const [customerIndex, setCustomerIndex] = useState({
    customer_id: customerId,
    customer_name: '',
    customer_name_abbreviated: '',
    customer_is_test_customer: '',
    customer_working_language: '',
    customer_restriction: '',
    customer_country_hq: '',
    customer_reason_of_hq_presence: '',
    customer_hq_address: '',
    customer_employee_count: '',
    customer_asset_sensor_count_man: '',
    customer_asset_adapter_count_man: '',
    customer_asset_total_count_man: '',
    customer_asset_sensor_count_auto: '',
    customer_asset_adapter_count_auto: '',
    customer_asset_total_count_auto: '',
    customer_sector_code: '',
    customer_business_code: '',
    customer_revenue: '',
    customer_operational_status: '',
    customer_operational_operational_date: '',
    customer_operational_operational_date_saying: '',
    customer_decommissioned_date: '',
    customer_decommissioned_date_saying: '',
    customer_former_company_names: '',
    customer_compliance: '',
    customer_service_window_days: '',
    customer_service_window_hours: '',
    customer_response_time_hours: '',
    customer_output: '',
    customer_notes_description: '',
    customer_notes_source: '',
    customer_procedures_description: '',
    customer_procedures_agreed_date: '',
    customer_procedures_added_date: '',
    customer_summary: '',
    customer_business_environment: '',
    customer_business_enablers: '',
    customer_business_threats: '',

    customer_created_timestamp: '',
    customer_created_date_saying: '',
    customer_created_by_user_email: '',
    customer_updated_timestamp: '',
    customer_updated_date_saying: '',
    customer_updated_by_user_email: ''
  });
  const [customerContacts, setCustomerContacts] = useState(() => []);
  const [customerAffiliations, setCustomerAffiliations] = useState(() => []);
  const [customerCriticalInfrastructureIndustries, setCustomerCriticalInfrastructureIndustries] = useState(() => []);
  const [customerURLs, setCustomerURLs] = useState(() => []);
  const [customerOperatingCountries, setCustomerOperatingCountries] = useState(() => []);
  const [customerTags, setCustomerTags] = useState(() => []);
  const [monthlyReports, setMonthlyReports] = useState(() => []);
  

  // Load data
  const loadCustomerIndex = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get_customer?customer_id=' + customerId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      if (resultJson.customer_operational_status == null) {
        resultJson.customer_operational_status = 'Onboarding';
        resultJson.customer_asset_client_count = 0;
        resultJson.customer_asset_server_count = 0;
        resultJson.customer_asset_total_count = 0;
        resultJson.customer_service_window_days = 'Mon-Sun';
        resultJson.customer_service_window_hours = '00-24';
        resultJson.customer_response_time_hours = 1;
        resultJson.customer_working_language = 'NOR';
        resultJson.customer_country_hq = '';
      }
      setCustomerIndex(resultJson);
      document.getElementById('show_customer').style.display = 'block';
    }
  };

  // Load loadCustomerContacts
  const loadCustomerContacts = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get_customer_contacts?customer_id=' + customerId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const resultJson = await result.json();
    if (result.status === 200) {
      setCustomerContacts(
        resultJson.map((fetch_object) => {
          return fetch_object;
        })
      );
    }
  };

  // Load loadCustomerAffiliations
  const loadCustomerAffiliations = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get_customer_afiliations?customer_id=' + customerId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const resultJson = await result.json();
    if (result.status === 200) {
      setCustomerAffiliations(
        resultJson.map((fetch_object) => {
          //console.log("ViewCustomer() :: loadCustomerAffiliations() :: fetch_object=" + fetch_object)
          return fetch_object;
        })
      );
    }
  };

  // Load loadCustomerCriticalInfrastructureIndustries
  const loadCustomerCriticalInfrastructureIndustries = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get_customer_critical_infrastructure_industries?customer_id=' + customerId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const resultJson = await result.json();
    if (result.status === 200) {
      setCustomerCriticalInfrastructureIndustries(
        resultJson.map((fetch_object) => {
          // console.log("ViewCustomer() :: loadCustomerCriticalInfrastructureIndustries() :: fetch_object=" + fetch_object)
          return fetch_object;
        })
      );
    }
  };

  // Load loadCustomerURLs
  const loadCustomerURLs = async (e) => {
    // console.log("ViewCustomer() :: loadCustomerURLs() :: Init")
    let result = await fetch(backendURL + '/api/customers/get_customer_urls?customer_id=' + customerId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const resultJson = await result.json();
    if (result.status === 200) {
      setCustomerURLs(
        resultJson.map((fetch_object) => {
          // console.log("ViewCustomer() :: loadCustomerURLs() :: fetch_object=" + JSON.stringify(fetch_object))
          return fetch_object;
        })
      );
    }
  };

  // Load loadCustomerOperatingCountries
  const loadCustomerOperatingCountries = async (e) => {
    // console.log("ViewCustomer() :: loadCustomerURLs() :: Init")
    let result = await fetch(backendURL + '/api/customers/get_customer_operating_countries?customer_id=' + customerId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const resultJson = await result.json();
    if (result.status === 200) {
      setCustomerOperatingCountries(
        resultJson.map((fetch_object) => {
          return fetch_object;
        })
      );
    }
  };

  const loadMonthlyReportsForCustomer = async () => {
    const queryURL = backendURL + `/api/monthly_reports/get_monthly_reports_for_customer?customer_id=${customerId}`;

    try {
      const result = await fetch(queryURL, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token
        }
      });

      if (result.status === 200) {
        const resultJson = await result.json();
        setMonthlyReports(resultJson.map((fetch_object) => fetch_object));
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  
  const loadCustomerTags = async () => {
    const queryURL = backendURL + `/api/customers/get-customer-tags-per-customer?customer_id=${customerId}`;

    try {
      const result = await fetch(queryURL, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token
        }
      });

      if (result.status === 200) {
        const resultJson = await result.json();
        setCustomerTags(resultJson.map((fetch_object) => fetch_object));
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  function copyToClipboard(whatToCopy) {
    let emailsForAllContacts = '';
    if (whatToCopy == 'copyAllContacts') {
      // All contacts
      customerContacts.forEach(function (item, index) {
        if (index == 0) {
          emailsForAllContacts = item['contact_email'];
        } else {
          emailsForAllContacts = emailsForAllContacts + ', ' + item['contact_email'];
        }
      });
    } else if (whatToCopy == 'priorityPrimary') {
      // Priority = Primary
      customerContacts.forEach(function (item, index) {
        if (item['contact_priority'] == 'Primary') {
          if (emailsForAllContacts == '') {
            emailsForAllContacts = item['contact_email'];
          } else {
            emailsForAllContacts = emailsForAllContacts + ', ' + item['contact_email'];
          }
        }
      });
    } else if (whatToCopy == 'prioritySecondary') {
      // Priority = Secondary
      customerContacts.forEach(function (item, index) {
        if (item['contact_priority'] == 'Secondary') {
          if (emailsForAllContacts == '') {
            emailsForAllContacts = item['contact_email'];
          } else {
            emailsForAllContacts = emailsForAllContacts + ', ' + item['contact_email'];
          }
        }
      });
    } else if (whatToCopy == 'companyRoleCustomersPoC') {
      // Company role = Customer's PoC
      customerContacts.forEach(function (item, index) {
        if (item['contact_company_role'] == "Customer's PoC") {
          if (emailsForAllContacts == '') {
            emailsForAllContacts = item['contact_email'];
          } else {
            emailsForAllContacts = emailsForAllContacts + ', ' + item['contact_email'];
          }
        }
      });
    } else if (whatToCopy == 'companyRoleSOCPrimaryContactSPC') {
      // Company role = SOC Primary Contact (SPC)
      customerContacts.forEach(function (item, index) {
        if (item['contact_company_role'] == 'SOC Primary Contact (SPC)') {
          if (emailsForAllContacts == '') {
            emailsForAllContacts = item['contact_email'];
          } else {
            emailsForAllContacts = emailsForAllContacts + ', ' + item['contact_email'];
          }
        }
      });
    } else if (whatToCopy == 'companyRoleSOCPrimaryContactSPCAndCustomersPoC') {
      // Company role = SOC Primary Contact (SPC) and Customer's PoC
      customerContacts.forEach(function (item, index) {
        if (item['contact_company_role'] == 'SOC Primary Contact (SPC)' || item['contact_company_role'] == "Customer's PoC") {
          if (emailsForAllContacts == '') {
            emailsForAllContacts = item['contact_email'];
          } else {
            emailsForAllContacts = emailsForAllContacts + ', ' + item['contact_email'];
          }
        }
      });
    } else if (whatToCopy == 'thresholdGreen') {
      // Threshold = Green
      customerContacts.forEach(function (item, index) {
        if (item['contact_threshold'] == 'green' || item['contact_threshold'] == 'Green' || item['contact_threshold'] == 'GREEN') {
          if (emailsForAllContacts == '') {
            emailsForAllContacts = item['contact_email'];
          } else {
            emailsForAllContacts = emailsForAllContacts + ', ' + item['contact_email'];
          }
        }
      });
    } else if (whatToCopy == 'thresholdAmber') {
      // Threshold = Amber
      customerContacts.forEach(function (item, index) {
        if (item['contact_threshold'] == 'amber' || item['contact_threshold'] == 'Amber' || item['contact_threshold'] == 'AMBER') {
          if (emailsForAllContacts == '') {
            emailsForAllContacts = item['contact_email'];
          } else {
            emailsForAllContacts = emailsForAllContacts + ', ' + item['contact_email'];
          }
        }
      });
    } else if (whatToCopy == 'thresholdRed') {
      // Threshold = Red
      customerContacts.forEach(function (item, index) {
        if (item['contact_threshold'] == 'red' || item['contact_threshold'] == 'Red' || item['contact_threshold'] == 'RED') {
          if (emailsForAllContacts == '') {
            emailsForAllContacts = item['contact_email'];
          } else {
            emailsForAllContacts = emailsForAllContacts + ', ' + item['contact_email'];
          }
        }
      });
    } else if (whatToCopy == 'thresholdBlack') {
      // Threshold = Black
      customerContacts.forEach(function (item, index) {
        if (item['contact_threshold'] == 'black' || item['contact_threshold'] == 'Black' || item['contact_threshold'] == 'BLACK') {
          if (emailsForAllContacts == '') {
            emailsForAllContacts = item['contact_email'];
          } else {
            emailsForAllContacts = emailsForAllContacts + ', ' + item['contact_email'];
          }
        }
      });
    } else {
      alert(`Unknown copy what: ${whatToCopy}`);
    }
    navigator.clipboard.writeText(emailsForAllContacts);
    document.getElementById('feedback_div_poi').style.display = 'block';
    document.getElementById('feedback_div_poi').className = '';
    document.getElementById('feedback_div_poi').classList.add('info_smal');
    document.getElementById('feedback_p_poi').innerHTML = 'Copyed to clipboard: ' + emailsForAllContacts;
    document.getElementById('feedback_div_poi').scrollIntoView();
  }

  function toggleById(divId) {
    document.getElementById(divId).style.display = 'block';
  }

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadCustomerIndex();
      loadCustomerContacts();
      loadCustomerAffiliations();
      loadCustomerCriticalInfrastructureIndustries();
      loadCustomerURLs();
      loadCustomerOperatingCountries();
      loadMonthlyReportsForCustomer();
      loadCustomerTags();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div id="show_customer" style={{ display: 'none' }}>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/">Customers</a>
          &nbsp; / &nbsp;
          <a href={`/customers/view_customer/${customerId}`}>{customerIndex.customer_name_abbreviated}</a>
        </p>
      </div>
      <div className="clear"></div>
      {/* Main Left */}
      <div className="grid_main_sidebar">
        <div className="item_main">
          {/* Headline and actions */}
          <div className="left">
            <h1>{customerIndex.customer_name}</h1>
          </div>
          {/* Customer action */}
          <div className="right">
            <p>
              &nbsp;
              {
                <a href={`/customers/edit_customer/${customerId}`} className="btn_default">
                  <img src="/gfx/icons/20x20/edit_rounded_nofill_black_20x20.svg" alt="edit_rounded_nofill_black_20x20.svg" /> Edit customer
                </a>
              }
              &nbsp;
              {
                <a href={`/customers/delete_customer/${customerId}`} className="btn_default">
                  <img src="/gfx/icons/20x20/delete_rounded_nofill_black_20x20.svg" alt="delete_rounded_nofill_black_20x20.svg" /> Delete customer
                </a>
              }
            </p>
          </div>
          <div className="clear"></div>

          {/* Customer tabs */}
          <ul className="tabs">
            <li>
              <a href={`/customers/view_customer/${customerId}`} className="active">
                Index
              </a>
            </li>
            <li>
              <a href={`/customers/view_business/${customerId}`}>Business</a>
            </li>
            <li>
              <a href={`/customers/view_identifiers/${customerId}`}>Identifiers</a>
            </li>
            <li>
              <a href={`/customers/view_engagements/${customerId}`}>Engagements</a>
            </li>
            {/* <li><a href={`/customers/view_sensors/${customerId}`}>Sensors</a></li> */}
          </ul>

          {/* Feedback */}
          <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
            <p id="feedback_p">Feedback box is here</p>
          </div>

          {/* Customer Summary */}
          <div className="main_box">
            {typeof customerIndex.customer_summary == 'string' ? (
              <div>{parse(customerIndex?.customer_summary)}</div>
            ) : (
              <div className="warning_smal">
                <p>Missing customer summary!</p>
              </div>
            )}
          </div>

          {/* Point of Contacts */}
          <a id="contacts"></a>
          <h1>Point of Contacts</h1>
          <div className="main_box">
            {/* PoI Feedback */}
            <div id="feedback_div_poi" className="info_smal" style={{ display: 'none' }}>
              <p id="feedback_p_poi">Feedback box is here</p>
            </div>

            {/* Point of Contacts Actions */}
            <p>
              {
                <a href={`/customers/new_contact/${customerId}`} className="btn_default">
                  <img src="/gfx/icons/20x20/add_rounded_nofill_black_20x20.svg" alt="add_rounded_nofill_black_20x20.svg" /> New contact
                </a>
              }
              &nbsp;
              {
                <a href="#copyAllContacts" className="btn_default" onClick={() => copyToClipboard('copyAllContacts')}>
                  <img src="/gfx/icons/20x20/assignment_add_rounded_nofill_black_20x20.svg" alt="assignment_add_rounded_nofill_black_20x20.svg" /> Copy all
                </a>
              }
              &nbsp;
              <button className="btn_default btn_default_submenu">
                <img src="/gfx/icons/20x20/assignment_returned_rounded_nofill_black_20x20.svg" alt="assignment_returned_rounded_nofill_black_20x20.svg" /> Copy Priority &#9660;
                <ul>
                  <li>
                    <a href="#priorityPrimary" onClick={() => copyToClipboard('priorityPrimary')}>
                      Primary contacts
                    </a>
                  </li>
                  <li>
                    <a href="#prioritySecondary" onClick={() => copyToClipboard('prioritySecondary')}>
                      Secondary contacts
                    </a>
                  </li>
                  <li>
                    <a href="#companyRoleCustomersPoC" onClick={() => copyToClipboard('companyRoleCustomersPoC')}>
                      Customer PoC Emails
                    </a>
                  </li>
                  <li>
                    <a href="#companyRoleSOCPrimaryContactSPC" onClick={() => copyToClipboard('companyRoleSOCPrimaryContactSPC')}>
                      Advania PoC Emails
                    </a>
                  </li>
                  <li>
                    <a href="#companyRoleSOCPrimaryContactSPCAndCustomersPoC" onClick={() => copyToClipboard('companyRoleSOCPrimaryContactSPCAndCustomersPoC')}>
                      Advania and Customer PoCs
                    </a>
                  </li>
                </ul>
              </button>
              <button className="btn_default btn_default_submenu">
                <img src="/gfx/icons/20x20/assignment_returned_rounded_nofill_black_20x20.svg" alt="assignment_returned_rounded_nofill_black_20x20.svg" /> Copy Threshold for Incident Notification &#9660;
                <ul>
                  <li>
                    <a href="#thresholdGreen" onClick={() => copyToClipboard('thresholdGreen')}>
                      <span style={{ color: 'green', fontWeight: 'bold' }}>&#x25CF;</span> Green
                    </a>
                  </li>
                  <li>
                    <a href="#thresholdAmber" onClick={() => copyToClipboard('thresholdAmber')}>
                      <span style={{ color: '#FFBF00', fontWeight: 'bold' }}>&#x25CF;</span> Amber
                    </a>
                  </li>
                  <li>
                    <a href="#thresholdRed" onClick={() => copyToClipboard('thresholdRed')}>
                      <span style={{ color: 'red', fontWeight: 'bold' }}>&#x25CF;</span> Red
                    </a>
                  </li>
                  <li>
                    <a href="#thresholdBlack" onClick={() => copyToClipboard('thresholdBlack')}>
                      <span style={{ color: 'black', fontWeight: 'bold' }}>&#x25CF;</span> Black
                    </a>
                  </li>
                </ul>
              </button>
            </p>
            <div className="clear"></div>

            {/* Table */}
            <table className="hor-zebra">
              <thead>
                <tr>
                  <th>
                    <span>Name</span>
                  </th>
                  <th>
                    <span>Affiliation</span>
                  </th>
                  <th>
                    <span>Threshold</span>
                  </th>
                  <th>
                    <span>Priority</span>
                  </th>
                  <th>
                    <span>Company role</span>
                  </th>
                  <th>
                    <span>Preferred language</span>
                  </th>
                  <th>
                    <span>E-mail</span>
                  </th>
                  <th>
                    <span>Phone</span>
                  </th>
                  <th>
                    <span>Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {customerContacts.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <span>{item.contact_name}</span>
                    </td>
                    <td>
                      <span>{item.contact_affiliation}</span>
                    </td>
                    <td>
                      <span className={`contact_threshold_${item.contact_threshold_color}`}>{item.contact_threshold_color}</span>
                    </td>
                    <td>
                      <span className={`contact_priority_${item.contact_priority}`}>{item.contact_priority}</span>
                    </td>
                    <td>
                      <span>{item.contact_company_role}</span>
                    </td>
                    <td>
                      <span>{item.contact_preferred_language}</span>
                    </td>
                    <td>
                      <span>{item.contact_email}</span>
                    </td>
                    <td>
                      <span>{item.contact_phone}</span>
                    </td>
                    <td>
                      <span>
                        <a href={`/customers/edit_contact/${item.contact_id}`}>
                          <img src="/gfx/icons/20x20/edit_rounded_fill_black_20x20.svg" alt="edit_rounded_fill_black_20x20.svg" />
                        </a>
                        &nbsp;
                        {
                          <a href={`/customers/delete_contact/${item.contact_id}`}>
                            <img src="/gfx/icons/20x20/delete_rounded_nofill_black_20x20.svg" alt="delete_rounded_fill_black_20x20.svg" />
                          </a>
                        }
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <h2>Customer Procedures</h2>
          <div className="main_box">{typeof customerIndex.customer_procedures_description == 'string' ? <div>{parse(customerIndex?.customer_procedures_description)}</div> : <div></div>}</div>

          <h2>Domains and URLs</h2>
          <div className="main_box">
            {/* URLs Actions */}
            <p>
              {
                <a href={`/customers/new_url/${customerId}`} className="btn_default">
                  <img src="/gfx/icons/20x20/add_rounded_nofill_black_20x20.svg" alt="add_rounded_nofill_black_20x20.svg" /> New URL
                </a>
              }
            </p>

            <table className="hor-zebra">
              <tbody>
                {customerURLs.map((item, index) => (
                  <tr key={index}>
                    <td>
                      {item.url_type == 'website' ? (
                        <span>
                          <a href={item.url_value}>{item.url_title}</a>
                        </span>
                      ) : (
                        <span>{item.url_title}</span>
                      )}
                    </td>
                    <td>{item.url_type == 'website' ? <span>Website</span> : <span>Domain</span>}</td>
                    <td>
                      <span>
                        <a href={`/customers/edit_url/${item.url_id}`}>
                          <img src="/gfx/icons/20x20/edit_rounded_fill_black_20x20.svg" alt="edit_rounded_fill_black_20x20.svg" />
                        </a>
                        &nbsp;
                        {
                          <a href={`/customers/delete_url/${item.url_id}`}>
                            <img src="/gfx/icons/20x20/delete_rounded_nofill_black_20x20.svg" alt="delete_rounded_fill_black_20x20.svg" />
                          </a>
                        }
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <h2>Operating Countries</h2>
          <div className="main_box">
            {/* Operating Countries */}
            <p>
              {
                <a href={`/customers/new_operating_country/${customerId}`} className="btn_default">
                  <img src="/gfx/icons/20x20/add_rounded_nofill_black_20x20.svg" alt="add_rounded_nofill_black_20x20.svg" /> Add operating country
                </a>
              }
            </p>

            <table className="hor-zebra">
              <tbody>
                {customerOperatingCountries.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <span>
                        <img src={`/gfx/countries/20x20/${item.operating_country_country_iso_three}_20x20.svg`} alt={`${item.operating_country_country_iso_three}_20x20.svg`} width="20" height="20" />
                      </span>
                    </td>
                    <td>
                      <span>{item.operating_country_country_iso_three}</span>
                    </td>
                    <td>
                      {item.operating_country_risk_level == 'high' ? (
                        <span style={{ fontWeight: 'bold', color: 'red' }}>
                          High-risk area
                          <br />
                        </span>
                      ) : (
                        <span></span>
                      )}

                      <span>{item.operating_country_reason_of_presence}</span>
                    </td>
                    <td>
                      <span>
                        <a href={`/customers/edit_operating_country/${item.operating_country_id}`}>
                          <img src="/gfx/icons/20x20/edit_rounded_fill_black_20x20.svg" alt="edit_rounded_fill_black_20x20.svg" />
                        </a>
                        &nbsp;
                        {
                          <a href={`/customers/delete_operating_country/${item.operating_country_id}`}>
                            <img src="/gfx/icons/20x20/delete_rounded_nofill_black_20x20.svg" alt="delete_rounded_fill_black_20x20.svg" />
                          </a>
                        }
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

                
          {/* monthly reports */}
          <h2>Monthly Report Metrics</h2>
          <div className="main_box">
                
            <table className="hor-zebra">
            <thead>
              <tr>
                <th style={{"textAlign": "center", "borderRight": "#000 1px solid"}}>
                  <span>Periode</span>
                </th>
                <th style={{"textAlign": "center"}}>
                  <span>Alerts</span>
                </th>
                <th style={{"textAlign": "center"}}>
                  <span>Tickets</span>
                </th>
                <th style={{"textAlign": "center"}}>
                  <span>Cases</span>
                </th>
                <th style={{"textAlign": "center", "borderRight": "#000 1px solid"}}>
                  <span>Incidents</span>
                </th>
                
                <th style={{"textAlign": "center"}}>
                  <span>FPO</span>
                </th>
                
                <th style={{"textAlign": "center", "borderRight": "#000 1px solid"}}>
                  <span>LEA</span>
                </th>

                <th style={{"textAlign": "center"}}>
                  <span>Generated</span>
                </th>
                <th style={{"textAlign": "center"}}>
                  <span>Sent internally</span>
                </th>
                <th style={{"textAlign": "center"}}>
                  <span>Sent externally</span>
                </th>
              </tr>
            </thead>
            <tbody>
            {monthlyReports.map((item, index) => (
                <tr key={index}>
                <td style={{"textAlign": "center", "borderRight": "#000 1px solid"}}>
                    <span>
                    <a href={`https://console.cloud.google.com/storage/browser/_details/monthly-customer-reports/prod/${item.stats_customer_month_year}/${item.stats_customer_month_month_int}/${customerIndex.customer_name_abbreviated}-${item.stats_customer_month_year}-${item.stats_customer_month_month_int}.pdf?pageState=(%22StorageObjectListTable%22:(%22f%22:%22%255B%255D%22))&project=advania-cdc-engineering-infra&prefix=&forceOnObjectsSortingFiltering=false`}>{item.stats_customer_month_month}/{item.stats_customer_month_year}</a>
                    </span>
                  </td>
                  <td style={{"textAlign": "center"}}>
                    <span>{item.stats_customer_month_alerts_count}</span>
                  </td>
                  <td style={{"textAlign": "center"}}>
                    <span>{item.stats_customer_month_tickets_count}</span>
                  </td>
                  <td style={{"textAlign": "center"}}>
                    <span>{item.stats_customer_month_cases_count}</span>
                  </td>
                  <td style={{"textAlign": "center", "borderRight": "#000 1px solid"}}>
                    <span>{item.stats_customer_month_cases_case_code_incidents_count}</span>
                  </td>

                  
                  <td style={{"textAlign": "center"}}>
                    <span>{item.stats_customer_month_cases_case_code_false_positives_rate} %</span>
                  </td>
                  <td style={{"textAlign": "center", "borderRight": "#000 1px solid"}}>
                    <span>{item.stats_customer_month_cases_case_code_legitimate_activity_rate} %</span>
                  </td>


                  <td style={{"textAlign": "center"}}>
                    <span>{item.stats_customer_month_report_generated_date}</span>
                  </td>
                  <td style={{"textAlign": "center"}}>
                    <span>{item.stats_customer_month_emailed_internally_date}</span>
                  </td>
                  <td style={{"textAlign": "center","borderRight": "#000 1px solid"}}>
                    <span>{item.stats_customer_month_emailed_externally_date}</span>
                  </td>

                </tr>
              ))}
            </tbody>
            </table>{/* // monthly reports */}
           
          </div>{/* // monthly reports */}
            
        </div>
        {/* End of item_main */}
        <div className="item_sidebar">
          {/* At a glance */}
          <h2>At a Glance</h2>
          <div className="main_box" style={{ minWidth: '28%' }}>
            <table className="hor-zebra">
              <tbody>
                <tr>
                  <td>
                    <span style={{ fontWeight: 'bold' }}>Status:</span>
                  </td>
                  <td>
                    <span className={`operational_status_${customerIndex.customer_operational_status}`}>{customerIndex.customer_operational_status}</span>
                    {customerIndex.customer_operational_status === 'Operational' && <span>&nbsp;{customerIndex.customer_operational_operational_date_saying}</span>}
                    {customerIndex.customer_operational_status === 'Historical' && <span>&nbsp;{customerIndex.customer_decommissioned_date_saying}</span>}
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style={{ fontWeight: 'bold' }}>Service:</span>
                  </td>
                  <td>
                    {customerIndex.customer_service_window_days === 'Mon-Fri' || customerIndex.customer_service_window_days === 'MON-FRI' ? (
                      <span className="tag_blue">
                        {customerIndex.customer_service_window_days} {customerIndex.customer_service_window_hours}
                      </span>
                    ) : customerIndex.customer_service_window_days === 'Mon-Sun' || customerIndex.customer_service_window_days === 'MON-SUN' ? (
                      <span className="tag_amber">
                        {customerIndex.customer_service_window_days} {customerIndex.customer_service_window_hours}
                      </span>
                    ) : (
                      <span>None</span>
                    )}
                  </td>
                </tr>

                <tr>
                  <td>
                    <span style={{ fontWeight: 'bold' }}>Affiliations:</span>
                  </td>
                  <td>
                    {customerAffiliations.map((item, index) => (
                      <div key={index}>{index == 0 ? <span>{item.affiliation_title}</span> : <span>&middot; {item.affiliation_title}</span>}</div>
                    ))}
                    <span>
                      <a href={`/customers/view_affiliations/${customerIndex.customer_id}`}><img src="/gfx/icons/20x20/edit_rounded_fill_black_20x20.svg" alt="edit_rounded_fill_black_20x20.svg" /></a>
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span style={{ fontWeight: 'bold' }}>Phone auth:</span>
                  </td>
                  <td>
                    <span>?</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span style={{ fontWeight: 'bold' }}>Restriction:</span>
                  </td>
                  <td>{customerIndex.customer_restriction === 'tlp:red' ? <span className="tag_red">{customerIndex.customer_restriction}</span> : customerIndex.customer_restriction === 'tlp:amber' ? <span className="tag_amber">{customerIndex.customer_restriction}</span> : customerIndex.customer_restriction === 'tlp:green' ? <span className="tag_green">{customerIndex.customer_restriction}</span> : customerIndex.customer_restriction === 'tlp:white' ? <span className="tag_white">{customerIndex.customer_restriction}</span> : <span>N/A</span>}</td>
                </tr>

                <tr>
                  <td>
                    <span style={{ fontWeight: 'bold' }}>Abbreviation:</span>
                  </td>
                  <td>
                    <span>{customerIndex.customer_name_abbreviated}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span style={{ fontWeight: 'bold' }}>Working Language:</span>
                  </td>
                  <td>
                    <span style={{ float: 'left' }}>
                      <img src={`/gfx/languages/20x20/${customerIndex.customer_working_language}_20x20.svg`} width="20" height="20" alt={`${customerIndex.customer_working_language}_20x20.svg`} />{' '}
                    </span>
                    <span>&nbsp; {customerIndex.customer_working_language}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span style={{ fontWeight: 'bold' }}>HQ Address:</span>
                  </td>
                  <td>
                    <span>{customerIndex.customer_hq_address}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span style={{ fontWeight: 'bold' }}>Country HQ:</span>
                  </td>
                  <td>
                    <span style={{ float: 'left' }}>
                      <img src={`/gfx/countries/20x20/${customerIndex.customer_country_hq}_20x20.svg`} alt={`${customerIndex.customer_country_hq}`} />{' '}
                    </span>
                    <span>&nbsp; {customerIndex.customer_country_hq}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span style={{ fontWeight: 'bold' }}>Employees:</span>
                  </td>
                  <td>
                    <span>{customerIndex.customer_employee_count}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span style={{ fontWeight: 'bold' }}>Assets manual count:</span>
                  </td>
                  <td>
                    <span>
                      Total: {customerIndex.customer_asset_total_count_man} <br />
                      &nbsp; Adapters: {customerIndex.customer_asset_adapter_count_man} <br />
                      &nbsp; Sensors: {customerIndex.customer_asset_sensor_count_man}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span style={{ fontWeight: 'bold' }}>Assets automatic count:</span>
                  </td>
                  <td>
                    <span>
                      Total: {customerIndex.customer_asset_total_count_auto} <br />
                      &nbsp; Adapters: {customerIndex.customer_asset_adapter_count_auto} <br />
                      &nbsp; Sensors: {customerIndex.customer_asset_sensor_count_auto}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span style={{ fontWeight: 'bold' }}>VM assets count:</span>
                  </td>
                  <td>
                    <span>
                      Manual: {customerIndex.customer_asset_vm_count_man} <br />
                      &nbsp; Automatic: {customerIndex.customer_asset_vm_count_auto}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span style={{ fontWeight: 'bold' }}>Services:</span>
                  </td>
                  <td>
                    <span>
                      SIDR: {customerIndex.customer_has_service_sidr ? <span>&#10004;</span> : <span>&#10008;</span>} <br />
                      &nbsp; VM: {customerIndex.customer_has_service_vm ? <span>&#10004;</span> : <span>&#10008;</span>}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span style={{ fontWeight: 'bold' }}>Revenue:</span>
                  </td>
                  <td>
                    <span>{customerIndex.customer_revenue}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span style={{ fontWeight: 'bold' }}>National Business ID:</span>
                  </td>
                  <td>
                    <span>{customerIndex.national_business_id}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span style={{ fontWeight: 'bold' }}>Critical infrastructure industries:</span>
                  </td>
                  <td>
                    {customerCriticalInfrastructureIndustries.map((item, index) => (
                      <div key={index}>{index == 0 ? <span>{item.industry_title}</span> : <span>&middot; {item.industry_title}</span>}</div>
                    ))}
                  </td>
                </tr>

                <tr>
                  <td>
                    <span style={{ fontWeight: 'bold' }}>Sector code:</span>
                  </td>
                  <td>
                    <span onClick={() => toggleById('customer_business_code')}>{customerIndex.customer_sector_code} &#9660;</span>
                    <div id="customer_business_code" style={{ display: 'none' }}>
                      <span>
                        <b>Business code:</b> {customerIndex.customer_business_code}
                      </span>
                    </div>
                  </td>
                </tr>

                
                <tr>
                  <td>
                    <span style={{ fontWeight: 'bold' }}>Tags:</span>
                  </td>
                  <td>
                    {customerTags.map((item, index) => (
                      <span key={index}><a href={`/customers/view-customer-tag/${item.tag_per_customer_title_clean}`} className="tag">{item.tag_per_customer_title}</a> &nbsp;</span>
                    ))}
                    <span>
                    <a href={`/customers/view-customer-tags-per-customer/${customerIndex.customer_id}`}><img src="/gfx/icons/20x20/edit_rounded_fill_black_20x20.svg" alt="edit_rounded_fill_black_20x20.svg" /></a>
                    </span>

                  </td>
                </tr>


              </tbody>
            </table>
          </div>
        </div>{' '}
        {/* End of item_sidebar */}
      </div>{' '}
      {/* End of grid_main_sidebar */}
      <div className="clear"></div>
    </div>
  );
}

export default ViewCustomer;
