import { useEffect, useState } from 'react';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

export function NewCustomer() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const [isdisabled, setIsDisabled] = useState(false);
  const [customer, setCustomer] = useState({
    customer_id: '',
    customer_name: '',
    customer_name_abbreviated: '',
    customer_working_language: '',
    customer_is_test_customer: '0'
  });

  // Load data
  const [customers, setCustomers] = useState(() => []);
  const loadData = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get_customers', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const resultJson = await result.json();
    if (result.status === 200) {
      setCustomers(
        resultJson.map((fetch_object) => {
          return fetch_object;
        })
      );
    }
  };

  // On load
  useEffect(() => {
    // Focus
    document.forms['form'].elements['customer_name'].focus();

    let ignore = false;
    // Loading
    if (!ignore) {
      loadData();
    }
    return () => {
      ignore = true;
    };
  }, []);

  const [abbrv_message, setNewCheckMessage] = useState('');

  function abbrv_check() {
    if (abbrv_message != '') {
      return <p className="error_smal"> {abbrv_message} </p>;
    } else {
      return <button>Submit</button>;
    }
  }

  //Display error/submit
  useEffect(() => {
    const existing_abbrvs = customers.map((customers) => {
      return customers.customer_name_abbreviated;
    });

    // console.log("now lengths is "+ customer.customer_name_abbreviated.length);
    if (customer.customer_name_abbreviated.length != 3) {
      setNewCheckMessage('Abbreviation requires 3 letters');
    } else if (existing_abbrvs.indexOf(customer.customer_name_abbreviated.toUpperCase()) > -1) {
      {
        setNewCheckMessage('Abbreviation already in use ');
      }
    } else {
      setNewCheckMessage('');
    }
  });

  // Form data chaned
  const handleChange = (e) => {
    // console.log("NewCustomer() :: handleChange() :: e.target.value="  + e.target.value);
    // console.log("field is " + e.target.name);

    if (e.target.name == 'customer_name') {
      var abbrv = abbreviator(e.target.value);
      if (e.target.value.length > 0) {
        e.target.value = e.target.value[0].toUpperCase() + e.target.value.slice(1);
      }
      setCustomer({
        ...customer,
        [e.target.name]: e.target.value,
        ['customer_name_abbreviated']: abbrv
      });
    } else if (e.target.name == 'customer_name_abbreviated')
      setCustomer({
        ...customer,
        [e.target.name]: e.target.value.toUpperCase()
      });
    else {
      setCustomer({
        ...customer,
        [e.target.name]: e.target.value
      });
    }
  };
  // Send general settings
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/customers/new_customer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(customer)
    });
    let resultJson = await result.json();
    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      document.getElementById('feedback_div').scrollIntoView();

      const newCustomerId = resulData['customer_id'];

      window.location.href = '/customers/view_customer/' + newCustomerId + '?ft=success&fm=created_successfully';
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    }
  };

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_title">You are here:</p>
        <p className="you_are_here_content">
          <a href="/">Customers</a>
          &nbsp; / &nbsp;
          <a href={`/customers/new_customer`}>New Customer</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>New Customer</h1>
      <form onSubmit={handleSubmit} id="form">
        <div className="main_box">
          {/* Feedback */}
          <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
            <p id="feedback_p">Feedback box is here</p>
          </div>

          {/* Form */}

          <p>
            Customer name
            <br />
            <input type="text" name="customer_name" value={customer.customer_name} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
            Abbreviated
            <br />
            <input type="text" name="customer_name_abbreviated" value={customer.customer_name_abbreviated} onChange={handleChange} size="3" />{' '}
          </p>

          <p>
            Test or production customer?
            <br />
            <select name="customer_is_test_customer" value={customer.customer_is_test_customer} onChange={handleChange}>
              <option value="0">Production customer</option>
              <option value="1">Test customer</option>
            </select>
          </p>

          <p>
            HQ Address
            <br />
            <input type="text" name="customer_hq_address" value={customer.customer_hq_address} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
            HQ Latitude and Longitude
            <br />
            <span className="small">In order to show alarms on a map we need to know the lat and long</span>
            <br />
            <input type="text" name="customer_hq_latitude" value={customer.customer_hq_latitude} onChange={handleChange} style={{ minWidth: '20px' }} />
            &nbsp; <input type="text" name="customer_hq_longitude" value={customer.customer_hq_longitude} onChange={handleChange} style={{ minWidth: '20px' }} />
          </p>

          {abbrv_check()}
        </div>
      </form>
    </div>
  );
}

function abbreviator(str_val) {
  var abbrv;
  abbrv = '';
  str_val = str_val.trim();
  if (str_val.length > 0) {
    str_val = str_val[0].toUpperCase() + str_val.slice(1);
  }
  //remove AS ASA AB
  if (str_val.endsWith(' AS')) {
    str_val = str_val.replace(' AS', '');
  }
  if (str_val.endsWith(' ASA')) {
    str_val = str_val.replace(' ASA', '');
  }
  if (str_val.endsWith(' AB')) {
    str_val = str_val.replace(' AB', '');
  }

  //Try First letter of each word
  if (str_val.split(' ').length > 2) {
    // console.log(str_val.split(" "))
    for (let i = 0; i < str_val.split(' ').length; i += 1) {
      abbrv = abbrv + str_val.split(' ')[i][0];
    }
  }

  //Try taking out capitals
  str_val = str_val.replace(/ /g, '');
  // console.log (str_val)
  // console.log (abbrv)
  for (let i = 0; i < str_val.length; i += 1) {
    if (str_val[i].toUpperCase() === str_val[i]) {
      abbrv = abbrv + str_val[i];
      abbrv = abbrv.replace(' ', '');
    }
  }
  // console.log (str_val)
  // console.log (abbrv)

  //Try taking out vowels
  if (abbrv.length < 3) {
    abbrv = str_val[0] + str_val.substring(1, str_val.length).replace(/[AEIOUaeiou]/g, '');
  }

  var output = abbrv.substring(0, 3).toUpperCase();

  if (output == 'UND') {
    output = '';
  }
  return output;
}

export default NewCustomer;
