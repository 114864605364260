import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

export function EditOperationalReadinessThreatImpact() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { statsId } = useParams();

  // Data
  const [stats, setStats] = useState({
    stats_month_id: statsId,
    stats_month_threat_number: '',
    stats_month_threat_title: '',
    stats_month_threat_reason: '',

    stats_month_impact_number: '',
    stats_month_impact_title: '',
    stats_month_impact_reason: '',

    stats_month_operational_readiness_level_value: '',
    stats_month_operational_readiness_level_title: '',
    stats_month_operational_readiness_level_reason: ''
  });

  // Load data
  const loadStats = async (e) => {
    let result = await fetch(backendURL + '/api/statistics/get_statistics_alerts_cases_tickets_all_customers_per_month_for_stats_month_id?stats_month_id=' + statsId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setStats(resultJson);
      document.getElementById('main_box').style.display = 'block';
    }
  };

  // Form data chaned
  const handleChange = (e) => {
    setStats({
      ...stats,
      [e.target.name]: e.target.value
    });
  };

  // Send new contact
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/statistics/edit_operational_readiness_impact_threat', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(stats)
    });
    let resultJson = await result.json();
    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadStats();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/statistics">Statistics</a>
          &nbsp; / &nbsp;
          <a href={`/statistics/view_stats_alerts_all_customers_per_month/${statsId}`}>
            {stats.stats_month_month}/{stats.stats_month_year}
          </a>
          &nbsp; / &nbsp;
          <a href={`/statistics/edit_operational_readiness/${statsId}`}>Edit threat</a>
        </p>
      </div>
      <div className="clear"></div>
      {/* Main Left */}
      <h1>
        Edit Opeational Readiness, Threath and Impact for {stats.stats_month_month}/{stats.stats_month_year}
      </h1>
      <div className="main_box" id="main_box" style={{ display: 'none' }}>
        <form onSubmit={handleSubmit} id="form">
          {/* Feedback */}
          <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
            <p id="feedback_p">Feedback box is here</p>
          </div>

          {/* Form */}

          <p>
            Opeational Readiness
            <br />
            <select name="stats_month_operational_readiness_level_value" value={stats.stats_month_operational_readiness_level_value} onChange={handleChange}>
              <option value="N/A">Please select</option>
              <option value="normal">Normal</option>
              <option value="elevated">Elevated</option>
              <option value="high">High</option>
              <option value="extraordinary">Extraordinary</option>
            </select>
          </p>

          <p>
            Reason
            <br />
            <textarea name="stats_month_operational_readiness_level_reason" value={stats.stats_month_operational_readiness_level_reason} onChange={handleChange} cols="50" rows="20" style={{ width: '99%' }}></textarea>
          </p>

          <p>
            Threath
            <br />
            <select name="stats_month_threat_title" value={stats.stats_month_threat_title} onChange={handleChange}>
              <option value="N/A">Please select</option>
              <option value="Low">Low</option>
              <option value="Moderate">Moderate</option>
              <option value="High">High</option>
            </select>
          </p>

          <p>
            Threat reason
            <br />
            <textarea name="stats_month_threat_reason" value={stats.stats_month_threat_reason} onChange={handleChange} cols="50" rows="4" style={{ width: '99%' }}></textarea>
          </p>

          <p>
            Impact
            <br />
            <select name="stats_month_impact_title" value={stats.stats_month_impact_title} onChange={handleChange}>
              <option value="N/A">Please select</option>
              <option value="Low">Low</option>
              <option value="Moderate">Moderate</option>
              <option value="High">High</option>
            </select>
          </p>

          <p>
            Impact reason
            <br />
            <textarea name="stats_month_impact_reason" value={stats.stats_month_impact_reason} onChange={handleChange} cols="50" rows="4" style={{ width: '99%' }}></textarea>
          </p>

          <p>
            <button>Submit</button>
          </p>
        </form>
      </div>{' '}
      {/* // main_box */}
    </div>
  );
}

export default EditOperationalReadinessThreatImpact;
