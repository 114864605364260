import { useEffect, useState } from 'react';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

export function CollectorsIndex() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken } = useToken();
  const [collectors, setCollectors] = useState([]);

  // Load data
  const loadData = async (e) => {
    let result = await fetch(backendURL + '/api/collectors/get-collectors', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const resultJson = await result.json();
    if (result.status === 200) {
      setCollectors(resultJson);
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadData();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/collectors">Collectors</a>
        </p>
      </div>
      <div className="clear"></div>
      
      <h1>Collectors</h1>

      <div className="main_box">

        <p>
        Collectors is a list of all our managed collector servers. 
        It is important to have control of them because of OS upgrades and distrubution of certificates.
        </p>

        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        {/* Table */}
        <table className="hor-zebra">
          <thead>
            <tr>
              <th>
                <span>Hostname</span>
              </th>
              <th>
                <span>Customer</span>
              </th>
              <th>
                <span>IP</span>
              </th>
              <th>
                <span>OS</span>
              </th>
              <th>
                <span>OS EOL</span>
              </th>
              <th>
                <span>Created</span>
              </th>
              <th>
                <span>Updated</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {collectors.map((item, index) => (
              <tr key={index}>
                <td>
                  <span>
                    <a href={`/collectors/view-collector/${item.collector_id}`}>{item.collector_hostname}</a>
                  </span>
                </td>
                <td>
                  <span>
                    <a href={`/customers/view_customer/${item.collector_customer_id}`}>{item.collector_customer_abbreviation}</a>
                  </span>
                </td>
                <td>
                  <span>{item.collector_ip_now}</span>
                </td>
                <td>
                  <span>
                  {item.collector_os}
                  &nbsp;
                  {item.collector_os_version}
                  </span>
                </td>
                <td>
                  <span>{item.collector_os_eol_saying}</span>
                </td>
                <td>
                  <span>{item.collector_created_date_saying}</span>
                </td>
                <td>
                  <span>{item.collector_updated_date_saying}</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

      </div>
      {/* //main_box */}

    </div>
  );
}

export default CollectorsIndex
;
