import { useEffect, useState } from 'react';
import useToken from '../../../useToken.js';
import prodOrDev from '../../../utils.js';

export function ThreatActorsIndex() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken } = useToken();
  const [threatActors, setThreatActors] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  // Load data
  const loadData = async (e) => {
    let result = await fetch(backendURL + '/api/threat-actors/get-threat-actors', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const resultJson = await result.json();
    if (result.status === 200) {
      setThreatActors(resultJson);
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadData();
    }
    return () => {
      ignore = true;
    };
  }, []);

  // Filtered list based on search query
  const filteredThreatActors = threatActors.filter((actor) => actor.threat_actor_name.toLowerCase().includes(searchQuery.toLowerCase()));

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/intelligence">Intelligence</a>
          &nbsp; / &nbsp;
          <a href="/intelligence/threat-actors">Threat Actors</a>
        </p>
      </div>
      <div className="clear"></div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
        <h1>Threat Actors</h1>
        <h1 style={{ background: '#000000', color: '#ffc000',fontWeight: 'bold', marginLeft: 'auto', paddingRight: '0.7rem' }}>TLP: AMBER+STRICT </h1>
      </div>
      <p>The threat actors overview is meant for internal use for the intelligence department</p>
      <div className="main_box">
        {/* Actions and Search */}
        <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginBottom: '1rem' }}>
          <a href="/intelligence/threat-actors/new-threat-actor" className="btn_default">
            New threat actor
          </a>
          <input type="text" placeholder="Search by name" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} style={{ marginLeft: '1rem' }} />
        </div>

        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        {/* Table */}
        <table className="hor-zebra">
          <thead>
            <tr>
              <th>
                <span>Name</span>
              </th>
              <th>
                <span>Description</span>
              </th>
              <th>
                <span>Updated</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredThreatActors.map((item, index) => (
              <tr key={index}>
                <td>
                  <span>
                    <a href={`/intelligence/threat-actors/view-threat-actor/${item.threat_actor_id}`}>{item.threat_actor_name}</a>
                  </span>
                </td>
                <td>
                  <span>{item.threat_actor_short_description}</span>
                </td>
                <td>
                  <span>{item.threat_actor_updated_date_saying}</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

      </div>{' '}
      {/* //main_box */}
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
          <h1 style={{ background: '#000000', color: '#ffc000',fontWeight: 'bold', marginLeft: 'auto', paddingRight: '0.7rem' }}>TLP: AMBER+STRICT </h1>
        </div>
    </div>
  );
}

export default ThreatActorsIndex;
