import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../../useToken.js';
import prodOrDev from '../../../utils.js';

export function NewAPIPermission() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { apiId } = useParams();

  const [api, setAPI] = useState({
    api_id: apiId,
    api_title: '',
    api_description: '',
    api_key_encrypted: '',
    api_valid_to_date: '',
    api_valid_to_date_saying: '',
    api_valid_to_warning_sent: '',
    api_created_timestamp: '',
    api_created_date_saying: '',
    api_created_by_user_email: '',
    api_updated_timestamp: '',
    api_updated_date_saying: '',
    api_updated_by_user_email: ''
  });

  const [permission, setPermission] = useState({
    permission_id: '',
    permission_api_id: apiId,
    permission_route: '',
    permission_read_write_delete: '',
    permission_reason: ''
  });

  // Load API
  const loadAPI = async (e) => {
    let result = await fetch(backendURL + '/api/apis/get_api', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify({
        api_id: apiId
      })
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setAPI(resultJson);

      // Display form (last element is loaded)
      document.getElementById('form').style.display = 'block';
    }
  };

  // Form data chaned
  const handleChange = (e) => {
    setPermission({
      ...permission,
      [e.target.name]: e.target.value
    });
  };

  // Send new API
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/apis/new_api_permission', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(permission)
    });
    let resultJson = await result.json();
    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadAPI();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/">Settings</a>
          &nbsp; / &nbsp;
          <a href={`/settings/apis`}>APIs</a>
          &nbsp; / &nbsp;
          <a href={`/settings/apis/view_api/${api.api_id}`}>{api.api_title}</a>
          &nbsp; / &nbsp;
          <a href={`/settings/apis/api_permissions_index/${apiId}`}>API Permissions {api.api_title}</a>
          &nbsp; / &nbsp;
          <a href={`/settings/apis/new_api_permission`}>New API permission</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>New API permission</h1>
      <form onSubmit={handleSubmit} id="form">
        <div className="main_box">
          {/* Feedback */}
          <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
            <p id="feedback_p">Feedback box is here</p>
          </div>

          {/* Form */}

          <p>
            Permission route
            <br />
            <select name="permission_route" value={permission.permission_route} onChange={handleChange}>
              <option value="N/A">Please select</option>

              <option value="0"> </option>
              <option value="0">- Certificiates ----</option>
              <option value="cdc-24-backend/api/certificate-api/get-certificate-meta-data">cdc-24-backend/api/certificate-api/get-certificate-meta-data</option>
              <option value="cdc-24-backend/api/certificate-api/get-certificate-crt">cdc-24-backend/api/certificate-api/get-certificate-crt</option>
              <option value="cdc-24-backend/api/certificate-api/get-certificate-key">cdc-24-backend/api/certificate-api/get-certificate-key</option>
              <option value="cdc-24-backend/api/certificate-api/upload-certificate-zip">cdc-24-backend/api/certificate-api/upload-certificate-zip</option>
              <option value="0"> </option>
              
              <option value="0">- Customers ----</option>
              <option value="cdc-24-backend/customers_api/get_contacts_app">cdc-24-backend/customers_api/get_contacts_app</option>
              <option value="cdc-24-backend/customers_api/get_customer_abbreviation_and_contacts_for_name">cdc-24-backend/customers_api/get_customer_abbreviation_and_contacts_for_name</option>
              <option value="cdc-24-backend/customers_api/get_customer_contacts_for_threshold_and_case_codes">cdc-24-backend/customers_api/get_customer_contacts_for_threshold_and_case_codes</option>
              <option value="cdc-24-backend/customers_api/update_requested_participants_based_on_jira_key">cdc-24-backend/customers_api/update_requested_participants_based_on_jira_key</option>
              <option value="cdc-24-backend/customers_api/get_customers_names_abbreviations_operational_status_urls_and_identifiers">cdc-24-backend/customers_api/get_customers_names_abbreviations_operational_status_urls_and_identifiers</option>
              <option value="cdc-24-backend/customers_api/get_customers_names_abbreviations_operational_status_urls_identifiers_and_engagements">cdc-24-backend/customers_api/get_customers_names_abbreviations_operational_status_urls_identifiers_and_engagements</option>
              <option value="cdc-24-backend/customers_api/get_customers_names_abbreviations_operational_status_urls_identifiers_and_contacts">cdc-24-backend/customers_api/get_customers_names_abbreviations_operational_status_urls_identifiers_and_contacts</option>
              <option value="cdc-24-backend/customers_api/get_customers_names_and_lc_organization_ids">cdc-24-backend/customers_api/get_customers_names_and_lc_organization_ids</option>
              <option value="cdc-24-backend/customers_api/get_contacts_email_addresses_to_tams_for_all_customers">cdc-24-backend/customers_api/get_contacts_email_addresses_to_tams_for_all_customers</option>
              <option value="cdc-24-backend/customers_api/get_contacts_email_addresses_to_tams_for_all_operational_customers">cdc-24-backend/customers_api/get_contacts_email_addresses_to_tams_for_all_operational_customers</option>

              <option value="0"> </option>
              <option value="0">- Alerts, tickets, cases ----</option>
              <option value="cdc-24-backend/customers_api/get_soc_alerts_index">cdc-24-backend/customers_api/get_soc_alerts_index</option>
              <option value="cdc-24-backend/customers_api/get_soc_tickets_index">cdc-24-backend/customers_api/get_soc_tickets_index</option>
              <option value="cdc-24-backend/customers_api/get_soc_cases_index">cdc-24-backend/customers_api/get_soc_cases_index</option>
              <option value="cdc-24-backend/customers_api/get_soc_cases_case_codes">cdc-24-backend/customers_api/get_soc_cases_case_codes</option>
              <option value="cdc-24-backend/customers_api/get_soc_cases_classifications">cdc-24-backend/customers_api/get_soc_cases_classifications</option>

              <option value="0"> </option>
              <option value="0">- Intelligence ----</option>
              <option value="cdc-24-backend/customers_api/get_soc_intelligence_assessments">cdc-24-backend/customers_api/get_soc_intelligence_assessments</option>

              <option value="0"> </option>
              <option value="0">- Statistics ----</option>
              <option value="cdc-24-backend/customers_api/get_stats_alerts_cases_tickets_all_customers_per_month">cdc-24-backend/customers_api/get_stats_alerts_cases_tickets_all_customers_per_month</option>
              <option value="cdc-24-backend/customers_api/get_stats_alerts_cases_tickets_per_customers_per_month">cdc-24-backend/customers_api/get_stats_alerts_cases_tickets_per_customers_per_month</option>
              <option value="cdc-24-backend/customers_api/get_stats_alerts_time_to_ack_per_customer_graph">cdc-24-backend/customers_api/get_stats_alerts_time_to_ack_per_customer_graph</option>

              

              <option value="cdc-24-backend/customers_api/get_stats_cases_case_codes_all_customers_per_month">cdc-24-backend/customers_api/get_stats_cases_case_codes_all_customers_per_month</option>
              <option value="cdc-24-backend/customers_api/get_stats_cases_case_codes_per_customer_per_month">cdc-24-backend/customers_api/get_stats_cases_case_codes_per_customer_per_month</option>
              <option value="cdc-24-backend/customers_api/get_stats_cases_classifications_all_customers_per_month">cdc-24-backend/customers_api/get_stats_cases_classifications_all_customers_per_month</option>
              <option value="cdc-24-backend/customers_api/get_stats_cases_classifications_per_customer_per_month">cdc-24-backend/customers_api/get_stats_cases_classifications_per_customer_per_month</option>

              <option value="0"> </option>
              <option value="0">- Depricated ----</option>
              <option value="auto-deploy-limacharlie/get_connection_strings">auto-deploy-limacharlie/get_connection_strings</option>
              <option value="auto-deploy-limacharlie/new_connection_string">auto-deploy-limacharlie/new_connection_string</option>
            </select>
          </p>

          <p>
            Permission read, write, delete
            <br />
            <select name="permission_read_write_delete" value={permission.permission_read_write_delete} onChange={handleChange}>
              <option value="N/A">Please select</option>
              <option value="read">read</option>
              <option value="write">write</option>
              <option value="delete">delete</option>
              <option value="read,write">read, write</option>
              <option value="read,write,delete">read, write, delete</option>
            </select>
          </p>

          <p>
            Reason for permission
            <br />
            <input type="text" name="permission_reason" value={permission.permission_reason} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
            <button>Submit</button>
          </p>
        </div>
      </form>
    </div>
  );
}

export default NewAPIPermission;
