import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

export function ViewCustomerTag() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { tagTitleClean } = useParams();

  // Data
  const [customersTagsIndex, setCustomersTagsIndex] = useState(() => []);
  const [customers, setCustomers] = useState(() => []);

  // Load customers tags index
  const loadCustomerTagsIndex  = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get-customer-tags-index', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setCustomersTagsIndex(resultJson);
      // console.log("EditAssessment.js::loadCustomerTagsIndex()::resultJson=" + JSON.stringify(resultJson, null, 2));
    }
  };

  // Load customers with tag 
  const loadCustomersWithTag  = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get-customers-with-customer-tag?tag_title_clean=' + tagTitleClean, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setCustomers(resultJson);
    }
  };
  

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadCustomerTagsIndex();
      loadCustomersWithTag();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/">Customers</a>
          &nbsp; / &nbsp;
          <a href={`/customers/view-customer-tag/${tagTitleClean}`}>{tagTitleClean}</a>
        </p>
      </div>
      <div className="clear"></div>

      {/* Headline and actions */}
      <h1>{tagTitleClean}</h1>

      {/* All tags */}
      <div style={{padding: "0px 0px 10px 10px"}}>
      {customersTagsIndex.map((item, index) => (
        <span key={index}><a href={`/customers/view-customer-tag/${item.tag_title_clean}`} className="tag">{item.tag_title}</a> &nbsp;</span>
      ))}
      </div>
      {/* //All tags */}

      <div className="main_box">
        
        {/* Customers with this tag */}

        
        <table className="hor-zebra">
          <thead>
            <tr>
              <th>
                <span>Name</span>
              </th>
              <th>
                <span>Abbreviation</span>
              </th>
              <th>
                <span>Status</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {customers.map((item, index) => (
              <tr key={index}>
                <td>
                  <span>
                    <a href={`/customers/view_customer/${item.customer_id}`}>{item.customer_name}</a>
                  </span>
                </td>
                <td>
                  <span>{item.customer_name_abbreviated}</span>
                </td>
                <td>
                  <span className={`operational_status_${item.customer_operational_status}`}>{item.customer_operational_status}</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ViewCustomerTag;
