import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../../useToken.js';
import prodOrDev from '../../../utils.js';

export function APIPermissionsIndex() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { apiId } = useParams();
  const [api, setAPI] = useState(() => []);
  const [apiPermissions, setAPIPermissions] = useState(() => []);

  // Load API
  const loadAPI = async (e) => {
    let result = await fetch(backendURL + '/api/apis/get_api', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify({
        api_id: apiId
      })
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setAPI(resultJson);

      // Load API Permissions
      loadAPIPermissions();

      // Display form (last element is loaded)
      document.getElementById('form').style.display = 'block';
    }
  };
  // Load API
  const loadAPIPermissions = async (e) => {
    let result = await fetch(backendURL + '/api/apis/get_api_permissions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify({
        api_id: apiId
      })
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setAPIPermissions(resultJson);

      // Display form (last element is loaded)
      document.getElementById('form').style.display = 'block';
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadAPI();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/">Settings</a>
          &nbsp; / &nbsp;
          <a href={`/settings/apis`}>APIs</a>
          &nbsp; / &nbsp;
          <a href={`/settings/apis/view_api/${api.api_id}`}>{api.api_title}</a>
          &nbsp; / &nbsp;
          <a href={`/settings/apis/api_permissions_index/${api.api_id}`}>API Permissions {api.api_title}</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>API permissions {api.api_title}</h1>
      <div className="main_box" id="form" style={{ display: 'none' }}>
        {/* Actions */}
        <p>
          <a href={`/settings/apis/new_api_permission/${apiId}`} className="btn_default">
            New permission
          </a>
        </p>

        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        {/* Table */}
        <table className="hor-zebra">
          <thead>
            <tr>
              <th>
                <span>Route</span>
              </th>
              <th>
                <span>Read, write, delete</span>
              </th>
              <th>
                <span>Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {apiPermissions.map((item, index) => (
              <tr key={index}>
                <td>
                  <span>{item.permission_route}</span>
                </td>
                <td>
                  <span>{item.permission_read_write_delete}</span>
                </td>
                <td>
                  <span>
                    <a href={`/settings/apis/api_permission_info/${item.permission_id}`}>
                      <img src="/gfx/icons/20x20/quiz_rounded_nofill_black_20x20.svg" alt="quiz_rounded_nofill_black_20x20.svg" />
                    </a>
                    <a href={`/settings/apis/api_permission_info/${item.permission_id}`}>Info</a>
                    &nbsp; &nbsp;
                    <a href={`/settings/apis/delete_api_permission/${item.permission_id}`}>
                      <img src="/gfx/icons/20x20/delete_rounded_nofill_black_20x20.svg" alt="delete_rounded_fill_black_20x20.svg" />
                    </a>
                    <a href={`/settings/apis/delete_api_permission/${item.permission_id}`}>Delete</a>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default APIPermissionsIndex;
