import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

export function NewAffilation() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { customerId } = useParams();

  const [customer, setCustomer] = useState({
    customer_id: customerId,
    customer_name: '',
    customer_name_abbreviated: ''
  });
  const [customerAffilation, setCustomerAffilation] = useState({
    affiliation_id: '',
    affiliation_customer_id: customerId,
    affiliation_title: ''
  });
  const [optionsAffiliations, setOptionsAffiliations] = useState(() => []);

  // Load data
  const loadCustomerIndex = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get_customer?customer_id=' + customerId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setCustomer(resultJson);
      customerAffilation.affiliation_customer_id = customerId;
      loadOptionsAffiliations();
    }
  };
  // Load Options affiliations
  const loadOptionsAffiliations = async (e) => {
    let result = await fetch(backendURL + '/api/options/get_affiliations', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setOptionsAffiliations(resultJson);

      // Display form (last element is loaded)
      document.getElementById('form').style.display = 'block';

      // Focus
      document.forms['form'].elements['affiliation_title'].focus();
    }
  };

  // Form data chaned
  const handleChange = (e) => {
    setCustomerAffilation({
      ...customerAffilation,
      [e.target.name]: e.target.value
    });
  };

  // Send new affilation
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/customers/new_customer_affiliation', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(customerAffilation)
    });
    let resultJson = await result.json();
    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      document.getElementById('feedback_div').scrollIntoView();

      window.location.href = '/customers/view_affiliations/' + customerId + '?ft=success&fm=created_successfully';
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadCustomerIndex();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/">Customers</a>
          &nbsp; / &nbsp;
          <a href={`/customers/view_customer/${customerId}`}>{customer.customer_name_abbreviated}</a>
          &nbsp; / &nbsp;
          <a href={`/customers/view_affiliations/${customerId}`}>Affilations</a>
          &nbsp; / &nbsp;
          <a href={`/customers/new_affiliation/${customerId}`}>New affilation</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>New affilation for {customer.customer_name_abbreviated}</h1>
      <form onSubmit={handleSubmit} id="form" style={{ display: 'none' }}>
        <div className="main_box">
          {/* Feedback */}
          <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
            <p id="feedback_p">Feedback box is here</p>
          </div>

          {/* Form */}

          <p>
            Affiliation title
            <br />
            <select name="affiliation_title" value={customerAffilation.affiliation_title} onChange={handleChange}>
              <option value="N/A">Please select</option>
              {optionsAffiliations.map((item, index) => (
                <option key={index} value={item.affiliation_title}>
                  {item.affiliation_title}
                </option>
              ))}
            </select>
          </p>

          <p>
            <button>Submit</button>
          </p>
        </div>
      </form>
    </div>
  );
}

export default NewAffilation;
