import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../../useToken.js';
import prodOrDev from '../../../utils.js';

export function DeleteCaseCode() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { caseCodeId } = useParams();

  const [caseCode, setCaseCode] = useState({
    case_code_id: caseCodeId, 
    case_code_title: '', 
    case_code_title_full: '', 
    case_code_abbreviation: '', 
    case_code_description: '', 
    case_code_piece_bg_color: '', 
    case_code_piece_text_color: '', 
    case_code_external_id: '', 
    case_code_is_active: '', 
    case_code_created_datetime: '', 
    case_code_created_date_saying: '', 
    case_code_created_by_user_id: '', 
    case_code_created_by_user_name: '',
    case_code_updated_datetime: '',
    case_code_updated_date_saying: '',
    case_code_updated_by_user_id: '',
    case_code_updated_by_user_name: ''
  });

  // Load 
  const loadData = async (e) => {
    let result = await fetch(backendURL + '/api/case-codes/get-case-code?case_code_id=' + caseCodeId, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setCaseCode(resultJson);

    }
  };

  
  // Confirm delete
  async function confirmDelete(e) {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/case-codes/delete-case-code?case_code_id=' + caseCodeId, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();

    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      window.history.back();
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
    }
  }


  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadData();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/">Settings</a>
          &nbsp; / &nbsp;
          <a href={`/settings/case-codes`}>Case Codes</a>
          &nbsp; / &nbsp;
          <a href={`/settings/case-codes/delete-case-code/${caseCode.case_code_id}`}>Delete Case Code</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>Delete case code {caseCode.case_code_title_full}</h1>

      <div className="main_box">
        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        {/* Form */}
        <p>Are you sure you want to delete the case code? The action cannot be undone!</p>

        <p>
        <a href="#delete" className="btn_danger" onClick={confirmDelete}>Confirm</a>
        </p>
      </div>
    </div>
  );
}
export default DeleteCaseCode;
