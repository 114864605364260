import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

export function InitializeEngagementStage() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { engagementId } = useParams();

  const [engagement, setEngagement] = useState({
    engagement_id: engagementId,
    engagement_customer_id: '',
    engagement_customer_name: '',
    engagement_customer_name_abbreviated: '',
    engagement_service_results_id: '',
    engagement_service_id: '',
    engagement_service_master_guid: '',
    engagement_service_archive_guid: '',
    engagement_service_name: '',
    engagement_service_generation: '',
    engagement_service_revision: '',
    engagement_service_flavor: '',
    engagement_service_created_at: '',
    engagement_service_updated_at: '',
    engagement_current_stage_letter: '',
    engagement_current_stage_title: '',
    engagement_next_stage_letter: '',
    engagement_next_stage_title: '',
    engagement_stage_has_warned_to_chat: '',
    engagement_stage_has_warned_to_email: '',
    engagement_stage_warned_to_chat_week: '',
    engagement_created_datetime: '',
    engagement_created_date_saying: '',
    engagement_created_by_user_id: '',
    engagement_created_by_user_email: '',
    engagement_created_by_full_name: '',
    engagement_updated_datetime: '',
    engagement_updated_date_saying: '',
    engagement_updated_by_user_id: '',
    engagement_updated_by_user_email: '',
    engagement_updated_by_full_name: ''
  });

  // Load data
  const loadEngagement = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get_customer_engagement', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        engagement_id: engagementId
      })
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setEngagement(resultJson);
      // Display form (last element is loaded)
      document.getElementById('form').style.display = 'block';
    }
  };
  // Confirm edit stage
  async function confirmEditStage(e) {
    e.preventDefault();

    // Set approved status
    engagement.engagement_stage_approved = 1;

    let result = await fetch(backendURL + '/api/customers/edit_customer_engagement', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(engagement)
    });
    let resultJson = await result.json();

    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      window.history.back();
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
    }
  }

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadEngagement();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/">Customers</a>
          &nbsp; / &nbsp;
          <a href={`/customers/view_customer/${engagement.engagement_customer_id}`}>{engagement.engagement_customer_name_abbreviated}</a>
          &nbsp; / &nbsp;
          <a href={`/customers/view_engagements/${engagement.engagement_customer_id}`}>Engagements</a>
          &nbsp; / &nbsp;
          <a href={`/customers/edit_contact/${engagementId}`}>Approve engagement stage</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>Approve engagement stage {engagement.engagement_id}</h1>

      <div className="main_box" id="form" style={{ display: 'none' }}>
        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        {/* Form */}
        <p>You are about to approve the following customer engagement:</p>

        <ul>
          <li>
            <span>
              <b>ID:</b> {engagement.engagement_id}
            </span>
          </li>
          <li>
            <span>
              <b>Customer name:</b> {engagement.engagement_customer_name}
            </span>
          </li>
          <li>
            <span>
              <b>Abbreviated:</b> {engagement.engagement_customer_name_abbreviated}
            </span>
          </li>
          <li>
            <span>
              <b>Service name:</b> {engagement.engagement_service_name}
            </span>
          </li>
          <li>
            <span>
              <b>Previous stage:</b> {engagement.engagement_previous_stage_letter} {engagement.engagement_previous_stage_title}
            </span>
          </li>
          <li>
            <span>
              <b>Current stage:</b> {engagement.engagement_current_stage_letter} {engagement.engagement_current_stage_title}
            </span>
          </li>
          <li>
            <span>
              <b>Next stage:</b> {engagement.engagement_next_stage_letter} {engagement.engagement_next_stage_title}
            </span>
          </li>
          <li>
            <span>
              <b>Created:</b> {engagement.engagement_created_date_saying}
            </span>
          </li>
        </ul>

        <p>
          <a href="#confirmEditStage" className="btn_default" onClick={confirmEditStage}>
            Confirm
          </a>
        </p>
      </div>
    </div>
  );
}

export default InitializeEngagementStage;
