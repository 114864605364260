import { useContext, useEffect, useState } from 'react';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

export function ContactsIndex() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const [contacts, setContacts] = useState(() => []);

  // Load data
  const loadData = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get_contacts', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const resultJson = await result.json();
    if (result.status === 200) {
      setContacts(
        resultJson.map((fetch_object) => {
          return fetch_object;
        })
      );
    }
  };

  function handleChangeFilter(e) {
    // Declare variables
    var table, tr, i, j, colValue, position, th;
    const targ = e.target;
    console.log('Initial Filter is ' + targ.value);
    const all_inputs = document.getElementsByTagName('input');
    filterFunc(targ, true);
    for (i = 0; i < all_inputs.length; i++) {
      filterFunc(all_inputs[i], false);
    }
  }

  function filterFunc(targ, initial) {
    var input, filter, table, tr, td, i, txtValue, colValue, position, th;
    position = 0;
    const inputColVal = targ.id.replace(' Search', '');
    table = document.getElementById('cust_table');
    tr = table.getElementsByTagName('tr');

    th = tr[0].getElementsByTagName('th');
    for (i = 0; i < th.length; i++) {
      colValue = th[i].textContent || th[i].innerText;

      if (inputColVal === colValue) {
        position = i;
      }
    }
    input = targ.value;

    if (input != null) {
      filter = input.toUpperCase();
      table = document.getElementById('cust_table');
      tr = table.getElementsByTagName('tr');
      th = tr[0].getElementsByTagName('th');

      // Loop through all table rows, and hide those who don't match the search query
      for (i = 0; i < tr.length; i++) {
        td = tr[i].getElementsByTagName('td')[position];
        if (td) {
          txtValue = td.textContent || td.innerText;

          if (initial || tr[i].style.display != 'none') {
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
              tr[i].style.display = '';
            } else {
              tr[i].style.display = 'none';
            }
          }
        }
      }
    }
  }

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadData();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/">Customers</a>
          &nbsp; / &nbsp;
          <a href={`/customers/contacts_index`}>Contacts Index</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>Contacts Index</h1>
      <div className="main_box">
        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        {/* Table */}
        <table id="cust_table" className="hor-zebra">
          <thead>
            <tr>
              <th>
                <span>Customer</span>
              </th>
              <th>
                <span>Name</span>
              </th>
              <th>
                <span>Affilation</span>
              </th>
              <th>
                <span>Email</span>
              </th>
              <th>
                <span>Jira User ID</span>
              </th>
              <th>
                <span>Actions</span>
              </th>
            </tr>

            <tr>
              <th className="th_sub_headline">
                <span>
                  <input type="input" className="btn_default" id="Customer Search" onChange={handleChangeFilter} />
                </span>
              </th>
              <th className="th_sub_headline">
                <span>
                  <input type="input" className="btn_default" id="Name Search" onChange={handleChangeFilter} />
                </span>
              </th>
              <th className="th_sub_headline">
                <span>
                  <input type="input" className="btn_default" id="Affilation Search" onChange={handleChangeFilter} />
                </span>
              </th>
              <th className="th_sub_headline">
                <span>
                  <input type="input" className="btn_default" id="Email Search" onChange={handleChangeFilter} />
                </span>
              </th>
              <th className="th_sub_headline">
                <span>
                  <input type="input" className="btn_default" id="Jira User ID Search" onChange={handleChangeFilter} />
                </span>
              </th>
              <th className="th_sub_headline"></th>
            </tr>
          </thead>
          <tbody>
            {contacts.map((item, index) => (
              <tr key={index}>
                <td>
                  <span>
                    <a href={`/customers/view_customer/${item.contact_customer_id}`}>{item.customer_name}</a>
                  </span>
                </td>
                <td>
                  <span>
                    <a href={`/customers/edit_contact/${item.contact_id}`}>{item.contact_name}</a>
                  </span>
                </td>
                <td>
                  <span>{item.contact_affiliation}</span>
                </td>
                <td>
                  <span>{item.contact_email}</span>
                </td>
                <td>
                  <span>{item.contact_jira_user_id}</span>
                </td>
                <td>
                  <span>
                    <a href={`/customers/edit_contact/${item.contact_id}`}>
                      <img src="/gfx/icons/20x20/edit_rounded_fill_black_20x20.svg" alt="edit_rounded_fill_black_20x20.svg" />
                    </a>
                    &nbsp;
                    <a href={`/customers/delete_contact/${item.contact_id}`}>
                      <img src="/gfx/icons/20x20/delete_rounded_nofill_black_20x20.svg" alt="delete_rounded_fill_black_20x20.svg" />
                    </a>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ContactsIndex;
