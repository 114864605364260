import { useEffect, useState } from 'react';
import useToken from '../../../useToken.js';
import prodOrDev from '../../../utils.js';

export function NewUser() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();

  const [user, setUser] = useState({
    user_id: '',
    user_email: '',
    user_first_name: '',
    user_middle_name: '',
    user_last_name: '',
    user_display_name: '',
    user_rank: '',
    user_department: ''
  });

  // Form data chaned
  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value
    });
  };

  // Send new User
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/users/new_user', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(user)
    });
    let resultJson = await result.json();
    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      // Focus
      document.forms['form'].elements['user_email'].focus();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/">Settings</a>
          &nbsp; / &nbsp;
          <a href={`/settings/users`}>Users</a>
          &nbsp; / &nbsp;
          <a href={`/settings/users/new-user`}>New user</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>New User</h1>
      <form onSubmit={handleSubmit} id="form">
        <div className="main_box">
          {/* Feedback */}
          <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
            <p id="feedback_p">Feedback box is here</p>
          </div>

          {/* Form */}

          <p>
            Email
            <br />
            <input type="text" name="user_email" value={user.user_email} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
            First name
            <br />
            <input type="text" name="user_first_name" value={user.user_first_name} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
            Middle name
            <br />
            <input type="text" name="user_middle_name" value={user.user_middle_name} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
            Last name
            <br />
            <input type="text" name="user_last_name" value={user.user_last_name} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
            Display name (example Sindre Ditlefsen would be Sindre, while Knut Feragen would be Knut F)
            <br />
            <input type="text" name="user_display_name" value={user.user_display_name} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
            Department
            <br />
            <input type="text" name="user_department" value={user.user_department} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
            <button>Submit</button>
          </p>
        </div>
      </form>
    </div>
  );
}

export default NewUser;
