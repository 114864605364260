import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

export function ViewMonitorParent() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { monitorParentId } = useParams();

  const [monitorParent, setMonitorParent] = useState({
    monitor_parent_id: monitorParentId,
    monitor_parent_name: '',
    monitor_parent_description: '',
    monitor_parent_url: '',
    monitor_parent_criticality: '',
    monitor_parent_type: '',
    monitor_parent_weight: '',
    monitor_parent_timeout_value: '',
    monitor_parent_timeout_measuring_unit: '',
    monitor_parent_last_ping_datetime: '',
    monitor_parent_last_ping_datetime_saying: '',
    monitor_parent_current_status_this: '',
    monitor_parent_current_status_children: '',
    monitor_parent_current_status_comment: '',
    monitor_parent_warning_slack_channel: '',
    monitor_parent_warning_email_receivers: '',
    monitor_parent_warning_sent: '',
    monitor_parent_owner_name: '',
    monitor_parent_administrator_name: '',
    monitor_parent_commercial_responsibility_name: '',
    monitor_parent_created_datetime: '',
    monitor_parent_created_date_saying: '',
    monitor_parent_created_user_email: '',
    monitor_parent_updated_datetime: '',
    monitor_parent_updated_date_saying: '',
    monitor_parent_updated_user_email: ''
  });

  const [monitorChildren, setMonitorChildren] = useState(() => []);

  // Load Parent
  const loadMonitorParent = async (e) => {
    let result = await fetch(backendURL + '/api/monitor/get_monitor_parent?monitor_parent_id=' + monitorParentId, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setMonitorParent(resultJson);
      // Display form (last element is loaded)
      document.getElementById('main_box').style.display = 'block';

      // Load childrens
      loadMonitorChildren();
    }
  };

  // Load Parent
  const loadMonitorChildren = async (e) => {
    let result = await fetch(backendURL + '/api/monitor/get_monitor_children?monitor_parent_id=' + monitorParentId, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setMonitorChildren(resultJson);
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadMonitorParent();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/monitor">Monitor</a>
          &nbsp; / &nbsp;
          <a href={`/monitor/view_monitor_parent/${monitorParent.monitor_parent_id}`}>{monitorParent.monitor_parent_name}</a>
        </p>
      </div>
      <div className="clear"></div>
      <h1>{monitorParent.monitor_parent_name}</h1>
      <div className="main_box" id="main_box" style={{ display: 'none' }}>
        {/* Actions */}
        <p>
          <a href={`/monitor/edit_monitor_parent/${monitorParent.monitor_parent_id}`} className="btn_default">
            Edit
          </a>
          <a href={`/monitor/generate_new_api_key_for_monitor_parent/${monitorParent.monitor_parent_id}`} className="btn_default">
            Generate new API key
          </a>
          <a href={`/monitor/ping_instructions/${monitorParent.monitor_parent_id}`} className="btn_default">
            Ping instructions
          </a>
          <a href={`/monitor/delete_monitor_parent/${monitorParent.monitor_parent_id}`} className="btn_default">
            Delete
          </a>
          <a href={`/monitor/new_monitor_child/${monitorParent.monitor_parent_id}`} className="btn_default">
            New monitor child
          </a>
        </p>

        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        {/* Quick facts */}
        <h2 style={{ paddingLeft: 0 }}>Quick Facts for {monitorParent.monitor_parent_name}</h2>
        <div className="left">
          <table>
            <tbody>
              <tr>
                <td style={{ padding: '0px 4px 0px 0px' }}>
                  <span>
                    <b>Type:</b>
                  </span>
                </td>
                <td style={{ padding: '0px 20px 0px 0px' }}>
                  <span>{monitorParent.monitor_parent_type}</span>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '0px 4px 0px 0px' }}>
                  <span>
                    <b>Timeout:</b>
                  </span>
                </td>
                <td style={{ padding: '0px 20px 0px 0px' }}>
                  <span>
                    {monitorParent.monitor_parent_timeout_value} {monitorParent.monitor_parent_timeout_measuring_unit}
                  </span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 4px 0px 0px' }}>
                  <span>
                    <b title={`${monitorParent.monitor_parent_last_ping_datetime}`}>Last Ping:</b>
                  </span>
                </td>
                <td style={{ padding: '0px 20px 0px 0px' }}>
                  <span>{monitorParent.monitor_parent_last_ping_datetime_saying}</span>
                  {monitorParent.monitor_parent_last_ping_message !== '' ? <span>({monitorParent.monitor_parent_last_ping_message})</span> : <span></span>}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="left">
          <table>
            <tbody>
              <tr>
                <td style={{ padding: '0px 4px 0px 0px' }}>
                  <span>
                    <b title={`${monitorParent.monitor_parent_degraded_datetime}`}>Degraded:</b>
                  </span>
                </td>
                <td style={{ padding: '0px 20px 0px 0px' }}>
                  <span>{monitorParent.monitor_parent_degraded_datetime_saying}</span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 4px 0px 0px' }}>
                  <span>
                    <b title={`${monitorParent.monitor_parent_resolved_datetime}`}>Resolved:</b>
                  </span>
                </td>
                <td style={{ padding: '0px 20px 0px 0px' }}>
                  <span>{monitorParent.monitor_parent_resolved_datetime_saying}</span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 4px 0px 0px' }}>
                  <span>
                    <b>Warning sent:</b>
                  </span>
                </td>
                <td style={{ padding: '0px 20px 0px 0px' }}>
                  <span>{monitorParent.monitor_parent_warning_sent}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="left">
          <table>
            <tbody>
              <tr>
                <td style={{ padding: '0px 4px 0px 0px' }}>
                  <span>
                    <b>Current status this:</b>
                  </span>
                </td>
                <td style={{ padding: '0px 20px 0px 0px' }}>
                  <span>{monitorParent.monitor_parent_current_status_this}</span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 4px 0px 0px' }}>
                  <span>
                    <b>Current status children:</b>
                  </span>
                </td>
                <td style={{ padding: '0px 20px 0px 0px' }}>
                  <span>{monitorParent.monitor_parent_current_status_children}</span>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0px 4px 0px 0px' }}>
                  <span>
                    <b>Current status comment:</b>
                  </span>
                </td>
                <td style={{ padding: '0px 20px 0px 0px' }}>
                  <span>{monitorParent.monitor_parent_current_status_comment}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="clear" style={{ padding: '20px 0px 0px 0px' }}></div>

        {/* Children */}
        <h2 style={{ paddingLeft: 0 }}>Children of {monitorParent.monitor_parent_name}</h2>

        <table className="hor-zebra">
          <thead>
            <tr>
              <th>
                <span>Name</span>
              </th>
              <th>
                <span>Ping status</span>
              </th>
              <th>
                <span>Event status</span>
              </th>
              <th>
                <span>Events expected</span>
              </th>
              <th>
                <span>Events per month</span>
              </th>
              <th>
                <span>Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {monitorChildren.map((item, index) => (
              <tr key={index}>
                <td>
                  {/* Name */}
                  {item.monitor_child_customer_name === '' ? (
                    <span>
                      {item.monitor_child_name}
                      <br />
                    </span>
                  ) : (
                    <p style={{ padding: 0, margin: 0 }}>
                      <span>
                        <a href={`/customers/view_customer/${item.monitor_child_customer_id}`} style={{ color: 'black' }}>
                          {item.monitor_child_name}
                        </a>
                        <br />
                      </span>
                      <span className={`operational_status_${item.monitor_child_customer_operational_status}`} style={{ fontSize: '90%' }}>
                        {item.monitor_child_customer_operational_status}
                      </span>
                    </p>
                  )}
                  <span className="small">{item.monitor_child_type}</span>
                </td>
                <td>
                  {/* Ping status */}
                  {item.monitor_child_ping_status === 'Online' ? (
                    <span className="tag_green" title="Online">
                      &#10003;
                    </span>
                  ) : item.monitor_child_ping_status === 'Degraded' ? (
                    <span className="tag_amber" titlte="Degraded">
                      &#10071;
                    </span>
                  ) : item.monitor_child_ping_status === 'Offline' ? (
                    <span className="tag_red" title="Offline">
                      &#10006;
                    </span>
                  ) : (
                    <span className="tag_light_grey" title="Unknown">
                      ?
                    </span>
                  )}
                  <span>
                    <br />
                    Last: {item.monitor_child_ping_last_datetime_saying}
                    <br />
                    Msg: {item.monitor_child_ping_last_message}
                    <br />
                  </span>

                  {/* Ping Degraded */}
                  {item.monitor_child_ping_degraded_datetime_saying !== '' ? (
                    <span style={{ color: 'red' }}>
                      Degraded: {item.monitor_child_ping_degraded_datetime_saying}
                      <br />
                    </span>
                  ) : (
                    <span></span>
                  )}
                  {/* Ping Resolved */}
                  {item.monitor_child_ping_resolved_datetime_saying !== '' ? (
                    <span>
                      Resolved: {item.monitor_child_ping_resolved_datetime_saying}
                      <br />
                    </span>
                  ) : (
                    <span></span>
                  )}
                </td>
                <td>
                  {/* Event status */}
                  {item.monitor_child_events_status === 'Online' ? (
                    <span className="tag_green" title={`${item.monitor_child_events_status}`}>
                      &#10003;
                    </span>
                  ) : item.monitor_child_events_status === 'Degraded' ? (
                    <span className="tag_amber" titlte="Degraded">
                      &#10071;
                    </span>
                  ) : item.monitor_child_events_status === 'Did not receive desired number of events' ? (
                    <span className="tag_amber" title={`${item.monitor_child_events_status}`}>
                      &#10071;
                    </span>
                  ) : item.monitor_child_events_status === 'Offline' ? (
                    <span className="tag_red" title={`${item.monitor_child_events_status}`}>
                      &#10006;
                    </span>
                  ) : (
                    <span className="tag_light_grey" title={`${item.monitor_child_events_status}`}>
                      ?
                    </span>
                  )}
                  <span>
                    <br />
                    Last: {item.monitor_child_events_last_event_datetime_saying}
                    <br />
                  </span>

                  {/* Events Degraded */}
                  {item.monitor_child_events_degraded_datetime_saying !== '' ? (
                    <span style={{ color: 'red' }}>
                      Degraded: {item.monitor_child_events_degraded_datetime_saying}
                      <br />
                    </span>
                  ) : (
                    <span></span>
                  )}
                  {/* Events Resolved */}
                  {item.monitor_child_events_resolved_datetime_saying !== '' ? (
                    <span>
                      Resolved: {item.monitor_child_events_resolved_datetime_saying}
                      <br />
                    </span>
                  ) : (
                    <span></span>
                  )}
                </td>
                <td>
                  {/* Events expected */}
                  <span>{item.monitor_child_events_expected}</span>
                </td>
                <td>
                  {/* Events per month */}
                  <table>
                    <tbody>
                      <tr>
                        <td style={{ padding: '0px 4px 0px 0px' }}>
                          <span>Jan:</span>
                        </td>
                        <td style={{ padding: '0px 10px 0px 0px' }}>{item.monitor_child_events_month_01 > item.monitor_child_events_expected ? <span style={{ color: '#198754' }}>{item.monitor_child_events_month_01}</span> : item.monitor_child_events_month_01 < item.monitor_child_events_expected ? <span style={{ color: '#871925', fontWeight: 'bold' }}>{item.monitor_child_events_month_01}</span> : <span>{item.monitor_child_events_month_01}</span>}</td>
                        <td style={{ padding: '0px 4px 0px 0px' }}>
                          <span>Feb</span>
                        </td>
                        <td style={{ padding: '0px 10px 0px 0px' }}>{item.monitor_child_events_month_02 > item.monitor_child_events_expected ? <span style={{ color: '#198754' }}>{item.monitor_child_events_month_02}</span> : item.monitor_child_events_month_02 < item.monitor_child_events_expected ? <span style={{ color: '#871925', fontWeight: 'bold' }}>{item.monitor_child_events_month_02}</span> : <span>{item.monitor_child_events_month_02}</span>}</td>
                        <td style={{ padding: '0px 4px 0px 0px' }}>
                          <span>Mar:</span>
                        </td>
                        <td style={{ padding: '0px 10px 0px 0px' }}>{item.monitor_child_events_month_03 > item.monitor_child_events_expected ? <span style={{ color: '#198754' }}>{item.monitor_child_events_month_03}</span> : item.monitor_child_events_month_03 < item.monitor_child_events_expected ? <span style={{ color: '#871925', fontWeight: 'bold' }}>{item.monitor_child_events_month_03}</span> : <span>{item.monitor_child_events_month_03}</span>}</td>
                        <td style={{ padding: '0px 4px 0px 0px' }}>
                          <span>Apr:</span>
                        </td>
                        <td style={{ padding: '0px 10px 0px 0px' }}>{item.monitor_child_events_month_04 > item.monitor_child_events_expected ? <span style={{ color: '#198754' }}>{item.monitor_child_events_month_04}</span> : item.monitor_child_events_month_04 < item.monitor_child_events_expected ? <span style={{ color: '#871925', fontWeight: 'bold' }}>{item.monitor_child_events_month_04}</span> : <span>{item.monitor_child_events_month_04}</span>}</td>
                      </tr>

                      <tr>
                        <td>
                          <span>May:</span>
                        </td>
                        <td>{item.monitor_child_events_month_05 > item.monitor_child_events_expected ? <span style={{ color: '#198754' }}>{item.monitor_child_events_month_05}</span> : item.monitor_child_events_month_05 < item.monitor_child_events_expected ? <span style={{ color: '#871925', fontWeight: 'bold' }}>{item.monitor_child_events_month_05}</span> : <span>{item.monitor_child_events_month_05}</span>}</td>
                        <td>
                          <span>Jun:</span>
                        </td>
                        <td>{item.monitor_child_events_month_06 > item.monitor_child_events_expected ? <span style={{ color: '#198754' }}>{item.monitor_child_events_month_06}</span> : item.monitor_child_events_month_06 < item.monitor_child_events_expected ? <span style={{ color: '#871925', fontWeight: 'bold' }}>{item.monitor_child_events_month_06}</span> : <span>{item.monitor_child_events_month_06}</span>}</td>
                        <td>
                          <span>Jul:</span>
                        </td>
                        <td>{item.monitor_child_events_month_07 > item.monitor_child_events_expected ? <span style={{ color: '#198754' }}>{item.monitor_child_events_month_07}</span> : item.monitor_child_events_month_07 < item.monitor_child_events_expected ? <span style={{ color: '#871925', fontWeight: 'bold' }}>{item.monitor_child_events_month_07}</span> : <span>{item.monitor_child_events_month_07}</span>}</td>
                        <td>
                          <span>Aug:</span>
                        </td>
                        <td>{item.monitor_child_events_month_08 > item.monitor_child_events_expected ? <span style={{ color: '#198754' }}>{item.monitor_child_events_month_08}</span> : item.monitor_child_events_month_08 < item.monitor_child_events_expected ? <span style={{ color: '#871925', fontWeight: 'bold' }}>{item.monitor_child_events_month_08}</span> : <span>{item.monitor_child_events_month_08}</span>}</td>
                      </tr>

                      <tr>
                        <td>
                          <span>Sep:</span>
                        </td>
                        <td>{item.monitor_child_events_month_09 > item.monitor_child_events_expected ? <span style={{ color: '#198754' }}>{item.monitor_child_events_month_09}</span> : item.monitor_child_events_month_09 < item.monitor_child_events_expected ? <span style={{ color: '#871925', fontWeight: 'bold' }}>{item.monitor_child_events_month_09}</span> : <span>{item.monitor_child_events_month_09}</span>}</td>
                        <td>
                          <span>Oct:</span>
                        </td>
                        <td>{item.monitor_child_events_month_10 > item.monitor_child_events_expected ? <span style={{ color: '#198754' }}>{item.monitor_child_events_month_10}</span> : item.monitor_child_events_month_10 < item.monitor_child_events_expected ? <span style={{ color: '#871925', fontWeight: 'bold' }}>{item.monitor_child_events_month_10}</span> : <span>{item.monitor_child_events_month_10}</span>}</td>
                        <td>
                          <span>Nov:</span>
                        </td>
                        <td>{item.monitor_child_events_month_11 > item.monitor_child_events_expected ? <span style={{ color: '#198754' }}>{item.monitor_child_events_month_11}</span> : item.monitor_child_events_month_11 < item.monitor_child_events_expected ? <span style={{ color: '#871925', fontWeight: 'bold' }}>{item.monitor_child_events_month_11}</span> : <span>{item.monitor_child_events_month_11}</span>}</td>
                        <td>
                          <span>Dec:</span>
                        </td>
                        <td>{item.monitor_child_events_month_12 > item.monitor_child_events_expected ? <span style={{ color: '#198754' }}>{item.monitor_child_events_month_12}</span> : item.monitor_child_events_month_12 < item.monitor_child_events_expected ? <span style={{ color: '#871925', fontWeight: 'bold' }}>{item.monitor_child_events_month_12}</span> : <span>{item.monitor_child_events_month_12}</span>}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <span>
                    <a href={`/monitor/edit_monitor_child/${item.monitor_child_id}`}>
                      <img src="/gfx/icons/20x20/edit_rounded_fill_black_20x20.svg" alt="edit_rounded_fill_black_20x20.svg" />
                    </a>
                    &nbsp;
                    <a href={`/monitor/ping_instructions/${item.monitor_child_parent_id}`}>
                      <img src="/gfx/icons/20x20/quiz_rounded_nofill_black_20x20.svg" alt="quiz_rounded_nofill_black_20x20.svg" />
                    </a>
                    &nbsp;
                    <a href={`/monitor/delete_monitor_child/${item.monitor_child_id}`}>
                      <img src="/gfx/icons/20x20/delete_rounded_nofill_black_20x20.svg" alt="delete_rounded_fill_black_20x20.svg" />
                    </a>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>{' '}
      {/* //main_box */}
    </div>
  );
}

export default ViewMonitorParent;
