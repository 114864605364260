import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

export function ViewSensors() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { customerId } = useParams();

  // Data
  const [customerIndex, setCustomerIndex] = useState({
    customer_id: customerId,
    customer_name: '',
    customer_name_abbreviated: '',
    customer_is_test_customer: '',
    customer_working_language: '',
    customer_restriction: '',
    customer_country_hq: '',
    customer_reason_of_hq_presence: '',
    customer_hq_address: '',
    customer_employee_count: '',
    customer_asset_client_count: '',
    customer_asset_server_count: '',
    customer_asset_total_count: '',
    customer_sector_code: '',
    customer_business_code: '',
    customer_revenue: '',
    customer_operational_status: '',
    customer_operational_operational_date: '',
    customer_operational_operational_date_saying: '',
    customer_decommissioned_date: '',
    customer_decommissioned_date_saying: '',
    customer_former_company_names: '',
    customer_compliance: '',
    customer_service_window_days: '',
    customer_service_window_hours: '',
    customer_response_time_hours: '',
    customer_output: '',
    customer_notes_description: '',
    customer_notes_source: '',
    customer_procedures_description: '',
    customer_procedures_agreed_date: '',
    customer_procedures_added_date: '',
    customer_summary: '',
    customer_business_environment: '',
    customer_business_enablers: '',
    customer_business_threats: '',

    customer_created_timestamp: '',
    customer_created_date_saying: '',
    customer_created_by_user_email: '',
    customer_updated_timestamp: '',
    customer_updated_date_saying: '',
    customer_updated_by_user_email: ''
  });
  const [sensors, setSensors] = useState(() => []);

  // Load data
  const loadCustomerIndex = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get_customer?customer_id=' + customerId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setCustomerIndex(resultJson);
      document.getElementById('show_customer').style.display = 'block';
    }
  };

  // Load sensors
  const loadSensors = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get_sensors_index_for_customer_id?customer_id=' + customerId, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setSensors(resultJson);
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultJson['error'] + ': ' + resultJson['message'];
      document.getElementById('feedback_div').scrollIntoView();
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadCustomerIndex();
      loadSensors();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div id="show_customer" style={{ display: 'none' }}>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/">Customers</a>
          &nbsp; / &nbsp;
          <a href={`/customers/view_customer/${customerId}`}>{customerIndex.customer_name_abbreviated}</a>
          &nbsp; / &nbsp;
          <a href={`/customers/view_identifiers/${customerId}`}>Identifiers</a>
        </p>
      </div>
      <div className="clear"></div>

      {/* Headline and actions */}
      <h1>{customerIndex.customer_name}</h1>

      {/* Customer tabs */}
      <ul className="tabs">
        <li>
          <a href={`/customers/view_customer/${customerId}`}>Index</a>
        </li>
        <li>
          <a href={`/customers/view_business/${customerId}`}>Business</a>
        </li>
        <li>
          <a href={`/customers/view_identifiers/${customerId}`}>Identifiers</a>
        </li>
        <li>
          <a href={`/customers/view_engagements/${customerId}`}>Engagements</a>
        </li>
        <li>
          <a href={`/customers/view_sensors/${customerId}`} className="active">
            Sensors
          </a>
        </li>
      </ul>

      <div className="main_box">
        {/* Actions */}
        <p>
          <a href={`${backendURL}/api/customers/add_customers_to_sensor_queue`} className="btn_default">
            <img src="/gfx/icons/20x20/published_with_changes_rounded_nofill_black_20x20.svg" alt="published_with_changes_rounded_nofill_black_20x20.svg" /> Add customers to sensor queue
          </a>
          <a href={`${backendURL}/api/customers/fetch_sensors_from_lc`} className="btn_default">
            <img src="/gfx/icons/20x20/published_with_changes_rounded_nofill_black_20x20.svg" alt="published_with_changes_rounded_nofill_black_20x20.svg" /> Fetch sensors from LC (for one customer at the time)
          </a>
          <a href={`${backendURL}/api/dao/liquid_base`} className="btn_default">
            <img src="/gfx/icons/20x20/published_with_changes_rounded_nofill_black_20x20.svg" alt="published_with_changes_rounded_nofill_black_20x20.svg" /> Sync liquid base (database changes)
          </a>
          <a href="https://console.cloud.google.com/run/detail/europe-north1/cdc-24-backend/logs?project=advania-cdc-engineering-infra" className="btn_default">
            <img src="/gfx/icons/20x20/description_rounded_nofill_black_20x20.svg" alt="description_rounded_nofill_black_20x20.svg" /> cdc-24-backend log
          </a>
        </p>

        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        <p>The customers sensors will appear here. During development only 11 sensors will appear.</p>

        {/* Table */}
        <table className="hor-zebra">
          <thead>
            <tr>
              <th>
                <span>No</span>
              </th>
              <th>
                <span>Hostname</span>
              </th>
              <th>
                <span>Last Seen</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {sensors.map((item, index) => (
              <tr key={index}>
                <td>
                  <span>{item.sensor_number}</span>
                </td>
                <td>
                  <span>{item.sensor_hostname}</span>
                </td>
                <td>
                  <span>{item.sensor_last_seen_date_saying}</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ViewSensors;
