import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../../useToken.js';
import prodOrDev from '../../../utils.js';

export function APIUsageLogs() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { apiId } = useParams();
  const [api, setAPI] = useState(() => []);
  const [apiLog, setAPILog] = useState(() => []);

  // Load API
  const loadAPI = async (e) => {
    let result = await fetch(backendURL + '/api/apis/get_api', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify({
        api_id: apiId
      })
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setAPI(resultJson);

      // Load API Permissions
      loadAPILog();

      // Display form (last element is loaded)
      document.getElementById('form').style.display = 'block';
    }
  };
  // Load API
  const loadAPILog = async (e) => {
    let result = await fetch(backendURL + '/api/apis/get_api_usage_logs', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify({
        api_id: apiId
      })
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setAPILog(resultJson);

      // Display form (last element is loaded)
      document.getElementById('form').style.display = 'block';
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadAPI();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/">Settings</a>
          &nbsp; / &nbsp;
          <a href={`/settings/apis`}>APIs</a>
          &nbsp; / &nbsp;
          <a href={`/settings/apis/view_api/${api.api_id}`}>{api.api_title}</a>
          &nbsp; / &nbsp;
          <a href={`/settings/apis/api_log/${api.api_id}`}>API Log</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>API Log {api.api_title}</h1>
      <div className="main_box" id="form" style={{ display: 'none' }}>
        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        {/* Table */}
        <table className="hor-zebra">
          <thead>
            <tr>
              <th>
                <span>ID</span>
              </th>
              <th>
                <span>Datetime</span>
              </th>
              <th>
                <span>App</span>
              </th>
              <th>
                <span>Route</span>
              </th>
              <th>
                <span>R/W/D</span>
              </th>
              <th>
                <span>Return</span>
              </th>
              <th>
                <span>IP</span>
              </th>
              <th>
                <span>Hostname</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {apiLog.map((item, index) => (
              <tr key={index}>
                <td>
                  <span>{item.log_id}</span>
                </td>
                <td>
                  <span>{item.log_created_datetime_saying}</span>
                </td>
                <td>
                  <span>{item.log_app}</span>
                </td>
                <td>
                  <span>{item.log_route}</span>
                </td>
                <td>
                  <span>{item.log_read_write_delete}</span>
                </td>
                <td>
                  <span>{item.log_return}</span>
                </td>
                <td>
                  <span>{item.log_ip}</span>
                </td>
                <td>
                  <span>{item.log_hostname}</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default APIUsageLogs;
