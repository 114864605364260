import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

export function DeleteMonitorParent() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { monitorParentId } = useParams();

  const [monitorParent, setMonitorParent] = useState({
    monitor_parent_id: monitorParentId,
    monitor_parent_name: '',
    monitor_parent_description: '',
    monitor_parent_url: '',
    monitor_parent_criticality: '',
    monitor_parent_type: '',
    monitor_parent_weight: '',
    monitor_parent_timeout_value: '',
    monitor_parent_timeout_measuring_unit: '',
    monitor_parent_last_ping_datetime: '',
    monitor_parent_last_ping_datetime_saying: '',
    monitor_parent_current_status_this: '',
    monitor_parent_current_status_children: '',
    monitor_parent_current_status_comment: '',
    monitor_parent_warning_slack_channel: '',
    monitor_parent_warning_email_receivers: '',
    monitor_parent_warning_sent: '',
    monitor_parent_owner_name: '',
    monitor_parent_administrator_name: '',
    monitor_parent_commercial_responsibility_name: '',
    monitor_parent_created_datetime: '',
    monitor_parent_created_date_saying: '',
    monitor_parent_created_user_email: '',
    monitor_parent_updated_datetime: '',
    monitor_parent_updated_date_saying: '',
    monitor_parent_updated_user_email: ''
  });

  // Load Identifier
  const loadMonitorParent = async (e) => {
    let result = await fetch(backendURL + '/api/monitor/get_monitor_parent?monitor_parent_id=' + monitorParentId, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setMonitorParent(resultJson);
      // Display form (last element is loaded)
      document.getElementById('main_box').style.display = 'block';
    }
  };

  // Confirm delete
  async function confirmDelete(e) {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/monitor/delete_monitor_parent', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify({
        monitor_parent_id: monitorParentId
      })
    });
    let resultJson = await result.json();

    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      window.history.back();
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
    }
  }

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadMonitorParent();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/monitor">Monitor</a>
          &nbsp; / &nbsp;
          <a href={`/monitor/view_monitor_parent/${monitorParent.monitor_parent_id}`}>{monitorParent.monitor_parent_name}</a>
          &nbsp; / &nbsp;
          <a href={`/monitor/delete_monitor_parent/${monitorParent.monitor_parent_id}`}>Delete {monitorParent.monitor_parent_name}</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>Delete {monitorParent.monitor_parent_name}</h1>

      <div className="main_box" id="main_box" style={{ display: 'none' }}>
        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        {/* Form */}
        <p>Are you sure you want to delete the monitor parent? The action cannot be undone!</p>
        <p>
          <a href="#delete" className="btn_danger" onClick={confirmDelete}>
            Confirm
          </a>
        </p>
      </div>
    </div>
  );
}

export default DeleteMonitorParent;
