import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../../useToken.js';
import prodOrDev from '../../../utils.js';

export function DeleteTarget() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { targetId } = useParams();

  // Data
  const [target, setTarget] = useState({
    target_id: targetId,
    target_threat_actor_id: '',
    target_sector: '',
    target_country: '',
    target_country_three: '',
    target_country_flag: '',
    target_comment: '',
    target_date: '',
    target_date_saying: '',
    target_created_datetime: '',
    target_created_date: '',
    target_created_date_saying: '',
    target_created_by_user_id: '',
    target_created_by_display_name: '',
    target_updated_date: '',
    target_updated_date_saying: '',
    target_updated_by_user_id: ''
  });
  const [threatActor, setThreatActor] = useState({
    threat_actor_id: '',
    threat_actor_name: '',
    threat_actor_short_description: '',
    threat_actor_first_seen_date: '',
    threat_actor_first_seen_date_saying: '',
    threat_actor_country_of_origin_name: '',
    threat_actor_country_of_origin_flag: '',
    threat_actor_country_of_origin_two: '',
    threat_actor_country_of_origin_three: '',
    threat_actor_country_of_attribution_name: '',
    threat_actor_country_of_attribution_flag: '',
    threat_actor_country_of_attribution_two: '',
    threat_actor_country_of_attribution_three: '',
    threat_actor_description: '',
    threat_actor_motivation: '', 
    threat_actor_capability: '',
    threat_actor_tools: '',
    threat_actor_techniques: '',
    threat_actor_infrastructure: '',
    threat_actor_counter_operations: '',
    threat_actor_sources: '',
    threat_actor_mitre_attck_link_name: '',
    threat_actor_mitre_attck_link_url: '',
    threat_actor_dangerous_number: '',
    threat_actor_dangerous_title: '',
    threat_actor_logo_path: '',
    threat_actor_logo_file: '',
    threat_actor_created_date: '',
    threat_actor_created_date_saying: '',
    threat_actor_created_by_user_id: '',
    threat_actor_created_by_display_name: '',
    threat_actor_updated_date: '',
    threat_actor_updated_date_saying: '',
    threat_actor_updated_by_user_id: '',
    threat_actor_updated_by_display_name: '',
    threat_actor_alternative_names: [],
    threat_actor_operations_performed: []
  });


  // Load data
  const loadTarget = async (e) => {
    let result = await fetch(backendURL + '/api/threat-actors/get-target?target_id=' + targetId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setTarget(resultJson);
      document.getElementById('main_box').style.display = 'block';

      // Load threat actor
      target.target_threat_actor_id = resultJson['target_threat_actor_id'];
      loadThreatActor();
    }
  };
  const loadThreatActor = async (e) => {
    let result = await fetch(backendURL + '/api/threat-actors/get-threat-actor?threat_actor_id=' + target.target_threat_actor_id, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setThreatActor(resultJson);
    }
  };

  // Delete
  async function confirmDelete(e) {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/threat-actors/delete-target?target_id=' + targetId, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();

    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      

      // Go to threat actor 
      window.location.href = '/intelligence/threat-actors/view-threat-actor/' + target.target_threat_actor_id;

    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
    }
  }

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadTarget();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
      <p className="you_are_here_content">
          <a href="/intelligence">Intelligence</a>
          &nbsp; / &nbsp;
          <a href="/intelligence/threat-actors">Threat Actors</a>
          &nbsp; / &nbsp;
          <a href={`/intelligence/threat-actors/view-threat-actor/${threatActor.threat_actor_id}`}>{threatActor.threat_actor_name}</a>
          &nbsp; / &nbsp;
          <a href={`/intelligence/threat-actors/view-target/${target.target_id}`}>{target.target_country_three}/{target.target_sector}</a>
          &nbsp; / &nbsp;
          <a href={`/intelligence/threat-actors/delete-target/${target.target_id}`}>Delete</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>Delete {target.target_country_three}/{target.target_sector}</h1>


      {/* Main box */}
      <div className="main_box" id="main_box" style={{ display: 'none' }}>
          {/* Feedback */}
          <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
            <p id="feedback_p">Feedback box is here</p>
          </div>

          {/* Form */}
          <p>Are you sure you want to delete the target? This action cannot be undone!</p>

          <p><a href="#delete" className="btn_danger" onClick={confirmDelete}>Confirm</a></p>
      </div>

    </div>
  );
}

export default DeleteTarget;
