import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../../useToken.js';
import prodOrDev from '../../../utils.js';
import parse from 'html-react-parser';

export function ViewAssessment() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { assessmentId } = useParams();

  // Data
  const [assessment, setAssessment] = useState({
    assessment_id: assessmentId,

    assessment_created_datetime: '',
    assessment_created_date: '',
    assessment_created_date_saying: '',
    assessment_created_by_user_id: '',
    assessment_created_by_first_name: '',
    assessment_created_by_last_name: '',
    assessment_created_by_display_name: '',
    assessment_created_by_email: '',
    
    assessment_alternative_title_value: '',
    assessment_alternative_title_color: '',
    assessment_title: '',
    
    assessment_introduction: '',
    assessment_intro_image_path: '',
    assessment_intro_image_file: '',
    assessment_intro_image_url: '',
    assessment_intro_image_text: '',
    assessment_text: '',
    assessment_summary: '',
    assessment_tlp: '',
    assessment_references: '',
    assessment_tag_a: '',
    assessment_tag_b: '',
    assessment_tag_c: '',
    assessment_state: '',
    assessment_customer_id: '',
    assessment_customer_number: '',
    assessment_customers_tag: '',
    assessment_unique_clicks: '',
    assessment_unique_clicks_ipblock: '',
    assessment_unique_hits: '',
    assessment_unique_hits_ipblock: ''
  });
  const [assessmentCreatedDate, setAssessmentCreatedDate] = useState('');
  const [customers, setCustomers] = useState(() => []);

  // Fomat Date
  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }
  // Load data
  const loadAssesment = async (e) => {
    let result = await fetch(backendURL + '/api/intelligence/get-intelligence-assessment-for-id?assessment_id=' + assessmentId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      //console.log("EditAssessment() :: loadAssesment() :: Assessment loaded! " + JSON.stringify(resultJson, null, 2) )
      setAssessment(resultJson);

      // setAssessmentCreatedDate
      if (resultJson['assessment_created_date'] != '') {
        const dateFormated = formatDate(resultJson['assessment_created_date']);
        assessment.assessment_created_date = dateFormated;
        // console.log("EditAssessment() :: loadAssesment() :: assessment_created_date=" + dateFormated)

        setAssessmentCreatedDate(dateFormated);
      }

      // Intro image
      if (resultJson['assessment_intro_image_file'] !== '' && resultJson['assessment_intro_image_file'] !== null) {
        console.log("EditAssessment() :: loadAssesment() :: assessment_intro_image_file=" + resultJson['assessment_intro_image_file']);
        const imageId = 'assessment_intro_image_file_img';
        const imageUrl = backendURL + '/api/intelligence/get-intelligence-assessment-intro-image?assessment_id=' + assessmentId;
        const authToken = token;
        displayProtectedImage(imageId, imageUrl, authToken);
      }

      // Customers
      loadCustomers();
    }
  };

  // Load intro image
  function fetchWithAuthentication(url, authToken) {
    const headers = new Headers();
    headers.set('Authorization', `Bearer ${authToken}`);
    return fetch(url, { headers });
  }
  async function displayProtectedImage(imageId, imageUrl, authToken) {
    // Fetch the image.
    const response = await fetchWithAuthentication(imageUrl, authToken);
  
    // Create an object URL from the data.
    const blob = await response.blob();
    const objectUrl = URL.createObjectURL(blob);
  
    // Update the source of the image.
    const imageElement = document.getElementById(imageId);
    imageElement.src = objectUrl;
  }

  // Load customers
  const loadCustomers  = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get_customers', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setCustomers(resultJson);
    }
  };



  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadAssesment();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/intelligence">Intelligence</a>
          &nbsp; / &nbsp;
          <a href="/intelligence/assessments">Assessments</a>
          &nbsp; / &nbsp;
          <a href={`/intelligence/assessments/view-assessment/${assessmentId}`}>{assessment.assessment_title}</a>
        </p>
      </div>
      <div className="clear"></div>
      {/* Main Left */}
      <div className="main_box" id="main_box">

        {/* Actions */ }

        <div style={{ float: 'right'}}>
          <p>
          <a href={`/intelligence/assessments/edit-assessment/${assessment.assessment_id}`}><img src="/gfx/icons/20x20/edit_rounded_fill_black_20x20.svg" alt="edit_rounded_fill_black_20x20.svg" /></a>
          &nbsp;
          <a href={`/intelligence/assessments/edit-assessment-image/${assessment.assessment_id}`}><img src="/gfx/icons/20x20/imagesmode_outlined_black_20x20.svg" alt="imagesmode_outlined_black_20x20.svg" /></a>
          &nbsp;
          <a href={`/intelligence/assessments/delete-assessment/${assessment.assessment_id}`}><img src="/gfx/icons/20x20/delete_rounded_fill_black_20x20.svg" alt="edit_rounded_fill_black_20x20.svg" /></a>
          </p>
        </div>


          {/* Image */}
          {assessment.assessment_intro_image_file ? (
            <p style={{color: "darkgrey"}}>
            <img id="assessment_intro_image_file_img" src="" alt={`${assessment.assessment_intro_image_file}`} /><br />
            {assessment.assessment_intro_image_text}
            </p>
          ) : (
            <span></span>
          )}
          
          
          {/* TLP */}
          <div style={{ float: 'right', paddingLeft: '10px' }}>
            <p>
            {assessment.assessment_tlp === 'CLEAR' || assessment.assessment_tlp === 'TLP:CLEAR' ? <span className="tlp_clear">{assessment.assessment_tlp}</span> : assessment.assessment_tlp === 'GREEN' || assessment.assessment_tlp === 'TLP:GREEN' ? <span className="tlp_green">{assessment.assessment_tlp}</span> : assessment.assessment_tlp === 'AMBER' || assessment.assessment_tlp === 'TLP:AMBER' ? <span className="tlp_amber">{assessment.assessment_tlp}</span> : assessment.assessment_tlp === 'AMBER+STRICT' || assessment.assessment_tlp === 'TLP:AMBER+STRICT' ? <span className="tlp_amber">{assessment.assessment_tlp}</span> : assessment.assessment_tlp === 'RED' || assessment.assessment_tlp === 'TLP:RED' ? <span className="tlp_red">{assessment.assessment_tlp}</span> : <span>?{assessment.assessment_tlp}?</span>}
            </p>
          </div>
          

          {/* Headline */}
          {assessment.assessment_alternative_title_value === '' ? (
            <h1 style={{paddingLeft: "0"}}>{assessment.assessment_title}</h1>
          ) : (
            <h1 style={{paddingLeft: "0"}}>{assessment.assessment_alternative_title_value} {assessment.assessment_title}</h1>
          )}

          {/* Intro */}
          {typeof assessment.assessment_introduction == 'string' ? (
            <div>{parse(assessment?.assessment_introduction)}</div>
          ) : (
            <div className="warning_smal"><p>Missing introduction!</p></div>
          )}

          {/* Date */}
          <span>{assessment.assessment_created_date_saying} &middot; {assessment.assessment_created_by_display_name}</span>
          
          {/* Summary */}
          {typeof assessment.assessment_summary == 'string' ? (
            <div style={{background: "#eef5ff", margin: "10px 0px 10px 0px", padding: "10px 10px 10px 10px"}}>
              <h2 style={{padding: "10px 0px 0px 0px", marginBottom: "0"}}>Summary</h2>
              {parse(assessment.assessment_summary)}
            </div>
          ) : (
            <div className="warning_smal"><p>Missing summary!</p></div>
          )}

          {/* Text */}
          {parse(assessment.assessment_text)}

          {/* References */}
          {assessment.assessment_references !== '' ? (
            <div>
              <h2 style={{padding: "10px 0px 0px 0px", marginBottom: "0"}}>References</h2>
              {parse(assessment.assessment_references)}
            </div>
          ) : (
            <div></div>
          )}

          
          
      </div>
      {/* // main_box */}
    </div>
  );
}

export default ViewAssessment;
