import { useContext, useEffect, useState } from 'react';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

export function CustomersIndex() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const [customers, setCustomers] = useState(() => []);
  const [originalCustomers, setOriginalCustomers] = useState([]);
  const [sortAscending, setSortAscending] = useState(false);

  // Load data
  const loadData = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get_customers', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const resultJson = await result.json();
    if (result.status === 200) {
      setCustomers(
        resultJson.map((fetch_object) => {
          return fetch_object;
        })
      );
      setOriginalCustomers(resultJson); // Store the original data
    }
  };

  function handleSortByDate() {
    const filteredCustomers = customers.filter((customer) => customer.customer_operational_status === 'Operational');

    const sortedCustomers = [...filteredCustomers].sort((a, b) => {
      const dateA = parseDate(a.customer_operational_operational_date_saying);
      const dateB = parseDate(b.customer_operational_operational_date_saying);
      if (sortAscending) {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });

    const nonOperationalCustomers = customers.filter((customer) => customer.customer_operational_status !== 'Operational');

    const combinedCustomers = [...sortedCustomers, ...nonOperationalCustomers];
    setCustomers(combinedCustomers);
    setSortAscending(!sortAscending);
  }

  function parseDate(dateString) {
    const parts = dateString.split('.');
    if (parts.length === 3) {
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1; // Month is zero-based
      const year = parseInt(parts[2], 10);
      return new Date(year, month, day);
    }
    return new Date(0); // Return a default date if parsing fails
  }

  function handleResetData() {
    setCustomers(originalCustomers);
    setSortAscending(true); // Reset sorting order to ascending
  }

  function handleChangeFilter(e) {
    // Declare variables
    var table, tr, i, j, colValue, position, th;
    const targ = e.target;
    console.log('Initial Filter is ' + targ.value);
    const all_inputs = document.getElementsByTagName('input');
    filterFunc(targ, true);
    for (i = 0; i < all_inputs.length; i++) {
      filterFunc(all_inputs[i], false);
    }
  }

  function filterFunc(targ, initial) {
    var input, filter, table, tr, td, i, txtValue, colValue, position, th;
    position = 0;
    const inputColVal = targ.id.replace(' Search', '');
    table = document.getElementById('cust_table');
    tr = table.getElementsByTagName('tr');

    th = tr[0].getElementsByTagName('th');
    for (i = 0; i < th.length; i++) {
      colValue = th[i].textContent || th[i].innerText;

      if (inputColVal === colValue) {
        position = i;
      }
    }
    input = targ.value;

    if (input != null) {
      filter = input.toUpperCase();
      table = document.getElementById('cust_table');
      tr = table.getElementsByTagName('tr');
      th = tr[0].getElementsByTagName('th');

      // Loop through all table rows, and hide those who don't match the search query
      for (i = 0; i < tr.length; i++) {
        td = tr[i].getElementsByTagName('td')[position];
        if (td) {
          txtValue = td.textContent || td.innerText;

          if (initial || tr[i].style.display != 'none') {
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
              tr[i].style.display = '';
            } else {
              tr[i].style.display = 'none';
            }
          }
        }
      }
    }
  }

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadData();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      <h1>Customers</h1>
      <div className="main_box">
        {/* Actions */}
        <p>
          <a href="/customers/new_customer" className="btn_default">
            New customer
          </a>
          <a href="/customers/copy_emails" className="btn_default">
            <img src="/gfx/icons/20x20/assignment_add_rounded_nofill_black_20x20.svg" alt="assignment_add_rounded_nofill_black_20x20.svg" /> Copy ... emails
          </a>
          <a href="/customers/contacts_index" className="btn_default">
            <img src="/gfx/icons/20x20/contacts_rounded_nofill_black_20x20.svg" alt="contacts_rounded_nofill_black_20x20.svg" /> Contacts index
          </a>
        </p>

        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        {/* Table */}
        <table id="cust_table" className="hor-zebra">
          <thead>
            <tr>
              <th>
                <span>Name</span>
              </th>
              <th>
                <span>Abbreviation</span>
              </th>
              <th>
                <span>Country HQ</span>
              </th>
              <th>
                <span>Service Window</span>
              </th>
              <th>
                <span>Status</span>
              </th>
              <th>
                <span>Operational Since</span>
              </th>
              <th>
                <span>
                  <abbr title="Security Intrusion Detection and Response">SIDR</abbr> Sensors
                </span>
              </th>
              <th>
                <span>
                  <abbr title="Vulnerability Management">VM</abbr> Assets
                </span>
              </th>
              <th>
                <span>Actions</span>
              </th>
            </tr>
            <tr>
              <th className="th_sub_headline">
                <span>
                  <input type="input" className="btn_default" id="Name Search" onChange={handleChangeFilter} />
                </span>
              </th>
              <th className="th_sub_headline">
                <span>
                  <input type="input" className="btn_default" id="Abbreviation Search" style={{ width: '70px' }} onChange={handleChangeFilter} />
                </span>
              </th>
              <th className="th_sub_headline">
                <span>
                  <input type="input" className="btn_default" id="Country HQ Search" style={{ width: '70px' }} onChange={handleChangeFilter} />
                </span>
              </th>
              <th className="th_sub_headline">
                <span>
                  <input type="input" className="btn_default" id="Service Window Search" onChange={handleChangeFilter} />
                </span>
              </th>
              <th className="th_sub_headline">
                <span>
                  <input type="input" className="btn_default" id="Status Search" style={{ width: '100px' }} onChange={handleChangeFilter} />
                </span>
              </th>
              <th className="th_sub_headline">
                <span>
                  <input type="input" className="btn_default" id="Status Date Search" style={{ width: '100px' }} onChange={handleChangeFilter} />
                </span>
                <span>
                  <button type="button" className="btn" id="Status date sort" title="Sort" onClick={handleSortByDate}>
                    {sortAscending ? '↓' : '↑'}
                  </button>
                </span>
                <span>
                  <button type="button" className="btn" id="Reset Data" onClick={handleResetData}>
                    Reset
                  </button>
                </span>
              </th>

              <th className="th_sub_headline">
                <span>
                  <input type="input" className="btn_default" id="SIDR Sensors Search" style={{ width: '40px' }} onChange={handleChangeFilter} />
                </span>
              </th>
              <th className="th_sub_headline">
                <span>
                  <input type="input" className="btn_default" id="VM Assets Search" style={{ width: '40px' }} onChange={handleChangeFilter} />
                </span>
              </th>
              <th className="th_sub_headline"></th>
            </tr>
          </thead>
          <tbody>
            {customers.map((item, index) => (
              <tr key={index}>
                <td>
                  <span>
                    <a href={`/customers/view_customer/${item.customer_id}`}>{item.customer_name}</a>
                  </span>
                </td>
                <td>
                  <span>{item.customer_name_abbreviated}</span>
                </td>
                <td>
                  <span style={{ float: 'left' }}>
                    {item.customer_country_hq ? (
                      <img src={`/gfx/languages/20x20/${item.customer_country_hq}_20x20.svg`} alt={`${item.customer_country_hq}_20x20.svg`} width="20" height="20" />
                    ) : (
                      // Display a placeholder image when country hq is not set
                      <img src="/gfx/languages/20x20/unknown_20x20.svg" alt="Missing customer HQ country" width="20" height="20" />
                    )}
                  </span>
                  <span>&nbsp; {item.customer_country_hq}</span>
                </td>
                <td>
                  {item.customer_service_window_days === 'Mon-Fri' || item.customer_service_window_days === 'MON-FRI' ? (
                    <span className="tag_blue">
                      {item.customer_service_window_days} {item.customer_service_window_hours}
                    </span>
                  ) : item.customer_service_window_days === 'Mon-Sun' || item.customer_service_window_days === 'MON-SUN' ? (
                    <span className="tag_amber">
                      {item.customer_service_window_days} {item.customer_service_window_hours}
                    </span>
                  ) : (
                    <span>None</span>
                  )}
                </td>
                <td>
                  <span className={`operational_status_${item.customer_operational_status}`}>{item.customer_operational_status}</span>
                </td>
                <td>
                  {item.customer_operational_status === 'Operational' && <span className={'operational_date_saying'}>{item.customer_operational_operational_date_saying}</span>}
                </td>
                <td>
                  <span>{item.customer_asset_sensor_count_auto}</span>
                </td>
                <td>
                  <span>{item.customer_asset_vm_count_auto}</span>
                </td>
                <td>
                  <span>
                    <a href={`/customers/edit_customer/${item.customer_id}`}>
                      <img src="/gfx/icons/20x20/edit_rounded_fill_black_20x20.svg" alt="edit_rounded_fill_black_20x20.svg" />
                    </a>
                    &nbsp;
                    <a href={`/customers/delete_customer/${item.customer_id}`}>
                      <img src="/gfx/icons/20x20/delete_rounded_nofill_black_20x20.svg" alt="delete_rounded_fill_black_20x20.svg" />
                    </a>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CustomersIndex;
