import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../../useToken.js';
import prodOrDev from '../../../utils.js';
import parse from 'html-react-parser';

export function ViewOperationPerformed() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { performedId } = useParams();

  // Data
  const [operationPerformed, setOperationPerformed] = useState({
    performed_id: performedId,
    performed_threat_actor_id: '',
    performed_title: '',
    performed_short_description: '',
    performed_handled_by_us: '',
    performed_reported_by_others: '',
    performed_created_date: '',
    performed_created_date_saying: '',
    performed_created_by_user_id: '',
    performed_created_by_display_name: '',
    performed_updated_date: '',
    performed_updated_date_saying: '',
    performed_updated_by_user_id: '',
    performed_updated_by_display_name: '',
  });
  const [threatActor, setThreatActor] = useState({
    threat_actor_id: '',
    threat_actor_name: '',
    threat_actor_short_description: '',
    threat_actor_first_seen_date: '',
    threat_actor_first_seen_date_saying: '',
    threat_actor_country_of_origin_name: '',
    threat_actor_country_of_origin_flag: '',
    threat_actor_country_of_origin_two: '',
    threat_actor_country_of_origin_three: '',
    threat_actor_country_of_attribution_name: '',
    threat_actor_country_of_attribution_flag: '',
    threat_actor_country_of_attribution_two: '',
    threat_actor_country_of_attribution_three: '',
    threat_actor_description: '',
    threat_actor_motivation: '', 
    threat_actor_capability: '',
    threat_actor_tools: '',
    threat_actor_techniques: '',
    threat_actor_infrastructure: '',
    threat_actor_counter_operations: '',
    threat_actor_sources: '',
    threat_actor_mitre_attck_link_name: '',
    threat_actor_mitre_attck_link_url: '',
    threat_actor_dangerous_number: '',
    threat_actor_dangerous_title: '',
    threat_actor_logo_path: '',
    threat_actor_logo_file: '',
    threat_actor_created_date: '',
    threat_actor_created_date_saying: '',
    threat_actor_created_by_user_id: '',
    threat_actor_created_by_display_name: '',
    threat_actor_updated_date: '',
    threat_actor_updated_date_saying: '',
    threat_actor_updated_by_user_id: '',
    threat_actor_updated_by_display_name: '',
    threat_actor_alternative_names: [],
    threat_actor_operations_performed: []
  });

  // Load data
  const loadOperationPerformed = async (e) => {
    let result = await fetch(backendURL + '/api/threat-actors/get-operation-performed?performed_id=' + performedId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setOperationPerformed(resultJson);
      document.getElementById('main_box').style.display = 'block';

      // Load threat actor
      operationPerformed.performed_threat_actor_id = resultJson['performed_threat_actor_id'];
      loadThreatActor();
    }
  };
  const loadThreatActor = async (e) => {
    console.log("operationPerformed.performed_threat_actor_id=" + operationPerformed.performed_threat_actor_id)
    let result = await fetch(backendURL + '/api/threat-actors/get-threat-actor?threat_actor_id=' + operationPerformed.performed_threat_actor_id, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setThreatActor(resultJson);
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadOperationPerformed();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/intelligence">Intelligence</a>
          &nbsp; / &nbsp;
          <a href="/intelligence/threat-actors">Threat Actors</a>
          &nbsp; / &nbsp;
          <a href={`/intelligence/threat-actors/view-threat-actor/${threatActor.threat_actor_id}`}>{threatActor.threat_actor_name}</a>
          &nbsp; / &nbsp;
          <a href={`/intelligence/threat-actors/view-operation-performed/${operationPerformed.performed_id}`}>{operationPerformed.performed_title}</a>
        </p>
      </div>
      <div className="clear"></div>

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
        <h1>{operationPerformed.performed_title} {operationPerformed.performed_created_date_saying}</h1>
        <h1 style={{ background: '#000000', color: '#ffc000',fontWeight: 'bold', marginLeft: 'auto', paddingRight: '0.7rem' }}>TLP: AMBER+STRICT </h1>
      </div>

      {/* Main box */}
      <div className="main_box" id="main_box" style={{ display: 'none' }}>
        <p>
        <a href={`/intelligence/threat-actors/edit-operation-performed/${operationPerformed.performed_id}`} className='btn_default'><img src={'/gfx/icons/20x20/edit_rounded_nofill_black_20x20.svg'} alt="edit_rounded_nofill_black_20x20.svg" /> Edit</a>
        &nbsp;
        <a href={`/intelligence/threat-actors/delete-operation-performed/${operationPerformed.performed_id}`} className='btn_default'><img src={'/gfx/icons/20x20/delete_rounded_nofill_black_20x20.svg'} alt="delete_rounded_nofill_black_20x20.svg" /> Delete</a>
        </p>

        {operationPerformed.performed_short_description}

        <h2 style={{padding: "15px 0px 0px 0px"}}>Handled by us</h2>
        <div>{parse(operationPerformed?.performed_handled_by_us)}</div>

        <h2 style={{padding: "15px 0px 0px 0px"}}>Reported by others</h2>
        <div>{parse(operationPerformed?.performed_reported_by_others)}</div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
        <h1 style={{ background: '#000000', color: '#ffc000',fontWeight: 'bold', marginLeft: 'auto', paddingRight: '0.7rem' }}>TLP: AMBER+STRICT </h1>
      </div>

    </div>
  );
}

export default ViewOperationPerformed;
