import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

export function EditMonthlyReports() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { year, month } = useParams();

  // Data
  const [monthlyReport, setMonthlyReport] = useState({
    reports_month: month,
    reports_month_int: '',
    reports_year: year,
    reports_generated: '',
    reports_generated_date: '',
    reports_generated_how_many: '',
    reports_generated_left: '',

    reports_email_internally: '',
    reports_email_internally_date: '',
    reports_email_internally_how_many: '',
    reports_email_internally_left: '',

    reports_email_externally: '',
    reports_email_externally_date: '',
    reports_email_externally_how_many: '',
    reports_email_externally_left: ''
  });

  // Load data
  const loadData = async (e) => {
    let result = await fetch(backendURL + '/api/monthly_reports/get_monthly_report?month=' + month + '&year=' + year, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setMonthlyReport(resultJson);
      document.getElementById('main_box').style.display = 'block';
    }
  };

  // Form data chaned
  const handleChange = (e) => {
    setMonthlyReport({
      ...monthlyReport,
      [e.target.name]: e.target.value
    });
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadData();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/monthly-reports">Monthly Reports</a>
          &nbsp; / &nbsp;
          <a href={`/monthly-reports/edit-monthly-report/${month}/${year}`}>{month}/{year}</a>
        </p>
      </div>
      <div className="clear"></div>
      {/* Main Left */}
      <h1>
        Edit Monthly Report {month}/{year}
      </h1>
      <div className="main_box" id="main_box" style={{ display: 'none' }}>

          {/* Feedback */}
          <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
            <p id="feedback_p">Feedback box is here</p>
          </div>

          {/* Generated */}
          <h2 style={{paddingLeft: "0"}}>Generated</h2>
          <table>
            <tr>
              <td style={{padding: "0px 5px 2px 0px"}}>
                <span><b>Generated:</b></span>
              </td>
              <td  style={{padding: "0px 0px 2px 0px"}}>
                <span>{monthlyReport.reports_generated}</span>
              </td>
            </tr>
            
            <tr>
              <td style={{padding: "0px 5px 2px 0px"}}>
                <span><b>Generated date:</b></span>
              </td>
              <td  style={{padding: "0px 0px 2px 0px"}}>
                <span>{monthlyReport.reports_generated_date}</span>
              </td>
            </tr>

            
            <tr>
              <td style={{padding: "0px 5px 2px 0px"}}>
                <span><b>Generated how many:</b></span>
              </td>
              <td  style={{padding: "0px 0px 2px 0px"}}>
                <span>{monthlyReport.reports_generated_how_many}</span>
              </td>
            </tr>

            
            <tr>
              <td style={{padding: "0px 5px 2px 0px"}}>
                <span><b>Generated left:</b></span>
              </td>
              <td  style={{padding: "0px 0px 2px 0px"}}>
                <span>{monthlyReport.reports_generated_left}</span>
              </td>
            </tr>

            
            <tr>
              <td style={{padding: "0px 5px 2px 0px"}}>
                <span><b>Bucket:</b></span>
              </td>
              <td  style={{padding: "0px 0px 2px 0px"}}>
                <span><a href={`https://console.cloud.google.com/storage/browser/monthly-customer-reports/prod/${monthlyReport.reports_year}/${monthlyReport.reports_month_int}?pageState=(%22StorageObjectListTable%22:(%22f%22:%22%255B%255D%22))&project=advania-cdc-engineering-infra&prefix=&forceOnObjectsSortingFiltering=false`}>https://console.cloud.google.com/storage/browser/monthly-customer-reports/prod/{monthlyReport.reports_year}/{monthlyReport.reports_month_int}</a></span>
              </td>
            </tr>

          </table>
          <p><a href={`/monthly-reports/edit-monthly-reports/${year}/${month}/regenerate-reports`} className='btn_default'>Regenerate reports</a></p>

          <p><hr /></p>

          {/* Emailed internally */}
          <h2 style={{paddingLeft: "0"}}>Emailed internally</h2>
          <table>
            <tr>
              <td style={{padding: "0px 5px 2px 0px"}}>
                <span><b>Emailed internally:</b></span>
              </td>
              <td  style={{padding: "0px 0px 2px 0px"}}>
                <span>{monthlyReport.reports_email_internally}</span>
              </td>
            </tr>
            
            <tr>
              <td style={{padding: "0px 5px 2px 0px"}}>
                <span><b>Emailed internally date:</b></span>
              </td>
              <td  style={{padding: "0px 0px 2px 0px"}}>
                <span>{monthlyReport.reports_email_internally_date}</span>
              </td>
            </tr>

            
            <tr>
              <td style={{padding: "0px 5px 2px 0px"}}>
                <span><b>Emailed internally how many:</b></span>
              </td>
              <td  style={{padding: "0px 0px 2px 0px"}}>
                <span>{monthlyReport.reports_email_internally_how_many}</span>
              </td>
            </tr>

            
            <tr>
              <td style={{padding: "0px 5px 2px 0px"}}>
                <span><b>Emailed internally left:</b></span>
              </td>
              <td  style={{padding: "0px 0px 2px 0px"}}>
                <span>{monthlyReport.reports_email_internally_left}</span>
              </td>
            </tr>

          </table>
          <p><a href={`/monthly-reports/edit-monthly-reports/${year}/${month}/resend-reports-internally`} className='btn_default'>Resend internally</a></p>



          <p><hr /></p>

          {/* Emailed externally */}
          <h2 style={{paddingLeft: "0"}}>Emailed externally</h2>
          <table>
            <tr>
              <td style={{padding: "0px 5px 2px 0px"}}>
                <span><b>Emailed externally:</b></span>
              </td>
              <td  style={{padding: "0px 0px 2px 0px"}}>
                <span>{monthlyReport.reports_email_externally}</span>
              </td>
            </tr>
            
            <tr>
              <td style={{padding: "0px 5px 2px 0px"}}>
                <span><b>Emailed externally date:</b></span>
              </td>
              <td  style={{padding: "0px 0px 2px 0px"}}>
                <span>{monthlyReport.reports_email_externally_date}</span>
              </td>
            </tr>

            
            <tr>
              <td style={{padding: "0px 5px 2px 0px"}}>
                <span><b>Emailed externally how many:</b></span>
              </td>
              <td  style={{padding: "0px 0px 2px 0px"}}>
                <span>{monthlyReport.reports_email_externally_how_many}</span>
              </td>
            </tr>

            
            <tr>
              <td style={{padding: "0px 5px 2px 0px"}}>
                <span><b>Emailed externally left:</b></span>
              </td>
              <td  style={{padding: "0px 0px 2px 0px"}}>
                <span>{monthlyReport.reports_email_externally_left}</span>
              </td>
            </tr>

          </table>
          <p><a href={`/monthly-reports/edit-monthly-reports/${year}/${month}/resend-reports-externally`} className='btn_warning'>Resend externally</a></p>

      </div>{' '}
      {/* // main_box */}
    </div>
  );
}

export default EditMonthlyReports;
