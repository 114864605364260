import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

export function NewOperatingCountry() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { customerId } = useParams();

  const [operatingCountry, setOperatingCountry] = useState({
    operating_country_id: '',
    operating_country_customer_id: customerId,
    operating_country_country_iso_three: '',
    operating_country_risk_level: '',
    operating_country_reason_of_presence: ''
  });
  const [countries, setCountries] = useState(() => []);
  const [operatingCountryRiskLevels, setOperatingCountryRiskLevels] = useState(() => []);
  const [customerIndex, setCustomerIndex] = useState(() => []);

  // Load customer
  const loadCustomerIndex = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get_customer?customer_id=' + customerId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setCustomerIndex(resultJson);

      // Display form (last element is loaded)
      document.getElementById('form').style.display = 'block';
      // Focus
      document.forms['form'].elements['operating_country_country_iso_three'].focus();
    }
  };

  // Load countries
  const loadCountries = async (e) => {
    let result = await fetch(backendURL + '/api/options/get_countries', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setCountries(resultJson);
    }
  };

  // Load operatingCountryRiskLevels
  const loadOperatingCountryRiskLevels = async (e) => {
    let result = await fetch(backendURL + '/api/options/get_operating_country_risk_levels', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setOperatingCountryRiskLevels(resultJson);
    }
  };
  // Form data chaned
  const handleChange = (e) => {
    setOperatingCountry({
      ...operatingCountry,
      [e.target.name]: e.target.value
    });
  };

  // Send new contact
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/customers/new_operating_country', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(operatingCountry)
    });
    let resultJson = await result.json();
    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadCustomerIndex();
      loadCountries();
      loadOperatingCountryRiskLevels();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/">Customers</a>
          &nbsp; / &nbsp;
          <a href={`/customers/view_customer/${customerId}`}>{customerIndex.customer_name_abbreviated}</a>
          &nbsp; / &nbsp;
          <a href={`/customers/new_url/${customerId}`}>New operating country</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>New Operating Country for {customerIndex.customer_name_abbreviated}</h1>
      <form onSubmit={handleSubmit} id="form" style={{ display: 'none' }}>
        <div className="main_box">
          {/* Feedback */}
          <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
            <p id="feedback_p">Feedback box is here</p>
          </div>

          {/* Form */}

          <p>
            Country
            <br />
            <select name="operating_country_country_iso_three" value={operatingCountry.operating_country_country_iso_three} onChange={handleChange}>
              <option value="N/A">Please select</option>
              {countries.map((item, index) => (
                <option key={index} value={item.country_iso_three}>
                  {item.country_iso_three}
                </option>
              ))}
            </select>
          </p>

          <p>
            Operating country risk level:
            <br />
            <select name="operating_country_risk_level" value={operatingCountry.operating_country_risk_level} onChange={handleChange}>
              <option value="N/A">Please select</option>
              {operatingCountryRiskLevels.map((item, index) => (
                <option key={index} value={item.risk_level_title}>
                  {item.risk_level_title}
                </option>
              ))}
            </select>
          </p>

          <p>
            Reason of precence
            <br />
            <input type="text" name="operating_country_reason_of_presence" value={operatingCountry.operating_country_reason_of_presence} onChange={handleChange} style={{ minWidth: '50%' }} />
          </p>

          <p>
            <button>Submit</button>
          </p>
        </div>
      </form>
    </div>
  );
}

export default NewOperatingCountry;
