import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

export function StatisticsIndexClassificationsPerMonth() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { year } = useParams();
  const [caseClassifications, setCaseClassifications] = useState(() => []);
  const [statsCaseClassifications, setStatsCaseClassifications] = useState(() => []);
  const date = new Date();

  // Load data
  const loadCaseClassifications = async (e) => {
    let result = await fetch(backendURL + '/api/statistics/get_cases_classifications', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const resultJson = await result.json();
    if (result.status === 200) {
      setCaseClassifications(
        resultJson.map((fetch_object) => {
          return fetch_object;
        })
      );
    }
  };
  // Load data
  const loadStatisticsCaseClassifications = async (e) => {
    let result = await fetch(backendURL + '/api/statistics/get_statistics_cases_classifications_all_customers_per_month?year=' + year, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const resultJson = await result.json();
    if (result.status === 200) {
      setStatsCaseClassifications(
        resultJson.map((fetch_object) => {
          return fetch_object;
        })
      );
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadCaseClassifications();
      loadStatisticsCaseClassifications();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      <h1>Statistics</h1>
      <div className="main_box">
        {/* Tabs */}
        <ul className="tabs">
          <li>
            <a href={`/statistics`}>Monthly</a>
          </li>
          <li>
            <a href={`/statistics/statistics_index_case_codes_per_month/${date.getFullYear()}`}>Case codes per month</a>
          </li>
          <li>
            <a href={`/statistics/statistics_index_classifications_per_month/${date.getFullYear()}`} className="active">
              Classifications per month
            </a>
          </li>
        </ul>

        {/* Year selector */}
        <p>
          <a href={`/statistics/statistics_index_classifications_per_month/2025`}>2025</a>
          &middot;
          <a href={`/statistics/statistics_index_classifications_per_month/2024`}>2024</a>
          &middot;
          <a href={`/statistics/statistics_index_classifications_per_month/2023`}>2023</a>
        </p>

        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        {/* Table */}
        <table id="cust_table" className="hor-zebra">
          <thead>
            <tr>
              <th>
                <span>Month</span>
              </th>
              {caseClassifications.map((item, index) => (
                <th key={index} style={{ textAlign: 'center', backgroundColor: item.classification_bg_color }}>
                  <span style={{ color: item.classification_text_color }}>
                    {item.classification_title}
                    <br />({item.classification_color})
                  </span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {statsCaseClassifications.map((item, index) => {
              const data = item['data'];
              return (
                <tr>
                  <td>
                    <span>{item.month}</span>
                  </td>

                  {data.map((item) => (
                    <td style={{ textAlign: 'center' }}>
                      <span>{item.count}</span>
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default StatisticsIndexClassificationsPerMonth;
