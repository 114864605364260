import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

export function ViewCustomerTagsPerCustomer() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { customerId } = useParams();

  // Data
  const [customerIndex, setCustomerIndex] = useState(() => []);
  const [customerTags, setCustomerTags] = useState(() => []);
  const [customerTag, setCustomerTag] = useState({
    tag_id: '',
    tag_customer_id: customerId,
    tag_title: '',
    tag_title_clean: ''
  });

  // Load data
  const loadCustomerIndex = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get_customer?customer_id=' + customerId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setCustomerIndex(resultJson);
      document.getElementById('show_customer').style.display = 'block';
    }
  };

  // Load customer tags
  const loadCustomerTags = async (e) => {
    let result = await fetch(backendURL + '/api/customers/get-customer-tags-per-customer?customer_id=' + customerId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const resultJson = await result.json();
    if (result.status === 200) {
      setCustomerTags(
        resultJson.map((fetch_object) => {
          return fetch_object;
        })
      );
    }
  };

  
  // Form data chaned
  const handleChange = (e) => {
    setCustomerTag({
      ...customerTag,
      [e.target.name]: e.target.value
    });
  };

  // Send new tag
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/customers/new-customer-tag-per-customer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(customerTag)
    });
    let resultJson = await result.json();
    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
      
      loadCustomerTags();
      // window.location.href = '/customers/view-customer-tags/' + customerId + '?ft=success&fm=created_successfully';
    }
    else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    }
  };



  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadCustomerIndex();
      loadCustomerTags();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div id="show_customer" style={{ display: 'none' }}>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/">Customers</a>
          &nbsp; / &nbsp;
          <a href={`/customers/view_customer/${customerId}`}>{customerIndex.customer_name_abbreviated}</a>
          &nbsp; / &nbsp;
          <a href={`/customers/view-customer-tags/${customerId}`}>Tags</a>
        </p>
      </div>
      <div className="clear"></div>

      {/* Headline and actions */}
      <h1>Tags for {customerIndex.customer_name}</h1>

      <div className="main_box">

        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        {/* New tag form */}
        <form onSubmit={handleSubmit} id="form">
          <p>New tag: 
          &nbsp;
          <input type="text" name="tag_title" value={customerTag.tag_title} onChange={handleChange} />
          <button>Submit</button>
          </p>
        </form>


        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        {/* Table */}
        <table className="hor-zebra">
          <thead>
            <tr>
              <th>
                <span>Tag</span>
              </th>
              <th>
                <span>Clean</span>
              </th>
              <th>
                <span>Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {customerTags.map((item, index) => (
              <tr key={index}>
                <td>
                  <span>{item.tag_per_customer_title}</span>
                </td>
                <td>
                  <span>{item.tag_per_customer_title_clean}</span>
                </td>
                <td>
                  <span>
                    {
                      <a href={`/customers/delete-customer-tag-per-customer/${item.tag_per_customer_id}`}><img src="/gfx/icons/20x20/delete_rounded_nofill_black_20x20.svg" alt="delete_rounded_fill_black_20x20.svg" /></a>
                    }
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ViewCustomerTagsPerCustomer;
