import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './Login.css';
import prodOrDev from '../../utils.js';
import { jwtDecode } from "jwt-decode";

export default function Login({ setToken }) {
  // Backend URL
  const backendURL = prodOrDev();
  const google = useRef(window.google);


  // Handle response from Google login
  async function handleCallbackResponse(response) {
    // Change H1
    document.getElementById('login_h1').innerHTML = 'Logging you in...';

    // console.log("Google token: " + response.credential)
    // var userObject = jwt_decode(response.credential)
    // var userEmail = userObject.email;
    // console.log("userEmail=" + userEmail)
    document.getElementById('sign_in_form_google').hidden = true;

    // Call login on backend async
    const backendToken = await loginBackend(response.credential);
    if (!backendToken) {
      document.getElementById('feedback_div').hidden = false;
    } else {
      // Check that response.credential is backendToken
      const loginData = backendToken['data'];
      if (loginData == null) {
        // Change h1
        document.getElementById('login_h1').innerHTML = 'Server Error 403 - Access Denied';
      } else {
        const loginToken = backendToken['data']['token'];
        if (response.credential == loginToken) {
          // Change h1

          var userObject = jwtDecode(response.credential);
          document.getElementById('login_h1').innerHTML = 'Welcome ' + userObject.name;

          // console.log("Backend token: " + backendToken)
          setToken(loginData);
        } else {
          // Change h1
          document.getElementById('login_h1').innerHTML = 'Server Error 401 - Unauthorized';
        }
      }
    }
  }

  // Login to backend
  async function loginBackend(credentials) {
    // console.log("Login.js :: loginBackend():: Logging in with " + credentials)
    var backendToken = fetch(backendURL + '/api/login', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + credentials
      }
    }).then((data) => data.json());
    // console.log("Login.js :: loginBackend() :: Response is " + backendToken)
    return backendToken;
  }

  // Google Login
  useEffect(() => {
    if (google.current === 'undefined') {
      window.location.reload();
    } else {
      /* global google */
      try {
        google.current.accounts.id.initialize({
          client_id: '88091540017-hvq7f5kp0ri9cf9n2pivdsuuvutf9fsk.apps.googleusercontent.com',
          callback: handleCallbackResponse
        });
      } catch (e) {
        setTimeout(function () {
          window.location.reload(1);
        }, 1000);
      }

      google.current.accounts.id.renderButton(document.getElementById('sign_in_button_google_inner'), { theme: 'outline', size: 'large' });

      google.current.accounts.id.prompt();
    }
  }, [google]);

  return (
    <div className="login_body_bg">
      <div className="login_wrapper">
        {/* User login header */}
        <div className="login_header">
          <div className="login_header_left">
            <p>Please Log In</p>
          </div>
          <div className="login_header_right">
            <img src="gfx/login/advania_logo_100x49.png" alt="Advania" />
          </div>
        </div>

        {/* User login main */}
        <div className="login_main">
          <h1 id="login_h1">Use Google to login below</h1>

          {/* Feedback */}
          <div id="feedback_div" className="error_medium" style={{ display: 'none' }}>
            <p className="feedback_p">Login failed</p>
          </div>

          {/* Login form */}
          <div id="sign_in_form_google">
            <p>Please contact Systems Engineering if you got any problems.</p>
            <div className="sign_in_button_google_wrapper">
              <div id="sign_in_button_google_inner"></div>
            </div>
          </div>
        </div>

        {/* User login footer */}
        <div className="login_footer">
          <p>&copy; 2022-2023 Advania</p>
        </div>
      </div>
    </div>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};
