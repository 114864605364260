import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../../useToken.js';
import prodOrDev from '../../../utils.js';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export function EditAssessmentImage() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { assessmentId } = useParams();
  const [file, setFile] = useState();

  // Data
  const [assessment, setAssessment] = useState({
    assessment_id: assessmentId,
    assessment_created_date: '',
    assessment_created_date_saying: '',
    assessment_tlp: '',
    assessment_title: '',
    assessment_text: '',
    assessment_reference: ''
  });

  // Load data
  const loadAssesment = async (e) => {
    let result = await fetch(backendURL + '/api/intelligence/get-intelligence-assessment-for-id?assessment_id=' + assessmentId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setAssessment(resultJson);
      document.getElementById('main_box').style.display = 'block';
    }
  };

  // HandleChange
  function handleChange(event) {
    setFile(event.target.files[0])
  };

  // Send new contact
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Form data
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', file.name);
    formData.append('assessment_id', assessmentId);

    // Post data
    let result = await fetch(backendURL + '/api/intelligence/edit-intelligence-assessment-image', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token
      },
      body: formData
    });

    // Result
    
    let resultJson = await result.json();

    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];

    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      document.getElementById('feedback_div').scrollIntoView();

      // Refresh
      if(resultMessage === "Image uploaded"){
        window.location.href = "/intelligence/assessments/edit-assessment/" + assessmentId; 
      }


    }

  };


  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadAssesment();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/intelligence">Intelligence</a>
          &nbsp; / &nbsp;
          <a href="/intelligence/assessments">Assessments</a>
          &nbsp; / &nbsp;
          <a href={`/intelligence/assessments/edit-assessment/${assessmentId}`}>{assessment.assessment_title}</a>
          &nbsp; / &nbsp;
          <a href={`/intelligence/assessments/edit-assessment-image/${assessmentId}`}>Edit Image</a>
        </p>
      </div>
      <div className="clear"></div>
      {/* Main Left */}
      <h1>Edit Image for {assessment.assessment_title}</h1>
      <div className="main_box" id="main_box" style={{ display: 'none' }}>
        <form onSubmit={handleSubmit} id="form">
          {/* Feedback */}
          <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
            <p id="feedback_p">Feedback box is here</p>
          </div>

          {/* Form */}
          <p>Add image &middot; 1280 x 720 jpg &middot; Will be scaled to 740 x 416 on PDF:<br />
          <input type="file" onChange={handleChange}/>
          </p>

          <p><button type="submit">Submit</button></p>
        </form>
      </div>{' '}
      {/* // main_box */}
    </div>
  );
}

export default EditAssessmentImage;
