import { useContext, useEffect, useState } from 'react';
import useToken from '../../../useToken.js';
import prodOrDev from '../../../utils.js';

export function APIsIndex() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const [apis, setAPIs] = useState(() => []);

  // Load data
  const loadData = async (e) => {
    let result = await fetch(backendURL + '/api/apis/get_apis', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const resultJson = await result.json();
    if (result.status === 200) {
      setAPIs(
        resultJson.map((fetch_object) => {
          return fetch_object;
        })
      );
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadData();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      <h1>APIs</h1>
      <div className="main_box">
        {/* Actions */}
        <p>
          <a href="/settings/apis/new_api" className="btn_default">
            New API
          </a>
        </p>

        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        {/* Table */}
        <table className="hor-zebra">
          <thead>
            <tr>
              <th>
                <span>Title</span>
              </th>
              <th>
                <span>Description</span>
              </th>
              <th>
                <span>Last used datetime</span>
              </th>
              <th>
                <span>Last used by IP</span>
              </th>
              <th>
                <span>Valid to</span>
              </th>
              <th>
                <span>Updated</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {apis.map((item, index) => (
              <tr key={index}>
                <td>
                  <span>
                    <a href={`/settings/apis/view_api/${item.api_id}`}>{item.api_title}</a>
                  </span>
                </td>
                <td>
                  <span>{item.api_description}</span>
                </td>
                <td>
                  <span>{item.api_last_used_datetime_saying}</span>
                </td>
                <td>
                  <span>{item.api_last_used_by_ip}</span>
                </td>
                <td>
                  <span>{item.api_valid_to_date_saying}</span>
                </td>
                <td>
                  <span>
                    {item.api_updated_date_saying}
                    &nbsp; by &nbsp;
                    {item.api_updated_by_user_email}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default APIsIndex;
