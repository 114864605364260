import React, { useEffect, useState } from 'react';
import useToken from '../../../useToken.js';

export default function MyKnownDevices() {
  // Backend URL
  let hostname = window.location.hostname;
  let backendURL = '';
  if (hostname == 'localhost') {
    backendURL = 'https://localhost:8080';
  } else {
    backendURL = 'https://cdc-24-backend-t6qfqcqcha-lz.a.run.app';
  }

  // Get token
  const { token, setToken, userObject } = useToken();
  const [myKnownDevices, setMyKnownDevices] = useState([]);

  // Call API
  // This is the same thing as your componentDitMount
    // Load data
    const loadData = async (e) => {
      let result = await fetch(backendURL + '/api/users/get_my_known_devices', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token
        }
      });
      const resultJson = await result.json();
      if (result.status === 200) {
        setMyKnownDevices(
          resultJson.map((fetch_object) => {
            return fetch_object;
          })
        );
      }
    };

    

  // Confirm delete
  async function handleDeleteClick(e, index, known_device_id) {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/users/delete_known_device?known_device_id=' + known_device_id, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();

    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;

      // Remove from list
      document.getElementById('list_id_' + index).style.display = 'none';
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
    }
  }

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadData();
    }
    return () => {
      ignore = true;
    };
  }, []);
  
  return (
    <div className="vertical">
      <ul>
        {myKnownDevices.map((device, index) => {
          return (
            <li key={index} id={'list_id_' + index}>
              <div className="right" style={{ padding: '10px 10px 0px 0px' }}>
                <a href={'#delete' + device.known_device_id} onClick={(event) => handleDeleteClick(event, index, device.known_device_id)} key={index}>
                  <img src="/gfx/icons/20x20/delete_rounded_nofill_black_20x20.svg" alt="delete_rounded_nofill_black_20x20.png" width="18" height="18" />
                </a>
              </div>

              <p>
                {device.known_device_updated_timestamp_saying}
                <span>&nbsp; &middot; &nbsp;</span>
                {device.known_device_last_ip}
                <span>&nbsp; &middot; &nbsp;</span>
                {device.known_device_user_agent}
              </p>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
