import { useContext, useEffect, useState } from 'react';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

export function MonitorIndex() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const [monitorParents, setMonitorParents] = useState(() => []);

  // Load data
  const loadData = async (e) => {
    let result = await fetch(backendURL + '/api/monitor/get_monitor_parents', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const resultJson = await result.json();
    if (result.status === 200) {
      setMonitorParents(
        resultJson.map((fetch_object) => {
          return fetch_object;
        })
      );
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadData();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/">Monitor</a>
        </p>
      </div>
      <div className="clear"></div>

      <h1>Monitor Index</h1>
      <div className="main_box">
        {/* Actions */}
        <p>
          <a href="/monitor/new_monitor_parent/" className="btn_default">
            New
          </a>
        </p>

        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        {/* Table */}
        <table className="hor-zebra">
          <thead>
            <tr>
              <th colSpan={2}>
                <span>Name</span>
              </th>
              <th>
                <span>Last ping (UTC)</span>
              </th>
              <th>
                <span>Owner</span>
              </th>
              <th>
                <span>Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {monitorParents.map((item, index) => (
              <tr key={index}>
                <td style={{ paddingRight: '0' }}>
                  {/* Status */}
                  {item.monitor_parent_current_status_this === 'Online' ? (
                    <span className="tag_green" title="Online">
                      &#10003;
                    </span>
                  ) : item.monitor_parent_current_status_this === 'Degraded' ? (
                    <span className="tag_amber" titlte="Degraded">
                      &#10071;
                    </span>
                  ) : item.monitor_parent_current_status_this === 'Offline' ? (
                    <span className="tag_red" title="Offline">
                      &#10006;
                    </span>
                  ) : (
                    <span className="tag_light_grey" title="Unknown">
                      ?
                    </span>
                  )}
                </td>
                <td style={{ paddingLeft: '0' }}>
                  {/* Name */}
                  <span>
                    <a href={`/monitor/view_monitor_parent/${item.monitor_parent_id}`}>{item.monitor_parent_name}</a>
                    <br />
                  </span>
                  <span className="small">
                    {item.monitor_parent_description}
                    <br />
                  </span>
                  <span className="small">{item.monitor_parent_type}</span>
                </td>
                <td>
                  {/* Last Ping */}
                  <span>
                    {item.monitor_parent_last_ping_datetime_saying}
                    <br />
                  </span>
                  {item.monitor_parent_current_status_this === 'Online' ? (
                    <span></span>
                  ) : item.monitor_parent_current_status_this === 'Degraded' ? (
                    <span className="tag_amber" titlte="Degraded">
                      {item.monitor_parent_current_status_comment}
                    </span>
                  ) : item.monitor_parent_current_status_this === 'Offline' ? (
                    <span className="tag_red" title="Offline">
                      {item.monitor_parent_current_status_comment}
                    </span>
                  ) : (
                    <span>{item.monitor_parent_current_status_comment}</span>
                  )}
                  <span className="small">
                    {item.monitor_parent_last_ping_message}
                    <br />
                  </span>
                </td>
                <td>
                  <span>{item.monitor_parent_owner_name}</span>
                </td>
                <td>
                  <span>
                    <a href={`/monitor/edit_monitor_parent/${item.monitor_parent_id}`}>
                      <img src="/gfx/icons/20x20/edit_rounded_fill_black_20x20.svg" alt="edit_rounded_fill_black_20x20.svg" />
                    </a>
                    &nbsp;
                    <a href={`/monitor/ping_instructions/${item.monitor_parent_id}`}>
                      <img src="/gfx/icons/20x20/quiz_rounded_nofill_black_20x20.svg" alt="quiz_rounded_nofill_black_20x20.svg" />
                    </a>
                    &nbsp;
                    <a href={`/monitor/generate_new_api_key_for_monitor_parent/${item.monitor_parent_id}`}>
                      <img src="/gfx/icons/20x20/lock_rounded_nofill_black_20x20.svg" alt="lock_rounded_nofill_black_20x20.svg" />
                    </a>
                    &nbsp;
                    <a href={`/monitor/delete_monitor_parent/${item.monitor_parent_id}`}>
                      <img src="/gfx/icons/20x20/delete_rounded_nofill_black_20x20.svg" alt="delete_rounded_fill_black_20x20.svg" />
                    </a>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MonitorIndex;
