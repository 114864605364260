import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

export function ResendReportsExternally() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { year, month } = useParams();

  // Data
  const [monthlyReport, setMonthlyReport] = useState({
    reports_month: month,
    reports_month_int: '',
    reports_year: year,
    reports_generated: '',
    reports_generated_date: '',
    reports_generated_how_many: '',
    reports_generated_left: '',

    reports_email_internally: '',
    reports_email_internally_date: '',
    reports_email_internally_how_many: '',
    reports_email_internally_left: '',

    reports_email_externally: '',
    reports_email_externally_date: '',
    reports_email_externally_how_many: '',
    reports_email_externally_left: '',

    confirm_action: ''
  });

  // Load data
  const loadData = async (e) => {
    console.log("EditMonthlyReport.js :: loadData() :: Loading");
    let result = await fetch(backendURL + '/api/monthly_reports/get_monthly_report?month=' + month + '&year=' + year, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    let resultJson = await result.json();
    if (result.status === 200 || result.status === 201) {
      setMonthlyReport(resultJson);
      document.getElementById('main_box').style.display = 'block';
    }
  };

  // Form data chaned
  const handleChange = (e) => {
    setMonthlyReport({
      ...monthlyReport,
      [e.target.name]: e.target.value
    });
  };

  
  // Handle submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await fetch(backendURL + '/api/monthly_reports/resend_monthly_reports_externally', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(monthlyReport)
    });
    let resultJson = await result.json();
    let resultMessage = resultJson['message'];
    let resulData = resultJson['data'];
    let resultError = resultJson['error'];
    if (result.status === 200 || result.status === 201) {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('success_smal');
      document.getElementById('feedback_p').innerHTML = resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    } else {
      document.getElementById('feedback_div').style.display = 'block';
      document.getElementById('feedback_div').className = '';
      document.getElementById('feedback_div').classList.add('error_smal');
      document.getElementById('feedback_p').innerHTML = resultError + ': ' + resultMessage;
      document.getElementById('feedback_div').scrollIntoView();
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadData();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {/* You are here */}
      <div className="you_are_here_div">
        <p className="you_are_here_content">
          <a href="/monthly-reports">Monthly Reports</a>
          &nbsp; / &nbsp;
          <a href={`/monthly-reports/edit-monthly-report/${month}/${year}`}>{month}/{year}</a>
          &nbsp; / &nbsp;
          <a href={`/monthly-reports/edit-monthly-report/${month}/${year}/resend-reports-externally`}>Resend reports externally</a>
        </p>
      </div>
      <div className="clear"></div>
      {/* Main Left */}
      <h1>Resend reports externally for {month}/{year}</h1>
      <div className="main_box" id="main_box" style={{ display: 'none' }}>

          {/* Feedback */}
          <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
            <p id="feedback_p">Feedback box is here</p>
          </div>

          {/* Generated */}
          <h2>Email externally</h2>
          <table>

          <tr>
              <td style={{padding: "0px 5px 2px 0px"}}>
                <span><b>Emailed externally:</b></span>
              </td>
              <td  style={{padding: "0px 0px 2px 0px"}}>
                <span>{monthlyReport.reports_email_externally}</span>
              </td>
            </tr>
            
            <tr>
              <td style={{padding: "0px 5px 2px 0px"}}>
                <span><b>Emailed externally date:</b></span>
              </td>
              <td  style={{padding: "0px 0px 2px 0px"}}>
                <span>{monthlyReport.reports_email_externally_date}</span>
              </td>
            </tr>

            
            <tr>
              <td style={{padding: "0px 5px 2px 0px"}}>
                <span><b>Emailed externally how many:</b></span>
              </td>
              <td  style={{padding: "0px 0px 2px 0px"}}>
                <span>{monthlyReport.reports_email_externally_how_many}</span>
              </td>
            </tr>

            
            <tr>
              <td style={{padding: "0px 5px 2px 0px"}}>
                <span><b>Emailed externally left:</b></span>
              </td>
              <td  style={{padding: "0px 0px 2px 0px"}}>
                <span>{monthlyReport.reports_email_externally_left}</span>
              </td>
            </tr>
          </table>

          {/* Form */}
          <h2>Form</h2>
          <form onSubmit={handleSubmit} id="form">
          <p>
          This action will resend all reports externally! Use with caution!
          </p>

          <p>
          Are you sure you want to resend reports externally?
          </p>

          <p>
          Enter yes to continue:<br />
          <input type="text" name="confirm_action" value={monthlyReport.confirm_action} onChange={handleChange} />
          </p>

          <p><button>Submit</button></p>

          </form>

      </div>
      {/* // main_box */}
    </div>
  );
}

export default ResendReportsExternally;
