import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken.js';
import prodOrDev from '../../utils.js';

export function StatisticsIndexCaseCodesPerMonth() {
  // Backend URL
  const backendURL = prodOrDev();
  const { token, setToken, userObject } = useToken();
  const { year } = useParams();
  const [caseCodes, setCaseCodes] = useState(() => []);
  const [statsCaseCodes, setStatsCaseCodes] = useState(() => []);
  const date = new Date();

  // Load data
  const loadCaseCodes = async (e) => {
    let result = await fetch(backendURL + '/api/statistics/get_cases_case_codes', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const resultJson = await result.json();
    if (result.status === 200) {
      setCaseCodes(
        resultJson.map((fetch_object) => {
          return fetch_object;
        })
      );
    }
  };
  // Load data
  const loadStatisticsCaseCodes = async (e) => {
    let result = await fetch(backendURL + '/api/statistics/get_statistics_cases_case_codes_all_customers_per_month?year=' + year, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const resultJson = await result.json();
    if (result.status === 200) {
      setStatsCaseCodes(
        resultJson.map((fetch_object) => {
          return fetch_object;
        })
      );
    }
  };

  // On load
  useEffect(() => {
    let ignore = false;

    // Loading
    if (!ignore) {
      loadCaseCodes();
      loadStatisticsCaseCodes();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      <h1>Statistics</h1>
      <div className="main_box">
        {/* Tabs */}
        <ul className="tabs">
          <li>
            <a href={`/statistics`}>Monthly</a>
          </li>
          <li>
            <a href={`/statistics/statistics_index_case_codes_per_month/${date.getFullYear()}`} className="active">
              Case codes per month
            </a>
          </li>
          <li>
            <a href={`/statistics/statistics_index_classifications_per_month/${date.getFullYear()}`}>Classifications per month</a>
          </li>
        </ul>

        {/* Year selector */}
        <p>
          <a href={`/statistics/statistics_index_case_codes_per_month/2025`}>2025</a>
          &middot;
          <a href={`/statistics/statistics_index_case_codes_per_month/2024`}>2024</a>
        </p>

        {/* Feedback */}
        <div id="feedback_div" className="info_smal" style={{ display: 'none' }}>
          <p id="feedback_p">Feedback box is here</p>
        </div>

        {/* Table */}
        <table id="cust_table" className="hor-zebra">
          <thead>
            <tr>
              <th>
                <span>Month</span>
              </th>
              {caseCodes.map((item, index) => (
                <th key={index} style={{ textAlign: 'center' }}>
                  <span>
                    {item.case_code_abbreviation}
                    <br />
                  </span>
                  <span style={{ fontWeight: 'normal' }}>({item.case_code_title})</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {statsCaseCodes.map((item, index) => {
              const data = item['data'];
              return (
                <tr>
                  <td>
                    <span>{item.month}</span>
                  </td>

                  {data.map((item) => (
                    <td style={{ textAlign: 'center' }}>
                      <span>{item.count}</span>
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default StatisticsIndexCaseCodesPerMonth;
